import { Col, Row, Select } from 'antd';
import React, { Component } from 'react';
import CampaignCarousel from '../../components/carousel/CampaignCarousel';
import { AiOutlineDown } from 'react-icons/ai';

import PublicSale1 from "../../assets/images/dummy-images/PublicSale1.jpg"
import PublicSale2 from "../../assets/images/dummy-images/PublicSale2.jpg"
import PublicSale3 from "../../assets/images/dummy-images/PublicSale3.jpg"
import PublicSale4 from "../../assets/images/dummy-images/PublicSale4.jpg"
import PrivateSale from "../../assets/images/dummy-images/PrivateSale.jpg"

import dummyImage1 from "../../assets/images/dummy-images/Promodex - Facebook - Küçük.png"
import dummyImage2 from "../../assets/images/dummy-images/Avon sample.png"
import dummyImage3 from "../../assets/images/dummy-images/englishhome_gorsel sample.png"
import dummyImage4 from "../../assets/images/dummy-images/Nike banner new sample.png"
import dummyImage5 from "../../assets/images/dummy-images/Shop.com sample.png"
import dummyImage6 from "../../assets/images/dummy-images/tetris_token sample.png"
import dummyImage7 from "../../assets/images/dummy-images/unigrid sample.png"

import moment from 'moment';

import { IdoToken } from '../../components/layout/Icons';
import { getIdos } from './api';
const IdoTokenIcon = IdoToken()

const { Option } = Select;

// This component will get the Ido Token Sale Campaigns data from an api
// And pass that data to CampaignCarousel component
export class IdoTokenSaleCampaign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            idoData: null,
            tempIdoData: null
        }
    }


    timeDifference = (date1, date2) => {
        var difference = date1.getTime() - date2.getTime();

        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24

        var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60

        var minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60

        var secondsDifference = Math.floor(difference / 1000);

        return daysDifference + ' D ' +
            hoursDifference + ' h ' +
            minutesDifference + ' m '
    }
    utcDateConverter = (tempDate) => {
        const date = new Date(tempDate);
        const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

        return new Date(utcDate);
    }

    idoDateCalculator = () => {
        const images = [PublicSale1, PublicSale2, PublicSale3, PublicSale4, PrivateSale]
        const test = this.state.tempIdoData.map((ido, index) => {
            const idoStartDate = this.utcDateConverter(ido.startDate)
            const idoEndDate = this.utcDateConverter(ido.endDate)
            const nowDate = this.utcDateConverter(new Date())
            const idoStartDateTime = idoStartDate.getTime()
            const idoEndDateTime = idoEndDate.getTime()
            const nowDateTime = nowDate.getTime()

            if (idoEndDateTime > nowDateTime) {
                if (idoStartDateTime > nowDateTime) {
                    const upComingCounter = this.timeDifference(idoStartDate, nowDate)
                    ido.status = "Upcoming"
                    ido.counter = upComingCounter
                } else {
                    const openCounter = this.timeDifference(idoEndDate, nowDate)
                    ido.status = "Open"
                    ido.counter = openCounter
                }
            } else {
                const endedCounter = this.timeDifference(idoStartDate, nowDate)
                ido.status = "Delayed"
                ido.counter = '0 D 0 h 0 m'

            }
            ido.img = images[index];
            return ido
        })
        this.setState({ idoData: test })
    }




    getList = async () => {
        const tempIdoData = await getIdos()
        this.setState({ tempIdoData: tempIdoData }, () => {
            this.idoDateCalculator()
        })


    }

    componentDidMount() {
        this.getList();
        setInterval(() => {
            this.getList();
        }, 60000)


    }
    render() {

        return (<div>
            <Row gutter={[4, 8]} className='margin-y-10'>
                <Col xs={{ span: 6, offset: 1 }} md={1}>
                    {IdoTokenIcon}
                </Col>
                <Col xs={{ span: 17 }} md={{ span: 5, offset: 1 }}>
                    <h3 style={{ color: '#4c4c66', fontWeight: '700', marginTop: 6 }}>
                        IDO Token Sale Campaigns
                    </h3>
                </Col>
                <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 6 }} lg={{ span: 2, offset: 7 }} align='middle' className="filter-selectbox">
                    <Select value={this.state.idoDateFilter} defaultValue="Today"
                        onChange={(e) => this.setState({ idoDateFilter: e })}>
                        <Option value="today">Today</Option>
                        <Option value="oneMount">This Week</Option>
                        <Option value="oneYear">This Mount</Option>
                    </Select>
                </Col>
                <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                    <Select value={this.state.idoStatusFilter} defaultValue="Open"
                        onChange={(e) => this.setState({ idoStatusFilter: e })}>
                        <Option value="open">Open</Option>
                        <Option value="upcoming">Upcoming</Option>
                        <Option value="ended">Ended</Option>
                    </Select>
                </Col>
                <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                    <Select value={this.state.idoTypeFilter} defaultValue="all"
                        onChange={(e) => this.setState({ idoTypeFilter: e })}>
                        <Option value="all">All</Option>
                        {/* <Option value="dummy1">Dummy</Option>
                        <Option value="dummy2">Dummy</Option> */}
                    </Select>
                </Col>
            </Row>
            {this.state.idoData &&
                <CampaignCarousel cards={this.state.idoData} />
            }
            <br />
            <br />
        </div>);
    }
}

export default IdoTokenSaleCampaign;

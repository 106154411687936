import React, {Component} from 'react';
import {Button, Checkbox, Col, Input, Row, Select, Upload} from "antd";
import bullhorn from "../../assets/images/icon/bullhorn.png"
import StepsButton from "../../components/steps/StepsButton";
import PromoInput from "../../components/input/PromoInput";
import {AiFillCaretDown, AiOutlineDoubleRight, AiOutlineUpload} from "react-icons/ai";
import TextArea from "antd/es/input/TextArea";
import CreatePromotion from "./CreatePromotion";
import CreateIdo from "./CreateIdo";
import CreateBounty from "./CreateBounty";
import CreateAirdrop from "./CreateAirdrop";



const selectedButtonStyle = {
    backgroundColor: '#6236ff',
    border: '1px solid #a72983',
    transition: 'background 0.4s'
}

class CreatePagesContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: "promotion"
        }
    }

    handleStatusState = (status) => {
        this.setState({status: status})
    }

    handleProjectOnChange = (value) => {
        console.log(`selected ${value}`);
    }
    handleProjectOnBlur = (value) => {
        console.log('blur');
    }
    handleProjectOnFocus = (value) => {
        console.log('focus');
    }
    handleProjectOnSearch = (value) => {
        console.log('search:', value);
    }

    render() {
        return (
            <div>
                <Row gutter={[0, 24]}>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 11, offset: 0}} lg={{span: 5, offset: 0}}
                         style={this.state.status === 'promotion' && selectedButtonStyle}
                         className="steps-div" onClick={() => this.handleStatusState('promotion')}>
                        <StepsButton icon={bullhorn} title=" Create A PROMOTION"
                                     description="Create a promotion campaign by staking reward for promoters."/>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}
                         style={this.state.status === 'ido' && selectedButtonStyle}
                         className="steps-div" onClick={() => this.handleStatusState('ido')}>
                        <StepsButton icon={bullhorn} title=" Create AN IDO"
                                     description="Create an initial token sale by staking your token."/>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 11, offset: 0}} lg={{span: 5, offset: 1}}
                         style={this.state.status === 'bounty' && selectedButtonStyle}
                         className="steps-div" onClick={() => this.handleStatusState('bounty')}>
                        <StepsButton icon={bullhorn} title=" Create A BOUNTY"
                                     description="Create a bounty campaign by staking token for participants."/>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}
                         style={this.state.status === 'airdrop' && selectedButtonStyle}
                         className="steps-div" onClick={() => this.handleStatusState('airdrop')}>
                        <StepsButton icon={bullhorn} title=" Create AN AIRDROP"
                                     description="Create an airdrop campaign by staking token for all members."/>
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 11, offset: 0}} lg={{span: 5, offset: 0}}>
                        {this.state.status === 'promotion' &&
                        <AiFillCaretDown color="#6236ff" size={44}/>
                        }
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}>
                        {this.state.status === 'ido' &&
                        <AiFillCaretDown color="#6236ff" size={44}/>
                        }
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}>
                        {this.state.status === 'bounty' &&
                        <AiFillCaretDown color="#6236ff" size={44}/>
                        }
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}>
                        {this.state.status === 'airdrop' &&
                        <AiFillCaretDown color="#6236ff" size={44}/>
                        }
                    </Col>
                </Row>
                {this.state.status === 'promotion' &&
                <CreatePromotion/>
                }
                {this.state.status === 'ido' &&
                <CreateIdo/>
                }
                {this.state.status === 'bounty' &&
                <CreateBounty/>
                }
                {this.state.status === 'airdrop' &&
                <CreateAirdrop/>
                }
            </div>
        );
    }
}

export default CreatePagesContent;
import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Alert, Button, Col, Divider, Image, Row, Tabs, Checkbox, Input, notification, Modal, Select } from "antd";
import { AllTokenSales } from '../../../components/layout/Icons';

const AllTokenSalesIcon = AllTokenSales()

class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }
  render() {
    return (
      <Col xs={24} lg={{ span: 8, offset: 1 }} style={{marginTop:10}} className={this.props.showSummary ? 'shadow-white-div summary-collapse' : 'shadow-white-div summary-collapse summary-close'}>
        <Row gutter={[8, 16]} className="margin-top-10">
          <Col xs={24} lg={{ span: 11 }}>
            <Row>
              <Col xs={24} align='middle'>
                <p className="investor-title"><span className='icon-inline'>{AllTokenSalesIcon}</span>Staking Reward </p>
              </Col>
            </Row>
            <Row>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>Period/Left:</h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>365/172 days</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>APY: </h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>30%</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>Current reward:</h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>24 Promo</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 6, offset: 0 }}>
                <Button className="margin-top-10 connect-wallet-btn sm-btn" size="small">
                  Withdraw Reward
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={1}>
            <Divider type="vertical" style={{
              borderColor: 'rgba(40,61,92,0.10)',
              height: this.props.innerWidth < 700 ? '0em' : '6em',
              boxShadow: '-2px 0 8px 1px rgba(40,61,92,0.10)',
              display: this.props.innerWidth < 500 && 'none'
            }} />
          </Col>
          <Col sm={{ span: 11, offset: 1 }}>
            <Row>
              <Col xs={24} align='middle'>
                <p className="investor-title"><span className='icon-inline'>{AllTokenSalesIcon}</span> Your participantions </p>
              </Col>
            </Row>
            <Row>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>No of PROMO:</h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>2500</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>Release time left:</h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>7 days</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>Reward loss:</h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>50 Promo</h5>
                  </Col>
                </Row>
              </Col>
              <Col sm={{ span: 6, offset: 0 }}>
                <Button className="margin-top-10 connect-wallet-btn sm-btn" size="small">
                  Cancel Unstaking
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }
}


const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(Information));

import { publicApi, protectedApi } from '../../services/axios'


export const getIdo = (id) => {
  return publicApi.get(`/management/ido/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export const createTransaction = (payload) => {
  return publicApi.post(`/management/salelogs`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export const getReport = (idoID) => {
  return publicApi.get(`/management/salelogreport/?projectId=1&idoId=` + idoID)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
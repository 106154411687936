import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../../../../redux/promodex/actions";
import { Button, Col, Row, Checkbox } from "antd";

import { WalletBlack } from '../../../../../components/layout/Icons';
import { userAgreement } from '../../../../../utils/Constants';
const WalletBlackIcon = WalletBlack()

class WithdrawPreparing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStakeable: true,
    }
  }


  render() {

    const GreenCircleCheckmarkIcon = this.props.GreenCircleCheckmarkIcon

    const RedCircleCrossmarkIcon = this.props.RedCircleCrossmarkIcon

    const BottomButtons = this.props.BottomButtons



    return (
      <div>
        <Row gutter={[16, 16]} className='margin-y-40'>
          <Col xs={24}>
            <p style={{
              fontWeight: 100,
              backgroundColor: 'rgba(254, 0, 94, 0.1)',
              borderRadius: '8px 8px 8px 0 8px',
              padding: '10px 4px'
            }}>
              <span className='icon-inline' style={{ marginRight: 30 }}>{WalletBlackIcon}</span>
              After unstaking you must wait 7 days for withdrawal of your PROMO & Rewards unstaked tokens are not counted for your TIER level
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} lg={6}>
            <div className='stake-promo-steps-div' style={{ height: 240 }}>
              <Row align="bottom"
                style={{
                  borderBottom: '1px solid rgba(40,61,92,0.50)',
                  padding: '4px 4px'
                }}>
                <Col sm={15} align='right' value={50}>
                  <p style={{ fontWeight: 700 }}>Wallet connection</p>
                </Col>
                <Col sm={9} align='right' value={100}>
                  {this.props.promodexInformation?.metamaskAccount !== undefined ?
                    <>{GreenCircleCheckmarkIcon}</>
                    :
                    <>{RedCircleCrossmarkIcon}</>

                  }
                </Col>
              </Row>
              <Row className='margin-top-20' align='middle'>
                <Col sm={{ span: 24 }} align='center'>
                  <br /><br />
                  {this.props.promodexInformation?.metamaskAccount !== undefined ?
                    <p style={{ marginBottom: 32 }}>Your wallet is connected</p>
                    :
                    <p style={{ marginBottom: 32 }}>Please connect your wallet</p>
                  }
                  <br /><br />
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12} lg={6}>
            <div className='stake-promo-steps-div' style={{ height: 240 }}>
              <Row align="bottom"
                style={{
                  borderBottom: '1px solid rgba(40,61,92,0.50)',
                  padding: '4px 4px'
                }}>
                <Col sm={15} align='right' value={50}>
                  <p style={{ fontWeight: 700 }}>Promo for staking</p>
                </Col>
                <Col sm={9} align='right' value={100}>
                  {this.props.promodexInformation?.metamaskAccount !== undefined ?
                    <>{GreenCircleCheckmarkIcon}</>
                    :
                    <>{RedCircleCrossmarkIcon}</>
                  }
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 24 }} align='center'>
                  <br /><br />
                  <p>Amount of PROMO in wallet</p>
                  <Row gutter={[24, 24]}>
                    <Col sm={{ span: 8, offset: 4 }} align='right'>
                      <p style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>0</p>
                    </Col>
                    <Col sm={8} align='right'>
                      <p style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>PROMO</p>
                    </Col>
                  </Row>
                </Col>

              </Row>

            </div>
            <Col sm={{ span: 16, offset: 8 }} md={{ span: 12, offset: 12 }} align='center' className='margin-y-10'>
              <Button className="connect-wallet-btn" size="small" style={{ borderRadius: 20 }}>
                Buy Promo
              </Button>
            </Col>
          </Col>
          <Col xs={24} md={12} lg={6}>
            <div className='stake-promo-steps-div' style={{ height: 240 }}>
              <Row align="bottom"
                style={{
                  borderBottom: '1px solid rgba(40,61,92,0.50)',
                  padding: '4px 4px'
                }}>
                <Col sm={15} align='right' value={50}>
                  <p style={{ fontWeight: 700 }}>BNB in wallet</p>
                </Col>
                <Col sm={9} align='right' value={100}>
                  {this.props.promodexInformation?.metamaskAccount !== undefined ?
                    <>{GreenCircleCheckmarkIcon}</>
                    :
                    <>{RedCircleCrossmarkIcon}</>
                  }
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 24 }} align='center'>
                  <br />
                  <p>You need BNB for transaction fee on blockchain</p>
                  <Row gutter={[24, 24]}>
                    <Col sm={{ span: 8, offset: 4 }} align='right'>
                      <p style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>0.001</p>
                    </Col>
                    <Col sm={8} align='right'>
                      <p style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>BNB</p>
                    </Col>
                  </Row>
                </Col>

              </Row>
            </div>
            <Col sm={{ span: 16, offset: 8 }} md={{ span: 12, offset: 12 }} align='center' className='margin-y-10'>
              <Button className="connect-wallet-btn" size="small" style={{ borderRadius: 20 }}>
                Buy BNB
              </Button>
            </Col>
          </Col>
          <Col xs={24} md={12} lg={6}>
            <div className='stake-promo-steps-div' style={{ height: 240 }}>
              <Row align="bottom"
                style={{
                  borderBottom: '1px solid rgba(40,61,92,0.50)',
                  padding: '4px 4px'
                }}>
                <Col sm={15} align='right' value={50}>
                  <p style={{ fontWeight: 700 }}>Eligible to unstake</p>
                </Col>
                <Col sm={9} align='right' value={100}>
                  {this.props.promodexInformation?.metamaskAccount !== undefined ?
                    <>{GreenCircleCheckmarkIcon}</>
                    :
                    <>{RedCircleCrossmarkIcon}</>
                  }
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 24 }} align='center'>
                  <br /><br />
                  <p>You have an active staking periods ends in</p>
                  <Row gutter={[24, 24]}>
                    <Col sm={{ span: 8, offset: 4 }} align='right'>
                      <p style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>6</p>
                    </Col>
                    <Col sm={8} align='right'>
                      <p style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>Days</p>
                    </Col>
                  </Row>
                </Col>

              </Row>
            </div>
            <Col sm={{ span: 20, offset: 4 }} md={{ span: 15, offset: 9 }} align='center' className='margin-y-10'>
              <Button className="connect-wallet-btn" size="small" style={{ borderRadius: 20 }}>
                Cancel Unstaking
              </Button>
            </Col>
          </Col>
        </Row>
        <Row gutter={[24, 48]} className='margin-y-40'>
          <Col sm={{ span: 24 }} align='center'>
            <Checkbox name="stakeReadConditions" onClick={(event) => this.props.handleWithdrawCondition(event)} >I read the <a target='_blank' href={userAgreement} style={{ color: '#5340FF' }} >terms and conditions</a></Checkbox>
          </Col>
          <Col sm={{ span: 24 }} align='center'>
            <a onClick={() => this.props.changeTab('2')} className="colorful-button nowrap" style={{ paddingLeft: 40, paddingRight: 40 }}>
              Start withdrawing
            </a>
          </Col>
          <Col sm={{ span: 24 }} align='center'>
            {this.state.isStakeable === false &&
              <p style={{
                color: '#FE005E',
                fontWeight: 100,
                textAlign: 'center'
              }}>Please complete all the checkpost before start staking</p>
            }
          </Col>
        </Row>
        {BottomButtons}
      </div>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(WithdrawPreparing));


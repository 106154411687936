import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Button, Table, Select, Popconfirm, Spin } from 'antd';
import BorderTextInput from '../../../components/input/BorderTextInput';
import { createProjectLink, deleteProjectLink, getLinkTypes, getProjectLinks } from '../api';
import BorderTextSelectbox from '../../../components/input/BorderTextSelectbox';

import { AiOutlineDelete } from 'react-icons/ai'
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;


class ProjectLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkTypes: [],
      link: null,
      url: null,
      dataSource: [],
      columns: [
        {
          title: 'Link',
          dataIndex: 'link',
          key: 'link',
        },
        {
          title: 'URL',
          dataIndex: 'url',
          key: 'url',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Popconfirm title='Sure to delete?' onConfirm={() => this.handleDelete(record.id)} size="middle">
              <a color='#5340FF'><AiOutlineDelete size={18} style={{ color: '#5340FF' }} /></a>
            </Popconfirm>
          ),
        },
      ],
      loading: false,
    }
  }

  componentDidMount() {
    this.getLinkTypes()
  }

  // Get Project links for the table 
  getLinks = async () => {
    try {
      // send request to get link by project id
      const projectLinks = await getProjectLinks(this.props.promodexInformation.project.id)

      let tempArr = []

      for (let i = 0; i < projectLinks.length; i++) {
        // create a data for table that includes link info.
        let data = {
          key: i + 1,
          link: this.convertIdToLink(projectLinks[i].linkType),
          url: projectLinks[i].url,
          id: projectLinks[i].id
        }
        tempArr.push(data)

      }
      // push data to state
      this.setState({
        dataSource: tempArr
      })

    } catch (ex) {
      console.log(ex)
    }
  }

  convertIdToLink = (id) => {
    const link = this.state?.linkTypes?.filter(data => data.id === id)[0]
    return link.linkTypeDesc
  }

  getLinkTypes = async () => {
    const linkTypes = await getLinkTypes()
    this.setState({ linkTypes: linkTypes })
  }

  handleDelete = async (id) => {
    try {
      const removeLink = await deleteProjectLink(id)
      this.getLinks()
    } catch (ex) {
      console.log(ex)
    }

  }


  handleAddLink = async () => {
    if (this.state.url === '') {
      this.props.openNotification('warning', 'No url found', 'Please write your url !')
      return
    }
    if (this.state.link === null) {
      this.props.openNotification('warning', 'No link found', 'Please select your link !')
      return
    }
    if (this.props.promodexInformation.project === null) {
      this.props.openNotification('warning', 'No project found', 'You must save your project information first !')
      return
    }

    const payload = {
      url: this.state.url,
      linkType: this.state.link,
      projectId: this.props.promodexInformation.project.id
    }

    try {
      const projectLinks = await createProjectLink(payload)
      this.getLinks()
      // this.props.setPromodexRedux({ project: createProject_ })
      // this.props.handleSteps(1)

    } catch (error) {
      console.log('ERROR : ', error)
    }

  }

  saveAndNext = () => {
    // if(this.state.dataSource.length === 0){
    //   this.props.openNotification('warning', 'No Link Found', 'You must add link if you want to save links!')
    //   return
    // }
    this.setState({loading: true}, () => {
      setTimeout(() => {
        this.setState({loading: false} , () => {
          this.props.openNotification('success', 'Links Saved', 'Links are successfuly saved!')
          this.props.handleSteps(this.props.currentStep + 1)
        })
      },3000)
    })
  }



  render() {
    const linkOptions = this.state?.linkTypes?.map((link, index) => {
      return (
        <Option key={index} value={link.id}>{link.linkTypeDesc}</Option>
      )
    })

    return (
      <div style={this.props.style}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
          <Row className='margin-top-20'>
            <Col xs={24} className='shadow-white-div'>
              <Row gutter={[24, 24]} align='middle'>
                <Col xs={24}>
                  <p style={this.props.titleStyle}>Project / Brand Links</p>
                </Col>
                <Col xs={24} lg={8} >
                  <BorderTextSelectbox labelText={'Add a New Link'} select={
                    <Select value={this.state.tags} onChange={(event) => this.setState({ link: event })}
                      style={{ width: '100%' }} size='large' defaultValue={undefined}>
                      {linkOptions}
                    </Select>
                  } />
                </Col>
                <Col xs={24} lg={8}>
                  <BorderTextInput onChange={(event) => this.setState({ url: event.target.value })}
                    labelText={'Add URL'} size='large' />
                </Col>
                <Col xs={24} lg={{ span: 6, offset: 2 }}>
                  <Button onClick={this.handleAddLink} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Add a new link
                  </Button>
                </Col>
              </Row>
              <Row className='margin-top-20'>
                <Col xs={24}>
                  <Table scroll={{ x: 1000 }} size='small' className='ant-table-strip'
                    showHeader={false} pagination={false} dataSource={this.state.dataSource} columns={this.state.columns} />
                </Col>
              </Row>
              <Row className='margin-top-20'>
                <Col xs={{ span: 10 }} md={{ span: 4 }} align='left'>
                  <Button onClick={() => this.props.handleSteps(this.props.currentStep - 1)} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Previus
                  </Button>
                </Col>
                <Col xs={{ span: 10, offset: 4 }} md={{ span: 4, offset: 16 }} align='right'>
                  <Button onClick={this.saveAndNext} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Save & Next
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ProjectLink));
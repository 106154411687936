import React, {Component} from 'react';
import {Col, Row} from "antd";
import {AiFillCaretDown} from "react-icons/ai";

class StepsButton extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs={4}>
                        <img src={this.props.icon} width={32} height={32}/>
                    </Col>
                    <Col xs={20}>
                        <h3>{this.props.title}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <h3>{this.props.description}</h3>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default StepsButton;
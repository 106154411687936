import React, {Component} from 'react';
import PromodexLayout from "../../components/layout/PromodexLayout";
import CreatePagesContent from "./CreatePagesContent";

class CreatePagesLayout extends Component {
    render() {
        return (
            <div>
                <PromodexLayout page='create' content={<CreatePagesContent/>}/>
            </div>
        );
    }
}

export default CreatePagesLayout;
import React, {Component} from 'react';
import {Col, Row, Table, Image} from "antd";
import {MdOutlineLocalOffer} from "react-icons/md";
import {FiLayers} from "react-icons/fi";

import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (r) => <h5 className='p-font-color'>{r}</h5>
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Project',
        dataIndex: 'project',
        key: 'project',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: () => <h5 className='p-font-color'>details</h5>
    },
];

const data = [
    {
        id: 'A25FB4',
        date: '23 Oct. 2021',
        project: "Eagleswap",
        type: 'Bounty',
        status: 'Pre-Success',
        amount: '517 PROMO',
    },
    {
        id: 'A25FB4',
        date: '23 Oct. 2021',
        project: "Eagleswap",
        type: 'IDO',
        status: 'Pre-Success',
        amount: '517 PROMO',
    },
    {
        id: 'A25FB4',
        date: '23 Oct. 2021',
        project: "Eagleswap",
        type: 'Campaign',
        status: 'Pre-Success',
        amount: '517 PROMO',
    },
];

class ActivityContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fadingOut: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({fadingOut: true})
        },1)
    }


    render() {
        return (
            <>
                {this.state.fadingOut &&
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        textAlign: 'center',
                        fontSize:24,
                        zIndex:20,
                        color:'#5340ff'
                    }}>
                        COMING SOON...
                    </div>
                }
                <div style={this.state.fadingOut ? {filter: 'blur(4px)', transition: 'filter 4s', pointerEvents: 'none', userSelect: 'none'} : undefined }>

                    <div className="shadow-white-div">
                        <Row>
                            <Col xl={{span:24}}>
                                <h3 className='p-font-bold'>
                                    <FiLayers size={22} className="vertical-align-text-bottom"/> MY PARTICIPATIONS &nbsp;&nbsp; &nbsp;&nbsp;
                                    <span style={{color: '#6b03fe'}}>{data.length}</span>
                                </h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={24}>
                                <Table size='small' scroll={{x:800}} loading={false} bordered={false} showHeader={true}
                                       pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
                                       columns={columns} dataSource={data} />
                            </Col>
                        </Row>
                    </div>
                    <div className="shadow-white-div">
                        <Row>
                            <Col xl={24}>
                                <h3 className='p-font-bold'>
                                    <MdOutlineLocalOffer size={22} className="vertical-align-text-bottom"/> MY OFFERINGS &nbsp;&nbsp; &nbsp;&nbsp;
                                    <span style={{color: '#6b03fe'}}>{data.length}</span>
                                </h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={24}>
                                <Table size='small' scroll={{x:800}} loading={false} bordered={false} showHeader={true}
                                       pagination={{ defaultPageSize: 10, showSizeChanger: false, pageSizeOptions: ['10', '20', '30']}}
                                       columns={columns} dataSource={data} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

export default ActivityContent;
import React, { Component } from 'react';
//import img from '../../assets/images/Hakan_Unal_greybg512px-115x115.png'
import { Button, Card, Col, Row } from "antd";
//import Meta from "antd/es/card/Meta";
import { Link } from "react-router-dom";

class DashboardCard extends Component {
    render() {
        return (
            <Card
                hoverable
                className="dashboard-card shadow-with-border"
                onClick={() => this.seeDetails.click()}
                style={{ borderRadius: '10px', marginLeft: this.props.innerWidth !== 0 && '10px', marginRight: this.props.innerWidth !== 0 && '10px' }}
                cover={
                    <>
                        <p style={{
                            position: 'absolute',
                            top: 8,
                            left: 8,
                            zIndex: 10,
                            color: "#ffffff",
                            backgroundColor: this.props.status === "Open" ? "#5dab22" : this.props.status === "Upcoming" ? "#c68b0f" : this.props.status === "Ended" ? "#bf054b" : "#bf054b",
                            width: 80,
                            textAlign: 'center',
                            borderRadius: 4
                        }}>
                            {this.props.status}
                        </p>
                        <img src={this.props.src} alt="test" />
                    </>}
            >
                <Row>
                    <Col xs={12} align="left">
                        <h4 className="p-font-bold" style={{ fontSize: '14px' }}>{this.props.companyName}</h4>
                    </Col>
                    <Col xs={12} align="right">
                        <h4 className="p-font-bold" style={{ fontSize: '14px' }}>{this.props.productName}</h4>
                    </Col>
                    <Col xs={12} align="left">
                        <h5>{this.props.publicStatus}</h5>
                    </Col>
                    <Col xs={12} align="right">
                        <h5>$ {this.props.price}</h5>
                    </Col>
                    <Col xs={24} align="center" style={{ marginTop: 20 }}>
                        {this.props.status === "Upcoming" ? "Token Sale Start in" :
                            this.props.status === "Open" ? "Token Sale End in" : this.props.status === "Ended" ? "Token Sale Delayed" : 'Promotion Campaign'}
                        <br />
                        <span className="p-font-bold">{this.props.counter}</span>
                    </Col>
                    <Col xs={24} align="center">
                        <Link to={"sale?id=" + this.props.id + "&status=" + this.props.status + "&idoname=" + this.props.saleType + "&idoType=" + this.props.idoType}>
                            <Button ref={(ref) => this.seeDetails = ref} className="margin-top-10 connect-wallet-btn" size="small">
                                See Details
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default DashboardCard;
import React, {Component} from 'react';
import {Alert, Button, Checkbox, Col, Row, Select} from "antd";
import PromoInput from "../../components/input/PromoInput";
import Modal from "antd/es/modal/Modal";
import {AiOutlineDoubleRight, AiOutlineDown} from "react-icons/ai";

const CheckboxGroup = Checkbox.Group;

const { Option } = Select;

const termsAndConditions = [
    'Please read the Terms and Conditions then agree to all the following to proceed!',
    'I have tested my token with the PROMODEX app on a test network (Ex. Ropsten)',
    'My token has a function to disable special transfers or has no special transfers',
    'My token is not already listed on PancakeSwap and i have not given out any tokens to users',
    'I understand fees paid to launch a SALE are non-recoverable',
    'I understand that i have to finalize my sale within 48 hours of hitting the hardcap!',
    'I am using PROMODEX as a software tool only and am alone responsible for anything i create on it',
    'I understand that i am responsible for following my local laws and regulations including KYC and AML practices',
    'I have read and agree to the terms and conditions',
];


class CreateAirdrop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkedList: [],
            indeterminate: false,
            checkAll: false,
            showAlert: false,
        }
    }


    showConfirmTermsModal = (status) => {
        this.setState({confirmTermsModal: status, isConfirmTerms: false})
    }

    handleConfirmCheckbox = () => {
        if (this.state.isConfirmTerms) {
            this.setState({isConfirmTerms: false})
        } else {
            this.showConfirmTermsModal(true)
        }
    }

    handleCheckboxGroupOnChange = list => {
        this.setState({
            checkedList: list,
            indeterminate: !!list.length && list.length < termsAndConditions.length,
            checkedAll: list.length === termsAndConditions.length
        })
    };

    onCheckAllChange = (e) => {
        this.setState({
            checkedList: e.target.checked ? termsAndConditions : [],
            indeterminate: false,
            checkAll: e.target.checked
        })
    };


    handleConfirmButton = () => {
        if (this.state.checkedList.length === termsAndConditions.length) {
            this.setState({isConfirmTerms: true, confirmTermsModal: false})
        } else {
            this.setState({showAlert: true})
        }
    }

    render() {
        return (
            <>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>GENERAL</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}>
                                <Row>
                                    <Col span={20}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Project
                                        </small>
                                    </Col>
                                    <Col span={20}>
                                        <Select className='select-style' defaultValue="promodex"
                                                suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                onChange={(e) => this.setState({selectBox1: e.target.value})}>
                                            <Option value="promodex">PROMODEX</Option>
                                            <Option value="bnc-smart-chain">Binance Smart Chain</Option>
                                            <Option value="dummy">Dummy</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}}>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}}>
                                <PromoInput isHorizontalLabel={false} labelText="Name of Airdrop"
                                           placeholder="Define a name for your airdrop campaign"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>RECEIVER PROFILE</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}>
                                <Row>
                                    <Col span={20}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Participants (who can participate the sale)
                                        </small>
                                    </Col>
                                    <Col span={20}>
                                        <Select className='select-style' defaultValue="members"
                                                suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                onChange={(e) => this.setState({selectBox1: e.target.value})}>
                                            <Option value="members">MEMBERS</Option>
                                            <Option value="bnc-smart-chain">Binance Smart Chain</Option>
                                            <Option value="dummy">Dummy</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}}>
                                <Row>
                                    <Col span={20}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Definition of participant
                                        </small>
                                    </Col>
                                    <Col span={20}>
                                        <Select className='select-style'
                                                defaultValue="kycPassed"
                                                suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                onChange={(e) => this.setState({selectBox1: e.target.value})}>
                                            <Option value="kycPassed">KYC PASSED</Option>
                                            <Option value="bnc-smart-chain">Binance Smart Chain</Option>
                                            <Option value="dummy">Dummy</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}}>
                                <PromoInput isHorizontalLabel={false} labelText="Number of matching profile" type="number"
                                           placeholder="Define a name for your bounty campaign"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} className="shadow-white-div-margin-10">
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>DISCLAMER</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 20, offset: 1}}>
                                <p style={{marginLeft: '8px'}}>
                                    Disclaimer: This process is entirely decentralized, we cannot be held reponsible
                                    for incorrect entry of information or be held liable for anything related to
                                    your use of our platform. Please ensure you enter all your details to the best
                                    accuracy possible and that you are in
                                    compliance with your local laws and regulations. This is a beta version! We
                                    cannot guarantee there will be no bugs. Use at your own risk! For tokens with
                                    burns, rebase or other special transfers please ensure you have a way to
                                    whitelist multiple addresses or turn off the
                                    special
                                    transfer events (By setting fees to 0 for example for the duration of the
                                    presale
                                </p>
                            </Col>
                        </Row>
                        <Row className="margin-top-20">
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <Checkbox style={{marginLeft: '8px'}} value={this.state.isConfirmTerms}
                                          checked={this.state.isConfirmTerms}
                                          onClick={this.handleConfirmCheckbox}>
                                    CONFIRM TERMS AND CONDITIONS
                                </Checkbox>
                            </Col>
                        </Row>
                        <Modal title="TERMS AND CONDITIONS" visible={this.state.confirmTermsModal}
                               onOk={() => this.showConfirmTermsModal(false)}
                               onCancel={() => this.showConfirmTermsModal(false)}
                               footer={[
                                   <Button style={{color: '#ffffff', backgroundColor: '#e02020'}} key="cancel"
                                           size="large" onClick={() => this.showConfirmTermsModal(false)}>
                                       CANCEL
                                   </Button>,
                                   <Button style={{color: '#ffffff', backgroundColor: '#6dd400'}} key="submit"
                                           size="large" onClick={this.handleConfirmButton}>
                                       CONFIRM
                                   </Button>,
                               ]}>
                            <div className="checkbox-group-vertical">
                                <CheckboxGroup options={termsAndConditions} value={this.state.checkedList}
                                               onChange={this.handleCheckboxGroupOnChange}/>
                                <Checkbox indeterminate={this.state.indeterminate} defaultChecked={false}
                                          onChange={this.onCheckAllChange}
                                          checked={this.state.checkAll}>
                                    Agree to all
                                </Checkbox>
                                {this.state.showAlert &&
                                <Alert style={{marginTop: '10px'}}
                                       message="You cannot confirm until you accept all the terms." type="error"
                                       showIcon/>
                                }
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>MY WALLET</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}} className="select-after-borderless">
                                <Row>
                                    <Col span={24}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Blockchain Network
                                        </small>
                                    </Col>
                                    <Col className="label-select" xs={10} md={8} align='right'>
                                        <Select defaultValue="1"
                                                style={{pointerEvents: 'none', color: '#ffffff'}}>
                                            <Option value="1">CONNECTED TO</Option>
                                        </Select>
                                    </Col>
                                    <Col className="select-with-box" xs={10} md={16}>
                                        <Select defaultValue="bnc-smart-chain"
                                                suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                onChange={(e) => this.setState({selectBox1: e.target.value})}>
                                            <Option value="all">All</Option>
                                            <Option value="bnc-smart-chain">Binance Smart Chain</Option>
                                            <Option value="dummy">Dummy</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}}>
                                <PromoInput isHorizontalLabel={false} labelText="Your Wallet address"
                                           value='0xf9e0B45e14D47DB8bc0E0c8c26E221'/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}} className='select-after-borderless'>
                                <PromoInput isHorizontalLabel={false} labelText="Wallet Balance"
                                           value='50,000'
                                           addonAfter={
                                               <Select defaultValue="promo" className="select-after">
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="dummy">Dummy</Option>
                                                   <Option value="dummy2">Dummy2</Option>
                                                   <Option value="dummy3">Dummy3</Option>
                                               </Select>
                                           }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>GENERAL</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}} className="select-after-borderless">
                                <PromoInput isHorizontalLabel={false} labelText="Total amount of token for airdrop"
                                           placeholder="Total token amount for airdrop ex:20000"
                                           addonAfter={
                                               <Select defaultValue="promo" className="select-after">
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="bnb">BNB</Option>
                                               </Select>}/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}}>
                                <Row>
                                    <Col span={20}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Time Zone
                                        </small>
                                    </Col>
                                    <Col span={20}>
                                        <Select className='select-style' defaultValue="istanbul"
                                                suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                onChange={(e) => this.setState({selectBox1: e.target.value})}>
                                            <Option value="istanbul">(GMT+3) ISTANBUL</Option>
                                            <Option value="dummy">Dummy</Option>
                                            <Option value="dummmy2">Dummy2</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}}>
                                <PromoInput isHorizontalInput={false} labelText="Date of airdrop"
                                           value={this.state.airdropDate}
                                           type="datetime-local"
                                           onChange={(event) => this.setState({airdropDate: event.target.value})}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>TOKEN</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}} className="select-after-borderless">
                                <PromoInput isHorizontalLabel={false} labelText="Token airdrop for each wallet"
                                           placeholder="Token reward per point ex:25"
                                           addonAfter={
                                               <Select defaultValue="promo" className="select-after">
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="bnb">BNB</Option>
                                               </Select>}/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}} className="select-after-borderless">
                                <PromoInput isHorizontalLabel={false} labelText="Minimum token reward per participant"
                                           placeholder="Min. token reward ex:20"
                                           addonAfter={
                                               <Select defaultValue="promo" className="select-after">
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="bnb">BNB</Option>
                                               </Select>}/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7}} className="select-after-borderless">
                                <PromoInput isHorizontalLabel={false} labelText="Maximum token reward per participant"
                                           placeholder="Max. token reward ex:100"
                                           addonAfter={
                                               <Select defaultValue="promo" className="select-after">
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="bnb">BNB</Option>
                                               </Select>}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} className="shadow-white-div-margin-10">
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>COST OF SERVICE</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                 className='select-after-borderless'>
                                <PromoInput isHorizontalLabel={false}
                                           labelText="NO - AMOUNT - UNIT"
                                           placeholder="ex: 1000"
                                           addonBefore={
                                               <Select defaultValue="1"
                                                       style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                   <Option value="1">1.</Option>
                                               </Select>
                                           }
                                           addonAfter={
                                               <Select defaultValue="bnb" className="select-after">
                                                   <Option value="bnb">BNB</Option>
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="token">TOKEN</Option>
                                                   <Option value="dummy">Dummy</Option>
                                               </Select>
                                           }/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                 className='select-after-borderless'>
                                <PromoInput isHorizontalLabel={false}
                                           labelText="NO - AMOUNT - UNIT"
                                           placeholder="ex: 1000"
                                           addonBefore={
                                               <Select defaultValue="1"
                                                       style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                   <Option value="1">1.</Option>
                                               </Select>
                                           }
                                           addonAfter={
                                               <Select defaultValue="promo" className="select-after">
                                                   <Option value="bnb">BNB</Option>
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="token">TOKEN</Option>
                                                   <Option value="dummy">Dummy</Option>
                                               </Select>
                                           }/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                 className='select-after-borderless'>
                                <PromoInput isHorizontalLabel={false}
                                           labelText="NO - AMOUNT - UNIT"
                                           placeholder="ex: 1000"
                                           addonBefore={
                                               <Select defaultValue="1"
                                                       style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                   <Option value="1">1.</Option>
                                               </Select>
                                           }
                                           addonAfter={
                                               <Select defaultValue="token" className="select-after">
                                                   <Option value="bnb">BNB</Option>
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="token">TOKEN</Option>
                                                   <Option value="dummy">Dummy</Option>
                                               </Select>
                                           }/>
                            </Col>
                        </Row>
                        <Row className="margin-top-20">
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <Checkbox>CONFIRM THE COST OF SERVICE AS</Checkbox>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} className="shadow-white-div-margin-10">
                        <Row align="center">
                            <Col className="margin-y-10" xs={{span: 20, offset: 1}} md={{span: 6, offset: 1}}
                                 align="middle">
                                <Button size="large" block className="promo-btn p-font-bold">CHECK</Button>
                            </Col>
                            <Col className="margin-y-10" md={{span: 2}} align="middle">
                                <AiOutlineDoubleRight size={30}/>
                            </Col>
                            <Col className="margin-y-10" xs={{span: 20, offset: 0}} md={{span: 6, offset: 0}}
                                 align="middle">
                                <Button size="large" block className="promo-btn p-font-bold">CONFIRM</Button>
                            </Col>
                            <Col className="margin-y-10" md={{span: 2}} align="middle">
                                <AiOutlineDoubleRight size={30}/>
                            </Col>
                            <Col className="margin-y-10" xs={{span: 20, offset: 0}} md={{span: 6, offset: 0}}
                                 align="middle">
                                <Button size="large" block className="promo-btn p-font-bold">APPROVE</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

export default CreateAirdrop;
import React, { Component } from 'react';
import { Row, Col, Card, Select, Image } from 'antd';
import DashboardCard from '../../components/card/DashboardCard'
import { Link } from 'react-router-dom';
import { AiOutlineDown } from 'react-icons/ai';
import { MyCampaign_ } from '../../components/layout/Icons';

import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"


const MyCampaignIcon = MyCampaign_()

const { Option } = Select;

export default class MyCampaign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth: 0,
            showSample: false
        }
    }

    componentDidMount() {
        this.setState({ innerWidth: window.innerWidth })
        setInterval(() => {
            this.setState({ innerWidth: window.innerWidth })
        }, 500)
    }
    render() {
        return <>
            <div className={this.state.showSample ? 'show-sample-div show-active' : 'show-sample-div'} onClick={() => this.setState({ showSample: !this.state.showSample })}>
                {this.state.showSample ? 'Hide Sample' : 'Show Sample'}
            </div>
            <div style={{ filter: this.state.showSample ? '' : 'blur(4px)', transition: 'filter .3s', pointerEvents: 'none', userSelect: 'none' }}>
                <Row gutter={[4, 8]} className='margin-y-10'>
                    <Col xs={{ span: 6, offset: 1 }} md={1}>
                        {MyCampaignIcon}
                    </Col>
                    <Col xs={{ span: 17 }} md={{ span: 5, offset: 1 }}>
                        <h3 style={{ color: '#4c4c66', fontWeight: '700', marginTop: 6 }}>
                            My Campaigns
                        </h3>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 6 }} lg={{ span: 2, offset: 7 }} align='middle' className="filter-selectbox">
                        <Select value={this.state.idoDateFilter} defaultValue="Today"
                            suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                            onChange={(e) => this.setState({ idoDateFilter: e })}>
                            <Option value="today">Today</Option>
                            <Option value="oneMount">1 Mount</Option>
                            <Option value="oneYear">1 Year</Option>
                        </Select>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                        <Select value={this.state.idoStatusFilter} defaultValue="Ongoing"
                            suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                            onChange={(e) => this.setState({ idoStatusFilter: e })}>
                            <Option value="ongoing">Ongoing</Option>
                        </Select>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                        <Select value={this.state.idoTypeFilter} defaultValue="Ongoing"
                            suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                            onChange={(e) => this.setState({ idoTypeFilter: e })}>
                            <Option value="all">All</Option>
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col xs={1}>
                    </Col>
                    <Col xs={{ span: 16, offset: 0 }}>
                        <Link to='/'>
                            <Card
                                hoverable style={{ height: '240px', width: '180px', border: '2px dashed #afafba', marginLeft: 20 }}>
                                <h4 className="vertical-center" style={{ textAlign: 'center', marginRight: 16, color: '#9494a3' }}>
                                    <strong>+</strong><br />Create New Campaign</h4>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </div>
        </>;
    }
}

import React, {Component} from 'react';
import {Row, Col, Alert, Button, Tabs, Table} from "antd";
import {FiCopy} from "react-icons/fi";
import {AiOutlinePlusCircle, AiOutlineSearch} from "react-icons/ai";
import {FcHighPriority, FcOk} from "react-icons/fc";
import checkmark from "../../assets/images/icon/checkmark.png"

const { TabPane } = Tabs;


const columns = [
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },
    {
        key: 'userNumber',
        title: 'User number',
        dataIndex: 'userNumber',
    },
    {
        key: 'influencer',
        title: 'Influencer',
        dataIndex: 'influencer',
        render: tag => {
            if(tag === true){
                return <img src={checkmark} width={24} height={24}/>
            }else {
                return <h4> <FcHighPriority/></h4>
            }
        }
    },
    {
        key: 'investor',
        title: 'Investor',
        dataIndex: 'investor',
        render: tag => {
            if(tag === true){
                return <img src={checkmark} width={24} height={24}/>
            }else {
                return <h4><FcHighPriority/></h4>
            }
        }
    },
    {
        key: 'campaigner',
        title: 'Campaigner',
        dataIndex: 'campaigner',
        render: tag => {
            if(tag === true){
                return <img src={checkmark} width={24} height={24}/>
            }else {
                return <h4> <FcHighPriority/></h4>
            }
        }
    },
];

const data = [
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: true,
        investor: false,
        campaigner: true,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: false,
        investor: true,
        campaigner: true,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: true,
        investor: false,
        campaigner: false,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: false,
        investor: false,
        campaigner: false,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: true,
        investor: false,
        campaigner: true,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: false,
        investor: true,
        campaigner: false,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: false,
        investor: true,
        campaigner: true,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: true,
        investor: false,
        campaigner: false,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: false,
        investor: false,
        campaigner: false,
    },
    {
        date: '25 Oct. 2021',
        userNumber: '1A2C345AE',
        influencer: false,
        investor: true,
        campaigner: false,
    },
];


class ReferralsContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCopied: false,
            fadingOut: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({fadingOut: true})
        },1)
    }

    handleCopy = () => {
        this.setState({isCopied: true}, () => {
            setTimeout(() => {
                this.setState({isCopied: false})
            },3000)
        })
    }

    render() {
        return (
            <>
            {this.state.fadingOut &&
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        textAlign: 'center',
                        fontSize:24,
                        zIndex:20,
                        color:'#5340ff'
                    }}>
                        COMING SOON...
                    </div>
                }
            
            <div style={this.state.fadingOut ? {filter: 'blur(4px)', transition: 'filter 4s', pointerEvents: 'none', userSelect: 'none'} : undefined }>
                <Row gutter={[24,8]}>
                    <Col xs={24} md={12} lg={12}>
                        <div className="shadow-white-div">
                            <Row>
                                <Col xs={{span:22, offset:1}}>
                                    <h4 className="p-font-bold">My Referrals</h4>
                                </Col>
                            </Row>
                            <Row className="shadow-white-div" style={{padding:'0px 0px'}}>
                                <Col xs={12} md={5} lg={{span:6, offset:1}} style={{textAlign:'center'}}>
                                    <h4>Total No. of Friends</h4>
                                    <h4>12</h4>
                                </Col>
                                <Col xs={12} md={{span:5, offset:4}} lg={{span:5, offset:2}} style={{textAlign:'center'}}>
                                    <h4>Promoter</h4>
                                    <h4>5</h4>
                                </Col>
                                <Col xs={12} md={5} lg={{span:5}} style={{textAlign:'center'}}>
                                    <h4>Investor</h4>
                                    <h4>3</h4>
                                </Col>
                                <Col xs={12} md={5} lg={{span:5}} style={{textAlign:'center'}}>
                                    <h4>Campaigner</h4>
                                    <h4>6</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{span:22, offset:1}}>
                                    <h2 className="p-font-bold">Invite Your Friends, Earn Together.</h2>
                                    <p>
                                        Earn up to 5% of rewards from your friends activities registered by your referral link.
                                        <br/>
                                        <h5 className="p-font-color p-font-bold no-padding">Read more</h5>
                                    </p>

                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                        <div className="shadow-white-div">
                            <Row>
                                <Col xs={{span:23, offset:1}} md={{span:10, offset:1}} lg={{span:10, offset:1}}>
                                    <h4 className="p-font-bold">My Referral Link</h4>
                                </Col>
                                <Col xs={{span:23, offset:0}} md={{span:5, offset:5}} lg={{span:5, offset:5}}>
                                    <Button size="medium"
                                            style={{
                                                backgroundColor: "#6b03fe",
                                                color: 'white',
                                                borderRadius: '10px',
                                            }}>
                                        <AiOutlinePlusCircle className="vertical-align-text-bottom" size={18} />
                                        Create a new link
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="shadow-white-div" style={{padding:'20px 0px 10px 0px'}}>
                                <Col xs={{span:20,offset:1}}>
                                    <h4>https://promodex.io/r/ahmetsayin_23</h4>
                                </Col>
                                <Col xs={{span:2,offset:1}}>
                                    <FiCopy size={24} onClick={this.handleCopy} style={{cursor:'pointer', pointerEvents: this.state.isCopied ? 'none' : 'auto'}}/>
                                </Col>
                            </Row>
                            {this.state.isCopied &&
                            <Row>
                                <Col xs={24} className="justify-content-center">
                                    <Alert message="Copied" type="success" style={{width:'100%', borderRadius:'10px'}}/>
                                </Col>
                            </Row>
                            }
                            <Row>
                                <Col xs={{span:10, offset:1}} md={{span:6, offset:1}} lg={{span:6, offset:1}} style={{textAlign:'center'}}>
                                    <h4>You will get</h4>
                                    <h4 className="p-font-color">100%</h4>
                                </Col>
                                <Col xs={{span:10, offset:1}} md={{span:6, offset:1}} lg={{span:6, offset:1}} style={{textAlign:'center'}}>
                                    <h4>Your friends will get</h4>
                                    <h4 className="p-font-color">0%</h4>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <div className="shadow-white-div">
                            <Tabs defaultActiveKey="1" type="card">
                                <TabPane tab="My Referrals" key="1">
                                    <Table size='small' scroll={{x: 500}} loading={false} bordered={false} showHeader={true}
                                           pagination={{
                                               defaultPageSize: 5,
                                               showSizeChanger: false,
                                               pageSizeOptions: ['10', '20', '30']
                                           }}
                                           columns={columns} dataSource={data}/>
                                </TabPane>
                                <TabPane tab="My Earnings" key="2">
                                    <Table size='small' scroll={{x: 500}} loading={false} bordered={false} showHeader={true}
                                           pagination={{
                                               defaultPageSize: 5,
                                               showSizeChanger: false,
                                               pageSizeOptions: ['10', '20', '30']
                                           }}
                                           columns={columns} dataSource={data}/>
                                </TabPane>
                                <TabPane tab="About Referral System" key="3">
                                    <Table size='small' scroll={{x: 500}} loading={false} bordered={false} showHeader={true}
                                           pagination={{
                                               defaultPageSize: 5,
                                               showSizeChanger: false,
                                               pageSizeOptions: ['10', '20', '30']
                                           }}
                                           columns={columns} dataSource={data}/>
                                </TabPane>
                            </Tabs>
                        </div>

                    </Col>
                </Row>
            </div>
            </>
            
        );
    }
}

export default ReferralsContent;
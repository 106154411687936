import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Button, Table, Select, Popconfirm, Spin } from 'antd';
import BorderTextInput from '../../../components/input/BorderTextInput';
import { createProjectTeamMember, deleteProjectTeamMember, getProjectTeamMembers} from '../api';
import BorderTextSelectbox from '../../../components/input/BorderTextSelectbox';

import { AiOutlineDelete } from 'react-icons/ai'
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

class ProjectTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      link: null,
      url: null,
      dataSource: [],
      columns: [
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Popconfirm title='Sure to delete?' onConfirm={() => this.handleDelete(record.id)} size="middle">
              <a color='#5340FF'><AiOutlineDelete size={18} style={{ color: '#5340FF' }} /></a>
            </Popconfirm>
          ),
        },
      ],
      loading: false,
    }
  }

  componentDidMount() {
  }

  getMembers = async () => {
    try {
      // send request to get link by project id
      const teamMembers = await getProjectTeamMembers(this.props.promodexInformation.project.id)

      let tempArr = []

      for (let i = 0; i < teamMembers.length; i++) {
        // create a data for table that includes link info.
        let data = {
          key: i + 1,
          title: teamMembers[i].title,
          name: teamMembers[i].name,
          id: teamMembers[i].id
        }
        tempArr.push(data)

      }
      // push data to state
      this.setState({
        dataSource: tempArr
      })

    } catch (ex) {
      console.log(ex)
    }
  }



  handleDelete = async (id) => {
    try {
      const removeLink = await deleteProjectTeamMember(id)
      this.getMembers()
    } catch (ex) {
      console.log(ex)
    }

  }


  handleAddLink = async () => {
    if (this.state.memberTitle === '') {
      this.props.openNotification('warning', 'No title found', 'Please write your team member\'s title !')
      return
    }
    if (this.state.memberName === null) {
      this.props.openNotification('warning', 'No name found', 'Please select your team member\'s name !')
      return
    }
    // if (this.props.promodexInformation.project === null) {
    //   this.props.openNotification('warning', 'No project found', 'You must save your project information first !')
    //   return
    // }

    const payload = {
      title: this.state.memberTitle,
      name: this.state.memberName,
      projectId: this.props.promodexInformation.project.id
    }

    try {
      const createTeamMember = await createProjectTeamMember(payload)
      console.log('createTeamMember : ', createTeamMember)
      this.getMembers()
    } catch (ex) {
      console.log(ex)
    }

  }

  saveAndNext = () => {
    // if(this.state.dataSource.length === 0){
    //   this.props.openNotification('warning', 'No Member Found', 'You must add link if you want to save Members!')
    //   return
    // }
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.setState({ loading: false }, () => {
          this.props.openNotification('success', 'Members Saved', 'Members are successfuly saved!')
          this.props.handleSteps(this.props.currentStep + 1)
        })
      }, 3000)
    })
  }



  render() {

    return (
      <div style={this.props.style}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
        <Row className='margin-top-20'>
          <Col xs={24} className='shadow-white-div'>
            <Row gutter={[24, 24]} align='middle'>
              <Col xs={24}>
                <p style={this.props.titleStyle}>Project / Brand Links</p>
              </Col>
              <Col xs={24} lg={8} >
                <BorderTextInput onChange={(event) => this.setState({ memberTitle: event.target.value })}
                  labelText={'Title'} size='large' />
              </Col>
              <Col xs={24} lg={8}>
                <BorderTextInput onChange={(event) => this.setState({ memberName: event.target.value })}
                  labelText={'Full name'} size='large' />
              </Col>
              <Col xs={24} lg={{ span: 6, offset: 2 }}>
                <Button onClick={this.handleAddLink} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                  Add a new link
                </Button>
              </Col>
            </Row>
            <Row className='margin-top-20'>
              <Col xs={24}>
                <Table scroll={{ x: 1000 }} size='small' className='ant-table-strip'
                  showHeader={false} pagination={false} dataSource={this.state.dataSource} columns={this.state.columns} />
              </Col>
            </Row>
                <Row className='margin-top-20'>
                  <Col xs={{ span: 10 }} md={{ span: 4 }} align='left'>
                    <Button onClick={() => this.props.handleSteps(this.props.currentStep - 1)} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                      Previus
                    </Button>
                  </Col>
                  <Col xs={{ span: 10, offset: 4 }} md={{ span: 4, offset: 16 }} align='right'>
                    <Button onClick={this.saveAndNext} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                      Save & Next
                    </Button>
                  </Col>
                </Row>
          </Col>
        </Row>
        </Spin>
      </div>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ProjectTeam));
import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Table, Button, Modal, Form, Spin } from 'antd';
import BorderTextInput from '../../../components/input/BorderTextInput';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyCustomUploadAdapterPlugin } from '../../../utils/CKEditorPlugin';
import { createProjectContent, getProjectContents } from '../api';
import { LoadingOutlined } from '@ant-design/icons';


class ProjectContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      columns: [
        {
          title: 'Content Title',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => {
            return (
              <Button onClick={() => this.showContextModal(record.key)}>Show</Button>
            )
          },
        },
      ],
      isContextModalVisible: false,
      content: null,
      modalContent: {
        title: null,
        context: null
      },
      loading: false,
    }
  }

  componentDidMount() {

  }

  getContents = async () => {
    if (this.props.promodexInformation.project === null) {
      this.props.openNotification('error', 'No Project Found', 'Please create your project first!')
      return
    }
    try {
      const projectContents = await getProjectContents(this.props.promodexInformation.project.id)
      let tempArr = []

      for (let i = 0; i < projectContents.length; i++) {
        // create a data for table that includes link info.
        let data = {
          key: i + 1,
          title: projectContents[i].projectContentTitle,
          id: projectContents[i].id
        }
        tempArr.push(data)

      }
      // push data to state
      this.setState({
        dataSource: tempArr,
        projectContents: projectContents
      })

    } catch (ex) {
      console.log(ex)
    }
  }

  showContextModal = (key) => {
    const index = key - 1


    this.setState({
      isContextModalVisible: !this.state.isContextModalVisible
    }, () => {
      if (this.state.isContextModalVisible === true) {
        document.getElementById('contentInModal').innerHTML = this.state.projectContents[index].contentDetails
      }
    })
  }

  handleSave = async (value) => {
    if (!value.title) return;
    if (this.props.promodexInformation.project === null) {
      this.props.openNotification('error', 'No Project Found', 'Please create your project first!')
      return
    }

    const payload = {
      projectContentTitle: value.title,
      contentDetails: this.state.content,
      projectId: this.props.promodexInformation.project.id
    }

    try {
      const createContent = await createProjectContent(payload)
      this.getContents()
    } catch (ex) {
      console.log(ex)
    }

  }



  saveAndNext = () => {
    // if(this.state.dataSource.length === 0){
    //   this.props.openNotification('warning', 'No Content Found', 'You must add link if you want to save Contents!')
    //   return
    // }
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.setState({ loading: false }, () => {
          this.props.openNotification('success', 'Contents Saved', 'Contents are successfuly saved!')
          this.props.handleSteps(this.props.currentStep + 1)
        })
      }, 3000)
    })
  }

  render() {
    const custom_config = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'blockQuote',
          'insertTable',
          '|',
          'imageUpload',
          'undo',
          'redo'
        ]
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      }
    }

    return (
      <div style={this.props.style}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
          <Form onFinish={this.handleSave}
            name="basic"
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 22,
              offset: 1
            }}
            initialValues={{
              remember: true,
            }}
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off">
            <Row className='margin-top-20'>
              <Col xs={24} className='shadow-white-div'>
                <Row gutter={[24, 24]}>
                  <Col xs={24} align='center'>
                    <p style={this.props.titleStyle}>Project Content</p>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                    <Form.Item
                      label=""
                      name="title"
                      wrapperCol={{
                        offset: 0,
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your Content Title!',
                        },
                      ]}
                    >
                      <BorderTextInput labelText={'Content Title'} size='large' />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>

                    <Form.Item
                      label=""
                      name="content"
                      wrapperCol={{
                        offset: 0,
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your Content!',
                        },
                      ]}
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data=""
                        config={custom_config}
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log({ event, editor, data });
                          this.setState({ content: data })
                        }}
                        onBlur={(event, editor) => {
                          // console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log('Focus.', editor);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={{ span: 6, offset: 18 }} align='right'>
                    <Form.Item
                      label=""
                      wrapperCol={{
                        offset: 0,
                        span: 24,
                      }}
                    >
                      <Button htmlType='submit' block className="margin-top-10 colorful-button nowrap register-button">
                        Add Content
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Table scroll={{ x: 1000 }} size='small' className='ant-table-strip'
                      showHeader={false} pagination={false} dataSource={this.state.dataSource} columns={this.state.columns} />

                    <Modal
                      className=''
                      width={600}
                      visible={this.state.isContextModalVisible}
                      maskClosable={true}
                      onCancel={this.showContextModal}
                      maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }}
                      footer={null}>
                      <Row>
                        <Col sm={24} align='center' className='margin-y-40'>
                          <div id='contentInModal'>
                          </div>
                        </Col>
                      </Row>
                    </Modal>

                  </Col>
                </Row>
                <Row className='margin-top-20'>
                  <Col xs={{ span: 10 }} md={{ span: 4 }} align='left'>
                    <Button onClick={() => this.props.handleSteps(this.props.currentStep - 1)} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                      Previus
                    </Button>
                  </Col>
                  <Col xs={{ span: 10, offset: 4 }} md={{ span: 4, offset: 16 }} align='right'>
                    <Button onClick={this.saveAndNext} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                      Save & Next
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    )
  }
}




const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ProjectContent));
import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Button, Table, Select, Popconfirm, Spin } from 'antd';
import BorderTextInput from '../../../components/input/BorderTextInput';
import { createProjectCommunityChannel, deleteProjectCommunityChannel, getChannelTags, getChannelTypes, getProjectCommunityChannels } from '../api';
import BorderTextSelectbox from '../../../components/input/BorderTextSelectbox';
import { AiOutlineDelete } from 'react-icons/ai';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

class ProjectCommunityChannel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      communityChannels: [],
      channelTags: [],
      communityChannel: null,
      url: null,
      tag: null,
      dataSource: [],
      columns: [
        {
          title: 'Community Channel',
          dataIndex: 'communityChannel',
          key: 'communityChannel',
        },
        {
          title: 'Tag/Language',
          dataIndex: 'tag',
          key: 'tag',
        },
        {
          title: 'URL',
          dataIndex: 'url',
          key: 'url',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Popconfirm title='Sure to delete?' onConfirm={() => this.handleDelete(record.id)} size="middle">
              <a color='#5340FF'><AiOutlineDelete size={18} style={{ color: '#5340FF' }} /></a>
            </Popconfirm>
          ),
        },
      ],
      loading: false,
    }
  }

  componentDidMount() {
    this.getCommunityChannelTypes()
    this.getTags()
  }



  // Get Project community channels for the table 
  getProjectCommunityChannels = async () => {
    try {
      // send request to get community channels by project id
      const projectCommunityChannels = await getProjectCommunityChannels(this.props.promodexInformation.project.id)

      console.log('projectCommunityChannels : ', projectCommunityChannels)

      let tempArr = []


      for (let i = 0; i < projectCommunityChannels.length; i++) {
        // create a data for table that includes community channels info.


        let data = {
          key: i + 1,
          communityChannel: this.convertIdToName(this.state.communityChannels, projectCommunityChannels[i].channelType),
          tag: this.convertIdToName(this.state.channelTags, projectCommunityChannels[i].channelTag),
          url: projectCommunityChannels[i].url,
          id: projectCommunityChannels[i].id,
        }

        tempArr.push(data)

      }
      // push data to state
      this.setState({
        dataSource: tempArr
      })

    } catch (ex) {
      console.log(ex)
    }
  }


  convertIdToName = (list, id) => {
    const data = list.filter(data => data.id === id)[0]
    return data.description
  }

  getCommunityChannelTypes = async () => {
    const communityChannels = await getChannelTypes()
    this.setState({ communityChannels: communityChannels })
  }

  getTags = async () => {
    const tags = await getChannelTags()
    this.setState({ channelTags: tags })
  }

  handleDelete = async (id) => {
    try {
      const removeCommunityChannel = await deleteProjectCommunityChannel(id)
      this.getProjectCommunityChannels()
    } catch (ex) {
      console.log(ex)
    }
  }


  handleAddAccount = async () => {
    if (this.state.communityChannel === null || this.state.url === null) {
      this.props.openNotification('warning', 'Cant Create Empty', 'You cant create without url or social media')
    }



    const payload = {
      url: this.state.url,
      channelType: this.state.communityChannel,
      channelTag: this.state.tag,
      projectId: this.props.promodexInformation.project.id
    }

    try {
      const projectCommunityChannel = await createProjectCommunityChannel(payload)

      this.getProjectCommunityChannels()
      // this.props.setPromodexRedux({ project: createProject_ })
      // this.props.handleSteps(1)

    } catch (error) {
      console.log('ERROR : ', error)
    }
  }

  saveAndNext = () => {
    // if(this.state.dataSource.length === 0){
    //   this.props.openNotification('warning', 'No Community Channel Found', 'You must add Community Channel if you want to save Community channels!')
    //   return
    // }
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.setState({ loading: false }, () => {
          this.props.openNotification('success', 'Community Channels Saved', 'Community Channels are successfuly saved!')
          this.props.handleSteps(this.props.currentStep + 1)
        })
      }, 3000)
    })
  }




  render() {
    const communityChannelOptions = this.state.communityChannels.map((communityChannel, index) => {
      return (
        <Option key={index} value={communityChannel.id}>{communityChannel.description}</Option>
      )
    })
    const channelTagsOptions = this.state.channelTags.map((tag, index) => {
      return (
        <Option key={index} value={tag.id}>{tag.description}</Option>
      )
    })


    return (
      <div style={this.props.style}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
        <Row className='margin-top-20'>
          <Col xs={24} className='shadow-white-div'>
            <Row gutter={[24, 24]} align='middle'>
              <Col xs={24}>
                <p style={this.props.titleStyle}>Project / Brand Social Media Accounts</p>
              </Col>
              <Col xs={12} lg={6} >
                <BorderTextSelectbox labelText={'Add a New Community Channel'} select={
                  <Select value={this.state.tags} onChange={(event) => this.setState({ communityChannel: event })}
                    style={{ width: '100%' }} size='large' defaultValue={undefined}>
                    {communityChannelOptions}
                  </Select>
                } />
              </Col>
              <Col xs={12} lg={6} >
                <BorderTextSelectbox labelText={'Add Tag / Language'} select={
                  <Select value={this.state.tags} onChange={(event) => this.setState({ tag: event })}
                    style={{ width: '100%' }} size='large' defaultValue={undefined}>
                    {channelTagsOptions}
                  </Select>
                } />
              </Col>
              <Col xs={12} lg={6}>
                <BorderTextInput onChange={(event) => this.setState({ url: event.target.value })}
                  labelText={'Add URL'} size='large' />
              </Col>
              <Col xs={12} lg={{ span: 6, offset: 0 }}>
                <Button onClick={this.handleAddAccount} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                  Add a new Account
                </Button>
              </Col>
            </Row>
            <Row className='margin-top-20'>
              <Col xs={24}>
                <Table scroll={{ x: 1000 }} size='small' className='ant-table-strip'
                  showHeader={false} pagination={false} dataSource={this.state.dataSource} columns={this.state.columns} />
              </Col>
            </Row>
            <Row className='margin-top-20'>
              <Col xs={{ span: 10 }} md={{ span: 4 }} align='left'>
                <Button onClick={() => this.props.handleSteps(this.props.currentStep - 1)} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                  Previus
                </Button>
              </Col>
              <Col xs={{ span: 10, offset: 4 }} md={{ span: 4, offset: 16 }} align='right'>
                <Button onClick={this.saveAndNext} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                  Save & Next
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        </Spin>
      </div>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ProjectCommunityChannel));
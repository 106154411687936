import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../redux/promodex/actions";
import { Alert, Button, Col, Divider, Image, Row, Tabs, Checkbox, Input, notification, Modal, Select } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FcHighPriority } from "react-icons/fc";
import { FiLayers } from "react-icons/fi";
import { VscVerified } from "react-icons/vsc";
import { IoDiamondOutline, IoWalletOutline, IoWarningOutline } from "react-icons/io5";
import checked from "../../assets/images/icon/checked.png"
import verifieduUser from "../../assets/images/icon/verified-user.png"
import contract from "../../assets/images/icon/contract.png"
import promodexBlueBg from "../../assets/images/logo/promodex_blue_backgound.png"
import whiteP from "../../assets/images/logo/promodex_white_background.png"
import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"


import {
    EyeImage, EyeInvisibleImage, HideSummary, WalletBlack, AllTokenSales,
    GreenCircleCheckmark, RedCircleCrossmark, FrequentlyAskedQuestions, StakingUnstaignTierCalcualtor, PromoToken, BinanceToken
} from '../../components/layout/Icons';
import TextCard from '../../components/card/TextCard';
import Stake from './tabs/stake/Stake';
import Unstake from './tabs/unstake/Unstake';
import Withdraw from './tabs/withdraw/Withdraw';
import MyWallet from './summary/MyWallet';
import Information from './summary/Information';
import Statistics from './summary/Statistics';

const EyeIcon = EyeImage()
const EyeInvisibleIcon = EyeInvisibleImage()
const WalletBlackIcon = WalletBlack()
const AllTokenSalesIcon = AllTokenSales()
const RedCircleCrossmarkIcon = RedCircleCrossmark()
const FrequentlyAskedQuestionsButton = FrequentlyAskedQuestions()
const StakingUnstaignTierCalcualtorButton = StakingUnstaignTierCalcualtor()
const PromoTokenIcon = PromoToken()
const BinanceTokenIcon = BinanceToken()

const { TabPane } = Tabs;


const { Option } = Select;



class StakePromoContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // fadingOut: false
            showSummary: true,


            tabName: 'stake',


            isComingSoonModalVisible: false,


            showSample: true,
        }
    }


    comingSoonModal = () => {
        this.setState({ isComingSoonModalVisible: !this.state.isComingSoonModalVisible })
    }

    handleTabChange = (name) => {
        this.setState({ tabName: name })
    }

    handleShowSummary = () => {
        this.setState({ showSummary: !this.state.showSummary })
    }

    componentDidMount() {
    }

    render() {

        const HideSummaryButton = HideSummary(this.state.showSummary ? EyeInvisibleIcon : EyeIcon, this.state.showSummary ? 'Hide' : 'Show')


        const GreenCircleCheckmarkIcon = GreenCircleCheckmark('51px', '50px')
        const GreenCircleCheckmarkIconSmall = GreenCircleCheckmark('26px', '25px')

        const BottomButtons = (
            <Row>
                <Col xs={12} align='left'>
                    <a onClick={this.comingSoonModal}>
                        {FrequentlyAskedQuestionsButton}
                    </a>
                </Col>
                <Col xs={12} align='right'>
                    <a onClick={this.comingSoonModal}>
                        {StakingUnstaignTierCalcualtorButton}
                    </a>
                </Col>
            </Row>
        )
        return (<>
            <div className={this.state.showSample ? 'show-sample-div-stake show-active-stake' : 'show-sample-div-stake'} onClick={() => this.setState({ showSample: !this.state.showSample })}>
                {this.state.showSample ? 'Hide Sample' : 'Show Sample'}
            </div>
            <div style={{ filter: this.state.showSample ? '' : 'blur(4px)', transition: 'filter .3s' }}>
                <Modal className='' title="" visible={this.state.isComingSoonModalVisible} maskClosable={true}
                    onCancel={this.comingSoonModal}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
                    <Row>
                        <Col sm={24} align='center' className='margin-y-40'>
                            <Image
                                style={{}}
                                width={100}
                                preview={false}
                                src={blueP}
                            /> <br />
                            <p style={{
                                textAlign: 'center',
                                fontSize: 24,
                                color: '#5340ff'
                            }}>
                                COMING SOON...
                            </p>
                        </Col>
                    </Row>
                </Modal>
                <Row>
                    <MyWallet showSummary={this.state.showSummary} />

                    <Information showSummary={this.state.showSummary} innerWidth={this.props.innerWidth} />

                    <Statistics showSummary={this.state.showSummary} />

                    <Col xs={{ span: 23, offset: 0 }} align='right'>
                        <div className='hide-summary-btn' onClick={this.handleShowSummary}>
                            {HideSummaryButton}
                        </div>
                    </Col>
                </Row>
                <Row className='margin-y-10'>
                    <Col xs={24} md={{ span: 20, offset: 2 }} className='shadow-white-div' align='center'>
                        <Row gutter={[24, 24]}>
                            <Col xs={24} lg={{ span: 4, offset: 6 }}>
                                <a onClick={() => this.handleTabChange('stake')}
                                    className={this.state.tabName === 'stake' ? "colorful-button nowrap" : "colorless-button nowrap"}
                                    style={{ paddingLeft: 40, paddingRight: 40 }}>
                                    STAKE
                                </a>
                            </Col>
                            <Col xs={24} lg={4}>
                                <a onClick={() => this.handleTabChange('unstake')}
                                    className={this.state.tabName === 'unstake' ? "colorful-button nowrap" : "colorless-button nowrap"}
                                    style={{ paddingLeft: 40, paddingRight: 40 }}>
                                    UNSTAKE
                                </a>
                            </Col>
                            <Col xs={24} lg={4}>
                                <a onClick={() => this.handleTabChange('withdraw')}
                                    className={this.state.tabName === 'withdraw' ? "colorful-button nowrap" : "colorless-button nowrap"}
                                    style={{ paddingLeft: 40, paddingRight: 40 }}>
                                    WITHDRAW
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.state.tabName === 'stake' &&
                    <Stake BottomButtons={BottomButtons}
                        RedCircleCrossmarkIcon={RedCircleCrossmarkIcon}
                        GreenCircleCheckmarkIcon={GreenCircleCheckmarkIcon}
                        GreenCircleCheckmarkIconSmall={GreenCircleCheckmarkIconSmall}
                     />
                }
                {this.state.tabName === 'unstake' &&
                    <Unstake BottomButtons={BottomButtons}
                    RedCircleCrossmarkIcon={RedCircleCrossmarkIcon}
                    GreenCircleCheckmarkIcon={GreenCircleCheckmarkIcon}
                    GreenCircleCheckmarkIconSmall={GreenCircleCheckmarkIconSmall} />
                }
                {this.state.tabName === 'withdraw' &&
                    <Withdraw BottomButtons={BottomButtons}
                    RedCircleCrossmarkIcon={RedCircleCrossmarkIcon}
                    GreenCircleCheckmarkIcon={GreenCircleCheckmarkIcon}
                    GreenCircleCheckmarkIconSmall={GreenCircleCheckmarkIconSmall} />
                }

            </div>
        </>
        );
    }
}

const mapState = (globalState) => {
    return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(StakePromoContent));

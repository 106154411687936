import React, { Component } from 'react';
import { Row, Col, Select, Table, Button, Card, Input, Image } from "antd";
import { BsWallet2 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlineDown, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import DashboardCard from "../../components/card/DashboardCard";
import { FiLayers } from "react-icons/fi";
import { VscVerified } from "react-icons/vsc";
import { IoDiamondOutline } from "react-icons/io5";

import whiteP from "../../assets/images/logo/promodex_white_background.png"
import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"
import ImageWithIcon from "../../components/profile/ImageWithIcon";

import facebook from "../../assets/images/social-media-icons/facebook.png"
import instagram from "../../assets/images/social-media-icons/instagram.png"
import twitter from "../../assets/images/social-media-icons/twitter.png"
import youtube from "../../assets/images/social-media-icons/youtube.png"
import linkedin from "../../assets/images/social-media-icons/linkedin.png"
import tiktok from "../../assets/images/social-media-icons/tiktok.png"


import dummyImage1 from "../../assets/images/dummy-images/Promodex - Facebook - Küçük.png"
import dummyImage2 from "../../assets/images/dummy-images/Avon sample.png"
import dummyImage3 from "../../assets/images/dummy-images/englishhome_gorsel sample.png"
import dummyImage4 from "../../assets/images/dummy-images/Nike banner new sample.png"
import dummyImage5 from "../../assets/images/dummy-images/Shop.com sample.png"
import dummyImage6 from "../../assets/images/dummy-images/tetris_token sample.png"
import dummyImage7 from "../../assets/images/dummy-images/unigrid sample.png"

import { getIdos } from "./api"
const { Option } = Select;

const columns = [
    {
        title: 'Project',
        dataIndex: 'project',
        key: 'project',
    },
    {
        title: 'Date(UTC)',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
];

const data = [
    {
        key: '1',
        project: 'DEMODYFY',
        date: "03 Oct. 2021",
        type: 'Pre-sale',
    },
    {
        key: '2',
        project: 'PROPLIFY',
        date: "03 Oct. 2021",
        type: 'Public',
    },
    {
        key: '3',
        project: 'SERKANDIFY',
        date: "03 Oct. 2021",
        type: 'Private',
    },
    {
        key: '4',
        project: 'SPINSWAP',
        date: "03 Oct. 2021",
        type: 'Seed',
    },
    {
        key: '5',
        project: 'FALSIFY',
        date: "03 Oct. 2021",
        type: 'Pre-sale',
    },
];

class DashboardContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cards: [
                {
                    status: 'Open',
                    img: dummyImage1,
                    companyName: 'Promodex.io',
                    publicStatus: 'Public Sale',
                    productName: '$Promo',
                    price: '$378.000',
                    endDate: '1d 5h 56m 54s',
                },
                {
                    status: 'Open',
                    img: dummyImage2,
                    companyName: 'Promodex.io',
                    publicStatus: 'Public Sale',
                    productName: '$Promo',
                    price: '$378.000',
                    endDate: '1d 5h 56m 54s',
                },
                {
                    status: 'Upcoming',
                    img: dummyImage3,
                    companyName: 'Promodex.io',
                    publicStatus: 'Public Sale',
                    productName: '$Promo',
                    price: '$378.000',
                    endDate: '1d 5h 56m 54s',
                },
                {
                    status: 'Delayed',
                    img: dummyImage4,
                    companyName: 'Promodex.io',
                    publicStatus: 'Public Sale',
                    productName: '$Promo',
                    price: '$378.000',
                    endDate: '1d 5h 56m 54s',
                },
                {
                    status: 'Upcoming',
                    img: dummyImage5,
                    companyName: 'Promodex.io',
                    publicStatus: 'Public Sale',
                    productName: '$Promo',
                    price: '$378.000',
                    endDate: '1d 5h 56m 54s',
                },
                {
                    status: 'Open',
                    img: dummyImage6,
                    companyName: 'Promodex.io',
                    publicStatus: 'Public Sale',
                    productName: '$Promo',
                    price: '$378.000',
                    endDate: '1d 5h 56m 54s',
                },
                {
                    status: 'Delayed',
                    img: dummyImage7,
                    companyName: 'Promodex.io',
                    publicStatus: 'Public Sale',
                    productName: '$Promo',
                    price: '$378.000',
                    endDate: '1d 5h 56m 54s',
                },],
            showCards: [],
            cardIndex: 0,

            cards2: [
                {
                    img: "https://dummyimage.com/600x400/6f0cfb/ffffff.png&text=img1",
                    title: 'Spheirum',
                    status: 'Pre-sale',
                    date: '3 oct. 2021'
                },
                {
                    img: "https://dummyimage.com/600x400/6f0cfb/ffffff.png&text=img2",
                    title: 'Spheirum',
                    status: 'Pre-sale',
                    date: '3 oct. 2021'
                },
                {
                    img: "https://dummyimage.com/600x400/6f0cfb/ffffff.png&text=img3",
                    title: 'Spheirum',
                    status: 'Pre-sale',
                    date: '3 oct. 2021'
                },
                {
                    img: "https://dummyimage.com/600x400/6f0cfb/ffffff.png&text=img4",
                    title: 'Spheirum',
                    status: 'Pre-sale',
                    date: '3 oct. 2021'
                },
                {
                    img: "https://dummyimage.com/600x400/6f0cfb/ffffff.png&text=img5",
                    title: 'Spheirum',
                    status: 'Pre-sale',
                    date: '3 oct. 2021'
                },
                {
                    img: "https://dummyimage.com/600x400/6f0cfb/ffffff.png&text=img6",
                    title: 'Spheirum',
                    status: 'Pre-sale',
                    date: '3 oct. 2021'
                },
                {
                    img: "https://dummyimage.com/600x400/6f0cfb/ffffff.png&text=img7",
                    title: 'Spheirum',
                    status: 'Pre-sale',
                    date: '3 oct. 2021'
                }],
            showCards2: [],
            cardIndex2: 0
        }

    }

    componentDidMount() {
        let innerWidth;
        if (window.innerWidth <= 550) innerWidth = 0
        else if (window.innerWidth > 550 && window.innerWidth < 1099) innerWidth = 2
        else if (window.innerWidth > 1100) innerWidth = 4

        const tempArr = []
        for (let i = this.state.cardIndex; i <= this.state.cardIndex + innerWidth; i++) {
            tempArr.push(this.state.cards[i])
        }

        const tempArr2 = []
        for (let i = this.state.cardIndex2; i <= this.state.cardIndex2 + innerWidth; i++) {
            tempArr2.push(this.state.cards2[i])
        }
        this.setState({ showCards: tempArr, showCards2: tempArr2, innerWidth: innerWidth })
    }

    handleSlider = (pos) => {

        if (pos === 'left') {
            this.setState({ cardIndex: this.state.cardIndex - 1 }, () => {
                const tempArr = []
                for (let i = this.state.cardIndex; i <= this.state.cardIndex + this.state.innerWidth; i++) {
                    tempArr.push(this.state.cards[i])
                }
                this.setState({ showCards: tempArr })
            })
        } else if (pos === 'right') {
            this.setState({ cardIndex: this.state.cardIndex + 1 }, () => {
                const tempArr = []
                for (let i = this.state.cardIndex; i <= this.state.cardIndex + this.state.innerWidth; i++) {
                    tempArr.push(this.state.cards[i])
                }
                this.setState({ showCards: tempArr })
            })
        }
    }
    handleSlider2 = (pos) => {
        if (pos === 'left') {
            this.setState({ cardIndex2: this.state.cardIndex2 - 1 }, () => {
                const tempArr = []
                for (let i = this.state.cardIndex2; i <= this.state.cardIndex2 + this.state.innerWidth; i++) {
                    tempArr.push(this.state.cards2[i])
                }
                this.setState({ showCards2: tempArr })
            })
        } else if (pos === 'right') {
            this.setState({ cardIndex2: this.state.cardIndex2 + 1 }, () => {
                const tempArr = []
                for (let i = this.state.cardIndex2; i <= this.state.cardIndex2 + this.state.innerWidth; i++) {
                    tempArr.push(this.state.cards2[i])
                }
                this.setState({ showCards2: tempArr })
            })
        }
    }


    render() {
        return (
            <div>
                <Row>
                    <Col sm={24} lg={6} className="shadow-white-div">
                        <Row>
                            <Col xs={12}>
                                <h4 className="promodex-grey-title"><BsWallet2 className="vertical-align-text-bottom"
                                    size={20} /> MY WALLET </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} md={24} lg={24}>
                                <Row>
                                    <Col xs={12}>
                                        <small className="promodex-grey-title"
                                            style={{ fontSize: 12 }}>
                                            Promo in Wallet
                                        </small>
                                    </Col>
                                    <Col xs={12} align="center">
                                        <Link to="/login" className="linkMenu" style={{ fontSize: 12 }}>
                                            Buy PROMO
                                        </Link>

                                    </Col>
                                    <Col xs={22}>
                                        <Row style={{ backgroundColor: '#5340ff', borderRadius: '10px' }} align='center'>
                                            <Col xs={2} md={4} lg={4}>
                                                <Image
                                                    style={{ marginLeft: '-20px', marginTop: 4 }}
                                                    width={50}
                                                    preview={false}
                                                    src={whiteP}
                                                />
                                            </Col>
                                            <Col xs={8} style={{ paddingTop: '10px' }}>
                                                <h3 style={{ color: '#ffffff', textAlign: 'left' }}>10.000</h3>
                                            </Col>
                                            <Col xs={8} style={{ paddingTop: '10px' }}>
                                                <h4 style={{ color: '#ffffff', textAlign: 'center' }}>Promo</h4>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col xs={12}>
                                        <small className="promodex-grey-title"
                                            style={{ fontSize: 12 }}>
                                            Promo Staked
                                        </small>
                                    </Col>
                                    <Col xs={12} align="center">
                                        <Link to="/login" className="linkMenu" style={{ fontSize: 12 }}>
                                            Stake PROMO
                                        </Link>

                                    </Col>
                                    <Col xs={22}>
                                        <Row style={{
                                            backgroundColor: '#fffff',
                                            border: '1px solid #e2e2e8',
                                            borderRadius: '10px'
                                        }} align='center'>
                                            <Col xs={2} md={4} lg={4}>
                                                <Image
                                                    style={{ marginLeft: '-20px', marginTop: 4 }}
                                                    width={35}
                                                    preview={false}
                                                    src={blueP}
                                                />
                                            </Col>
                                            <Col xs={8} style={{ paddingTop: '10px' }}>
                                                <h3 style={{ color: '#9292a1', textAlign: 'left' }}>10.000</h3>
                                            </Col>
                                            <Col xs={8} style={{ paddingTop: '10px' }}>
                                                <h4 style={{ color: '#9292a1', textAlign: 'center' }}>Promo</h4>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={24} lg={{ span: 5, offset: 1 }} className="shadow-white-div">
                        <Row>
                            <Col xs={24} align='middle'>
                                <h4 className="promodex-grey-title"><FiLayers className="vertical-align-text-bottom"
                                    size={20} /> MY Channels</h4>
                            </Col>
                        </Row>
                        <Row gutter={[8, 16]} className="margin-top-10">
                            <Col xs={24} align='middle'>
                                <Row>
                                    <Col xs={24} lg={{ span: 6, offset: 0 }} align='left' style={{ marginTop: '-10px' }}>
                                        <ImageWithIcon socialMedia={instagram} />
                                    </Col>
                                    <Col xs={12} lg={{ span: 8, offset: 0 }} align='left'>
                                        <h5 style={{ verticalAlign: 'bottom' }}>hakan_unal_1997</h5>
                                    </Col>
                                    <Col xs={12} lg={{ span: 8, offset: 0 }} align='right'>
                                        <h5>5</h5>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} align='middle'>
                                <Row>
                                    <Col xs={24} lg={{ span: 6, offset: 0 }} align='left' style={{ marginTop: '-10px' }}>
                                        <ImageWithIcon socialMedia={twitter} />
                                    </Col>
                                    <Col xs={12} lg={{ span: 8, offset: 0 }} align='left'>
                                        <h5 style={{ verticalAlign: 'bottom' }}>hakan_unal_1997</h5>
                                    </Col>
                                    <Col xs={12} lg={{ span: 8, offset: 0 }} align='right'>
                                        <h5>5</h5>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} align='middle'>
                                <Row>
                                    <Col xs={24} lg={{ span: 6, offset: 0 }} align='left' style={{ marginTop: '-10px' }}>
                                        <ImageWithIcon socialMedia={facebook} />
                                    </Col>
                                    <Col xs={12} lg={{ span: 8, offset: 0 }} align='left'>
                                        <h5 style={{ verticalAlign: 'bottom' }}>hakan_unal_1997</h5>
                                    </Col>
                                    <Col xs={12} lg={{ span: 8, offset: 0 }} align='right'>
                                        <h5>5</h5>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={24} align='middle'>
                                <Link to="/wallet">
                                    <Button className="margin-top-10 connect-wallet-btn" size="small">
                                        Connect Channel
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={24} lg={{ span: 5, offset: 1 }} className="shadow-white-div">
                        <Row>
                            <Col xs={24} align='middle'>
                                <h4 className="promodex-grey-title"><FiLayers className="vertical-align-text-bottom"
                                    size={20} /> Open Campaigns</h4>
                            </Col>
                        </Row>
                        <Row gutter={[8, 16]} className="margin-top-10">
                            <Col xs={24} align='middle'>
                                <Row>
                                    <Col xs={{ span: 10, offset: 2 }} align='left'>
                                        <h4>IDO:</h4>
                                    </Col>
                                    <Col xs={{ span: 8, offset: 1 }} align='right'>
                                        <h4>4</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} align='middle'>
                                <Row>
                                    <Col xs={{ span: 10, offset: 2 }} align='left'>
                                        <h4>Promotion:</h4>
                                    </Col>
                                    <Col xs={{ span: 8, offset: 1 }} align='right'>
                                        <h4>5</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} align='middle'>
                                <Row>
                                    <Col xs={{ span: 10, offset: 2 }} align='left'>
                                        <h4>My Campaigns:</h4>
                                    </Col>
                                    <Col xs={{ span: 8, offset: 1 }} align='right'>
                                        <h45>1</h45>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={24} align='middle'>
                                <Link to="/wallet">
                                    <Button className="margin-top-10 connect-wallet-btn" size="small">
                                        Create Campaign
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={{ span: 24 }} lg={{ span: 5, offset: 1 }} className="shadow-white-div">
                        <Row>
                            <Col xs={24} align='middle'>
                                <h4 className="promodex-grey-title"><FiLayers className="vertical-align-text-bottom"
                                    size={20} /> My Status</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <small className='p-font-bold'>My KYC Status</small>
                                <h5 className='p-font-bold' style={{
                                    color: '#ffffff',
                                    backgroundColor: '#59cbff',
                                    borderRadius: '6px',
                                    textAlign: 'center',
                                }}>VERIFIED <VscVerified size={20} className="vertical-align-text-bottom" /></h5>
                            </Col>
                            <Col lg={10} push={1} style={{ marginTop: '20px' }}>
                                <Link to="/dashboard" className="linkMenu" style={{ fontSize: 12 }}>
                                    Check now
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <small className='p-font-bold'>My TIER LEVEL</small>
                                <h5 className='p-font-bold' style={{
                                    color: '#ffffff',
                                    backgroundColor: '#ff6565',
                                    borderRadius: '6px',
                                    textAlign: 'center',
                                }}>PREMIUM <IoDiamondOutline size={20} className="vertical-align-text-bottom" /></h5>
                            </Col>

                            <Col lg={10} push={1} style={{ marginTop: '20px' }}>
                                <Link to="/dashboard" className="linkMenu" style={{ fontSize: 12 }}>
                                    Upgrade now
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <small className='p-font-bold'>My Loyalty Score</small>
                                <h5 className='p-font-bold' style={{
                                    color: '#ffffff',
                                    backgroundColor: '#f7b500',
                                    borderRadius: '6px',
                                    textAlign: 'center',
                                }}>24/100</h5>
                            </Col>

                            <Col lg={10} push={1} style={{ marginTop: '20px' }}>
                                <Link to="/dashboard" className="linkMenu" style={{ fontSize: 12 }}>
                                    Upgrade now
                                </Link>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={24} align='middle'>
                                <Link to="/profile">
                                    <h4 className="p-font-color p-font-bold text-underline clickable-text"> See
                                        Profile Details</h4>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="margin-y-10 shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            {this.state.cardIndex !== 0 && this.state.innerWidth !== 0 &&
                                <Col xs={1}>
                                </Col>
                            }
                            <Col xs={{ span: 11, offset: 1 }} md={4}>
                                <h3 style={{ color: '#4c4c66', fontWeight: '700' }}>IDO</h3>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 6 }} lg={{ span: 2, offset: 9 }} align='middle' className="filter-selectbox">
                                <Select value={this.state.idoDateFilter} defaultValue="Today"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ idoDateFilter: e })}>
                                    <Option value="today">Today</Option>
                                    <Option value="oneMount">1 Mount</Option>
                                    <Option value="oneYear">1 Year</Option>
                                </Select>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                                <Select value={this.state.idoStatusFilter} defaultValue="Ongoing"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ idoStatusFilter: e })}>
                                    <Option value="ongoing">Ongoing</Option>
                                    <Option value="dummy1">Dummy</Option>
                                    <Option value="dummy2">Dummy</Option>
                                </Select>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                                <Select value={this.state.idoTypeFilter} defaultValue="Ongoing"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ idoTypeFilter: e })}>
                                    <Option value="all">All</Option>
                                    <Option value="dummy1">Dummy</Option>
                                    <Option value="dummy2">Dummy</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.cardIndex !== 0 &&
                                <Col xs={1}>
                                    <AiOutlineLeft className="vertical-center slider-arrow" size={36}
                                        onClick={() => this.handleSlider('left')} />
                                </Col>
                            }
                            {this.state.showCards.map((data, index) => {
                                return (
                                    <Col xs={this.state.innerWidth === 0 ? { span: 16, offset: 3 } : this.state.innerWidth === 2 ? 7 : this.state.innerWidth === 4 && 4}>
                                        <DashboardCard
                                            status={data.status}
                                            src={data.img}
                                            companyName={data.companyName}
                                            publicStatus={data.publicStatus}
                                            productName={data.productName}
                                            price={data.price}
                                            endDate={data.endDate}
                                            innerWidth={this.state.innerWidth} />
                                    </Col>

                                )
                            })
                            }
                            {(this.state.cardIndex + (this.state.innerWidth + 1) === this.state.cards.length) ?
                                <Col xs={2}>
                                    <h5 className="vertical-center" style={{ color: '#9a98b8' }}>No other projects
                                        found</h5>
                                </Col>
                                :
                                <Col xs={1} align='left'>
                                    <AiOutlineRight className="vertical-center slider-arrow" size={36}
                                        onClick={() => this.handleSlider('right')} />
                                </Col>

                            }
                        </Row>
                    </Col>
                </Row>
                <Row className="margin-y-10 shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            {this.state.cardIndex2 !== 0 && this.state.innerWidth !== 0 &&
                                <Col xs={1}>
                                </Col>
                            }
                            <Col xs={{ span: 11, offset: 1 }} md={4}>
                                <h3 style={{ color: '#4c4c66', fontWeight: '700' }}>CAMPAIGNS</h3>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 6 }} lg={{ span: 2, offset: 9 }} align='middle' className="filter-selectbox">
                                <Select value={this.state.campaignsDateFilter} defaultValue="Today"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ campaignsDateFilter: e })}>
                                    <Option value="today">Today</Option>
                                    <Option value="oneMount">1 Mount</Option>
                                    <Option value="oneYear">1 Year</Option>
                                </Select>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                                <Select value={this.state.campaignsStatusFilter} defaultValue="Ongoing"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ campaignsStatusFilter: e })}>
                                    <Option value="ongoing">Ongoing</Option>
                                    <Option value="dummy1">Dummy</Option>
                                    <Option value="dummy2">Dummy</Option>
                                </Select>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                                <Select value={this.state.campaignsTypeFilter} defaultValue="Ongoing"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ campaignsTypeFilter: e })}>
                                    <Option value="all">All</Option>
                                    <Option value="dummy1">Dummy</Option>
                                    <Option value="dummy2">Dummy</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.cardIndex2 !== 0 &&
                                <Col xs={1}>
                                    <AiOutlineLeft className="vertical-center slider-arrow" size={36}
                                        onClick={() => this.handleSlider2('left')} />
                                </Col>
                            }
                            {this.state.showCards2.map((data, index) => {
                                return (
                                    <Col xs={this.state.innerWidth === 0 ? { span: 16, offset: 3 } : this.state.innerWidth === 2 ? 7 : this.state.innerWidth === 4 && 4}>
                                        <DashboardCard src={data.img} title={data.title} status={data.status}
                                            date={data.date} innerWidth={this.state.innerWidth} />
                                    </Col>

                                )
                            })
                            }
                            {(this.state.cardIndex2 + (this.state.innerWidth + 1) === this.state.cards2.length) ?
                                <Col xs={2}>
                                    <h5 className="vertical-center" style={{ color: '#9a98b8' }}>No other projects found</h5>
                                </Col>
                                :
                                <Col xs={1} align='left'>
                                    <AiOutlineRight className="vertical-center slider-arrow" size={36}
                                        onClick={() => this.handleSlider2('right')} />
                                </Col>

                            }
                        </Row>
                    </Col>

                </Row>
                <Row className="margin-y-10 shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{ span: 11, offset: 1 }} md={4}>
                                <h3 style={{ color: '#4c4c66', fontWeight: '700' }}>MY CAMPAIGNS</h3>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 6 }} lg={{ span: 2, offset: 9 }} align='middle' className="filter-selectbox">
                                <Select value={this.state.campaignsDateFilter} defaultValue="Today"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ campaignsDateFilter: e })}>
                                    <Option value="today">Today</Option>
                                    <Option value="oneMount">1 Mount</Option>
                                    <Option value="oneYear">1 Year</Option>
                                </Select>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} className="filter-selectbox">
                                <Select value={this.state.campaignsStatusFilter} defaultValue="Ongoing"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ campaignsStatusFilter: e })}>
                                    <Option value="ongoing">Ongoing</Option>
                                    <Option value="dummy1">Dummy</Option>
                                    <Option value="dummy2">Dummy</Option>
                                </Select>
                            </Col>
                            <Col xs={{ span: 12, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                                <Select value={this.state.campaignsTypeFilter} defaultValue="Ongoing"
                                    suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                                    onChange={(e) => this.setState({ campaignsTypeFilter: e })}>
                                    <Option value="all">All</Option>
                                    <Option value="dummy1">Dummy</Option>
                                    <Option value="dummy2">Dummy</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={this.state.innerWidth === 0 ? { span: 24, offset: 0 } : this.state.innerWidth === 2 ? 7 : this.state.innerWidth === 4 && 4}>
                                <DashboardCard src={"https://dummyimage.com/600x400/6f0cfb/ffffff.png&text=img"}
                                    title={"Spheirum"} status={"Pre-sale"} date={"3 oct. 2021"} innerWidth={this.state.innerWidth} />
                            </Col>
                            <Col xs={this.state.innerWidth === 0 ? { span: 24, offset: 0 } : this.state.innerWidth === 2 ? 7 : this.state.innerWidth === 4 && 4}>
                                <Link to='/create'>
                                    <Card
                                        hoverable style={{ height: '100%', border: '2px dashed #afafba' }}>
                                        <h4 className="vertical-center" style={{ textAlign: 'center', color: '#9494a3' }}>
                                            <strong>+</strong><br />Create New Campaign</h4>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/** COMMENTED OLD CODE*/}
                <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                    {/*<Row gutter={[24, 24]}>*/}
                    {/*    <Col sm={24} xl={{span: 12, offset: 0}} className='bg-white' style={{borderRadius: '20px'}}>*/}
                    {/*        <Row gutter={[8, 0]} style={{padding: '6px 6px 6px 6px'}}>*/}
                    {/*            <Col sm={1} md={1} lg={1} xl={10}>*/}
                    {/*                <h3>*/}
                    {/*                    <MdOutlineInventory size={36} className="vertical-align-text-bottom"/>*/}
                    {/*                    IDO*/}
                    {/*                </h3>*/}
                    {/*            </Col>*/}
                    {/*            <Col sm={11} md={11} lg={11} xl={{span: 7, offset: 0}}>*/}
                    {/*                <Select className='float-right select-style' defaultValue="ongoing"*/}
                    {/*                        style={{width: this.props.innerWidth < 1399 ? '92px' : '120px'}}*/}
                    {/*                        onChange={(e) => this.setState({selectBox1: e.target.value})}>*/}
                    {/*                    <Option value="all">All</Option>*/}
                    {/*                    <Option value="ongoing">Ongoing</Option>*/}
                    {/*                    <Option value="dummy">Dummy</Option>*/}
                    {/*                </Select>*/}
                    {/*            </Col>*/}
                    {/*            <Col sm={11} md={11} lg={11} xl={{span: 7, offset: 0}}>*/}
                    {/*                <Select className='float-left' defaultValue="all"*/}
                    {/*                        style={{width: this.props.innerWidth < 1399 ? '92px' : '120px'}}*/}
                    {/*                        onChange={(e) => this.setState({selectBox1: e.target.value})}>*/}
                    {/*                    <Option value="all">All</Option>*/}
                    {/*                    <Option value="ongoing">Ongoing</Option>*/}
                    {/*                    <Option value="dummy">Dummy</Option>*/}
                    {/*                </Select>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*        <Row style={{padding: '6px 6px 6px 6px'}}>*/}
                    {/*            <Col xl={22}>*/}
                    {/*                <Table size='small' scroll={{x: 400}} loading={false} bordered={false}*/}
                    {/*                       showHeader={true} pagination={false} columns={columns} dataSource={data}/>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm={24} xl={{span: 11, offset: 1}} className='bg-white'*/}
                    {/*         style={{borderRadius: '20px', padding: '6px 10px 6px 10px'}}>*/}
                    {/*        <Row gutter={[8, 0]} style={{padding: '6px 6px 6px 6px'}}>*/}
                    {/*            <Col sm={24} md={24} lg={24} xl={10}>*/}
                    {/*                <h3>*/}
                    {/*                    <MdOutlineInventory size={36} className="vertical-align-text-bottom"/>*/}
                    {/*                    CAMPAIGNS*/}
                    {/*                </h3>*/}
                    {/*            </Col>*/}
                    {/*            <Col sm={12} md={12} lg={11} xl={{span: 7, offset: 0}}>*/}
                    {/*                <Select className='float-right select-style' defaultValue="ongoing"*/}
                    {/*                        style={{width: this.props.innerWidth < 1399 ? '92px' : '120px'}}*/}
                    {/*                        onChange={(e) => this.setState({selectBox1: e.target.value})}>*/}
                    {/*                    <Option value="all">All</Option>*/}
                    {/*                    <Option value="ongoing">Ongoing</Option>*/}
                    {/*                    <Option value="dummy">Dummy</Option>*/}
                    {/*                </Select>*/}
                    {/*            </Col>*/}
                    {/*            <Col sm={12} md={12} lg={11} xl={{span: 7, offset: 0}}>*/}
                    {/*                <Select className='float-left' defaultValue="all"*/}
                    {/*                        style={{width: this.props.innerWidth < 1399 ? '92px' : '120px'}}*/}
                    {/*                        onChange={(e) => this.setState({selectBox1: e.target.value})}>*/}
                    {/*                    <Option value="all">All</Option>*/}
                    {/*                    <Option value="ongoing">Ongoing</Option>*/}
                    {/*                    <Option value="dummy">Dummy</Option>*/}
                    {/*                </Select>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*        <Row style={{padding: '6px 6px 6px 6px'}}>*/}
                    {/*            <Col xl={22}>*/}
                    {/*                <Table size='small' scroll={{x: 400}} loading={false} bordered={false}*/}
                    {/*                       showHeader={true} pagination={false} columns={columns} dataSource={data}/>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </div>
            </div>
        );
    }
}

export default DashboardContent;
import React, { Component } from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";



export default class BorderTextSelectbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <Row>
        <Col span={24} style={{ zIndex: 10 }}>
          <small
            style={{
              display: 'inline-block',
              marginBottom: '-10px',
              backgroundColor: '#FFFFFF',
              marginLeft: '5px',
              fontWeight: 700,
              fontSize: this.props.labelFontSize || 12,
              transition: 'all .4s',
              color: this.props.lableColor || 'rgba(40,61,92,0.50)'
            }}
            className={this.props.labelStyle}
          >
            {this.props.labelText}
          </small>
        </Col>
        <Col span={24}>
          {this.props.select}
        </Col>
      </Row>
    )
  }
}

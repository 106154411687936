import React, {Component} from 'react';
import PromodexLayout from "../../components/layout/PromodexLayout";
import StakePromoContent from "./StakePromoContent";

class StakePromoLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth:0
        }
    }

    componentDidMount() {
        this.setState({innerWidth: window.innerWidth})
        setInterval(() => {
            this.setState({innerWidth: window.innerWidth})
        }, 500)
    }
    render() {
        return (
            <div>
                <PromodexLayout page='stakepromo' content={<StakePromoContent innerWidth={this.state.innerWidth}/>}/>
            </div>
        );
    }
}

export default StakePromoLayout;
import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../redux/promodex/actions";
import ReactDOM from 'react-dom';
import { Row, Col, Upload, Modal } from "antd";
import ImgCrop from 'antd-img-crop';
import "antd/dist/antd.css";
import axios from 'axios';


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


function BorderImageUpload (props) {
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(null);

  const onChange = ({ file, fileList, event }) => {
    setFileList(fileList);
    props.setPromodexRedux({ [props.filename]: file.originFileObj })
  };

  // Close modal func
  const handleCancel = () => setPreviewVisible(false);
  // When Preview clicked
  const onPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  };

  const beforeUpload = (file) => {
    return true
  }

  const customRequest = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    fmData.append("image", file);
    onSuccess("Ok");
    
    // try {
    //   const res = await axios.post(
    //     "https://jsonplaceholder.typicode.com/posts",
    //     fmData,
    //     config
    //   );

    //   onSuccess("Ok");
    //   console.log("server res: ", res);
    // } catch (err) {
    //   console.log("Eroor: ", err);
    //   const error = new Error("Some error");
    //   onError({ err });
    // }
  }


  return (
    <Row>
      <Col span={24} style={{ zIndex: 10 }}>
        <small
          style={{
            display: 'inline-block',
            marginBottom: '-10px',
            marginLeft: '5px',
            fontWeight: 700,
            background: 'linear-gradient(0deg, #fafafa 50%, #FFFFFF 50%)',
            fontSize: props.labelFontSize || 12,
            transition: 'all .4s',
            color: props.lableColor || 'rgba(40,61,92,0.50)'
          }}
          className={props.labelStyle}
        >
          {props.labelText}
        </small>
      </Col>
      <Col span={24}>
        {props.crop === true ?
        <ImgCrop rotate zoom shape={props.shape}>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
          >
            {fileList.length < 1 && 'Click to Upload'}
          </Upload>
        </ImgCrop> : 
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
          >
            {fileList.length < 1 && 'Click to Upload'}
          </Upload>
        }
      </Col>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%', marginTop:20 }} src={previewImage} />
        </Modal>
      
    </Row>
  )
}

const mapState = (globalState) => {
    return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(BorderImageUpload));
import React, { Component } from 'react';
import PromodexLayout from '../../components/layout/PromodexLayout';
import InvestorContent from './InvestorContent';

export default class InvestorLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth:0
        }
    }

    componentDidMount() {
        this.setState({innerWidth: window.innerWidth})
        setInterval(() => {
            this.setState({innerWidth: window.innerWidth})
        }, 500)
    }



  render() {
    return <PromodexLayout page='investorPage' content={<InvestorContent innerWidth={this.state.innerWidth} />} />;
  }
}

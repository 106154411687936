import React, { Component } from 'react';
import LandingPageContent from './LandingPageContent';
import PromodexLayout from '../../components/layout/PromodexLayout';


export default class LandingPageLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth: 0
        }
    }

    componentDidMount() {
        this.setState({ innerWidth: window.innerWidth })
        setInterval(() => {
            this.setState({ innerWidth: window.innerWidth })
        }, 500)
    }


    render() {
        return (
            <PromodexLayout page='landingPage' content={<LandingPageContent innerWidth={this.state.innerWidth} />} />
        )

    }
}

import React, {Component} from 'react';
import {Col} from "antd";

class StatisticsContent extends Component {
    render() {
        return (
            <div>
                <Col md={24} xl={12}>
                    <h2 className='p-font-color p-font-bold'>MY STATISTICS</h2>
                </Col>
            </div>
        );
    }
}

export default StatisticsContent;
import React, { Component } from 'react';
import DetailedCard from '../../components/card/DetailedCard';
import { Row, Col, Tabs, Select, Image } from 'antd';
import { AiOutlineDown } from 'react-icons/ai';

import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"
import promodexImage from "../../assets/images/dummy-images/Promodex - Facebook - Küçük.png"




import PublicSale1 from "../../assets/images/dummy-images/PublicSale1.jpg"
import PublicSale2 from "../../assets/images/dummy-images/PublicSale2.jpg"
import PublicSale3 from "../../assets/images/dummy-images/PublicSale3.jpg"
import PublicSale4 from "../../assets/images/dummy-images/PublicSale4.jpg"
import PrivateSale from "../../assets/images/dummy-images/PrivateSale.jpg"


import {
  Dolar, AuditCheck, Rating,
  DetailedCardStatusOpen, DetailedCardStatusUpcoming, DetailedCardStatusEnded,
  DetailedCardChainBinance
} from '../../components/layout/Icons';

const DolarIcon = Dolar()
const AuditCheckIcon = AuditCheck()
const DetailedCardStatusOpenIcon = DetailedCardStatusOpen()
const DetailedCardStatusUpcomingIcon = DetailedCardStatusUpcoming()
const DetailedCardStatusEndedIcon = DetailedCardStatusEnded()
const DetailedCardChainBinanceIcon = DetailedCardChainBinance()

const { Option } = Select;
const { TabPane } = Tabs;

export default class InvestorCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSummary: false,
      salesCards: [],
      idoData: this.props.data,
      activeTab: "All"
    }
  }

  timeDifference = (date1, date2) => {
    var difference = date1.getTime() - date2.getTime();

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    var secondsDifference = Math.floor(difference / 1000);

    return daysDifference + ' D ' +
      hoursDifference + ' h ' +
      minutesDifference + ' m '
  }
  utcDateConverter = (tempDate) => {
    const date = new Date(tempDate);
    const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    return new Date(utcDate);
  }

  idoDateCalculator = () => {
    const images = [PublicSale1, PublicSale2, PublicSale3, PublicSale4, PrivateSale]
    const test = this.state.idoData.map((ido, index) => {
      const idoStartDate = this.utcDateConverter(ido.startDate)
      const idoEndDate = this.utcDateConverter(ido.endDate)
      const nowDate = this.utcDateConverter(new Date())
      const idoStartDateTime = idoStartDate.getTime()
      const idoEndDateTime = idoEndDate.getTime()
      const nowDateTime = nowDate.getTime()

      if (idoEndDateTime > nowDateTime) {
        if (idoStartDateTime > nowDateTime) {
          const upComingCounter = this.timeDifference(idoStartDate, nowDate)
          ido.status = "Upcoming"
          ido.counter = upComingCounter
        } else {
          const openCounter = this.timeDifference(idoEndDate, nowDate)
          ido.status = "Open"
          ido.counter = openCounter
        }
      } else {
        const endedCounter = this.timeDifference(idoStartDate, nowDate)
        ido.status = "Delayed"
        ido.counter = '0 D 0 h 0 m'

      }

      ido.img = images[index];
      return ido
    })
    this.setState({ idoData: this.state.idoData })
  }
  componentDidMount() {
    this.idoDateCalculator()
    setInterval(() => {
      this.idoDateCalculator()
    }, 60000)
  }

  render() {

    const RatingIcon = Rating('N/A')
    const idoCards = this.state.idoData.map((ido, index) => {
      if (ido.status === this.state.activeTab || this.state.activeTab === "All") {
        return (
          <Col key={index} xs={24} md={12} lg={8}>
            <DetailedCard date={ido.startDate}
              statusIcon={ido.status === 'Open' ? DetailedCardStatusUpcomingIcon : ido.status === 'Upcoming' ? DetailedCardStatusUpcomingIcon : DetailedCardStatusUpcomingIcon}
              chainIcon={DetailedCardChainBinanceIcon} image={ido.img} status={ido.status} counter={ido.counter} idoType={ido.idoType}
              companyIcon={<Image alt='promodex' src={blueP} width={30} preview={false} />} companyName={'Promodex'} saleType={ido.name} tokenName={'$Promo'} id={ido.id}
              auditIcon={AuditCheckIcon} ratingIcon={RatingIcon} price={ido.priceValue} targetFundSale={`$ ${ido.softCap}`} targetTokenSale={ido.totalTokenSale} />
          </Col>
        )
      }
    })
    return <div className='detailed-card-tabs'>
      <Tabs
        onChange={(e) => this.setState({ activeTab: e })}
        tabBarExtraContent={
          <Row>
            <Col xs={{ span: 8, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} align='middle' className="filter-selectbox">
              <Select defaultValue="Time">
                <Option value="today">Today</Option>
                <Option value="oneMount">One Week</Option>
                <Option value="oneYear">One Mount</Option>
              </Select>
            </Col>
            <Col xs={{ span: 8, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} align='middle' className="filter-selectbox">
              <Select defaultValue="Type">
                <Option value="type">Blockchain</Option>
                <Option value="type">Metamask</Option>
                <Option value="type">Gaming</Option>
              </Select>
            </Col>
            <Col xs={{ span: 8, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }} align='middle' className="filter-selectbox">
              <Select defaultValue="Chain">
                <Option value="bnb">BNB</Option>
                <Option value="eth">ETH</Option>
                <Option value="sol">SOL</Option>
              </Select>
            </Col>
          </Row>
        }
        animated={false}>
        <TabPane tab="All" key="All">
          <Row>
            {idoCards}
          </Row>
        </TabPane>
        <TabPane tab="Open" key="Open">
          <Row>
            {idoCards}
          </Row>
        </TabPane>
        <TabPane tab="Upcoming" key="Upcoming">
          <Row>
            {idoCards}
          </Row>
        </TabPane>
        <TabPane tab="Ended" key="Ended">
          <Row>
            {idoCards}
          </Row>
        </TabPane>
      </Tabs>
    </div>;
  }
}

import React, { Component } from "react";
import { Row, Col, Input } from "antd";
import "antd/dist/antd.css";

//PROPS
/**
 * addonBefore
 * addonAfter
 * defaultValue
 * isHorizontalLabel
 * labelFontSize
 * labelColorClass
 * labelText
 * addonBefore
 * addonAfter
 * defaultValue
 * allowClear
 * disabled
 * maxLength
 * prefixIcon
 * suffixIcon
 * onChange
 * value
 */

const style = {
  textAlign: "center",
  fontWeight: "bold",
};

export default class BorderTextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
}

  render() {
    return (
      <Row>
        <Col span={24} style={{ zIndex: 10 }}>
          <small
            style={{
              display: 'inline-block',
              marginBottom: '-10px',
              backgroundColor: '#FFFFFF',
              marginLeft: '5px',
              fontWeight: 700,
              fontSize: this.props.labelFontSize || 12,
              transition: 'all .4s',
              color: this.props.lableColor || 'rgba(40,61,92,0.50)'
            }}
            className={this.props.labelStyle}
          >
            {this.props.labelText}
          </small>
        </Col>
        <Col span={24}>
          <Input
            style={{
              borderRadius: 8
            }}
            size={this.props.size || undefined}
            block="true"
            addonBefore={this.props.addonBefore || ""}
            className={this.props.className || ""}
            addonAfter={this.props.addonAfter || ""}
            defaultValue={this.props.defaultValue || ""}
            allowClear={this.props.allowClear || true}
            bordered={true}
            disabled={this.props.disabled || false}
            maxLength={this.props.maxLength || undefined}
            prefix={this.props.prefixIcon || undefined}
            suffix={this.props.suffixIcon || undefined}
            onChange={this.props.onChange}
            value={this.props.value || undefined}
            type={this.props.type || "text"}
            placeholder={this.props.placeholder || ""}
          />
        </Col>
      </Row>
    )
  }
}

import { ethers } from "ethers";

class BscService {
  approve = async (values) => {
    let { ethereum, contract, cAddress, sender, amount, decimal, actionWith } =
      values;

    const _amount = ethers.utils.parseUnits(amount.toString(), decimal);
    let encoded = contract.methods.approve(actionWith, _amount).encodeABI();

    let tx = {
      from: sender,
      to: cAddress,
      data: encoded,
      nonce: "0x00",
    };

    let txHash = await ethereum
      .request({
        method: "eth_sendTransaction",
        params: [tx],
      })
      .catch((err) => console.log(err));

    console.log(txHash, "txHash");

    return txHash;
  };

  balanceOf = async (values) => {
    let { ethereum, contract, cAddress, sender, amount, decimal, actionWith } =
      values;

    let result = await contract.methods
      .balanceOf(sender)
      .call()
      .then((data) => data);

    let _balance = ethers.utils.formatUnits(result, decimal);

    return _balance;
  };

  isAllowed = async (values) => {
    let { ethereum, contract, cAddress, sender, amount, decimal, actionWith } =
      values;

    let result = await contract.methods
      .allowance(sender, actionWith)
      .call()
      .then((data) => data);

    let _allowedAmount = ethers.utils.formatUnits(result, decimal);
    console.log(_allowedAmount, "_allowedAmount");

    return _allowedAmount;
  };
}

export default new BscService();

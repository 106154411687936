import React, { Component } from 'react';
import LoginRegisterContent from './LoginRegisterContent';
import PromodexLayout from '../../components/layout/PromodexLayout';


export default class LoginRegisterLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

  render() {
    return (
        <PromodexLayout page='loginRegisterPage' content={<LoginRegisterContent />} />
    )

  }
}

import { Col, Row, Tabs, Image, Modal, Table } from 'antd';
import React, { Component } from 'react';
import PromodexLogoHeader from "../../assets/images/logo/Promodex-350x195-cropped.png";
import TextCard from '../../components/card/TextCard'


import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"

import whatIsPromodex from "../../assets/images/project-images/whatIsPromodex.png"
import ProgramInfluencerMarketing from '../../assets/images/project-images/ProgrammatizingInflMarketing.png'
import marketCondition from '../../assets/images/project-images/marketCondition.png'

import {
    PromodexCircle, Web, Youtube, Linkedin, Instagram, Twitter, Telegram, Medium,
    MikailGokmen, SerkanUgur, HakanAysel, FeridunYilmaz, WhitePaper, OnePager, Video, SeeOnBscScan, AddToMetamask,
    PromodexTechUsed
} from '../../components/layout/Icons';
import { getProject } from './api';
import { AddBNBToMetamask } from '../../web3/utils/AddToMetamask';
const PromodexCircleIcon = PromodexCircle()
const WebIcon = Web()
const YoutubeIcon = Youtube()
const LinkedinIcon = Linkedin()
const InstagramIcon = Instagram()
const TwitterIcon = Twitter()
const TelegramIcon = Telegram()
const MediumIcon = Medium()
const MikailGokmenImage = MikailGokmen()
const SerkanUgurImage = SerkanUgur()
const HakanAyselImage = HakanAysel()
const FeridunYilmazImage = FeridunYilmaz()
const WhitePaperIcon = WhitePaper()
const OnePagerIcon = OnePager()
const VideoIcon = Video()
const SeeOnBscScanIcon = SeeOnBscScan()
const AddToMetamaskIcon = AddToMetamask()
const PromodexTechUsedImage = PromodexTechUsed()

const { TabPane } = Tabs;



const realizationColumns = [
    {
        title: 'Token Sale Realizations',
        dataIndex: 'realization',
        key: 'realization',
        render: text => <div>
            <Row>
                <Col xs={7}>
                    <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12 }}>{text[0]}</p>

                </Col>
                <Col xs={6}>
                    <p style={{ fontWeight: 100, color: '#283D5C', fontSize: 10 }}>{text[1]}</p>
                </Col>
            </Row>
        </div>,
    },
    {
        title: 'Token Sale Timelog',
        dataIndex: 'timelog',
        key: 'timelog',
        render: text => <div>
            <Row>
                <Col xs={5}>
                    <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12 }}>{text[0]}</p>

                </Col>
                <Col xs={8}>
                    <p style={{ fontWeight: 100, color: '#283D5C', fontSize: 10 }}>{text[1]}</p>
                </Col>
                <Col xs={8}>
                    <p style={{ fontWeight: 100, color: '#283D5C', fontSize: 10 }}>{text[1]}</p>
                </Col>
            </Row>
        </div>,
    },
];

const realizationData = [
    {
        key: '1',
        realization: ['Total Users Swapped:', '2345'],
        timelog: ['Open:', '2021-12-02 08:00:00 UTC', '2021-12-02 08:00:00 UTC']
    },
    {
        key: '2',
        realization: ['Total Funds Swapped:', '100.099 BUSD'],
        timelog: ['FCFS Prepare:', '2021-12-02 08:00:00 UTC', '2021-12-02 08:00:00 UTC']
    },
    {
        key: '3',
        realization: ['Total Token Swapped:', '9.000.000 PROMO'],
        timelog: ['FCFS started:', '2021-12-02 08:00:00 UTC', '2021-12-02 08:00:00 UTC']
    },
    {
        key: '4',
        realization: ['Total Users Swapped:', '2345'],
        timelog: ['Open:', '2021-12-02 08:00:00 UTC', '2021-12-02 08:00:00 UTC']
    },
    {
        key: '5',
        realization: ['Total Funds Swapped:', '100.099 BUSD'],
        timelog: ['FCFS Prepare:', '2021-12-02 08:00:00 UTC', '2021-12-02 08:00:00 UTC']
    },
    {
        key: '6',
        realization: ['Total Token Swapped:', '9.000.000 PROMO'],
        timelog: ['FCFS started:', '2021-12-02 08:00:00 UTC', '2021-12-02 08:00:00 UTC']
    },
];

// This component will be project detail page.
export default class ProjectPageContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedListItem: 1,
            isPurchaseAuditModalVisible: false,
            showSample: false,
            isComingSoonModalVisible: false,

            project: null
        }
    }

    comingSoonModal = () => {
        this.setState({ isComingSoonModalVisible: !this.state.isComingSoonModalVisible })
    }

    componentDidMount() {

        // Get project id from url
        const parsedUrl = new URL(window.location.href);
        const id = parsedUrl.searchParams.get("id")


        getProject('1').then(response => {
            this.setState({
                id: id,
                project: response.data
            }, () => console.log('RESPONSE : ', this.state.project));

        }).catch(error => console.log('ERROR : ', error))

        // After getting the id from url, we will send a request to our backend for the spesific details about project.

    }

    handleContent = (index) => {
        switch (index) {
            case 0:
                this.setState({ selectedListItem: index })
                return index;
            case 1:
                this.setState({ selectedListItem: index })
                return index;
            case 2:
                this.setState({ selectedListItem: index })
                return index;
            case 3:
                this.setState({ selectedListItem: index })
                return index;
            case 4:
                this.setState({ selectedListItem: index })
                return index;
            case 5:
                this.setState({ selectedListItem: index })
                return index;
            case 6:
                this.setState({ selectedListItem: index })
                return index;
            case 7:
                this.setState({ selectedListItem: index })
                return index;
            case 8:
                this.setState({ selectedListItem: index })
                return index;
            case 9:
                this.setState({ selectedListItem: index })
                return index;
            default:
                return index;
        }
    }

    purchaseAuditModal = () => {
        this.setState({ isPurchaseAuditModalVisible: !this.state.isPurchaseAuditModalVisible })
    }



    render() {
        const projectTags = this.state.project?.project[0].tags.map((tag, index) => {
            return (
                <Col key={index}>
                    <TextCard context={tag} />
                </Col>
            )
        })


        return <div>

            <Modal className='' title="" visible={this.state.isComingSoonModalVisible} maskClosable={true}
                onCancel={this.comingSoonModal}
                maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
                <Row>
                    <Col xs={24} align='center' className='margin-y-40'>
                        <Image
                            style={{}}
                            width={100}
                            preview={false}
                            src={blueP}
                        /> <br />
                        <p style={{
                            textAlign: 'center',
                            fontSize: 24,
                            color: '#5340ff'
                        }}>
                            COMING SOON...
                        </p>
                    </Col>
                </Row>
            </Modal>
            <Row gutter={[24, 0]} style={{marginLeft: 0, marginRight: 0}}>
                <Col xs={{ span: 24 }} md={{ span: 10, offset: 2 }} className='project-page-tabs'>
                    <Tabs animated={false}>
                        <TabPane tab="Project Overview" key="1" className='padding-16 bg-white border-bottom'>
                            <Row gutter={[0,8]}>
                                <Col xs={14} lg={3}>
                                    {PromodexCircleIcon}
                                </Col>
                                <Col xs={8} lg={4}>
                                    <Image width={120} preview={false} src={PromodexLogoHeader} style={{ marginTop: 8 }} />
                                </Col>
                                <Col xs={24} lg={{ span: 17 }} align='right'>
                                    <a target='_blank' href='https://promodex.io'>
                                        {WebIcon}
                                    </a>
                                    <a target='_blank' href='https://www.youtube.com/channel/UCEtCnSnFlHqgB6woV4HVP4w'>
                                        {YoutubeIcon}
                                    </a>
                                    <a target='_blank' href='https://www.linkedin.com/company/promodexio/'>
                                        {LinkedinIcon}
                                    </a>
                                    <a target='_blank' href='https://www.instagram.com/promodexio/'>
                                        {InstagramIcon}
                                    </a>
                                    <a target='_blank' href='https://twitter.com/promodexio'>
                                        {TwitterIcon}
                                    </a>
                                    <a target='_blank' href='https://t.me/promodexio_global?utm_medium=social&utm_source=linktree&utm_campaign=community+channel'>
                                        {TelegramIcon}
                                    </a>
                                    <a target='_blank' href='https://promodex.medium.com/'>
                                        {MediumIcon}
                                    </a>
                                </Col>
                            </Row>
                            <Row className='margin-top-10'>
                                <Col xs={{ span: 16 }}>
                                    <p style={{ color: '#283D5C', fontWeight: 'bold' }}>{this.state.project?.project[0].title}</p>
                                </Col>
                                <Col xs={{ span: 8 }} align='right'>
                                    <a target='_blank' href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view'>
                                        {WhitePaperIcon}
                                    </a>
                                    <a target='_blank' href='https://drive.google.com/file/d/1JL55LpwbJ_XmD-LRNbAwDeANQNIicXsf/view' style={{ marginLeft: 6, marginRight: 6 }}>
                                        {OnePagerIcon}
                                    </a>
                                    <a target='_blank' href='https://www.youtube.com/watch?v=ZnMfRoYBG6Q&ab_channel=promodexio'>
                                        {VideoIcon}
                                    </a>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                {projectTags}
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <p style={{ color: '#283D5C', fontWeight: 100 }}>
                                        {this.state.project?.project[0].description}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 24 }} align='center'>
                                    <p style={{ fontSize: 12, fontWeight: 'bold', color: '#283D5C' }}>Core Team</p>
                                </Col>
                                <Col xs={5} align='left'>
                                    <Row>
                                        <Col xs={24}>
                                            <a target='_blank' href='https://www.linkedin.com/in/mikail-g%C3%B6kmen/'>
                                                {MikailGokmenImage}
                                            </a>
                                        </Col>
                                        <Col xs={24}>
                                            <p style={{ fontSize: 12, color: '#283D5C' }}>Mikail GOKMEN <br />
                                                <small style={{ fontSize: 12, color: '#283D5C', fontWeight: 100 }}>Founder, CEO</small>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6} align='center'>
                                    <Row>
                                        <Col xs={24}>
                                            <a target='_blank' href='https://www.linkedin.com/in/serkan-u%C4%9Fur-9b011743/'>
                                                {SerkanUgurImage}
                                            </a>
                                        </Col>
                                        <Col xs={24}>
                                            <p style={{ fontSize: 12, color: '#283D5C' }}>Serkan UGUR <br />
                                                <small style={{ fontSize: 12, color: '#283D5C', fontWeight: 100 }}>CoFounder, CMO</small>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={8} align='center'>
                                    <Row>
                                        <Col xs={24}>
                                            <a target='_blank' href='https://www.linkedin.com/in/hakan-aysel-58398516/'>
                                                {HakanAyselImage}
                                            </a>
                                        </Col>
                                        <Col xs={24}>
                                            <p style={{ fontSize: 12, color: '#283D5C' }}>Hakan AYSEL <br />
                                                <small style={{ fontSize: 12, color: '#283D5C', fontWeight: 100 }}>CoFounder, CTO</small>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={5} align='right'>
                                    <Row>
                                        <Col xs={24}>
                                            <a target='_blank' href='https://www.linkedin.com/in/feridun-y%C4%B1lmaz-24105112b/'>
                                                {FeridunYilmazImage}
                                            </a>
                                        </Col>
                                        <Col xs={24}>
                                            <p style={{ fontSize: 12, color: '#283D5C' }}>Feridun YILMAZ <br />
                                                <small style={{ fontSize: 12, color: '#283D5C', fontWeight: 100 }}>CoFounder, CNO</small>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col sm={{ span: 6, offset: 5 }} align='center'>
                                    <Row>
                                        <Col sm={24}>
                                            <p style={{ fontWeight: 'bold', fontSize: 12, color: '#283D5C' }}>Lead Advisor</p>
                                        </Col>
                                        <Col sm={24}>
                                            <a target='_blank' href='https://www.linkedin.com/in/mikail-g%C3%B6kmen/'>
                                                {MikailGokmenImage}
                                            </a>
                                        </Col>
                                        <Col sm={24}>
                                            <p style={{ fontSize: 12, color: '#283D5C' }}>Mikail GOKMEN <br />
                                                <small style={{ fontSize: 12, color: '#283D5C', fontWeight: 100 }}>Founder, CEO</small>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={8} align='center'>
                                    <Row>
                                        <Col sm={24}>
                                            <p style={{ fontWeight: 'bold', fontSize: 12, color: '#283D5C' }}>Lead Advisor</p>
                                        </Col>
                                        <Col sm={24}>
                                            <a target='_blank' href='https://www.linkedin.com/in/mikail-g%C3%B6kmen/'>
                                                {MikailGokmenImage}
                                            </a>
                                        </Col>
                                        <Col sm={24}>
                                            <p style={{ fontSize: 12, color: '#283D5C' }}>Mikail GOKMEN <br />
                                                <small style={{ fontSize: 12, color: '#283D5C', fontWeight: 100 }}>Founder, CEO</small>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col> */}
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 10, offset: 0 }} className='project-page-tabs'>
                    <Tabs animated={false}>
                        <TabPane tab="Token Details" key="1" className='padding-16 bg-white border-bottom'>
                            <Row gutter={[24,24]}>
                                <Col xs={24}>
                                    <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Token</p>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Ticker
                                        </div>
                                        Promo
                                    </div>
                                    <div style={{ marginTop: 8, width: 100, height: 15 }}>
                                        <a target='_blank' href='https://bscscan.com/address/0x52FF7dda3FCDc8D3ca2bbf150FDcC7A5f42d989E#code'>
                                            {SeeOnBscScanIcon}
                                        </a>
                                    </div>

                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Type
                                        </div>
                                        BEP20
                                    </div>
                                    <div style={{ marginTop: 8, width: 100, height: 15 }}>
                                        <a onClick={AddBNBToMetamask}>
                                            {AddToMetamaskIcon}
                                        </a>
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Blockchain
                                        </div>
                                        Binance Smart Chain
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 80, marginBottom: 80 }} gutter={[24,24]}>
                                <Col xs={24} align='center'>
                                    <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Token Metrics</p>
                                </Col>
                                <Col xs={24} lg={8} lg={{ span: 6, offset: 5 }} align='center'>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Total Supply
                                        </div>
                                        900.000.000
                                    </div>
                                </Col>
                                <Col xs={24} lg={8} lg={8} align='center'>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Circulating Supply
                                        </div>
                                        N/A
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 40, marginBottom: 85 }} gutter={[24,24]}>
                                <Col xs={24}>
                                    <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Market Condition</p>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Market Cap
                                        </div>
                                        $ 15.500.000
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Price
                                        </div>
                                        $ 0.022
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            ATL / ATH
                                        </div>
                                        N/A
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Token Sale Screening" key="2" className='padding-16 bg-white border-bottom'>
                            <Row gutter={[24, 24]}>
                                <Col xs={24}>
                                    <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Audit</p>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Audit
                                        </div>
                                        Passed
                                    </div>
                                    <p style={{ color: '#5340FF', fontWeight: 100, fontSize: 12, cursor: 'pointer' }}
                                        onClick={this.comingSoonModal}>
                                        Purchase Audit
                                    </p>

                                    {/* <Modal className='connect-wallet-modal'
                                        title="" visible={this.state.isPurchaseAuditModalVisible}
                                        maskClosable={true} onCancel={this.purchaseAuditModal}
                                        maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }}
                                        footer={null}>
                                        <Row>
                                            <Col sm={{ span: 22, offset: 1 }}>
                                                <p style={{
                                                    fontFamily: 'Helvetica-Bold',
                                                    fontSize: 12,
                                                }}>Promodex Project Audit Service</p>
                                            </Col>
                                            <Col sm={{ span: 8, offset: 0 }}>

                                            </Col>
                                        </Row>
                                    </Modal> */}
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Experts Rating
                                        </div>
                                        N/A
                                    </div>
                                    <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                        Purchase Expert Rating
                                    </a>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Followers
                                        </div>
                                        60000
                                    </div>
                                    {/* <a target='_blank' href='' style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                        See Project Page
                                    </a> */}
                                </Col>
                            </Row>

                            <Row className='margin-y-40' gutter={[24, 24]}>
                                <Col xs={24}>
                                    <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Engagement</p>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Incubator
                                        </div>
                                        Devtagon
                                    </div>
                                    <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                        See Incubator Page
                                    </a>

                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Lead Investor
                                        </div>
                                        Alpha Capital
                                    </div>
                                    <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                        See Lead Investor Page
                                    </a>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Lead Advisor
                                        </div>
                                        N/A
                                    </div>
                                    {/* <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                        See Advisor Page
                                    </a> */}
                                </Col>
                            </Row>

                            <Row className='margin-y-40' gutter={[24, 24]}>
                                <Col xs={24}>
                                    <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Market Hit</p>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Initial Circulation
                                        </div>
                                        5.400.00 Promo
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Initial Market Price
                                        </div>
                                        $ 0.022
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Initial Market Cap
                                        </div>
                                        $ 118.800
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 40, marginBottom: 21 }} gutter={[24, 24]}>
                                <Col xs={24}>
                                    <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Listing</p>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Listing Date
                                        </div>
                                        28 Febuary 2022
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Listing Time
                                        </div>
                                        20.00 UTC
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Listing Exchange
                                        </div>
                                        PancakeSwap
                                    </div>
                                    <a target='_blank' href='https://pancakeswap.finance/' style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                        See Exchange Page
                                    </a>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Row className='margin-top-20'>
                <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }} className='padding-16 shadow-white-div project-page-tabs-2'>
                    <Tabs animated={false}>
                        <TabPane tab='About' key='1'>
                            <Row>
                                <Col xs={24} lg={6} style={{ boxShadow: '0 0 3px 3px rgba(40,61,92,0.10)' }}>
                                    <p style={{ fontWeight: 900, color: '#000659', fontSize: 14 }}>Content</p>
                                    <ol>
                                        {/* {this.state.project?.projectContent.map((content, index) => {
                                            return(
                                                <li key={index} className='ordered-list clickable-text' onClick={() => this.handleContent(index)} style={{ color: this.state.selectedListItem === index && '#5340FF' }}>
                                                    {content.projectContentTitle__title}
                                                </li>
                                            )
                                        })
                                        } */}
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(1)} style={{ color: this.state.selectedListItem === 1 && '#5340FF' }}>What is Promodex?</li>
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(2)} style={{ color: this.state.selectedListItem === 2 && '#5340FF' }}>Programmatizing Influencer Marketing</li>
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(3)} style={{ color: this.state.selectedListItem === 3 && '#5340FF' }}>How does it work for a promoter?</li>
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(4)} style={{ color: this.state.selectedListItem === 4 && '#5340FF' }}>How does it work for a campaigner?</li>
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(5)} style={{ color: this.state.selectedListItem === 5 && '#5340FF' }}>Market Condition</li>
                                        {/* <li className='ordered-list clickable-text' onClick={() => this.handleContent(6)} style={{ color: this.state.selectedListItem === 6 && '#5340FF' }}>Technology Used</li>
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(7)} style={{ color: this.state.selectedListItem === 7 && '#5340FF' }}>Backers & Partnets</li>
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(8)} style={{ color: this.state.selectedListItem === 8 && '#5340FF' }}>Tokenomics</li>
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(9)} style={{ color: this.state.selectedListItem === 9 && '#5340FF' }}>Token Release</li>
                                        <li className='ordered-list clickable-text' onClick={() => this.handleContent(10)} style={{ color: this.state.selectedListItem === 10 && '#5340FF' }}>Market Condition</li> */}
                                    </ol>
                                </Col>
                                <Col xs={24} lg={18} className='padding-16'>
                                    {this.state.selectedListItem === 1 &&
                                        <div>
                                            <Row gutter={[8, 16]}>
                                                <Col xs={24}>
                                                    <p>1. What is Promodex?</p>
                                                </Col>
                                                <Col xs={24} md={10}>
                                                    <Image alt='What Is Promodex?' src={whatIsPromodex} preview={false} />
                                                </Col>
                                                <Col xs={24} md={14}>
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        Promodex basically brings the project owners together with influencers, channel owners, publishers in every size and bounty hunting followers under a marketplace. When project owners as a campaigner meet their promotional needs and obtain sustainability for their projects, promoters monetize their publicity potential and followers get bounties by participating in the promotion campaigns.
                                                    </p>
                                                </Col>
                                                <Col xs={24} >
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        Promodex is a programmatic marketplace for campaigners to create promotion and bounty campaigns for publicity as well as initial coin offering for fundraising. So a blockchain based start-up may launch its token offering and at the same time launch a promotion, bounty or ads campaign for contribution of promoters in every size available on the platform.
                                                        <br />
                                                        They can launch the campaign in different languages, to different geolocations, on different social media platforms by different requirements. They may filter influencers according to their Influence Power Grade (IPG) which is algorithmically defined by the number, the profile and engagement level of the followers.
                                                        <br />
                                                        Token based or not, a new or already running company may launch a campaign on Promodex. The campaigners have to stake the campaign budget in token, so the rewards are guaranteed for the participant promoters under blockchain decentralized nature. Those staked tokens can be PROMO token of Promodex as well as the own token of the project itself. Moreover, locked tokens of projects in vesting period can be staked as the reward of the campaign. In general there are locked tokens allocated for the marketing purpose in the hands of projects and so far they were not able to utilize them before the vesting period ends. Now locked tokens in the vesting period can be utilized on Promodex for a campaign before they meet the market. So that the projects may get the benefits of those tokens earlier than they meet and dump the market price.
                                                    </p>
                                                    <a target='_blank' href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view' style={{color: '#5340FF'}}>Check for more details from Whitepaper</a>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    {this.state.selectedListItem === 2 &&
                                        <div>
                                            <Row gutter={[8, 16]}>
                                                <Col xs={24}>
                                                    <p>2. Programmatizing Influencer Marketing?</p>
                                                </Col>
                                                <Col xs={24} md={10}>
                                                    <Image alt='Programmatizing Influencer Marketing?' src={ProgramInfluencerMarketing} preview={false} />
                                                </Col>
                                                <Col xs={24} md={14}>
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        <b>Campaign Launch: </b> Any project needs publicity can launch a promotion campaign on Promodex. Token based or not, new or already running projects may create campaigns as well. Campaigners can create campaigns exclusively for a selected group of influencers or public in general. Again campaigners can distribute circulating valuable tokens or non-circulating potentially valuable tokens as reward. So the contribution-reward model is known as airdrop in the market.
                                                    </p>
                                                </Col>
                                                <Col xs={24} >
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        <b>IDO Launch: </b> A new crypto based start-ups may launch their Initial Token Offering IDO) on decentralized infrastructure of the Promodex platform. They can offer their token exclusively to participating promoters of the campaign as a private sale and separately to ordinary investors in a public sale. So they are able to run a private sale and public sale on the same platform. IDO can be conducted exclusively for the campaign participants. Furthermore, contribution points to related promotion campaigns can be taken as a parameter of token allocation in IDO along with the tier model.
                                                        <br />
                                                        <b>Budget Staking: </b> Either PROMO token or project own token can be staked for algorithmic reward calculation and smart contract based reward distribution. Project own tokens may also be locked-up as in the vesting period.                                                    </p>
                                                        <a target='_blank' href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view' style={{color: '#5340FF'}}>Check for more details from Whitepaper</a>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    {this.state.selectedListItem === 3 &&
                                        <div>
                                            <Row gutter={[8, 16]}>
                                                <Col xs={24}>
                                                    <p>3. How does it work for a promoter?</p>
                                                </Col>
                                                <Col xs={24} >
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        <b>Registration</b><br />
                                                        Influencers and publishers who want to use the infrastructure of Promodex can click on the registration button. On the registration page, the influencer or the publisher sets an email and a password. Every social media account which is to be registered must be connected to an authentication api. For a publisher registration a confirmation email will be sent to the official email address. Then registration is completed.
                                                        <br />
                                                        <br />
                                                        <b>Completing the Profile and KYC</b><br />
                                                        The promoter completes the missing profile on the admin panel. If available, other social media accounts or websites are added. The KYC process has to be completed to participate in the campaigns.  The wallet address is entered and confirmed by the confirmation code sent to the e-mail address or mobile phone.
                                                        <br />
                                                        <br />
                                                        <b>Evaluating the campaigns on the dashboard</b><br />
                                                        Campaigns that are matching a profile appear on the dashboard. Promoters can choose any campaigns to participate in.
                                                        <br />
                                                        <br />
                                                        <b>Participating in the campaigns</b><br />
                                                        Promoter completes the campaign participation requirements and sends the proof of participation link for the campaigner confirmation.
                                                        <br />
                                                        <br />
                                                        <b>Campaign approval process</b><br />
                                                        Campaign participation requests appear on the campaigner dashboard. In case the request is approved, the promoter Contribution Point(CP) is automatically added to the pool. If the requirements are not fulfilled, the campaign owner may demand an update or reject by a reasonable remark. In case of disagreement, Promodex is involved for a resolution.
                                                        <br />
                                                        <br />
                                                        <b>Rewards is automatically transferred to wallet</b><br />
                                                        Whenever the campaign period ends or pool is filled the rewards are automatically transferred by the smart contract to the wallet of participating promoters.
                                                    </p>
                                                    <a target='_blank' href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view' style={{color: '#5340FF'}}>Check for more details from Whitepaper</a>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    {this.state.selectedListItem === 4 &&
                                        <div>
                                            <Row gutter={[8, 16]}>
                                                <Col xs={24}>
                                                    <p>3. How does it work for a campaigner?</p>
                                                </Col>
                                                <Col xs={24} >
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        <b> Pre-registration</b><br />
                                                        Project owners who want to launch an IDO or a campaign apply for pre-registration with the campaign details and personal information.
                                                        <br />
                                                        <br />
                                                        <b>The registration is approved after the evaluation of project and campaign</b><br />
                                                        Based on the pre-registration, the Promodex team checks the project and the project owner's information. Pre-registrations with the convenient conditions are approved. A confirmation email is sent to the project owner. Then he can log in to the administration panel with the username and password.
                                                        <br />
                                                        <br />
                                                        <b>The owner and project details are filled and  KYC is completed</b><br />
                                                        The missing information of the project and owner's profile are completed and then the KYC process is completed.
                                                        <br />
                                                        <br />
                                                        <b>Launch a campaign</b><br />
                                                        <li>
                                                            <ol>Log into the campaign section</ol>
                                                            <ol>Click to create a new campaign</ol>
                                                            <ol>Choose the type of campaign either Promotion Campaign or IDO</ol>
                                                            <ol>Select languages, platforms, geolocations, beginning and end date</ol>
                                                            <ol>Filter the promoter interest and grade</ol>
                                                            <ol>Specify the campaign information and requirements</ol>
                                                            <ol>Select to include or exclude a specific promoter if available</ol>
                                                            <ol>Launch the campaign for approval of Promodex team</ol>
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <b>Review of campaign by the Promodex team</b><br />
                                                        The created campaign is reviewed by the Promodex team. For a successful campaign, the Promodex team guides the campaigner. If required, necessary corrections and additions are done by the campaigner. The final form of campaign is published by the campaigner and approved by the Promodex team.
                                                        <br />
                                                        <br />
                                                        <b>Approving campaign participations</b><br />
                                                        Participation of promoters are reviewed by campaigners during the campaign period.
                                                        Participations that fulfill the campaign requirements are approved. If the requirements are not fulfilled, the campaign owner may demand an update or reject by a reasonable remark. Rejection is reviewed and finalized by the Promodex team either as accepted or rejected.
                                                    </p>
                                                    <a target='_blank' href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view' style={{color: '#5340FF'}}>Check for more details from Whitepaper</a>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    {this.state.selectedListItem === 5 &&
                                        <div>
                                            <Row gutter={[8, 16]}>
                                                <Col xs={24}>
                                                    <p>5. Market Condition</p>
                                                </Col>
                                                <Col xs={24} md={10}>
                                                    <Image alt='Market Condition' src={marketCondition} preview={false} />
                                                </Col>
                                                <Col xs={24} md={14}>
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        <b>Influencer Marketing Market</b><br/>
                                                        Digital advertising market is expanding rapidly. According to Statista, global digital marketing will be 332B. USD in 2020. It was expected to be 526B. USD in 2024 and it is expected to be over 1T USD by the end of decade.
                                                    </p>
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        According to Statista almost 10% of all digital budgets, 35B USD is going to ad fraud. Moreover, there is a long way for advertisers to meet the audience due to many intermediary products and service providers. According to the Incorporated Society of British Advertisers (ISBA) study, publishers receive just 51% of the budget spent by the advertisers. Some other studies show this goes up to 55% of all ad budgets spent.
                                                    </p>
                                                    <a target='_blank' href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view' style={{color: '#5340FF'}}>Check for more details from Whitepaper</a>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    {this.state.selectedListItem === 6 &&
                                        <div>
                                            <Row gutter={[8, 16]}>
                                                <Col xs={24}>
                                                    <p>6. Technology Used</p>
                                                </Col>
                                                <Col xs={24} md={10}>
                                                    {PromodexTechUsedImage}
                                                </Col>
                                                <Col xs={24} md={14}>
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        With the evolution of the internet, people have started to spend more time on the web. Especially with the mobile revolution, user engagement has been shifting to mobile, which has dramatically transformed advertising strategies and practices. Advertisers are simply where people spend time. That's why advertising on the web and more on mobile has been increasing rapidly. Especially with the evolution of programmatic digital advertising, it has been easier for advertisers to reach their target audiences. Programmatic digital advertising, appearing on thousands of websites, apps, and social media platforms, is just a few clicks away and catches the target
                                                    </p>
                                                </Col>
                                                <Col xs={24} >
                                                    <p style={{ fontWeight: 100, color: '#283D5C', lineHeight: 'normal' }}>
                                                        audience at the right time wherever they are. Today the digital advertising market amounts to around 330B USD in 2020 and is
                                                        projected to exceed 1 Trillion USD by the end of the decade. While having great advantages, programmatic digital advertising
                                                        bears three main problems causing low Return of Investment (ROI) and pushing advertisers to seek alternative options. Taking
                                                        away 55% of all the advertising budget, service providers are the major problem between advertisers and their audiences.
                                                        Secondly, ad fraud poses a significant threat to digital advertising, biting a tenth of the budget. The third problem is the limited
                                                        and centralized advertising options but tremendously increasing demand in line with the digitalization of the world. That being
                                                        the case, high demand and insufficient supply creates increasingly fierce competition and
                                                        consequently increasing ad prices.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab='Audit' key='2' style={{ paddingBottom: 22 }}>
                            <div style={{ marginLeft: 10 }} className={this.state.showSample ? 'show-sample-div show-active' : 'show-sample-div'} onClick={() => this.setState({ showSample: !this.state.showSample })}>
                                {this.state.showSample ? 'Hide Sample' : 'Show Sample'}
                            </div>
                            <div style={{ filter: this.state.showSample ? '' : 'blur(4px)', transition: 'filter .3s', pointerEvents: 'none', userSelect: 'none' }}>
                                <Row>
                                    <Col xs={24}>
                                        <Table scroll={{ x: 1000 }} size='small' columns={realizationColumns} dataSource={realizationData} pagination={false} className='ant-table-strip' />
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tab='Experts Rating' key='3' style={{ paddingBottom: 22 }}>
                            <div style={{ marginLeft: 10 }} className={this.state.showSample ? 'show-sample-div show-active' : 'show-sample-div'} onClick={() => this.setState({ showSample: !this.state.showSample })}>
                                {this.state.showSample ? 'Hide Sample' : 'Show Sample'}
                            </div>
                            <div style={{ filter: this.state.showSample ? '' : 'blur(4px)', transition: 'filter .3s', pointerEvents: 'none', userSelect: 'none' }}>
                                <Row>
                                    <Col xs={24}>
                                        <Table scroll={{ x: 1000 }} size='small' columns={realizationColumns} dataSource={realizationData} pagination={false} className='ant-table-strip' />
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>;
    }
}

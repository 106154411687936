import { Col, Row, Tabs, Image, Progress, Table, Modal, Spin, notification, Popover } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import PromodexLogoHeader from "../../assets/images/logo/Promodex-350x195-cropped.png";
import TextCard from '../../components/card/TextCard'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../redux/promodex/actions";
import moment from "moment"

import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"
import promodexImage from '../../assets/images/dummy-images/Promodex - Facebook - Küçük.png'


import PromoInput from "../../components/input/PromoInput"
import { BUSDisAllowed, BUSDapprove, buy, getLockDetails, getAddressBuyLimitLeft, getDepositsByWithdrawalAddress } from "../../web3/contractService/service"


import approveImage from "../../assets/images/dummy-images/startBuying.png"

import { AiFillInfoCircle } from "react-icons/ai";
import { MdOutlineLooksOne } from "react-icons/md"
import { AddBNBToMetamask, AddBusdToMetamask, AddPromoToMetamask } from '../../web3/utils/AddToMetamask';
import { getIdo, createTransaction, getReport } from './api';

import {
    PromodexCircle, Web, Youtube, Linkedin, Instagram, Twitter, Telegram, Medium,
    MikailGokmen, SerkanUgur, HakanAysel, FeridunYilmaz, WhitePaper, OnePager, Video, SeeOnBscScan, AddToMetamask,
    PromodexTechUsed, numberOne, numberTwo
} from '../../components/layout/Icons';
import { SplitNumber } from '../../utils/NumberUtils';

const PromodexCircleIcon = PromodexCircle()
const WebIcon = Web()
const YoutubeIcon = Youtube()
const LinkedinIcon = Linkedin()
const InstagramIcon = Instagram()
const TwitterIcon = Twitter()
const TelegramIcon = Telegram()
const MediumIcon = Medium()
const MikailGokmenImage = MikailGokmen()
const SerkanUgurImage = SerkanUgur()
const HakanAyselImage = HakanAysel()
const FeridunYilmazImage = FeridunYilmaz()
const WhitePaperIcon = WhitePaper()
const OnePagerIcon = OnePager()
const VideoIcon = Video()
const SeeOnBscScanIcon = SeeOnBscScan()
const AddToMetamaskIcon = AddToMetamask()
const PromodexTechUsedImage = PromodexTechUsed()
const numberOneIcon = numberOne()
const numberTwoIcon = numberTwo()
const { TabPane } = Tabs;


const popoverStep1 = (
    <div>
        <p>First step: Start Buying ( <img style={{ width: 103, height: 18 }} src={approveImage}></img> )</p>

    </div>
);
const popoverStep2 = (
    <div>
        <p>We are sending your request to Metamask please wait</p>
    </div>
);

const popoverStep3 = (
    <div>
        <p>Let's join pool and buy promo :)</p>
    </div>
);

const idoNotOpenPopover = (
    <div>
        <p>This IDO not on sale please visit to investor page</p>
    </div>
)
// wait for approvel finish buying.  wait for start buying then click on     buy progress notifiaction yazısı

const joinPoolInformationPopover = (
    <div>
        <ul>
            <li>Connect Metamask</li>
            <li>Buy BUSD</li>
            <li>Approve your amount</li>
            <li>Confirm from metamask</li>
            <li>Join pool and buy token</li>
        </ul>
    </div>
)


const detailsColumns = [
    {
        title: 'Token Sale Details',
        dataIndex: 'details',
        key: 'details',
        render: text => <div>
            <Row>
                <Col xs={7}>
                    <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12, marginTop: 10 }}>{text[0]}</p>

                </Col>
                <Col xs={6}>
                    <p style={{ fontWeight: 100, color: '#283D5C', fontSize: 10, marginTop: 10 }}>{text[1]}</p>
                </Col>
                {text[2] === true &&
                    <Col xs={6} style={{ marginTop: 10 }}>
                        <a target='_blank' href='https://bscscan.com/address/0x52FF7dda3FCDc8D3ca2bbf150FDcC7A5f42d989E#code'>
                            {SeeOnBscScanIcon}
                        </a>
                    </Col>
                }
                {text[3] === true &&
                    <Col xs={5} style={{ marginTop: 6 }}>
                        <div onClick={text[4]} style={{ cursor: 'pointer' }}>
                            {AddToMetamaskIcon}
                        </div>
                    </Col>
                }
            </Row>
        </div>,
    },
    {
        title: 'Token Sale Schedule',
        dataIndex: 'schedule',
        key: 'schedule',
        render: text => <div>
            <Row>
                <Col xs={7} style={{ marginTop: 10 }}>
                    <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12 }}>{text[0]}</p>

                </Col>
                <Col xs={8} style={{ marginTop: 10 }}>
                    <p style={{ fontWeight: 100, color: '#283D5C', fontSize: 10 }}>{text[1]}</p>
                </Col>
                {text[2] !== null &&
                    <Col xs={6} style={{ marginTop: 10 }}>
                        <a href={text[3]} style={{ fontWeight: 100, color: '#5340FF', fontSize: 12 }}>{text[2]}</a>
                    </Col>
                }
            </Row>
        </div>,
    },
];


const realizationColumns = [
    {
        title: 'Token Sale Realizations',
        dataIndex: 'realization',
        key: 'realization',
        render: text => <div>
            <Row>
                <Col xs={7}>
                    <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12, marginTop: 10 }}>{text[0]}</p>

                </Col>
                <Col xs={6}>
                    <p style={{ fontWeight: 100, color: '#283D5C', fontSize: 10, marginTop: 10 }}>{text[1]}</p>
                </Col>
            </Row>
        </div>,
    },
    // {
    //     title: 'Token Sale Timelog',
    //     dataIndex: 'timelog',
    //     key: 'timelog',
    //     render: text => <div>
    //         <Row>
    //             <Col sm={5}>
    //                 <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12, marginTop: 10 }}>{text[0]}</p>
    //             </Col>
    //             <Col sm={8}>
    //                 <p style={{ fontWeight: 100, color: '#283D5C', fontSize: 10, marginTop: 10 }}>{text[1]}</p>
    //             </Col>
    //             <Col sm={8}>
    //                 <p style={{ fontWeight: 100, color: '#283D5C', fontSize: 10, marginTop: 10 }}>{text[1]}</p>
    //             </Col>
    //         </Row>
    //     </div>,
    // },
];


const convertTimeStamp = (unix_timestamp) => {

    var a = new Date(unix_timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getUTCFullYear();
    var month = months[a.getUTCMonth()];
    var date = a.getUTCDate();
    var hour = a.getUTCHours();
    var min = a.getUTCMinutes();
    var sec = a.getUTCSeconds();

    min = min.toString().length === 1 ? "0" + min : min

    sec = sec.toString().length === 1 ? "0" + sec : sec
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
}

const convertPromoTokenAmount = (amount) => {
    const index = amount.length - 8
    const amountPart1 = amount.slice(0, index)
    const amountPart2 = amount.slice(index, amount.length)
    return amountPart1 + "," + amountPart2 + " PROMO"
}



const allocationColumns = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        render: text => <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12, marginTop: 10 }}>{text}</p>,
    },
    {
        title: 'Token Amount',
        dataIndex: '_tokenAmount',
        key: '_tokenAmount',
        render: text => <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12, marginTop: 10 }}>{convertPromoTokenAmount(text)}</p>,
    },
    {
        title: 'Unlock Time',
        dataIndex: '_unlockTime',
        key: '_unlockTime',
        render: text => <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12, marginTop: 10 }}>{convertTimeStamp(text)}</p>,
    },
    {
        title: 'Withdrawal Address',
        dataIndex: '_withdrawalAddress',
        key: '_withdrawalAddress',
        render: text => <p style={{ fontWeight: 400, color: '#283D5C', fontSize: 12, marginTop: 10 }}>{text}</p>,
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: text => <div>
            <Row>
                <Col xs={12}>
                    <div style={{
                        fontWeight: 400,
                        color: '#FFFFFF',
                        fontSize: 12,
                        display: 'inline-block',
                        backgroundColor: text[0],
                        borderRadius: 15,
                        padding: '4px 30px',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer'
                    }}>Claim Now</div>

                </Col>
                <Col xs={8}>
                    {text[1] === true &&
                        AddToMetamaskIcon
                    }
                </Col>
            </Row>
        </div>,
    },
];


// This component will be project sale page.
class ProjectSalePageContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            howToBuyPromoTokenVisible: false,
            idoID: null,
            idoStatus: null,
            idoName: null,
            idoType: null,
            isComingSoonModalVisible: false,
            approveParticipationModalVisible: false,
            joinPoolModalVisible: false,
            approveAmount: null,
            joinAmount: null,
            loading: false,
            changeJoinPoolPopover: "1",
            lockedTokens: [],
            lockedTokenTableLoading: true,
            userAllowedAmount: null,
            report: {},
            idoData: {}
        }
    }

    openNotification = (type, title, description) => {
        notification[type]({
            message: title,
            description: description,
        })
    }

    //Join pool işlemleri
    joinPoolModal = async () => {

        if (this.props.promodexInformation !== null) {

            const tempAmount = await BUSDisAllowed(this.props)
            const allowedAmount = parseFloat(tempAmount)
            if (allowedAmount > 0) {

                this.setState({ joinPoolModalVisible: !this.state.joinPoolModalVisible, userAllowedAmount: allowedAmount }, async () => {
                    if (this.state.joinPoolModalVisible) {
                        if (this.props.promodexInformation === null) {
                            this.openNotification("error", "Metamask not connected", "Please connected to metamask account")
                        } else {
                            const isAllowed = await BUSDisAllowed(this.props)
                            this.openNotification("info", "Information", "Your allowed amount " + isAllowed + " BUSD")
                        }
                    } else {
                        this.setState({ joinAmount: null })
                    }
                })
            } else {
                this.openNotification("info", "Information", <p> <span style={{ color: "red" }}>Insufficient allowance amount!</span > Please approve the amount you want to buy first</p >)
            }
        }
        else {
            this.openNotification("error", "Metamask not connected", "Please connected to metamask account")
        }

    }
    handleJoin = async () => {
        if (this.props.promodexInformation !== null) {

            const amount = parseFloat(this.state.joinAmount)


            this.setState({ loading: true })
            const transactionAdress = await buy(this.props, amount)
            const payload = {
                txValue: transactionAdress,
                projectId: 1,
                idoId: this.state.idoID,
                uWallet: this.props.promodexInformation.metamaskAccount,
                amount: amount
            }
            await createTransaction(payload)

            this.setState({ loading: false, joinPoolModalVisible: false, changeJoinPoolPopover: "2" })
            const link = "https://bscscan.com/tx/" + transactionAdress
            this.openNotification("info", "Information", <a target="_blank" href={link} rel="noopener noreferrer">Click here for transaction detail</a>)
            setTimeout(() => {
                this.setState({ changeJoinPoolPopover: "1" })
                this.openNotification("success", "Information", "Transaction success")
            }, 15000)
        } else {
            this.openNotification("error", "Metamask not connected", "Please connected to metamask account")
        }
    }




    //Approve participation işlemleri
    approveParticipationModal = async () => {
        if (this.props.promodexInformation !== null) {
            this.setState({ approveParticipationModalVisible: !this.state.approveParticipationModalVisible }, async () => {
                if (this.state.approveParticipationModalVisible) {
                    const limit = await getAddressBuyLimitLeft(this.props)
                    this.openNotification("info", "Information", "Your max cap: " + limit + " BUSD")
                } else {
                    this.setState({ approveAmount: this.state.idoData.minPerAllocation })
                }
            })
        }
        else {
            this.openNotification("error", "Metamask not connected", "Please connected to metamask account")
        }

    }


    handleApprove = async () => {

        if (this.props.promodexInformation === null) {
            this.openNotification("error", "Metamask not connected", "Please connected to metamask account")
            return
        }
        const amount = parseFloat(this.state.approveAmount)
        if (amount < this.state.idoData.minPerAllocation) {
            this.openNotification('error', 'Warning', 'Can not enter less amount than min amount')
            return
        }

        if (amount > this.state.idoData.maxPerAllocation) {
            this.openNotification('error', 'Warning', 'Can not enter more amount than max amount')
            return
        }

        this.setState({ loading: true })

        const transactionAdress = await BUSDapprove(this.props, amount)

        if (transactionAdress) {
            const link = "https://bscscan.com/tx/" + transactionAdress
            this.openNotification("info", "Information", <a target="_blank" href={link} rel="noopener noreferrer">Click here for transaction detail</a>)
            this.setState({ loading: false, approveParticipationModalVisible: false, changeJoinPoolPopover: "2" })
            this.openNotification("info", "Information", "Wait for start buying then click on (Finish Buying)")
            setTimeout(() => {
                this.setState({ changeJoinPoolPopover: "3" })
            }, 15000)
        } else {
            this.openNotification('error', 'User denied!', 'User didnt give access to metamask')
            this.setState({ loading: false })
        }

    }

    comingSoonModal = () => {
        this.setState({ isComingSoonModalVisible: !this.state.isComingSoonModalVisible })
    }

    getLockedTokens = async () => {

        const allIds = await getDepositsByWithdrawalAddress(this.props)
        const tempArr = [];
        allIds.map(async (id, index) => {
            const token = await getLockDetails(this.props, id)
            tempArr.push({
                ...token, key: index, no: index + 1, action: ['#F7B500', token._withdrawn],
            })
        })
        setTimeout(() => {
            this.setState({ lockedTokens: tempArr, lockedTokenTableLoading: false })
        }, 2000)

    }

    getIdoInformationUrl = () => {
        const parsedUrl = new URL(window.location.href);

        this.setState({
            idoID: parsedUrl.searchParams.get("id"),
            idoStatus: parsedUrl.searchParams.get("status"),
            idoName: parsedUrl.searchParams.get("idoname"),
            idoType: parsedUrl.searchParams.get("idoType"),
        }, () => {
            this.getIdo()
        });
    }


    getSaleReport = async () => {
        const report = await getReport(this.state.idoID)
        this.setState({ report: report })
    }

    howToBuyPromoTokenModal = () => {
        this.setState({ howToBuyPromoTokenVisible: !this.state.howToBuyPromoTokenVisible })
    }

    getIdo = async () => {
        const tempIdoData = await getIdo(this.state.idoID)
        this.setState({ idoData: tempIdoData, approveAmount: tempIdoData.minPerAllocation }, () => console.log('IDO : ', this.state.idoData))
    }

    allocationTabChanged = () => {
        if (this.props.promodexInformation !== null) {
            this.setState({ lockedTokenTableLoading: true }, () => {
                setTimeout(() => {
                    this.getLockedTokens()
                }, 2000)
            })

        }
    }

    async componentDidMount() {
        await this.getIdoInformationUrl()
        await this.getSaleReport()
    }




    render() {
        const realizationData = [
            {
                key: '1',
                realization: ['Total Users Swapped:', this.state.report?.uniqueUwalletCount],
                // timelog: ['Open:', '2021-12-02 08:00:00 UTC', '2021-12-02 08:00:00 UTC']
            },
            {
                key: '2',
                realization: ['Total Funds Swapped:', this.state.report?.totalAmount + " BUSD"],
                // timelog: ['FCFS Started:', '2021-12-02 08:00:00 UTC', '2021-12-02 08:00:00 UTC']
            },
            {
                key: '3',
                realization: ['Total Token Swapped:', Math.floor(this.state.report?.totalAmount * 1000 / 21)],
            },
        ];
        const detailsData = [
            {
                key: '1',
                details: ['Token:', 'PROMO', true, true, AddPromoToMetamask],
                schedule: ['Sale Starts On:', this.state.idoData.startDate]
            },
            {
                key: '2',
                details: ['Swapped by:', 'BUSD', false, true, AddBusdToMetamask],
                schedule: ['Sale End On:', this.state.idoData.endDate]
            },
            {
                key: '3',
                details: ['Swap Rate:', '1 BUSD = 47.6 Promo', false, false],
                schedule: ['FCFS Starts On:', this.state.idoData.fcfsStartDate]
            },
            {
                key: '4',
                details: ['Total Token for Sale:', '4,761,904.76 Promo', false, false],
                schedule: ['FCFS End On:', this.state.idoData.fcfsEndDate]
            },
            {
                key: '5',
                details: ['Total Fund Cap:', '100,000 BUSD', false, false],
                schedule: []
            },
            {
                key: '6',
                details: ['Access type:', 'Public', false, false],
                schedule: []
            },
        ];


        return <div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
                <Row gutter={[24, 0]} style={{marginLeft: 0, marginRight: 0}}>
                    <Col xs={{ span: 24 }} md={{ span: 10, offset: 2 }} className='project-page-tabs'>
                        <Tabs animated={false}>
                            <TabPane tab="Project Overview" key="1" className='padding-16 bg-white border-bottom'>
                                <Row>
                                    <Col xs={24}>
                                        <div style={{
                                            position: 'absolute',
                                            left: '2%',
                                            top: '2%',
                                            zIndex: 1
                                        }}>
                                            {PromodexCircleIcon}
                                        </div>
                                        <Image alt='Promodex' src={promodexImage} width='100%' preview={false} />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 4 }}>
                                    <Col xs={7}>
                                        <Image width={120} preview={false} src={PromodexLogoHeader} style={{ marginTop: 8 }} />
                                    </Col>
                                    <Col xs={{ span: 17 }} align='right'>
                                        <a target='_blank' href='https://promodex.io'>
                                            {WebIcon}
                                        </a>
                                        <a target='_blank' href='https://www.youtube.com/channel/UCEtCnSnFlHqgB6woV4HVP4w'>
                                            {YoutubeIcon}
                                        </a>
                                        <a target='_blank' href='https://www.instagram.com/promodexio/'>
                                            {LinkedinIcon}
                                        </a>
                                        <a target='_blank' href='https://www.instagram.com/promodexio/'>
                                            {InstagramIcon}
                                        </a>
                                        <a target='_blank' href='https://twitter.com/promodexio'>
                                            {TwitterIcon}
                                        </a>
                                        <a target='_blank' href='https://t.me/promodexio_global?utm_medium=social&utm_source=linktree&utm_campaign=community+channel'>
                                            {TelegramIcon}
                                        </a>
                                        <a target='_blank' href='https://promodex.medium.com/'>
                                            {MediumIcon}
                                        </a>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 4 }}>
                                    <Col xs={18} lg={{ span: 12 }}>
                                        <p style={{ color: '#283D5C', fontWeight: 'bold' }}>Programmatic Promotion Marketplace</p>
                                    </Col>
                                    <Col xs={6} lg={{ span: 12 }} align='right'>
                                        <a target='_blank' href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view'>
                                            {WhitePaperIcon}
                                        </a>
                                        <a target='_blank' href='https://drive.google.com/file/d/1JL55LpwbJ_XmD-LRNbAwDeANQNIicXsf/view' style={{ marginLeft: 6, marginRight: 6 }}>
                                            {OnePagerIcon}
                                        </a>
                                        <a target='_blank' href='https://www.youtube.com/watch?v=ZnMfRoYBG6Q&ab_channel=promodexio'>
                                            {VideoIcon}
                                        </a>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col>
                                        <TextCard context={'#Marketing'} />
                                    </Col>
                                    <Col>
                                        <TextCard context={'#Influencer'} />
                                    </Col>
                                    <Col>
                                        <TextCard context={'#Launchpad'} />
                                    </Col>
                                    <Col>
                                        <TextCard context={'#Ads'} />
                                    </Col>
                                    <Col>
                                        <TextCard context={'#BSC'} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <p style={{ color: '#283D5C', fontWeight: 100 }}>Promodex Is A Blockchain Based Programmatic Promotion Marketplace That Brings The Campaigners Together With The Promoters. As a campaigner just create your account and launch your campaign by staking the budget. As an influencer just register, promote the campaigns and get your rewards automatically according to your grade.
                                            <a href={`/project?id=${this.state.id}`} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}> See more</a>
                                        </p>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 4, marginBottom: 2 }}>
                                    {/* <Col sm={6}>
                                    <p style={{ fontWeight: 400, color: '#283D5C' }}>Token:</p>
                                    <p style={{ fontWeight: 400, color: '#283D5C' }}>Type:</p>
                                    <p style={{ fontWeight: 400, color: '#283D5C' }}>Chain:</p>
                                    <p style={{ fontWeight: 400, color: '#283D5C' }}>Total Supply:</p>
                                </Col>
                                <Col sm={8}>
                                    <p className='icon-inline' style={{ fontWeight: 100, color: '#283D5C' }}>PROMO &nbsp;&nbsp;&nbsp; <span>{SeeOnBscScanIcon}</span></p>
                                    <p className='icon-inline' style={{ fontWeight: 100, color: '#283D5C' }}>BEP20 &nbsp;&nbsp;&nbsp; <span>{AddToMetamaskIcon}</span></p>
                                    <p style={{ fontWeight: 100, color: '#283D5C' }}>Binance Smart Chain</p>
                                    <p style={{ fontWeight: 100, color: '#283D5C' }}>900.000.000 Promo</p>
                                </Col> */}
                                    <Col xs={24}>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{ fontWeight: 400, color: '#283D5C' }}>Token:</p>
                                            </Col>
                                            <Col xs={4}>
                                                <p style={{ fontWeight: 100, color: '#283D5C' }}>PROMO</p>
                                            </Col>
                                            <Col xs={4} style={{ marginTop: 4 }}>
                                                <a target='_blank' href='https://bscscan.com/address/0x52FF7dda3FCDc8D3ca2bbf150FDcC7A5f42d989E#code'>{SeeOnBscScanIcon}</a>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{ fontWeight: 400, color: '#283D5C' }}>Type:</p>
                                            </Col>
                                            <Col xs={4}>
                                                <p style={{ fontWeight: 100, color: '#283D5C' }}>BEP20</p>
                                            </Col>
                                            <Col xs={4} style={{ marginTop: 0 }}>
                                                <a onClick={AddBNBToMetamask}>{AddToMetamaskIcon}</a>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{ fontWeight: 400, color: '#283D5C' }}>Chain:</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{ fontWeight: 100, color: '#283D5C' }}>Binance Smart Chain</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <p style={{ fontWeight: 400, color: '#283D5C' }}>Total Supply:</p>
                                            </Col>
                                            <Col xs={8}>
                                                <p style={{ fontWeight: 100, color: '#283D5C' }}>900,000,000 Promo</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                            {/* <TabPane tab="Project Screening" key="2" className='padding-16 bg-white border-bottom'>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Audit</p>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Audit
                                            </div>
                                            Passed
                                        </div>
                                        <p onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12, cursor: 'pointer' }}>
                                            Purchase Audit
                                        </p>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Experts Rating
                                            </div>
                                            Not Raited
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                            Purchase Expert Rating
                                        </a>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Followers
                                            </div>
                                            60000
                                        </div>
                                        <a href={`/project?id=${this.state.id}`} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                            See Project Page
                                        </a>
                                    </Col>
                                </Row>

                                <Row className='margin-y-40' gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Engagement</p>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Incubator
                                            </div>
                                            Devtagon
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                            See Incubator Page
                                        </a>

                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Lead investor
                                            </div>
                                            Alpha Capital
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                            See Lead Investor Page
                                        </a>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Lead Advisor
                                            </div>
                                            N/A
                                        </div>
                                        {/* <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                            See Advisor Page
                                        </a> 
                    </Col>
                </Row>

                <Row className='margin-y-40' gutter={[24, 24]}>
                    <Col xs={24}>
                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Market Hit</p>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div className='border-text-wrapper'>
                            <div className='border-text'>
                                Initial Circulation
                            </div>
                            5.400.00 Promo
                        </div>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div className='border-text-wrapper'>
                            <div className='border-text'>
                                Initial Market Price
                            </div>
                            $ 0.022
                        </div>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div className='border-text-wrapper'>
                            <div className='border-text'>
                                Initial Market Cap
                            </div>
                            $ 118.800
                        </div>
                    </Col>
                </Row>

                <Row style={{ marginTop: 40, marginBottom: 21 }} gutter={[24, 24]}>
                    <Col xs={24}>
                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Listing</p>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div className='border-text-wrapper'>
                            <div className='border-text'>
                                Listing Date
                            </div>
                            28 February 2022
                        </div>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div className='border-text-wrapper'>
                            <div className='border-text'>
                                Listing Time
                            </div>
                            20.00 UTC
                        </div>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div className='border-text-wrapper'>
                            <div className='border-text'>
                                Listing Exchange
                            </div>
                            PancakeSwap
                        </div>
                        <a target='_blank' href='https://promodex.io' style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                            See Exchange Page
                        </a>
                    </Col>
                </Row>
            </TabPane> */}
                        </Tabs>
                    </Col >
                    <Col xs={{ span: 24 }} md={{ span: 10, offset: 0 }} className='project-page-tabs'>
                        <Tabs animated={false}>
                            <TabPane tab="Token Sale" key="1" className='padding-16 bg-white border-bottom'>
                                <Row gutter={[24, 24]} style={{ marginTop: 0, marginBottom: 0 }}>
                                    <Col xs={8} lg={{ span: 8 }} align='left'>
                                        <p style={{ fontSize: 16, fontWeight: 900 }}>{this.state.idoName}</p>
                                    </Col>
                                    <Col xs={{ offset: 8, span: 8 }} >
                                        <p style={{ fontWeight: 'bold', color: '#283D5C', textAlign: "center", marginBottom: 60 }}>How to buy ? </p>
                                        <a onClick={this.howToBuyPromoTokenModal} className="glightbox_video">
                                            <svg width="40" height="40" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path class="inner-circle" d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z" fill="#BF2428"></path>
                                                <circle class="outer_circle" cx="65.5" cy="65.5" r="64" stroke="#5340FF"></circle>
                                                <path class="play" fillRule="evenodd" clipRule="evenodd" d="M60 76V57L77 66.7774L60 76Z" fill="#FFFFFF"></path>
                                            </svg>
                                        </a>
                                    </Col>

                                </Row>
                                <Row style={{ marginTop: 0 }} gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Token</p>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper' style={{ fontSize: 12 }}>
                                            <div className='border-text'>
                                                Blockchain
                                            </div>
                                            Binance Smart Chain
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ fontSize: 12, color: '#5340FF', fontWeight: 100 }}>
                                            See Token Contract
                                        </a>

                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper' style={{ fontSize: 12 }}>
                                            <div className='border-text'>
                                                Access type
                                            </div>
                                            {this.state.idoType}
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ fontSize: 12, color: '#5340FF', fontWeight: 100 }}>
                                            See Participation Terms
                                        </a>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper' style={{ color: '#c68b0f' }}>
                                            <div className='border-text' style={{ fontSize: 12 }}>
                                                Sale Status
                                            </div>
                                            {this.state.idoStatus}
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ fontSize: 12, color: '#5340FF', fontWeight: 100 }}>
                                            See Token Sale Schedule
                                        </a>
                                    </Col>
                                </Row>

                                <Row gutter={[24, 24]} style={{ marginTop: 30, marginBottom: 60 }}>


                                    <Col xs={24} align='center'>
                                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Buy Promo</p>
                                    </Col>
                                    <Col xs={24} lg={{ span: 12, offset: 0 }} align='center'>
                                        {this.state.idoStatus === "Open" ?

                                            <a disabled={this.state.idoStatus !== "Open"} onClick={this.approveParticipationModal} className="colorful-button nowrap" style={{ fontSize: 14, paddingLeft: 70, paddingRight: 70 }}>
                                                Start Buying
                                            </a>
                                            :
                                            <Popover content={idoNotOpenPopover} title={<h2 style={{ textAlign: "center" }}>Information</h2>}>
                                                <a className="colorful-button nowrap" style={{ fontSize: 14, paddingLeft: 70, paddingRight: 70 }}>
                                                    Start Buying
                                                </a>
                                            </Popover>
                                        }
                                    </Col>
                                    <Col xs={24} lg={{ span: 12, offset: 0 }} align='center'>
                                        {this.state.idoStatus === "Open" ?
                                            <Popover content={this.state.changeJoinPoolPopover === "1" ? popoverStep1 : this.state.changeJoinPoolPopover === "2" ? popoverStep2 : popoverStep3} title={<h2 style={{ textAlign: "center" }}>Information</h2>}>
                                                <a onClick={this.joinPoolModal} className="colorful-button nowrap" style={{ paddingLeft: 70, paddingRight: 70 }}>

                                                    {this.state.changeJoinPoolPopover === "2" ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />} spinning={true} size="large" /> : "Finish Buying"}
                                                </a>
                                            </Popover>
                                            :
                                            <Popover content={idoNotOpenPopover} title={<h2 style={{ textAlign: "center" }}>Information</h2>}>
                                                <a className="colorful-button nowrap" style={{ paddingLeft: 70, paddingRight: 70 }}>
                                                    Finish Buying
                                                </a>
                                            </Popover>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 100, marginBottom: 35 }}>
                                    <Col xs={24} align='center'>
                                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Sale Progress</p>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <p style={{ textAlign: 'right' }}>{SplitNumber(this.state.report?.totalAmount)} BUSD / {SplitNumber(this.state.idoData?.softCap) + " " + this.state.idoData.swapToken}</p>
                                        <small style={{ fontSize: 14 }}>{Math.floor(this.state.report?.totalAmount / 1000)}%</small>
                                        <Progress style={{ height: 16 }} showInfo={false} percent={Math.floor(this.state.report?.totalAmount / 1000)} size="small" strokeColor={'#5340FF'} />
                                        <Row className='margin-top-10'>
                                            <Col xs={6}>
                                                <p>{SplitNumber(this.state.report?.uniqueUwalletCount)} Participants</p>
                                            </Col>
                                            <Col xs={{ span: 10, offset: 8 }} align='right'>
                                                <p>{SplitNumber(Math.floor(this.state.report?.totalAmount * 1000 / 21))} / {SplitNumber(Math.floor(this.state.idoData?.totalTokenSale))} Promo</p>
                                            </Col>
                                            <Col xs={{ span: 24 }} align='center'>
                                                {/* <p>if not sold out FCFS starts in 30 minutes</p> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                            {/* <TabPane tab="Token Sale Screening" key="2" className='padding-16 bg-white border-bottom'>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Your Participation Status</p>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper' style={{ color: 'red' }}>
                                            <div className='border-text'>
                                                Your Whitelist Status
                                            </div>
                                            Not Whitelisted
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                            Get Whitelisted
                                        </a>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper' style={{ color: 'red' }}>
                                            <div className='border-text'>
                                                Your KYC Status
                                            </div>
                                            Not Completed
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                            Complete Now
                                        </a>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Your Tier Status
                                            </div>
                                            N/A
                                        </div>
                                        <a onClick={this.comingSoonModal} style={{ color: '#5340FF', fontWeight: 100, fontSize: 12 }}>
                                            Upgrade Now
                                        </a>
                                    </Col>
                                </Row>

                                <Row className='margin-y-40' gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Your Allocation Status</p>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Your Allocation
                                            </div>
                                            {this.state.userAllowedAmount} BUSD
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                You Already Swapped
                                            </div>
                                            0 BUSD
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Your Remaining Allocation
                                            </div>
                                            0 BUSD
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 40, marginBottom: 21 }} gutter={[24, 24]}>
                                    <Col sm={24}>
                                        <p style={{ fontWeight: 'bold', color: '#283D5C' }}>Your Wallet Status</p>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Your BUSD Balance
                                            </div>
                                            {this.props.promodexInformation?.busdBalance}
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Your BNB Balance
                                            </div>
                                            {this.props.promodexInformation?.bnbBalance}

                                        </div>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <div className='border-text-wrapper'>
                                            <div className='border-text'>
                                                Your Promo Balance
                                            </div>
                                            {this.props.promodexInformation?.promoBalance}

                                        </div>
                                    </Col>
                                </Row>
                            </TabPane> */}
                        </Tabs>
                    </Col>
                </Row >
                <Row className='margin-top-20'>
                    <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }} className='padding-16 shadow-white-div project-page-tabs-2'>
                        <Tabs animated={false} onChange={(e) => e === "3" ? this.allocationTabChanged() : console.log("deneme")}>
                            <TabPane tab='Token Sale Details' key='1'>
                                <Row className='margin-y-40'>
                                    <Col xs={24}>
                                        <Table scroll={{ x: 1000 }} size='small' columns={detailsColumns} dataSource={detailsData} pagination={false} className='ant-table-strip' />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab='Realization' key='2'>
                                <Row className='margin-y-40'>
                                    <Col xs={24}>
                                        <Table scroll={{ x: 1000 }} size='small' columns={realizationColumns} dataSource={realizationData} pagination={false} className='ant-table-strip' />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab='Your Allocation' key='3'>
                                <Row className='margin-y-40'>
                                    <Col xs={24}>
                                        <Table loading={this.state.lockedTokenTableLoading} pagination={true} scroll={{ x: 1000 }} size='small' columns={allocationColumns} dataSource={this.state.lockedTokens} className='ant-table-strip' />

                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>

                <Modal
                    className=''
                    title={<h3 style={{ textAlign: "center", color: "#5340FF" }}>Coming Soon</h3>}
                    visible={this.state.isComingSoonModalVisible}
                    maskClosable={true}
                    onCancel={this.comingSoonModal}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }}
                    footer={null}>
                    <Row>
                        <Col xs={24} align='center' className='margin-y-40'>
                            <Image
                                style={{}}
                                width={100}
                                preview={false}
                                src={blueP}
                            /> <br />
                            <p style={{
                                textAlign: 'center',
                                fontSize: 24,
                                color: '#5340ff'
                            }}>
                                COMING SOON...
                            </p>
                        </Col>
                    </Row>
                </Modal>

                <Modal
                    className=''
                    title={<h3 style={{ textAlign: "center", color: "#5340FF" }}>Start Buying</h3>}
                    visible={this.state.approveParticipationModalVisible}
                    maskClosable={true}
                    onCancel={this.approveParticipationModal}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }}
                    footer={null}>
                    <Row style={{ marginTop: 0, marginBottom: 30 }}>
                        <Col xs={24} align='center' className='margin-y-40'>

                            <PromoInput
                                size="large"
                                className="externalCSSBorderRadius10"
                                labelFontSize={14}
                                labelColorClass="promo-input-label"
                                isHorizontalLabel={false}
                                labelText={"MIN:" + this.state.idoData?.minPerAllocation + " / " + "MAX:" + this.state.idoData?.maxPerAllocation}
                                type="number"
                                value={this.state.approveAmount}
                                placeholder="Enter amount"
                                onChange={(e) =>
                                    this.setState({ approveAmount: e.target.value })
                                }
                                prefixIcon={<div style={{ padding: 10 }} className='input-prefix-sale'>BUSD </div>}
                                suffixIcon={<div style={{ cursor: "pointer", padding: 10 }} onClick={() => this.setState({ approveAmount: parseFloat(this.props.promodexInformation?.busdBalance) })} className='input-suffix-sale'>MAX</div>}>
                            </PromoInput>

                        </Col>
                        <Col xs={24} lg={{ span: 24, offset: 0 }} align='center'>
                            <a onClick={() => this.handleApprove()} className="colorful-button nowrap" style={{ fontSize: 14 }}>
                                Approve Now
                            </a>
                        </Col>
                    </Row>
                </Modal>


                <Modal
                    className=''
                    title={<h3 style={{ textAlign: "center", color: "#5340FF" }}>Finish Buying</h3>}
                    visible={this.state.joinPoolModalVisible}
                    maskClosable={true}
                    onCancel={this.joinPoolModal}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }}
                    footer={null}>
                    <Row style={{ marginTop: 0, marginBottom: 30 }}>
                        <Col xs={24} align='center' className='margin-y-40'>

                            <PromoInput
                                className="externalCSSBorderRadius10"
                                labelFontSize={14}
                                labelColorClass="promo-input-label"
                                isHorizontalLabel={false}
                                labelText={"MIN:" + this.state.idoData?.minPerAllocation + " / " + "MAX:" + this.state.idoData?.maxPerAllocation}
                                type="number"
                                value={this.state.joinAmount}
                                placeholder="Enter amount"
                                onChange={(e) =>
                                    this.setState({ joinAmount: e.target.value })
                                }
                                prefixIcon={<div style={{ padding: 10 }} className='input-prefix-sale'>BUSD </div>}
                                suffixIcon={<div style={{ cursor: "pointer", padding: 10 }} onClick={() => this.setState({ joinAmount: parseFloat(this.state.userAllowedAmount) })} className='input-suffix-sale'> MAX</div>}>
                            </PromoInput>

                        </Col>
                        <Col xs={24} lg={{ span: 24, offset: 0 }} align='center'>
                            <a onClick={() => this.handleJoin()} className="colorful-button nowrap" style={{ fontSize: 14 }}>
                                Join Now
                            </a>
                        </Col>
                    </Row>
                </Modal>


                <Modal
                    className=''
                    title={<h3 style={{ textAlign: "center", color: "#5340FF" }}>How to Buy ?</h3>}
                    visible={this.state.howToBuyPromoTokenVisible}
                    maskClosable={true}
                    onCancel={this.howToBuyPromoTokenModal}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }}
                    footer={null}>
                    <Row>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/edFhqydXTIs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Row>
                </Modal>

            </Spin >
        </div >;
    }
}

const mapState = (globalState) => {
    return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ProjectSalePageContent));
import React, {Component} from 'react';
import {Alert, Button, Checkbox, Col, Row, Select, Upload} from "antd";
import PromoInput from "../../components/input/PromoInput";
import TextArea from "antd/es/input/TextArea";
import {AiOutlineDoubleRight, AiOutlineDown, AiOutlineUpload} from "react-icons/ai";
import Modal from "antd/es/modal/Modal";

const {Option} = Select;


class CreatePromotion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkModal: false,
            stakeModal: false,

            project: "",
            campaignName: "",
            targetLanguage: "",
            targetGeolocation: "",
            campaignDateStart: "",
            campaignDateEnd: "",

            blockchainNetwork: "bnc-smart-chain",
            walletAddress: "0xf9e0B45e14D47DB8bc0E0c8c26E221",
            walletBalance: "",
            confirmCostOfService: false,
            confirmTerms: false,
            showNotConfirmedAlert: false,

            platforms: [{
                campaignPlatform: "",
                minIqsOf: 0,
                matchingProfile: 0,
                campaignHeadline: "",
                campaignContent: "",
                campaignConditions: "",
                file: ""
            }]

        }
    }

    showCheckModal = (status) => {
        if (this.state.confirmCostOfService && this.state.confirmTerms) {
            this.setState({checkModal: status, showNotConfirmedAlert: false})
        } else {
            this.setState({showNotConfirmedAlert: true})
        }
    }

    showStakeModal = (status) => {
        this.setState({stakeModal: status})
    }

    handleConfirmButton = () => {
        // Confirm button inside the modal
        this.showCheckModal(false)
        this.showStakeModal(true)
    }

    handleContinue = () => {
        this.setState({isContinued: true})
    }


    handleSearchableSelectBoxOnChange = (e, status) => {
        this.setState({[status]: e})
    }

    handleInput = (e, status) => {
        this.setState({[status]: e.target.value})
    }


    handleAddPlatform = () => {
        const platforms = this.state.platforms
        platforms.push({
            campaignPlatform: "",
            minIqsOf: 0,
            matchingProfile: 0,
            campaignHeadline: "",
            campaignContent: "",
            campaignConditions: "",
            file: ""
        })
        this.setState({platforms: platforms})
    }

    handlePlatformsInput = (event, name, index) => {
        const platforms = this.state.platforms
        if (name === 'campaignPlatform' || name === 'file') {
            platforms[index][name] = event
        } else {
            platforms[index][name] = event.target.value
        }
        this.setState({platforms: platforms}, () => console.log('this platforms : ', this.state.platforms))
    }

    render() {
        return (
            <>
                <div style={{
                    opacity: this.state.isContinued ? "0" : "1",
                    transition: 'all .9s',
                    display: this.state.isContinued && 'none'
                }}>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 className="p-font-bold" style={{marginLeft: '8px'}}>CAMPAIGN</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                    <Row>
                                        <Col span={6}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Project
                                            </small>
                                        </Col>
                                        <Col span={14} align='right'>
                                            <small
                                                style={{fontSize: 12}}
                                                className="p-font-color"
                                            >
                                                + Register a new project
                                            </small>
                                        </Col>
                                        <Col span={22}>
                                            <Select
                                                showSearch
                                                style={{width: '100%'}}
                                                placeholder="Choose your project (if you dont have one please register your project first)"
                                                optionFilterProp="children"
                                                onChange={(e) => this.handleSearchableSelectBoxOnChange(e, "project")}
                                                onFocus={this.handleProjectOnFocus}
                                                onBlur={this.handleProjectOnBlur}
                                                onSearch={this.handleProjectOnSearch}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="project1">Project 1</Option>
                                                <Option value="project2">Project 2</Option>
                                                <Option value="project3">Project 3</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                    <PromoInput isHorizontalLabel={false} labelText="Campaign Name"
                                               placeholder="Name your campaign" value={this.state.campaignName}
                                               onChange={(e) => this.handleInput(e, 'campaignName')}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 className="p-font-bold" style={{marginLeft: '8px'}}>TARGET</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                    <PromoInput isHorizontalLabel={false} labelText="Target Language"
                                               placeholder="Target Language" value={this.state.targetLanguage}
                                               onChange={(e) => this.handleInput(e, 'targetLanguage')}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                    <PromoInput isHorizontalLabel={false} labelText="Target Geolocation"
                                               placeholder="Target Geolocation" value={this.state.targetGeolocation}
                                               onChange={(e) => this.handleInput(e, 'targetGeolocation')}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 className="p-font-bold" style={{marginLeft: '8px'}}>CAMPAIGN DATE</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                    <PromoInput isHorizontalLabel={false} labelText="Date start" type="date"
                                               value={this.state.campaignDateStart}
                                               onChange={(e) => this.setState({campaignDateStart: e})}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                    <PromoInput isHorizontalLabel={false} labelText="Date End" type="date"
                                               value={this.state.campaignDateEnd}
                                               onChange={(e) => this.setState({campaignDateEnd: e})}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row gutter={[0, 24]}>
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                    <h5 className="p-font-bold" style={{marginLeft: '8px'}}>PLATFORM 1</h5>
                                    <Row>
                                        <Col span={20}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Campaign Platform
                                            </small>
                                        </Col>
                                        <Col span={22}>
                                            <Select
                                                showSearch
                                                style={{width: '100%'}}
                                                placeholder="Choose the platform to lauch campaign"
                                                optionFilterProp="children"
                                                onChange={(e) => this.handlePlatformsInput(e, 'campaignPlatform', 0)}
                                                onFocus={this.handleProjectOnFocus}
                                                onBlur={this.handleProjectOnBlur}
                                                onSearch={this.handleProjectOnSearch}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="platform1">Platform 1</Option>
                                                <Option value="platform2">Platform 2</Option>
                                                <Option value="platform3">Platform 3</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                    <h5 className="p-font-bold" style={{marginLeft: '8px'}}>PARTICIPANT</h5>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 24}} md={{span: 11, offset: 0}}>
                                            <PromoInput isHorizontalLabel={false} labelText="Min. IQS of" type="number"
                                                       placeholder="0"
                                                       onChange={(e) => this.handlePlatformsInput(e, 'minIqsOf', 0)}/>
                                        </Col>
                                        <Col xs={{span: 24}} md={{span: 11, offset: 1}}>
                                            <PromoInput isHorizontalLabel={false} labelText="Number of Matching profile"
                                                       type='number' placeholder="0"
                                                       onChange={(e) => this.handlePlatformsInput(e, 'matchingProfile', 0)}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="margin-top-20">
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 className="p-font-bold" style={{marginLeft: '8px'}}>CONTENT</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 24, offset: 1}}>
                                    <PromoInput isHorizontalLabel={false} labelText="Headline of the campaign"
                                               onChange={(e) => this.handlePlatformsInput(e, 'campaignHeadline', 0)}/>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 24, offset: 1}}>
                                    <Row>
                                        <Col span={20}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Content of the campaign
                                            </small>
                                        </Col>
                                        <Col span={22}>
                                            <TextArea rows={4} maxLength={300}
                                                      onChange={(e) => this.handlePlatformsInput(e, 'campaignContent', 0)}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]} className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 18, offset: 1}}>
                                    <Row>
                                        <Col span={20}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Conditions of participation approval
                                            </small>
                                        </Col>
                                        <Col span={22}>
                                            <TextArea rows={3} maxLength={300}
                                                      onChange={(e) => this.handlePlatformsInput(e, 'campaignConditions', 0)}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 4, offset: 0}}>
                                    <Upload name='file' action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                            headers={{authorization: 'authorization-text'}}
                                            onChange={(e) => this.handlePlatformsInput(e, 'file', 0)}>
                                        <Button style={{marginTop: '18px', borderRadius: '10px'}}
                                                icon={<AiOutlineUpload size={18}
                                                                       className="vertical-align-text-bottom"/>}>
                                            Upload File
                                        </Button>
                                    </Upload>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={24} align="middle">
                                    <Button size="large" className="promo-btn p-font-bold"
                                            onClick={this.handleAddPlatform}>+ ADD MORE
                                        CAMPAIGNS</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {this.state.platforms.map((data, index) => {
                        if (index === 0) return
                        return (
                            <Row key={index}>
                                <Col xs={23} className="shadow-white-div-margin-10">
                                    <Row gutter={[0, 24]}>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                            <h5 className="p-font-bold"
                                                style={{marginLeft: '8px'}}>PLATFORM {index + 1}</h5>
                                            <Row>
                                                <Col span={20}>
                                                    <small
                                                        style={{fontSize: 12}}
                                                        className="profile-input-label"
                                                    >
                                                        Campaign Platform
                                                    </small>
                                                </Col>
                                                <Col span={22}>
                                                    <Select
                                                        showSearch
                                                        style={{width: '100%'}}
                                                        placeholder="Choose the platform to lauch campaign"
                                                        optionFilterProp="children"
                                                        onChange={(e) => this.handlePlatformsInput(e, 'campaignPlatform', index)}
                                                        onFocus={this.handleProjectOnFocus}
                                                        onBlur={this.handleProjectOnBlur}
                                                        onSearch={this.handleProjectOnSearch}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="platform1">Platform 1</Option>
                                                        <Option value="platform2">Platform 2</Option>
                                                        <Option value="platform3">Platform 3</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                            <h5 className="p-font-bold"
                                                style={{marginLeft: '8px'}}>PARTICIPANT</h5>
                                            <Row className="profile-PromoInput-style">
                                                <Col xs={{span: 24}} md={{span: 11, offset: 0}}>
                                                    <PromoInput isHorizontalLabel={false} labelText="Min. IQS of"
                                                               type="number"
                                                               placeholder="0"
                                                               onChange={(e) => this.handlePlatformsInput(e, 'minIqsOf', index)}/>
                                                </Col>
                                                <Col xs={{span: 24}} md={{span: 11, offset: 1}}>
                                                    <PromoInput isHorizontalLabel={false}
                                                               labelText="Number of Matching profile"
                                                               type='number' placeholder="0"
                                                               onChange={(e) => this.handlePlatformsInput(e, 'matchingProfile', index)}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="margin-top-20">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <h5 className="p-font-bold" style={{marginLeft: '8px'}}>CONTENT</h5>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 24, offset: 1}}>
                                            <PromoInput isHorizontalLabel={false} labelText="Headline of the campaign"
                                                       onChange={(e) => this.handlePlatformsInput(e, 'campaignHeadline', index)}/>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 24, offset: 1}}>
                                            <Row>
                                                <Col span={20}>
                                                    <small
                                                        style={{fontSize: 12}}
                                                        className="profile-input-label"
                                                    >
                                                        Content of the campaign
                                                    </small>
                                                </Col>
                                                <Col span={22}>
                                                    <TextArea rows={4} maxLength={300}
                                                              onChange={(e) => this.handlePlatformsInput(e, 'campaignContent', index)}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 18, offset: 1}}>
                                            <Row>
                                                <Col span={20}>
                                                    <small
                                                        style={{fontSize: 12}}
                                                        className="profile-input-label"
                                                    >
                                                        Conditions of participation approval
                                                    </small>
                                                </Col>
                                                <Col span={22}>
                                                    <TextArea rows={3} maxLength={300}
                                                              onChange={(e) => this.handlePlatformsInput(e, 'campaignConditions', index)}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 4, offset: 0}}>
                                            <Upload name='file'
                                                    action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                                    headers={{authorization: 'authorization-text'}}
                                                    onChange={(e) => this.handlePlatformsInput(e, 'file', index)}>
                                                <Button style={{marginTop: '18px', borderRadius: '10px'}}
                                                        icon={<AiOutlineUpload size={18}
                                                                               className="vertical-align-text-bottom"/>}>
                                                    Upload File
                                                </Button>
                                            </Upload>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })
                    }
                    <Row className="margin-y-10">
                        <Col xs={{span: 22, offset: 1}} lg={{span: 5, offset: 9}} align="middle">
                            <Button size="large" block className="promo-btn p-font-bold"
                                    onClick={this.handleContinue}>CONTINUE</Button>
                        </Col>
                    </Row>
                </div>
                {
                    <div style={{
                        opacity: !this.state.isContinued ? "0" : "1",
                        transition: 'all .9s',
                        display: !this.state.isContinued && 'none'
                    }}>
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <h5 style={{marginLeft: '8px'}}>MY WALLET</h5>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}>
                                        <Row>
                                            <Col xs={24}>
                                                <small
                                                    style={{fontSize: 12}}
                                                    className="profile-input-label"
                                                >
                                                    Blockchain Network
                                                </small>
                                            </Col>
                                            <Col className="label-select" xs={12} md={8} align='middle'>
                                                <Select defaultValue="1"
                                                        style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                    <Option value="1">CONNECTED TO</Option>
                                                </Select>
                                            </Col>
                                            <Col className="select-with-box" xs={8} md={12}>
                                                <Select defaultValue="bnc-smart-chain"
                                                        value={this.state.blockchainNetwork}
                                                        onChange={(e) => this.setState({blockchainNetwork: e})}>
                                                    <Option value="all">All</Option>
                                                    <Option value="bnc-smart-chain">Binance Smart Chain</Option>
                                                    <Option value="dummy">Dummy</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}>
                                        <PromoInput isHorizontalLabel={false} labelText="Your Wallet address"
                                                   defaultValue={this.state.walletAddress}
                                                   value={this.state.walletAddress}
                                                   onChange={(e) => this.handleInput(e, 'walletAddress')}/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false} labelText="Wallet Balance"
                                                   value={this.state.walletBalance}
                                                   onChange={(e) => this.handleInput(e, 'walletBalance')}
                                                   addonAfter={
                                                       <Select defaultValue="promo" className="select-after">
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                           <Option value="dummy2">Dummy2</Option>
                                                           <Option value="dummy3">Dummy3</Option>
                                                       </Select>
                                                   }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <h5 style={{marginLeft: '8px'}}>TOKEN REWARD FOR EACH PARTICIPANT</h5>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="Total amount of token reward to distribute"
                                                   placeholder="Please enter the total amount of reward token"
                                                   value={this.state.tokenRewardToDistribute}
                                                   onChange={(e) => this.handleInput(e, 'tokenRewardToDistribute')}
                                                   addonAfter={
                                                       <Select defaultValue="promo" className="select-after">
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                           <Option value="dummy2">Dummy2</Option>
                                                           <Option value="dummy3">Dummy3</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="Minimum token reward per IQS point"
                                                   placeholder="Min. token reward ex:20" type="number"
                                                   value={this.state.minTokenRewardPerIqs}
                                                   onChange={(e) => this.handleInput(e, 'minTokenRewardPerIqs')}
                                                   addonAfter={
                                                       <Select defaultValue="promo" className="select-after">
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                           <Option value="dummy2">Dummy2</Option>
                                                           <Option value="dummy3">Dummy3</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false} labelText="Maximum token reward per IQS"
                                                   placeholder="Max. token reward ex:10" type="number"
                                                   value={this.state.maxTokenRewardPerIqs}
                                                   onChange={(e) => this.handleInput(e, 'maxTokenRewardPerIqs')}
                                                   addonAfter={
                                                       <Select defaultValue="promo" className="select-after">
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                           <Option value="dummy2">Dummy2</Option>
                                                           <Option value="dummy3">Dummy3</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <h5 style={{marginLeft: '8px'}}>COST OF SERVICE</h5>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="NO  -  Amount  -  Unit"
                                                   placeholder="ex:1000"
                                                   value={this.state.costOfService1}
                                                   onChange={(e) => this.handleInput(e, 'costOfService1')}
                                                   addonBefore={
                                                       <Select defaultValue="1"
                                                               style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                           <Option value="1">1.</Option>
                                                       </Select>
                                                   }
                                                   addonAfter={
                                                       <Select defaultValue="bnb" className="select-after">
                                                           <Option value="bnb">BNB</Option>
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="token">TOKEN</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="NO  -  Amount  -  Unit"
                                                   placeholder="ex:1000"
                                                   value={this.state.costOfService2}
                                                   onChange={(e) => this.handleInput(e, 'costOfService2')}
                                                   addonBefore={
                                                       <Select defaultValue="1"
                                                               style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                           <Option value="1">1.</Option>
                                                       </Select>
                                                   }
                                                   addonAfter={
                                                       <Select defaultValue="promo" className="select-after">
                                                           <Option value="bnb">BNB</Option>
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="token">TOKEN</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="NO  -  Amount  -  Unit"
                                                   placeholder="ex:1000"
                                                   value={this.state.costOfService3}
                                                   onChange={(e) => this.handleInput(e, 'costOfService3')}
                                                   addonBefore={
                                                       <Select defaultValue="1"
                                                               style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                           <Option value="1">1.</Option>
                                                       </Select>
                                                   }
                                                   addonAfter={
                                                       <Select defaultValue="token" className="select-after">
                                                           <Option value="bnb">BNB</Option>
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="token">TOKEN</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                </Row>
                                <Row className="margin-top-20">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <Checkbox
                                            onChange={(e) => this.setState({confirmCostOfService: e.target.checked})}>CONFIRM
                                            THE COST OF SERVICE AS</Checkbox>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <h5 style={{marginLeft: '8px'}}>DISCLAMER</h5>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 22, offset: 1}}>
                                        <p style={{marginLeft: '8px'}}>
                                            Disclaimer: This process is entirely decentralized, we cannot be held
                                            reponsible
                                            for incorrect entry of information or be held liable for anything related to
                                            your use of our platform. Please ensure you enter all your details to the
                                            best
                                            accuracy possible and that you are in
                                            compliance with your local laws and regulations. This is a beta version! We
                                            cannot guarantee there will be no bugs. Use at your own risk! For tokens
                                            with
                                            burns, rebase or other special transfers please ensure you have a way to
                                            whitelist multiple addresses or turn off the
                                            special
                                            transfer events (By setting fees to 0 for example for the duration of the
                                            presale
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="margin-top-20">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <Checkbox onChange={(e) => this.setState({confirmTerms: e.target.checked})}
                                                  style={{marginLeft: '8px'}}>CONFIRM TERMS AND CONDITIONS</Checkbox>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {this.state.showNotConfirmedAlert &&
                        <Row>
                            <Col xs={23}>
                                <Alert style={{marginTop: '10px'}}
                                       message="You cannot check until you accept all the terms." type="error"
                                       showIcon/>
                            </Col>
                        </Row>
                        }
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row align="center">
                                    <Col className="margin-y-10" xs={{span: 20, offset: 1}} md={{span: 6, offset: 1}}
                                         align="middle">
                                        <Button size="large" block className="promo-btn p-font-bold"
                                                onClick={() => this.showCheckModal(true)}>CHECK</Button>
                                    </Col>
                                    <Col className="margin-y-10" md={{span: 2}} align="middle">
                                        <AiOutlineDoubleRight size={30}/>
                                    </Col>
                                    <Col className="margin-y-10" xs={{span: 20, offset: 0}} md={{span: 6, offset: 0}}
                                         align="middle">
                                        <Button size="large" block
                                                className="promo-btn p-font-bold">STAKE</Button>
                                    </Col>
                                    <Col className="margin-y-10" md={{span: 2}} align="middle">
                                        <AiOutlineDoubleRight size={30}/>
                                    </Col>
                                    <Col className="margin-y-10" xs={{span: 20, offset: 0}} md={{span: 6, offset: 0}}
                                         align="middle">
                                        <Button size="large" block
                                                className="promo-btn p-font-bold">LAUNCH</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/** CHECK MODAL*/}
                        <Modal title="CHECK" visible={this.state.checkModal}
                               onOk={() => this.showCheckModal(false)}
                               onCancel={() => this.showCheckModal(false)}
                               width={1500}
                               footer={[
                                   <Button style={{color: '#ffffff', backgroundColor: '#e02020'}} key="cancel"
                                           size="large" onClick={() => this.showCheckModal(false)}>
                                       CANCEL
                                   </Button>,
                                   <Button style={{color: '#ffffff', backgroundColor: '#6dd400'}} key="submit"
                                           size="large" onClick={this.handleConfirmButton}>
                                       CONFIRM DETAILS
                                   </Button>,
                               ]}>
                            <Row>
                                <Col xs={23} className="shadow-white-div-margin-10">
                                    <Row>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <h5 className="p-font-bold" style={{marginLeft: '8px'}}>CAMPAIGN</h5>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                            <Row>
                                                <Col span={6}>
                                                    <small
                                                        style={{fontSize: 12}}
                                                        className="profile-input-label"
                                                    >
                                                        Project
                                                    </small>
                                                </Col>
                                                <Col span={22}>
                                                    <Select
                                                        showSearch
                                                        style={{width: '100%'}}
                                                        placeholder="Choose your project (if you dont have one please register your project first)"
                                                        optionFilterProp="children"
                                                        suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                        value={this.state.project}
                                                        disabled
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="project1">Project 1</Option>
                                                        <Option value="project2">Project 2</Option>
                                                        <Option value="project3">Project 3</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                            <PromoInput isHorizontalLabel={false} labelText="Campaign Name"
                                                       placeholder="Name your campaign"
                                                       defaultValue={this.state.campaignName}
                                                       disabled/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={23} className="shadow-white-div-margin-10">
                                    <Row>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <h5 className="p-font-bold" style={{marginLeft: '8px'}}>TARGET</h5>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                            <PromoInput isHorizontalLabel={false} labelText="Target Language"
                                                       placeholder="Target Language" value={this.state.targetLanguage}
                                                       onChange={(e) => this.handleInput(e, 'targetLanguage')}/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                            <PromoInput isHorizontalLabel={false} labelText="Target Geolocation"
                                                       placeholder="Target Geolocation"
                                                       value={this.state.targetGeolocation}
                                                       onChange={(e) => this.handleInput(e, 'targetGeolocation')}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {this.state.platforms.map((data, index) => {
                                return (
                                    <Row key={index}>
                                        <Col xs={23} className="shadow-white-div-margin-10">
                                            <Row gutter={[0, 24]}>
                                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                                    <h5 className="p-font-bold"
                                                        style={{marginLeft: '8px'}}>PLATFORM {index + 1}</h5>
                                                    <Row>
                                                        <Col span={20}>
                                                            <small
                                                                style={{fontSize: 12}}
                                                                className="profile-input-label"
                                                            >
                                                                Campaign Platform
                                                            </small>
                                                        </Col>
                                                        <Col span={22}>
                                                            <Select
                                                                showSearch
                                                                style={{width: '100%'}}
                                                                placeholder="Choose the platform to lauch campaign"
                                                                suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                                optionFilterProp="children"
                                                                value={this.state.platforms[index].campaignPlatform}
                                                                disabled
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                <Option value="platform1">Platform 1</Option>
                                                                <Option value="platform2">Platform 2</Option>
                                                                <Option value="platform3">Platform 3</Option>
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                                    <h5 className="p-font-bold"
                                                        style={{marginLeft: '8px'}}>PARTICIPANT</h5>
                                                    <Row className="profile-PromoInput-style">
                                                        <Col xs={{span: 24}} md={{span: 11, offset: 0}}>
                                                            <PromoInput isHorizontalLabel={false} labelText="Min. IQS of"
                                                                       type="number"
                                                                       placeholder="0" disabled
                                                                       defaultValue={this.state.platforms[index].minIqsOf}/>
                                                        </Col>
                                                        <Col xs={{span: 24}} md={{span: 11, offset: 1}}>
                                                            <PromoInput isHorizontalLabel={false}
                                                                       labelText="Number of Matching profile"
                                                                       type='number' placeholder="0" disabled
                                                                       defaultValue={this.state.platforms[index].matchingProfile}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="margin-top-20">
                                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                                    <h5 className="p-font-bold"
                                                        style={{marginLeft: '8px'}}>CONTENT</h5>
                                                </Col>
                                            </Row>
                                            <Row className="profile-PromoInput-style">
                                                <Col xs={{span: 23, offset: 1}} md={{span: 24, offset: 1}}>
                                                    <PromoInput isHorizontalLabel={false}
                                                               labelText="Headline of the campaign"
                                                               disabled
                                                               defaultValue={this.state.platforms[index].campaignHeadline}/>
                                                </Col>
                                            </Row>
                                            <Row className="profile-PromoInput-style">
                                                <Col xs={{span: 23, offset: 1}} md={{span: 24, offset: 1}}>
                                                    <Row>
                                                        <Col span={20}>
                                                            <small
                                                                style={{fontSize: 12}}
                                                                className="profile-input-label"
                                                            >
                                                                Content of the campaign
                                                            </small>
                                                        </Col>
                                                        <Col span={22}>
                                                            <TextArea rows={4} maxLength={300} disabled
                                                                      value={this.state.platforms[index].campaignContent}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row gutter={[24, 24]} className="profile-PromoInput-style">
                                                <Col xs={{span: 23, offset: 1}} md={{span: 18, offset: 1}}>
                                                    <Row>
                                                        <Col span={20}>
                                                            <small
                                                                style={{fontSize: 12}}
                                                                className="profile-input-label"
                                                            >
                                                                Conditions of participation approval
                                                            </small>
                                                        </Col>
                                                        <Col span={22}>
                                                            <TextArea rows={3} maxLength={300} disabled
                                                                      value={this.state.platforms[index].campaignConditions}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={{span: 23, offset: 1}} md={{span: 4, offset: 0}}>
                                                    <Upload name='file'
                                                            action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                                            headers={{authorization: 'authorization-text'}} disabled
                                                            value={this.state.platforms[index].file}>
                                                        <Button style={{marginTop: '18px', borderRadius: '10px'}}
                                                                icon={<AiOutlineUpload size={18}
                                                                                       className="vertical-align-text-bottom"/>}>
                                                            Upload File
                                                        </Button>
                                                    </Upload>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                            }
                            <Row>
                                <Col xs={23} className="shadow-white-div-margin-10">
                                    <Row>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <h5 className="p-font-bold" style={{marginLeft: '8px'}}>CAMPAIGN
                                                DATE</h5>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                            <PromoInput isHorizontalLabel={false} labelText="Date start" type="date"
                                                       defaultValue={this.state.campaignDateStart} disabled/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                            <PromoInput isHorizontalLabel={false} labelText="Date End" type="date"
                                                       defaultValue={this.state.campaignDateEnd} disabled/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={23} className="shadow-white-div-margin-10">
                                    <Row>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <h5 style={{marginLeft: '8px'}}>TOKEN REWARD FOR EACH PARTICIPANT</h5>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="Total amount of token reward to distribute"
                                                       placeholder="Please enter the total amount of reward token"
                                                       defaultValue={this.state.tokenRewardToDistribute}
                                                       disabled
                                                       addonAfter={
                                                           <Select defaultValue="promo" className="select-after">
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                               <Option value="dummy2">Dummy2</Option>
                                                               <Option value="dummy3">Dummy3</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="Minimum token reward per IQS point"
                                                       placeholder="Min. token reward ex:20" type="number"
                                                       defaultValue={this.state.minTokenRewardPerIqs}
                                                       disabled
                                                       addonAfter={
                                                           <Select defaultValue="promo" className="select-after">
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                               <Option value="dummy2">Dummy2</Option>
                                                               <Option value="dummy3">Dummy3</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="Maximum token reward per IQS"
                                                       placeholder="Max. token reward ex:10" type="number"
                                                       defaultValue={this.state.maxTokenRewardPerIqs}
                                                       disabled
                                                       addonAfter={
                                                           <Select defaultValue="promo" className="select-after">
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                               <Option value="dummy2">Dummy2</Option>
                                                               <Option value="dummy3">Dummy3</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={23} className="shadow-white-div-margin-10">
                                    <Row>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <h5 style={{marginLeft: '8px'}}>COST OF SERVICE</h5>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="NO  -  Amount  -  Unit"
                                                       placeholder="ex:1000"
                                                       defaultValue={this.state.costOfService1}
                                                       disabled
                                                       addonBefore={
                                                           <Select defaultValue="1"
                                                                   style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                               <Option value="1">1.</Option>
                                                           </Select>
                                                       }
                                                       addonAfter={
                                                           <Select defaultValue="bnb" className="select-after">
                                                               <Option value="bnb">BNB</Option>
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="token">TOKEN</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="NO  -  Amount  -  Unit"
                                                       placeholder="ex:1000"
                                                       defaultValue={this.state.costOfService2}
                                                       disabled
                                                       addonBefore={
                                                           <Select defaultValue="1"
                                                                   style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                               <Option value="1">1.</Option>
                                                           </Select>
                                                       }
                                                       addonAfter={
                                                           <Select defaultValue="promo" className="select-after">
                                                               <Option value="bnb">BNB</Option>
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="token">TOKEN</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="NO  -  Amount  -  Unit"
                                                       placeholder="ex:1000"
                                                       defaultValue={this.state.costOfService3}
                                                       disabled
                                                       addonBefore={
                                                           <Select defaultValue="1"
                                                                   style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                               <Option value="1">1.</Option>
                                                           </Select>
                                                       }
                                                       addonAfter={
                                                           <Select defaultValue="token" className="select-after">
                                                               <Option value="bnb">BNB</Option>
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="token">TOKEN</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal>
                        {/** CHECK MODAL*/}
                        <Modal title="STAKE" visible={this.state.stakeModal}
                               onOk={() => this.showStakeModal(false)}
                               onCancel={() => this.showStakeModal(false)}
                               width={1500}
                               footer={[
                                   <Button style={{color: '#ffffff', backgroundColor: '#e02020'}} key="cancel"
                                           size="large" onClick={() => this.showStakeModal(false)}>
                                       CANCEL
                                   </Button>,
                                   <Button style={{color: '#ffffff', backgroundColor: '#6dd400'}} key="submit"
                                           size="large" onClick={() => this.showStakeModal(false)}>
                                       CONFIRM DETAILS
                                   </Button>,
                               ]}>
                            <Row>
                                <Col xs={23} className="shadow-white-div-margin-10">
                                    <Row>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <h5 style={{marginLeft: '8px'}}>TOKEN REWARD FOR EACH PARTICIPANT</h5>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="Total amount of token reward to distribute"
                                                       placeholder="Please enter the total amount of reward token"
                                                       defaultValue={this.state.tokenRewardToDistribute}
                                                       disabled
                                                       addonAfter={
                                                           <Select defaultValue="promo" className="select-after">
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                               <Option value="dummy2">Dummy2</Option>
                                                               <Option value="dummy3">Dummy3</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={23} className="shadow-white-div-margin-10">
                                    <Row>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <h5 style={{marginLeft: '8px'}}>COST OF SERVICE</h5>
                                        </Col>
                                    </Row>
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="NO  -  Amount  -  Unit"
                                                       placeholder="ex:1000"
                                                       defaultValue={this.state.costOfService1}
                                                       disabled
                                                       addonBefore={
                                                           <Select defaultValue="1"
                                                                   style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                               <Option value="1">1.</Option>
                                                           </Select>
                                                       }
                                                       addonAfter={
                                                           <Select defaultValue="bnb" className="select-after">
                                                               <Option value="bnb">BNB</Option>
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="token">TOKEN</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="NO  -  Amount  -  Unit"
                                                       placeholder="ex:1000"
                                                       defaultValue={this.state.costOfService2}
                                                       disabled
                                                       addonBefore={
                                                           <Select defaultValue="1"
                                                                   style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                               <Option value="1">1.</Option>
                                                           </Select>
                                                       }
                                                       addonAfter={
                                                           <Select defaultValue="promo" className="select-after">
                                                               <Option value="bnb">BNB</Option>
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="token">TOKEN</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                             className='select-after-borderless'>
                                            <PromoInput isHorizontalLabel={false}
                                                       labelText="NO  -  Amount  -  Unit"
                                                       placeholder="ex:1000"
                                                       defaultValue={this.state.costOfService3}
                                                       disabled
                                                       addonBefore={
                                                           <Select defaultValue="1"
                                                                   style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                               <Option value="1">1.</Option>
                                                           </Select>
                                                       }
                                                       addonAfter={
                                                           <Select defaultValue="token" className="select-after">
                                                               <Option value="bnb">BNB</Option>
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="token">TOKEN</Option>
                                                               <Option value="dummy">Dummy</Option>
                                                           </Select>
                                                       }/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal>

                    </div>
                }
            </>
        );
    }
}

export default CreatePromotion;
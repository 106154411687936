import React, {Component} from 'react';
import {Col, Image, Row} from "antd";
import hakan from "../../assets/images/Hakan_Unal_greybg512px-115x115.png"



// This component will hold the user's photo and social media icon together
class ImageWithIcon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth:0
        }
    }

    componentDidMount() {
        this.setState({innerWidth: window.innerWidth})
        setInterval(() => {
            this.setState({innerWidth: window.innerWidth})
        }, 500)
    }

    render() {
        return (
            <Row>
                <Col xs={6}>
                    <Image
                        style={{borderRadius: '50%'}}
                        width={40}
                        preview={false}
                        src={hakan}
                    />
                </Col>
                <Col xs={6} push={this.state.innerWidth < 500 ? 0 : 3} pull={this.state.innerWidth < 500 ? 3 : 0}>
                    <Image
                        style={{borderRadius: '50%', border:'1px solid #ffffff', marginTop:24}}
                        width={16}
                        preview={false}
                        src={this.props.socialMedia}
                    />
                </Col>
            </Row>
        );
    }
}

export default ImageWithIcon;
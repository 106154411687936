import { Col, Row, Image, Tabs, Progress, Button, Divider } from 'antd';
import React, { Component } from 'react';
import TextCard from './TextCard';
import { getReport } from './api';



import {
    PromodexCircle, Web, Youtube, Linkedin, Instagram, Twitter, Telegram, Medium,
} from '../../components/layout/Icons';
import { SplitNumber } from '../../utils/NumberUtils';
const WebIcon = Web()
const YoutubeIcon = Youtube()
const LinkedinIcon = Linkedin()
const InstagramIcon = Instagram()
const TwitterIcon = Twitter()
const TelegramIcon = Telegram()
const MediumIcon = Medium()

const { TabPane } = Tabs;

// This card component will be used in Investor/Promoter/Campaigner pages.
export default class DetailedCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth: 0,
            report: {}
        }

        this.seeDetailsButton = null
    }

    getIdoReport = async () => {
        const report = await getReport(this.props.id);
        this.setState({ report: report })
    }

    componentDidMount() {
        this.getIdoReport()
        this.setState({ innerWidth: window.innerWidth })
        setInterval(() => {
            this.setState({ innerWidth: window.innerWidth })
        }, 500)
    }


    render() {
        return <div className='detailed-card-wrapper'>
            <Row className='padding-16'>
                <Col xs={4}>
                    {this.props.statusIcon}
                </Col>
                <Col xs={16} align='center'>
                    <div className='border-text-wrapper'>
                        <div className='border-text'>
                            {this.props.status === "Upcoming" ? "Time to start" : "Delayed"}
                        </div>
                        {this.props.counter}
                    </div>
                </Col>
                <Col xs={4} align='right'>
                    {this.props.chainIcon}
                </Col>
            </Row>
            <Row>
                <Col xs={24} style={{ padding: 0 }}>
                    <p style={{
                        position: 'absolute',
                        top: -10,
                        left: 'calc(60% - 80px)',
                        zIndex: 10,
                        color: "#ffffff",
                        backgroundColor: this.props.status === "Open" ? "#5dab22" : this.props.status === "Upcoming" ? "#c68b0f" : this.props.status === "Ended" ? "#bf054b" : "bf054b",
                        width: 80,
                        textAlign: 'center',
                        borderRadius: 4
                    }}>
                        {this.props.status}
                    </p>
                    <Image onClick={() => {
                        this.seeDetailsButton.click()
                    }} style={{ cursor: 'pointer' }} preview={false} src={this.props.image} width="100%" />
                </Col>
            </Row>
            <Row style={{ marginTop: '-20px' }}>
                <Col xs={24} className='detailed-card-inside-tabs'>
                    <Tabs animated={false}>
                        <TabPane tab="Sale details" key="1">
                            <Row className='padding-16'>
                                <Col xs={{ span: 12 }} md={2}>
                                    {this.props.companyIcon}
                                </Col>
                                <Col xs={{ span: 12 }} md={10}>
                                    <p style={{ fontFamily: 'Helvetica-Bold', fontSize: 20, color: '#000659' }}>{this.props.companyName}</p>
                                </Col>
                                <Col xs={{ span: 16 }} md={12} align='right'>
                                    <p onClick={() => { this.seeDetailsButton.click() }}
                                        style={{ fontFamily: 'Helvetica-Bold', fontSize: 18, color: '#000659', cursor: 'pointer' }}>
                                        {this.props.saleType}
                                        <br />
                                        <small style={{ fontFamily: 'Helvetica', fontWeight: 100, fontSize: 14, color: '#000659' }}>{this.props.tokenName}</small>
                                    </p>
                                </Col>
                            </Row>
                            <Row className='padding-16'>
                                <Col xs={24}>
                                    <small style={{ fontSize: 14 }}>{Math.floor(this.state.report?.totalAmount / 1000)}%</small>
                                    <Progress percent={Math.floor(this.state.report?.totalAmount / 1000)} showInfo={false} size="small" strokeColor={'#5340FF'} />
                                    <Row>
                                        <Col sm={8}>
                                            <p style={{ fontSize: 14, color: '#283D5C' }}>{SplitNumber(Math.floor(this.state.report?.totalAmount))} / {SplitNumber(this.props.targetFundSale)}</p>
                                        </Col>
                                        <Col sm={16} align='right'>
                                            <p style={{ fontSize: 14, color: '#283D5C' }}>{SplitNumber(Math.floor(this.state.report?.totalAmount * 1000 / 21))} / {SplitNumber(this.props.targetTokenSale)} Promo</p>
                                        </Col>
                                    </Row>
                                    {/* <p style={{ fontSize: 14, color: '#283D5C' }}>$0 / $100.000 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 0 / 4.761.904,76 Promo</p> */}
                                </Col>
                            </Row>
                            <br />
                            <Row className='padding-16' gutter={[24, 24]}>
                                <Col xs={{ span: 24 }} lg={12} align={this.state.innerWidth < 700 ? 'center' : 'left'}>
                                    <div className='border-text-wrapper' style={{fontSize:12}}>
                                        <div className='border-text'>
                                            Date
                                        </div>
                                        {this.props.date}
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} lg={12} align={this.state.innerWidth < 700 ? 'center' : 'right'}>
                                    <div className='border-text-wrapper' style={{fontSize:12}}>
                                        <div className='border-text'>
                                            Price
                                        </div>
                                        $ {SplitNumber(this.props.price)} per Promo
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Project Details" key="2">
                            <Row className='padding-16'>
                                <Col xs={12} lg={2}>
                                    {this.props.companyIcon}
                                </Col>
                                <Col xs={12} lg={10}>
                                    <p style={{ fontFamily: 'Helvetica-Bold', fontSize: 20, color: '#000659' }}>{this.props.companyName}</p>
                                </Col>
                                <Col xs={24} lg={12} align='right'>
                                    <a target='_blank' href='https://promodex.io'>
                                        {WebIcon}
                                    </a>
                                    <a target='_blank' href='https://www.youtube.com/channel/UCEtCnSnFlHqgB6woV4HVP4w'>
                                        {YoutubeIcon}
                                    </a>
                                    <a target='_blank' href='https://www.linkedin.com/company/promodexio/'>
                                        {LinkedinIcon}
                                    </a>
                                    <a target='_blank' href='https://www.instagram.com/promodexio/'>
                                        {InstagramIcon}
                                    </a>
                                    <a target='_blank' href='https://twitter.com/promodexio'>
                                        {TwitterIcon}
                                    </a>
                                    <a target='_blank' href='https://t.me/promodexio_global?utm_medium=social&utm_source=linktree&utm_campaign=community+channel'>
                                        {TelegramIcon}
                                    </a>
                                    <a target='_blank' href='https://promodex.medium.com/'>
                                        {MediumIcon}
                                    </a>
                                </Col>
                            </Row>
                            <Row className='padding-16'>
                                <Col xs={24}>
                                    <p style={{ fontFamily: 'Helvetica-Bold', fontSize: 14, color: '#000659' }}>Programmatic Promotion Marketplace</p>
                                    <Row gutter={[4, 8]}>
                                        <Col>
                                            <TextCard context={'#Marketing'} />
                                        </Col>
                                        <Col>
                                            <TextCard context={'#Influencer'} />
                                        </Col>
                                        <Col>
                                            <TextCard context={'#Launchpad'} />
                                        </Col>
                                        <Col>
                                            <TextCard context={'#Ads'} />
                                        </Col>
                                        <Col>
                                            <TextCard context={'#BSC'} />
                                        </Col>
                                    </Row>
                                    <p style={{ fontSize: 12, color: '#283D5C', fontWeight: 100 }}>
                                        Promodex Is A Blockchain Based Programmatic Promotion Marketplace That Brings The Campaigners Together With The Promoters. As a campaigner ...
                                        <a href={`project?id=${this.props.id}`} style={{ color: '#5340FF' }}> see more</a>
                                    </p>
                                </Col>
                            </Row>
                            <Row className='padding-16' gutter={[24, 24]}>
                                <Col xs={{ span: 24 }} lg={12} align={this.state.innerWidth < 700 ? 'center' : 'left'}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Initial Circulation
                                        </div>
                                        5.400.000 Promo
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} lg={12} align={this.state.innerWidth < 700 ? 'center' : 'right'}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Initial Market Cap
                                        </div>
                                        $ 118.800
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Engagements" key="3">
                            <Row className='padding-16'>
                                <Col xs={12} lg={2}>
                                    {this.props.companyIcon}
                                </Col>
                                <Col xs={12} lg={10}>
                                    <p style={{ fontFamily: 'Helvetica-Bold', fontSize: 20, color: '#000659' }}>{this.props.companyName}</p>
                                </Col>
                            </Row>
                            <br />
                            <Row className='padding-16' gutter={[24, 48]}>
                                <Col xs={{ span: 24 }} lg={12} align={this.state.innerWidth < 700 ? 'center' : 'left'}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            First Listing
                                        </div>
                                        Pancake Swap
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} lg={12} align={this.state.innerWidth < 700 ? 'center' : 'right'}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Listing Date / Price
                                        </div>
                                        28.02.22 / $ 0.022
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} lg={12} align={this.state.innerWidth < 700 ? 'center' : 'left'}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Incubator
                                        </div>
                                        Devtagon
                                    </div>
                                </Col>
                                <Col xs={{ span: 24 }} lg={12} align={this.state.innerWidth < 700 ? 'center' : 'right'}>
                                    <div className='border-text-wrapper'>
                                        <div className='border-text'>
                                            Lead investor
                                        </div>
                                        Alpha Capital
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Divider style={{ backgroundColor: 'rgba(40,61,92,0.25)' }} />
            <Row style={{ paddingLeft: 16, paddingRight: 16 }}>
                <Col xs={6} lg={8} align="left">
                    {this.props.auditIcon}
                </Col>
                <Col xs={7} lg={8} align="center" style={{ marginTop: 10 }} >
                    <Button ref={(ref) => (this.seeDetailsButton = ref)} href={"sale?id=" + this.props.id + "&status=" + this.props.status + "&idoname=" + this.props.saleType + "&idoType=" + this.props.idoType} className="connect-wallet-btn" size="small">
                        See Details
                    </Button>
                </Col>
                <Col xs={11} lg={8} align="right">
                    {this.props.ratingIcon}
                </Col>
            </Row>


            <Row style={{ paddingLeft: 16, paddingRight: 16 }}>
                <Col xs={8} align="left">
                    <p style={{ fontWeight: 100, fontSize: 12, color: '#5340FF' }}>Audit</p>
                </Col>
                <Col xs={8} align="center">

                </Col>
                <Col xs={8} align="right">
                    <p style={{ fontWeight: 100, fontSize: 12, color: '#5340FF' }}>Rating</p>
                </Col>
            </Row>
        </div>;
    }
}

import { Col, Row, Tabs } from 'antd';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../redux/promodex/actions";
import { LoginRegister } from '../../components/layout/Icons';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Register from './Register';

const LoginRegisterImage = LoginRegister()


const { TabPane } = Tabs;

class LoginRegisterContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {

  }

  handleTabChange = (key) => {
  }

  render() {
    return <div>
      <Row align="bottom">
        <Col xs={{ span: 24 }} md={{ span: 10, offset: 1 }} className='login-register-tabs'>
          <Tabs defaultActiveKey="1" onChange={this.handleTabChange} centered={false} animated={false}>
            <TabPane tab="Sign In" key="1">
              {this.props.promodexInformation?.forgotPassword === true ?
                <ForgotPassword />
                :
                <Login />
              }
            </TabPane>
            <TabPane tab="Register" key="2">
              <Register />
            </TabPane>
          </Tabs>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          {LoginRegisterImage}
        </Col>
      </Row>
    </div>;
  }
}


const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(LoginRegisterContent));
import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../../../redux/promodex/actions";
import { Alert, Button, Col, Divider, Image, Row, Tabs, Checkbox, Input, notification, Modal, Select } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoWarningOutline } from "react-icons/io5";

import UnstakePreparing from './tabs/UnstakePreparing';
import Unstaking from './tabs/Unstaking';

import {
  EyeImage, EyeInvisibleImage, HideSummary, WalletBlack, AllTokenSales,
  GreenCircleCheckmark, RedCircleCrossmark, FrequentlyAskedQuestions, StakingUnstaignTierCalcualtor, PromoToken, BinanceToken
} from '../../../../components/layout/Icons';
import TextCard from '../../../../components/card/TextCard';
const EyeIcon = EyeImage()
const EyeInvisibleIcon = EyeInvisibleImage()
const WalletBlackIcon = WalletBlack()
const AllTokenSalesIcon = AllTokenSales()
const RedCircleCrossmarkIcon = RedCircleCrossmark()
const FrequentlyAskedQuestionsButton = FrequentlyAskedQuestions()
const StakingUnstaignTierCalcualtorButton = StakingUnstaignTierCalcualtor()
const PromoTokenIcon = PromoToken()
const BinanceTokenIcon = BinanceToken()

const { TabPane } = Tabs;


const { Option } = Select;

class Unstake extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unStakeCondition: null,
      activeTab: '1'
    }
  }



  openNotification = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    })
  }
  //Unstake ile ilgli fonksiyonalr burada yazılmalı
  handleUnstakeCondition = (event) => {
    this.setState({ unStakeCondition: event.target.checked })
  }

  changeTab = (key) => {
    if (key === '2' && !this.state.unStakeCondition) {
      this.openNotification('warning', "Warning", "Please read and accept the terms")
    } else {
      this.setState({ activeTab: key });
    }

    // if (key === "2" && this.state.unStakeCondition) {
    //   this.setState({ unstakeActiveTab: key });
    // } else if (key === "1") {
    //   this.setState({ unstakeActiveTab: key });
    // } else if (key === "2" && !this.state.unStakeCondition) {
    //   this.openNotification('warning', "Warning", "Please read and accept the terms")
    // }
  }


  render() {

    const GreenCircleCheckmarkIcon = GreenCircleCheckmark('51px', '50px')
    const GreenCircleCheckmarkIconSmall = GreenCircleCheckmark('26px', '25px')

    const BottomButtons = (
      <Row>
        <Col sm={12} align='left'>
          <a onClick={this.comingSoonModal}>
            {FrequentlyAskedQuestionsButton}
          </a>
        </Col>
        <Col sm={12} align='right'>
          <a onClick={this.comingSoonModal}>
            {StakingUnstaignTierCalcualtorButton}
          </a>
        </Col>
      </Row>
    )


    return (
      <Row className='margin-y-10'>
        <Col sm={24} md={{ span: 20, offset: 2 }} className='shadow-white-div project-page-tabs'>
          <Tabs animated={false} activeKey={this.state.activeTab} onChange={this.changeTab}>
            <TabPane tab="Preparing" key="1" className='padding-16'>
              <UnstakePreparing
                handleUnstakeCondition={this.handleUnstakeCondition}
                changeTab={this.changeTab}
                BottomButtons={this.props.BottomButtons}
                GreenCircleCheckmarkIcon={this.props.GreenCircleCheckmarkIcon}
                RedCircleCrossmarkIcon={this.props.RedCircleCrossmarkIcon}
                GreenCircleCheckmarkIconSmall={this.props.GreenCircleCheckmarkIconSmall} />
            </TabPane>
            <TabPane tab="Unstaking" key="2" className='padding-16'>
              <Unstaking
                changeTab={this.changeTab}
                BottomButtons={this.props.BottomButtons}
                GreenCircleCheckmarkIcon={this.props.GreenCircleCheckmarkIcon}
                GreenCircleCheckmarkIconSmall={this.props.GreenCircleCheckmarkIconSmall} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    )
  }
}


const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(Unstake));

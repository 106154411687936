import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../redux/promodex/actions";
import { Row, Col, notification, Steps } from 'antd';
import ProjectLink from './modules/ProjectLink';
import ProjectSocialMedia from './modules/ProjectSocialMedia';
import ProjectCommunityChannel from './modules/ProjectCommunityChannel';
import BlockchainInfo from './modules/BlockchainInfo';
import ProjectContent from './modules/ProjectContent';
import ProjectInformation from './modules/ProjectInformation';
import { FiEdit } from 'react-icons/fi'

import { SolutionOutlined, LoadingOutlined } from '@ant-design/icons';
import ProjectTeam from './modules/ProjectTeam';

const { Step } = Steps;


export class CreateProjectContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      currentStep: 0,
    }

    this.titleStyle = {
      color: '#000659',
      fontWeight: 900,
    }
  }

  componentDidMount() {
    this.props.setPromodexRedux({ 
      project: null,
     })

  }


  openNotification = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    })
  }

  handleSteps = (current) => {
    this.setState({ currentStep: current })
  }


  render() {

    return (
      <div>
        <Row className='margin-top-20'>
          <Col xs={24} className='shadow-white-div'>
            <Steps current={this.state.currentStep} onChange={this.handleSteps}>
              <Step title="Information" icon={this.state.currentStep === 0 ? <FiEdit /> : <SolutionOutlined />} />
              <Step title="Links" icon={this.state.currentStep === 1 ? <FiEdit /> : <SolutionOutlined />} />
              <Step title="Social media" icon={this.state.currentStep === 2 ? <FiEdit /> : <SolutionOutlined />} />
              <Step title="Communities" icon={this.state.currentStep === 3 ? <FiEdit /> : <SolutionOutlined />} />
              <Step title="Blockchain" icon={this.state.currentStep === 4 ? <FiEdit /> : <SolutionOutlined />} />
              <Step title="Content" icon={this.state.currentStep === 5 ? <FiEdit /> : <SolutionOutlined />} />
              <Step title="Team" icon={this.state.currentStep === 6 ? <FiEdit /> : <SolutionOutlined />} />
              <Step title="Confirm" icon={this.state.currentStep === 7 ? <FiEdit /> : <SolutionOutlined />} />
            </Steps>
          </Col>
        </Row>
          <ProjectInformation style={{display: this.state.currentStep !== 0 ? 'none' : undefined}} titleStyle={this.titleStyle} 
          openNotification={this.openNotification} currentStep={this.state.currentStep} handleSteps={this.handleSteps} />
          <ProjectLink style={{display: this.state.currentStep !== 1 ? 'none' : undefined}} titleStyle={this.titleStyle} 
          openNotification={this.openNotification} currentStep={this.state.currentStep} handleSteps={this.handleSteps} />
          <ProjectSocialMedia style={{display: this.state.currentStep !== 2 ? 'none' : undefined}} titleStyle={this.titleStyle} 
          openNotification={this.openNotification} currentStep={this.state.currentStep} handleSteps={this.handleSteps} />
          <ProjectCommunityChannel style={{display: this.state.currentStep !== 3 ? 'none' : undefined}} titleStyle={this.titleStyle} 
          openNotification={this.openNotification} currentStep={this.state.currentStep} handleSteps={this.handleSteps} />
          <BlockchainInfo style={{display: this.state.currentStep !== 4 ? 'none' : undefined}} titleStyle={this.titleStyle} 
          openNotification={this.openNotification} currentStep={this.state.currentStep} handleSteps={this.handleSteps} />
          <ProjectContent style={{display: this.state.currentStep !== 5 ? 'none' : undefined}} titleStyle={this.titleStyle} 
          openNotification={this.openNotification} currentStep={this.state.currentStep} handleSteps={this.handleSteps} />
          <ProjectTeam style={{display: this.state.currentStep !== 6 ? 'none' : undefined}} titleStyle={this.titleStyle} 
          openNotification={this.openNotification} currentStep={this.state.currentStep} handleSteps={this.handleSteps} />
      </div>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(CreateProjectContent));
import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../../../redux/promodex/actions";
import { Col, Row, Tabs, notification } from "antd";

import WithdrawPreparing from './tabs/WithdrawPreparing';
import Withdrawing from './tabs/Withdrawing';

const { TabPane } = Tabs;



class Withdraw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      withdrawCondition: false,
      activeTab: '1'
    }
  }

  openNotification = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    })
  }


  handleWithdrawCondition = (event) => {
    this.setState({ withdrawCondition: event.target.checked })
  }
  changeTab = (key) => {
    if (key === '2' && !this.state.withdrawCondition) {
      this.openNotification('warning', "Warning", "Please read and accept the terms")
    } else {
      this.setState({ activeTab: key });
    }
  }


  render() {
    return (
      <Row className='margin-y-10'>
        <Col sm={24} md={{ span: 20, offset: 2 }} className='shadow-white-div project-page-tabs'>
          <Tabs animated={false} activeKey={this.state.activeTab} onChange={this.changeTab}>
            <TabPane tab="Preparing" key="1" className='padding-16'>
              <WithdrawPreparing
                handleWithdrawCondition={this.handleWithdrawCondition}
                changeTab={this.changeTab}
                BottomButtons={this.props.BottomButtons}
                GreenCircleCheckmarkIcon={this.props.GreenCircleCheckmarkIcon}
                RedCircleCrossmarkIcon={this.props.RedCircleCrossmarkIcon}
                GreenCircleCheckmarkIconSmall={this.props.GreenCircleCheckmarkIconSmall} />
            </TabPane>
            <TabPane tab="Withdrawing" key="2" className='padding-16'>
              <Withdrawing
                changeTab={this.changeTab}
                BottomButtons={this.props.BottomButtons}
                GreenCircleCheckmarkIcon={this.props.GreenCircleCheckmarkIcon}
                RedCircleCrossmarkIcon={this.props.RedCircleCrossmarkIcon}
                GreenCircleCheckmarkIconSmall={this.props.GreenCircleCheckmarkIconSmall} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    )
  }
}



const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(Withdraw));

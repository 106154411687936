import React, {Component} from 'react';
import PromodexLayout from "../../components/layout/PromodexLayout";
import DashboardContent from "./DashboardContent";


class DashboardLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth:0
        }
    }

    componentDidMount() {
        this.setState({innerWidth: window.innerWidth})
        setInterval(() => {
            this.setState({innerWidth: window.innerWidth})
        }, 500)
    }


    render() {
        return (
            <PromodexLayout page='dashboard' content={<DashboardContent innerWidth={this.state.innerWidth} />} />
        );
    }
}

export default DashboardLayout;
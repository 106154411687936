import React, { Component } from 'react';


// This component will make a text with border
export default class TextCard extends Component {
  render() {
    return <div>
            <p style={{
        padding:'4px 8px', 
        border: '1px solid rgba(40,61,92,0.10)',
        borderRadius: 5,
        fontSize: 12,
        fontWeight: 100,
        color: '#283D5C',
        background: '#FAFBFE',
        textAlign: 'center',
        display: 'inline-block'
        }}>
                {this.props.context}
            </p>
        </div>;
  }
}

//
//
//
//   !! TÜM FONKSİYONLARA KULLANILSIN KULLANILMASIN PAYLOADLARDA TÜM DEĞİŞKENLER EKLENMELİ !!
//   !! SADECE KONTRAT FONKSİYONLARI YER ALMALI FRONTENDE BİR ŞEY EKLENMESİ GEREKİRSE FRONTEND'TE YAPILMALI  !!
//
//
//

import Web3 from 'web3';
import { busdContractAddress, busdContractAbi } from "../../contract/abi/busd";
import { saleContractAddress, saleContractAbi } from "../../contract/abi/sale";
import { mainContractAddress, mainContractAbi } from "../../contract/abi/main";
import _busdService from "../../contract/services/busd.service";
import _saleService from "../../contract/services/sale.service";
import _mainService from "../../contract/services/main.service";

const trigger = document.getElementById("trigger")
const _bAbi = busdContractAbi;
const _sAbi = saleContractAbi;
const _mabi = mainContractAbi;
const _bAddress = busdContractAddress
const _sAddress = saleContractAddress
const _mAddress = mainContractAddress
const _mDecimal = 8;
const _bDecimal = 18;

let _cmain, _cbusd, _csale;

trigger.addEventListener("click", () => {
    if (window.ethereum) {
        window.ethereum.request({ method: "eth_requestAccounts" }).then((response) => {
            let w3 = new Web3(window.ethereum);
            _cbusd = new w3.eth.Contract(_bAbi, _bAddress)
            _csale = new w3.eth.Contract(_sAbi, _sAddress)
            _cmain = new w3.eth.Contract(_mabi, _mAddress)
        }).catch(err => console.log(err))
    }
})


//BUSD İŞLEMLERİ start
export const getBUSDBalance = async (metamaskAccount) => {
    const payload = {
        ethereum: window.ethereum,
        contract: _cbusd,
        cAddress: _bAddress,
        sender: metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _bDecimal,
        actionWith: _mAddress
    }
    const result = await _busdService.balanceOf(payload)
    return result
}



// action with için sale kontrat adresi kullanıldı çünkü methodu ve miktarı onaylanyan kontrat sale kontratı 
// sale kontratı harcananması gerken busd miktarını approve ediyor allow miktarlarıda o kontartta aciton with için
export const BUSDisAllowed = async (props, amount) => {
    const payload = {
        ethereum: window.ethereum,
        contract: _cbusd,
        cAddress: _mAddress,
        sender: props?.promodexInformation?.metamaskAccount.toLowerCase(),
        amount: amount,
        decimal: _bDecimal,
        actionWith: _sAddress
    }
    const result = await _busdService.isAllowed(payload);
    return result;

}


export const BUSDapprove = async (props, amount) => {
    const payload = {
        ethereum: window.ethereum,
        contract: _cbusd,
        cAddress: _bAddress,
        sender: props?.promodexInformation?.metamaskAccount.toLowerCase(),
        amount: amount,
        decimal: _bDecimal,
        actionWith: _sAddress
    }

    const result = await _busdService.approve(payload)
    return result

}

/////////////////////////////////////////





//SALE İŞLEMLERİ start

export const buy = async (props, amount) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _csale,
        cAddress: _sAddress,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: amount,
        decimal: _bDecimal,
        actionWith: _mAddress
    }

    const result = await _saleService.buy(payload);

    return result;
}


export const getAddressBuyLimit = async (props) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _csale,
        cAddress: _mAddress,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _mDecimal,
        actionWith: _mAddress
    }

    const result = await _saleService.getAddressBuyLimit(payload);

    return result;
}


export const getAddressBuyLimitLeft = async (props) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _csale,
        cAddress: _mAddress,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _bDecimal,
        actionWith: _mAddress
    }

    const result = await _saleService.getAddressBuyLimitLeft(payload);

    return result;
}


export const getDepositsByWithdrawalAddress = async (props) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _csale,
        cAddress: _mAddress,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _mDecimal,
        actionWith: _mAddress
    }

    const result = await _saleService.getLocksByWithdrawalAddress(payload);

    return result;
}


export const getLockDetails = async (props, lockID) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _csale,
        cAddress: _sAddress,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _mDecimal,
        actionWith: lockID
    }

    const result = await _saleService.getLockDetails(payload);

    return result;
}


export const withdrawTokens = async (props) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _csale,
        cAddress: _mAddress,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _mDecimal,
        actionWith: _mAddress
    }

    const result = await _saleService.withdrawTokens(payload);

    return result;
}


export const checkAddressWhiteListStatus = async (props) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _csale,
        cAddress: _mAddress,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _mDecimal,
        actionWith: _mAddress
    }

    const result = await _saleService.checkAddressWhiteListStatus(payload);

    return result;
}


export const totalLockedAmounts = async (props) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _csale,
        cAddress: _mabi,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _mDecimal,
        actionWith: _mAddress
    }

    const result = await _saleService.totalLockedAmounts(payload);

    return result;
}




///////////////////////////////////


export const getMAINBalance = async (metamaskAccount) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _cmain,
        cAddress: _mAddress,
        sender: metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _mDecimal,
        actionWith: _mAddress
    }

    const result = await _mainService.balanceOf(payload);

    return result;
}


export const getTotalSupply = async (props) => {
    const payload =
    {
        ethereum: window.ethereum,
        contract: _cmain,
        cAddress: _mAddress,
        sender: props?.promodexInformation?.metamaskAccount?.toLowerCase(),
        amount: null,
        decimal: _mDecimal,
        actionWith: _mAddress
    }

    const result = await _mainService.totalSupply(payload);

    return result;
}
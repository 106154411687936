import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Button, Table, Select, Popconfirm, Spin } from 'antd';
import BorderTextInput from '../../../components/input/BorderTextInput';
import { createProjectSocialMedia, deleteProjectSocialMedia, getProjectSocialMedias, getSocialMedias } from '../api';
import BorderTextSelectbox from '../../../components/input/BorderTextSelectbox';
import { AiOutlineDelete } from 'react-icons/ai';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

class ProjectSocialMedia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socialMedias: [],
      socialMedia: null,
      url: null,
      dataSource: [],
      columns: [
        {
          title: 'Social Media',
          dataIndex: 'socialMedia',
          key: 'socialMedia',
        },
        {
          title: 'URL',
          dataIndex: 'url',
          key: 'url',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Popconfirm title='Sure to delete?' onConfirm={() => this.handleDelete(record.id)} size="middle">
              <a color='#5340FF'><AiOutlineDelete size={18} style={{ color: '#5340FF' }} /></a>
            </Popconfirm>
          ),
        },
      ],
      loading: false,
    }
  }

  componentDidMount() {
    this.getSocialMediaTypes()
  }

  // Get Project social medias for the table 
  getProjectSocialMedias = async () => {
    try {
      // send request to get social media by project id
      const projectSocialMedia = await getProjectSocialMedias(this.props.promodexInformation.project.id)
      let tempArr = []

      for (let i = 0; i < projectSocialMedia.length; i++) {
        // create a data for table that includes social media info.

        let data = {
          key: i + 1,
          socialMedia: this.convertIdToName(projectSocialMedia[i].socialMedia),
          url: projectSocialMedia[i].url,
          id: projectSocialMedia[i].id,
        }
        tempArr.push(data)

      }
      // push data to state
      this.setState({
        dataSource: tempArr
      })

    } catch (ex) {
      console.log(ex)
    }
  }


  convertIdToName = (id) => {
    const socialMedia = this.state.socialMedias.filter(data => data.id === id)[0]
    return socialMedia.name
  }

  getSocialMediaTypes = async () => {
    const socialMedias = await getSocialMedias()
    this.setState({ socialMedias: socialMedias })
  }

  handleDelete = async (id) => {
    try {
      const removeSocialMedia = await deleteProjectSocialMedia(id)
      console.log('REMOVE SOCAIL : ', removeSocialMedia)
      this.getProjectSocialMedias()

    } catch (ex) {
      console.log(ex)
    }
  }


  handleAddAccount = async () => {
    if (this.state.socialMedia === null) {
      this.props.openNotification('warning', 'Cant Create Empty', 'You cant create without social media!')
      return
    }
    if (this.state.url === null) {
      this.props.openNotification('warning', 'Cant Create Empty', 'You cant create without url!')
      return
    }
    if (this.props.promodexInformation.project === null) {
      this.props.openNotification('warning', 'No project found', 'You must save your project information first !')
      return
    }

    const payload = {
      url: this.state.url,
      socialMedia: this.state.socialMedia,
      projectId: this.props.promodexInformation.project.id
    }

    try {
      const projectSocialMedia = await createProjectSocialMedia(payload)
      this.getProjectSocialMedias()

    } catch (error) {
      console.log('ERROR : ', error)
    }

  }

  saveAndNext = () => {
    // if(this.state.dataSource.length === 0){
    //   this.props.openNotification('warning', 'No Social Media Found', 'You must add socail media if you want to save socail medias!')
    //   return
    // }
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.setState({ loading: false }, () => {
          this.props.openNotification('success', 'Social Medias Saved', 'Social medias are successfuly saved!')
          this.props.handleSteps(this.props.currentStep + 1)
        })
      }, 3000)
    })
  }




  render() {
    const socialMediaOptions = this.state.socialMedias.map((socialMedia, index) => {
      return (
        <Option key={index} value={socialMedia.id}>{socialMedia.name}</Option>
      )
    })

    return (
      <div style={this.props.style}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
          <Row className='margin-top-20'>
            <Col xs={24} className='shadow-white-div'>
              <Row gutter={[24, 24]} align='middle'>
                <Col xs={24}>
                  <p style={this.props.titleStyle}>Project / Brand Social Media Accounts</p>
                </Col>
                <Col xs={24} lg={8} >
                  <BorderTextSelectbox labelText={'Add a New Social Media'} select={
                    <Select value={this.state.tags} onChange={(event) => this.setState({ socialMedia: event })}
                      style={{ width: '100%' }} size='large' defaultValue={undefined}>
                      {socialMediaOptions}
                    </Select>
                  } />
                </Col>
                <Col xs={24} lg={8}>
                  <BorderTextInput onChange={(event) => this.setState({ url: event.target.value })}
                    labelText={'Add URL'} size='large' />
                </Col>
                <Col xs={24} lg={{ span: 6, offset: 2 }}>
                  <Button onClick={this.handleAddAccount} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Add a new Account
                  </Button>
                </Col>
              </Row>
              <Row className='margin-top-20'>
                <Col xs={24}>
                  <Table scroll={{ x: 1000 }} size='small' className='ant-table-strip'
                    showHeader={false} pagination={false} dataSource={this.state.dataSource} columns={this.state.columns} />
                </Col>
              </Row>
              <Row className='margin-top-20'>
                <Col xs={{ span: 10 }} md={{ span: 4 }} align='left'>
                  <Button onClick={() => this.props.handleSteps(this.props.currentStep - 1)} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Previus
                  </Button>
                </Col>
                <Col xs={{ span: 10, offset: 4 }} md={{ span: 4, offset: 16 }} align='right'>
                  <Button onClick={this.saveAndNext} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Save & Next
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ProjectSocialMedia));
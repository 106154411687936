import { ethers } from "ethers";

class MainService {
  balanceOf = async (values) => {
    let { ethereum, contract, cAddress, sender, amount, decimal, actionWith } =
      values;

    let result = await contract.methods
      .balanceOf(sender)
      .call()
      .then((balance) => balance);

    let _balance = ethers.utils.formatUnits(result, decimal);
    return _balance;
  };

  totalSupply = async (values) => {
    let { ethereum, contract, cAddress, sender, amount, decimal, actionWith } =
      values;

    let result = await contract.methods
      .totalSupply()
      .call()
      .then((balance) => balance);

    let _totalSupply = ethers.utils.formatUnits(result, decimal);

    return _totalSupply;
  };
}

export default new MainService();

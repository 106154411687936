import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import DashboardCard from '../card/DashboardCard';


// This component builded for carousels in the landing page
export default class CampaignCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cards: this.props.cards,
            showCards: [],
            cardIndex: 0,

        }
    }


    componentDidMount() {
        // Getting innerWidth for the responsive
        let innerWidth;
        if (window.innerWidth <= 550) innerWidth = 0
        else if (window.innerWidth > 550 && window.innerWidth < 1099) innerWidth = 2
        else if (window.innerWidth > 1100) innerWidth = 4

        const tempArr = []
        for (let i = this.state.cardIndex; i <= this.state.cardIndex + innerWidth; i++) {
            tempArr.push(this.state.cards[i])
        }

        this.setState({ showCards: tempArr, innerWidth: innerWidth })
    }

    handleSlider = (pos) => {

        if (pos === 'left') {
            this.setState({ cardIndex: this.state.cardIndex - 1 }, () => {
                const tempArr = []
                for (let i = this.state.cardIndex; i <= this.state.cardIndex + this.state.innerWidth; i++) {
                    tempArr.push(this.state.cards[i])
                }
                this.setState({ showCards: tempArr })
            })
        } else if (pos === 'right') {
            this.setState({ cardIndex: this.state.cardIndex + 1 }, () => {
                const tempArr = []
                for (let i = this.state.cardIndex; i <= this.state.cardIndex + this.state.innerWidth; i++) {
                    tempArr.push(this.state.cards[i])
                }
                this.setState({ showCards: tempArr })
            })
        }
    }

    render() {
        return (<Row style={{ paddingLeft: 8 }}>
            <Col xs={1}>
                {this.state.cardIndex !== 0 &&
                    <AiOutlineLeft className="vertical-center slider-arrow" size={36}
                        onClick={() => this.handleSlider('left')} />
                }
            </Col>
            {this.state.showCards.map((data, index) => {
                return (
                    <Col key={index} xs={this.state.innerWidth === 0 ? { span: 16, offset: 3 } : this.state.innerWidth === 2 ? 7 : this.state.innerWidth === 4 && 4}>
                        <DashboardCard
                            saleType={data.name}
                            id={data.id}
                            status={data.status}
                            src={data.img}
                            idoType={data.idoType}
                            counter={data.counter}
                            companyName={data.projectId?.name}
                            publicStatus={data.idoType}
                            productName={data.name}
                            price={data.priceValue}
                            endDate={data.endDate}
                            innerWidth={this.state.innerWidth} />
                    </Col>

                )
            })
            }
            {(this.state.cardIndex + (this.state.innerWidth + 1) === this.state.cards.length) ?
                <Col xs={2}>
                    <h5 className="vertical-center" style={{ color: '#9a98b8' }}>No other projects
                        found</h5>
                </Col>
                :
                <Col xs={1} align='left'>
                    <AiOutlineRight className="vertical-center slider-arrow" size={36}
                        onClick={() => this.handleSlider('right')} />
                </Col>

            }
        </Row>);
    }
}

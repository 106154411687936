import React, { Component } from "react";
import { Input, Col, Row } from "antd";
import "antd/dist/antd.css";

//PROPS
/**
 * addonBefore
 * addonAfter
 * defaultValue
 * isHorizontalLabel
 * labelFontSize
 * labelColorClass
 * labelText
 * addonBefore
 * addonAfter
 * defaultValue
 * allowClear
 * disabled
 * maxLength
 * prefixIcon
 * suffixIcon
 * onChange
 * value
 */

const style = {
    textAlign: "center",
    fontWeight: "bold",
};

export default class PromoInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            isHidden: true,
        };
    }

    render() {
        return this.props.isHorizontalLabel ? (
            <Row gutter={24}>
                <Col className="gutter-row" span={6}>
                    <small
                        style={{ fontSize: this.props.labelFontSize || 12 }}
                        className={this.props.labelColorClass || "profile-input-label"}
                    >
                        {this.props.labelText}
                    </small>
                </Col>
                <Col className="gutter-row" span={14}>
                    <Input
                        placeholder={this.props.placeholder || ""}
                        style={style}
                        className={this.props.className || ""}
                        addonBefore={this.props.addonBefore || ""}
                        addonAfter={this.props.addonAfter || ""}
                        defaultValue={this.props.defaultValue || ""}
                        allowClear={this.props.allowClear || true}
                        bordered={true}
                        disabled={this.props.disabled || false}
                        maxLength={this.props.maxLength || 32}
                        prefix={this.props.prefixIcon || null}
                        suffix={this.props.suffixIcon || null}
                        onChange={this.props.onChange}
                        value={this.props.value || null}
                        type={this.props.type || "text"}
                    />
                </Col>
            </Row>
        ) : (
            <Row>
                <Col span={24}>
                    <small
                        style={{ fontSize: this.props.labelFontSize || 12 }}
                        className={this.props.labelColorClass || "profile-input-label"}
                    >
                        {this.props.labelText}
                    </small>
                </Col>
                <Col span={24}>
                    <Input
                        addonBefore={this.props.addonBefore || ""}
                        className={this.props.className || ""}
                        addonAfter={this.props.addonAfter || ""}
                        defaultValue={this.props.defaultValue || ""}
                        allowClear={this.props.allowClear || true}
                        bordered={true}
                        disabled={this.props.disabled || false}
                        maxLength={this.props.maxLength || 32}
                        prefix={this.props.prefixIcon || null}
                        suffix={this.props.suffixIcon || null}
                        onChange={this.props.onChange}
                        value={this.props.value || null}
                        type={this.props.type || "text"}
                        placeholder={this.props.placeholder || ""}
                    />
                </Col>
            </Row>
        );
    }
}

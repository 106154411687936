import React, {Component} from 'react';
import PromodexLayout from "../../components/layout/PromodexLayout";
import UnknownContent from "./UnknownContent";

class UnknownLayout extends Component {
    render() {
        return (
            <div>
                <PromodexLayout page='unknown' content={<UnknownContent/>}/>
            </div>
        );
    }
}

export default UnknownLayout;
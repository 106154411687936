import {publicApi} from '../../services/axios'

// Register API
export const signUp = (payload) => {
    return publicApi.post('/accounts/signup/', payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return error;
    });
}
// Login with google
export const logInWithGoogle = (payload) => {
    return publicApi.post('socialauth/google/', payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return error;
    });
}
// Login with facebook
export const logInWithFacebook = (payload) => {
    return publicApi.post('socialauth/facebook/', payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return error;
    });
}
// Login API 
export const logIn = (payload) => {
    return publicApi.post('/pass/login/', payload)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        return error
    });
}
// Logout API 
export const logout = () => {
    return publicApi.post('/pass/logout/', )
    .then(response => {
        return response;
    })
    .catch(error => {
        return error
    });
}

// RESET API 
export const resetPassword = (payload) => {
    return publicApi.post('/pass/reset/', payload)
    .then(response => {
        return response;
    })
    .catch(error => {
        return error
    });
}
// Create password API 
export const createPassword = (payload, uid, token) => {
    return publicApi.post(`/pass/reset_confirm/${uid}/${token}/`, payload)
    .then(response => {
        return response;
    })
    .catch(error => {
        return error
    });
}
import React, {Component} from 'react';
import {Button, Checkbox, Col, Input, Row, Select, Upload} from "antd";
import PromoInput from "../../components/input/PromoInput";
import {AiOutlineDown, AiOutlinePlusCircle, AiOutlineUpload} from "react-icons/ai";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

class UnknownContent extends Component {
    render() {
        return (
            <div>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>WITHDRAW PROJECT INFO</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="FROM" placeholder="Coinmarketcap"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="Page link"
                                           placeholder="https://coinmarketcap.com/promodex"/>
                            </Col>
                        </Row>
                        <Row className="margin-top-20">
                            <Col xs={24} md={{span: 24}} align="middle">
                                <Button className="promo-btn p-font-bold">SYNC INFO</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>PROJECT DETAILS</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="PROJECT NAME" placeholder="PROMODEX"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 5, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="FIELD" type="number"
                                           placeholder="DEFI"/>
                            </Col>
                            <Col xs={{span: 23}} md={{span: 5, offset: 1}} align="middle">
                                <Upload name='file' action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                        headers={{authorization: 'authorization-text'}}>
                                    <Button style={{marginTop: '18px', borderRadius: '10px'}}
                                            icon={<AiOutlineUpload size={18}
                                                                   className="vertical-align-text-bottom"/>}> Upload
                                        Logo</Button>
                                </Upload>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style margin-y-10">
                            <Col xs={{span: 23, offset: 1}} md={{span: 24}}>
                                <PromoInput isHorizontalLabel={false} labelText="BRIEF DESCRIPTION"
                                           placeholder="Programmatic promotion marketplace"/>
                            </Col>
                        </Row>
                        <Row className="margin-top-20">
                            <Col xs={{span: 23, offset: 1}} md={{span: 24, offset: 1}}>
                                <Row>
                                    <Col span={20}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            ABOUT PROJECT
                                        </small>
                                    </Col>
                                    <Col span={22}>
                                        <TextArea rows={4} maxLength={250}
                                                  placeholder="Promodex is a programmatic promotion marketplace bringing campaigners and promoters together.Campaigners can create their campaign, stakethe budget and aunch their campaing in a decentralized mannerand promotes can contribute to get their rewards according to their individualinfluencer score algoritmacally calculated."/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 22, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>LINKS</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="WEBSITE LINK"
                                           placeholder="https://promodex.io"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="INTRODUCTION VIDEO"
                                           placeholder="https://vimeo.com/promodexio"/>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style margin-y-10">
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false}
                                           labelText="MEDIA KIT REPOSITORY (log, images, banner, text contents etc.)"
                                           placeholder="https://dropbox.com/home/Promodex.io"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="WHITEPAPER LINK"
                                           placeholder="https://promodex.io&whitepaper"/>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style margin-y-10">
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false}
                                           labelText="CODE AUDIT CERTIFICATE LINK"
                                           placeholder="Please enter your audit certificate link"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="GITBOOK DOCS LINK"
                                           placeholder="Please enter your gitbook docs link"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>ADD SOCIAL MEDIA CHANNELS</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="Socail media platform" placeholder="Choose social media channel"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="Account link"
                                           placeholder="Add socail media account link"/>
                            </Col>
                        </Row>
                        <Row className="margin-top-20">
                            <Col xs={{span: 23, offset: 1}} md={{span: 23}} align="middle">
                                <h4 className="p-font-color p-font-bold">+ADD NEW SOCIAL MEDIA</h4>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>BLCOKCHAIN</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 4, offset: 1}}>
                                <Row>
                                    <Col span={20}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Network
                                        </small>
                                    </Col>
                                    <Col xs={22} lg={20}>
                                        <Select className='select-style' suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>} defaultValue="bnc-smart-chain" onChange={(e) => this.setState({selectBox1: e.target.value})}>
                                            <Option value="all">All</Option>
                                            <Option value="bnc-smart-chain">Binance Smart Chain</Option>
                                            <Option value="dummy">Dummy</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 5}}>
                                <PromoInput isHorizontalLabel={false} labelText="Token Ticker"
                                           placeholder="PROMO"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 2}}>
                                <PromoInput isHorizontalLabel={false} labelText="Token Contract Address" placeholder="Please enter token contract address"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>OWNERSHIP CONFIRMATION</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="Confirmation by" type="email" placeholder="Offical email"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="Offical email address" type="email" placeholder="________@promodex.io"/>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="Confirmation Code" placeholder="Please enter the code sent to your email"/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}} align='right'>
                                <Button size="large"
                                        style={{
                                            paddingRight:'50px',
                                            paddingLeft:'50px',
                                            backgroundColor: "#6236ff",
                                            color: 'white',
                                            borderRadius: '30px',
                                            marginTop:'14px'
                                        }}>
                                    CONFIRM
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="shadow-white-div">
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 20, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>DISCLAMER</h5>
                                <small style={{marginLeft: '0px'}}>I confirm that. i am an authorized person for this project. I confirm that all the information above regarding the project are true</small>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style margin-top-20">
                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                <Checkbox>CONFIRM TERMS AND CONDITIONS</Checkbox>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}} align="right">
                                <Button size="large"
                                        style={{
                                            backgroundColor: "#6236ff",
                                            color: 'white',
                                            borderRadius: '30px',
                                            marginTop:'14px'
                                        }}>
                                    CREATE MY PROJECT
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default UnknownContent;
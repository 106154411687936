import { Col, Row, Select, Image } from 'antd';
import React, { Component } from 'react';
import CampaignCarousel from '../../components/carousel/CampaignCarousel';
import { AiOutlineDown } from 'react-icons/ai';


import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"



import dummyImage2 from "../../assets/images/dummy-images/Avon sample.png"
import dummyImage3 from "../../assets/images/dummy-images/englishhome_gorsel sample.png"
import dummyImage4 from "../../assets/images/dummy-images/Nike banner new sample.png"
import dummyImage5 from "../../assets/images/dummy-images/Shop.com sample.png"
import dummyImage6 from "../../assets/images/dummy-images/tetris_token sample.png"
import dummyImage7 from "../../assets/images/dummy-images/unigrid sample.png"


import { Promotion } from '../../components/layout/Icons';
const PromotionIcon = Promotion()

const { Option } = Select;

const cards = [
    {
        id: 1,
        status: 'Open232',
        img: dummyImage6,
        companyName: 'Tetrix',
        publicStatus: 'Public Sale',
        productName: '$Tetrix',
        price: '$378.000',
        endDate: '1d 5h 56m 54s',
    },
    {
        id: 2,
        status: 'Open23',
        img: dummyImage2,
        companyName: 'Avon',
        publicStatus: 'Public Sale',
        productName: '$Avon',
        price: '$378.000',
        endDate: '1d 5h 56m 54s',
    },
    {
        id: 3,
        status: 'Upcoming23',
        img: dummyImage3,
        companyName: 'E. Home',
        publicStatus: 'Public Sale',
        productName: '$EnglishHome',
        price: '$378.000',
        endDate: '1d 5h 56m 54s',
    },
    {
        id: 4,
        status: 'Ended23',
        img: dummyImage4,
        companyName: 'Nike',
        publicStatus: 'Public Sale',
        productName: '$Nike',
        price: '$378.000',
        endDate: '1d 5h 56m 54s',
    },
    {
        id: 5,
        status: 'Upcoming23',
        img: dummyImage5,
        companyName: 'Jeans',
        publicStatus: 'Public Sale',
        productName: '$Jean',
        price: '$378.000',
        endDate: '1d 5h 56m 54s',
    },
    {
        id: 6,
        status: 'Open23',
        img: dummyImage6,
        companyName: 'Promodex.io',
        publicStatus: 'Public Sale',
        productName: '$Promo',
        price: '$378.000',
        endDate: '1d 5h 56m 54s',
    },
    {
        id: 7,
        status: 'Ended23',
        img: dummyImage7,
        companyName: 'Promodex.io',
        publicStatus: 'Public Sale',
        productName: '$Promo',
        price: '$378.000',
        endDate: '1d 5h 56m 54s',
    },]


// This component will get the Ido Token Sale Campaigns data from an api
// And pass that data to CampaignCarousel component
export class PromotionCampaign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth: 0,
            showSample: false
        }
    }

    componentDidMount() {
        this.setState({ innerWidth: window.innerWidth })
        setInterval(() => {
            this.setState({ innerWidth: window.innerWidth })
        }, 500)
    }
    render() {
        return (<>
            <div className={this.state.showSample ? 'show-sample-div show-active' : 'show-sample-div'} onClick={() => this.setState({ showSample: !this.state.showSample })}>
                {this.state.showSample ? 'Hide Sample' : 'Show Sample'}
            </div>
            <div style={{ filter: this.state.showSample ? '' : 'blur(4px)', transition: 'filter .3s', pointerEvents: 'none', userSelect: 'none' }}>
                <Row gutter={[4, 8]} className='margin-y-10'>
                    <Col xs={{ span: 6, offset: 1 }} md={1}>
                        {PromotionIcon}
                    </Col>
                    <Col xs={{ span: 17 }} md={{ span: 5, offset: 1 }}>
                        <h3 style={{ color: '#4c4c66', fontWeight: '700', marginTop: 6 }}>
                            Promotion Campaigns
                        </h3>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 6 }} lg={{ span: 2, offset: 7 }} align='middle' className="filter-selectbox">
                        <Select value={this.state.idoDateFilter} defaultValue="Today"
                            suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                            onChange={(e) => this.setState({ idoDateFilter: e })}>
                            <Option value="today">Today</Option>
                            <Option value="oneMount">1 Mount</Option>
                            <Option value="oneYear">1 Year</Option>
                        </Select>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                        <Select value={this.state.idoStatusFilter} defaultValue="Ongoing"
                            suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                            onChange={(e) => this.setState({ idoStatusFilter: e })}>
                            <Option value="ongoing">Ongoing</Option>
                            <Option value="dummy1">Dummy</Option>
                            <Option value="dummy2">Dummy</Option>
                        </Select>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 0 }} align='middle' className="filter-selectbox">
                        <Select value={this.state.idoTypeFilter} defaultValue="Ongoing"
                            suffixIcon={<AiOutlineDown style={{ color: '#4c4c66' }} />}
                            onChange={(e) => this.setState({ idoTypeFilter: e })}>
                            <Option value="all">All</Option>
                            <Option value="dummy1">Dummy</Option>
                            <Option value="dummy2">Dummy</Option>
                        </Select>
                    </Col>
                </Row>
                <CampaignCarousel cards={cards} />
                <br />
                <br />
            </div>
        </>);
    }
}

export default PromotionCampaign;

import React, {Component} from 'react';
import WalletContent from "./WalletContent";
import PromodexLayout from "../../components/layout/PromodexLayout";


class WalletLayout extends Component {
    render() {
        return (
            <PromodexLayout page='wallet' content={<WalletContent/>}/>
        );
    }
}

export default WalletLayout;
import React, {Component} from 'react';
import ActivityContent from "./ActivityContent";
import PromodexLayout from "../../components/layout/PromodexLayout";


class ActivityLayout extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <PromodexLayout page='activity' content={<ActivityContent />} />
        );
    }
}

export default ActivityLayout;
import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../redux/promodex/actions";
import { Button, Col, Form, Row, notification, Spin, Image } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { AiOutlineEye, AiOutlineEyeInvisible, } from "react-icons/ai";
import PromoInput from "../../components/input/PromoInput";

import bgImage from '../../assets/images/logo/Promodex-Logotype7-2048x1444.png'
import { createPassword } from './api';

// This regex used to make the password strong
const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%.,:;+-/\^&\*])(?=.{8,})");

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
    };
  }

  componentDidMount() {
    let uid = this.props.match.params.uid;
    let token = this.props.match.params.token;

    this.setState({
      uid: uid,
      token: token
    });
  }


  handleContinue = async (values) => {
    if (!values.password) return;
    const payload = {
      "new_password1": values.password,
      "new_password2": values.password,
      "uid": this.state.uid,
      "token": this.state.token
    }

    createPassword(payload, this.state.uid, this.state.token).then(response => {
      console.log('RESPONSE : ', response.data)
      notification.success({
        message: 'Password Changed!',
        description:
            'You have successfully change your password!\n We are redirecting you to the login page',
        duration: 3
    });
    setTimeout(() => {
      this.props.history.push('/login')
    },3000)
    return;

    }).catch(error => {
      console.log('error :' ,error)
      notification.error({
        message: 'Your Token Expired',
        description:
            'Your token is expired. Please try reset password process again.',
    });
    return;
  })


  };

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }


  render() {
    return (
      <div>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
          <Row className='margin-y-40'>
            <Col sm={{ span: 12, offset: 6 }} align='center'>
              <div
                className="box-shadow"
                style={{
                  // backgroundColor: "transparent",
                  margin: '0px 12px 10px 12px',
                  borderRadius: "20px 20px 20px 20px",
                }}
              >
                {/* <Image alt='' src={bgImage}/> */}
                <Form onFinish={this.handleContinue}
                  name="basic"
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 22,
                    offset: 1
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinishFailed={this.onFinishFailed}
                  autoComplete="off"
                  className="profile-promoInput-style">
                  <Form.Item
                    wrapperCol={{
                      offset: 6,
                      span: 12,
                    }}
                  >
                    <p className='register-title'>
                      Change Password
                    </p>
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && strongRegex.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Min 8 Char. Password must have at least one uppercase and lowercase (A-Z), one digit(0-9), one symbols(!,+,% etc.) !'));
                        },
                      }),
                    ]}
                  >
                    <PromoInput labelFontSize={14} labelColorClass="login-input-label"
                      isHorizontalLabel={false} type={this.state.showPassword ? "text" : "password"} onChange={(event) => this.setState({ password: event.target.value })}
                      labelText="Create a password" suffixIcon={this.state.showPassword ? <AiOutlineEye onClick={this.handleShowPassword} /> : <AiOutlineEyeInvisible onClick={this.handleShowPassword} />} />
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="passwordAgain"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  >
                    <PromoInput labelFontSize={14} labelColorClass="login-input-label"
                      isHorizontalLabel={false} type={this.state.showPassword ? "text" : "password"}
                      labelText="Confirm password" suffixIcon={this.state.showPassword ? <AiOutlineEye onClick={this.handleShowPassword} /> : <AiOutlineEyeInvisible onClick={this.handleShowPassword} />} />
                  </Form.Item>
                  <Form.Item>
                    <Button block htmlType="submit" onClick={this.handleContinue} className="margin-top-10 colorful-button nowrap register-button">
                      Change Password
                    </Button>
                    {/* a etiketi ile submit işlemi olmadığından dolayı görünmez bir butonu submit buton yapıp ref ile triggerlıyoruz. */}
                    {/* <Button ref={(ref) => this.submitButton = ref} htmlType="submit" onClick={this.handleContinue} style={{ display: 'none' }}></Button> */}
                  </Form.Item>

                  <Row className="margin-y-40">
                    <Col xs={24} align="center">
                      {/* <p onClick={() => {
                      this.props.setPromodexRedux({ forgotPassword: false })
                    }} style={{ paddingBottom: "20px", color: '#5340FF', cursor: 'pointer' }}>Go back to sign in</p> */}
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>

      </div>
    )
  }
}



const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ChangePassword));
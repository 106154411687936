import { publicApi, protectedApi } from '../../services/axios'


export const getIdos = () => {
  return publicApi.get(`/management/idos`)
    .then((response) => {
      return response.data.results;
    })
    .catch((error) => {
      return error;
    });
}
import React, {Component} from 'react';
import {Button, Col, Row, Switch} from "antd";
import key from "../../assets/images/icon/key.png"
import {AiOutlineBell, AiOutlineCreditCard, AiOutlineMail} from "react-icons/ai";
import {MdOutlinePhoneIphone, MdSecurity} from "react-icons/md";
import walletVerify from "../../assets/images/icon/wallet-verification.png"
import googleAuth from "../../assets/images/icon/google-auth.png"
import smsVerify from "../../assets/images/icon/sms-verify.png"
import password from "../../assets/images/icon/password.png"
import preferances from "../../assets/images/icon/preferances.png"
import Checkbox from "antd/es/checkbox/Checkbox";

class SettingsContent extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs={{span:22, offset:1}}>
                        <h3><img src={key} width={42} height={42} style={{transform: 'scaleX(-1)'}} /> Settings</h3>
                    </Col>
                </Row>
                <Row gutter={[24,0]}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div className="shadow-white-div">
                            <Row>
                                <Col xs={{span:22, offset:1}}>
                                    <h2> <MdSecurity size={36} className="vertical-align-text-bottom"/> Security </h2>
                                </Col>
                            </Row>
                            <Row className="margin-y-40">
                                <Col xs={{span:12, offset: 1}}>
                                    <h3><img src={googleAuth} width={24} height={24} /> Google Authanticator</h3>
                                </Col>
                                <Col xs={{span:10}}>
                                    <Button className="settings-promo-btn p-font-bold float-right">Activate</Button>
                                </Col>
                            </Row>
                            <Row className="margin-y-40">
                                <Col xs={{span:12, offset: 1}}>
                                    <h3><img src={smsVerify} width={24} height={24} /> SMS Verification</h3>
                                </Col>
                                <Col xs={{span:10}}>
                                    <Button className="settings-promo-btn p-font-bold float-right">Activate</Button>
                                </Col>
                            </Row>

                            <Row className="margin-y-40">
                                <Col xs={{span:12, offset: 1}}>
                                    <h3><AiOutlineMail size={24} className="vertical-align-text-bottom"/> E-mail Verification</h3>
                                </Col>
                                <Col xs={{span:10}}>
                                    <Button className="settings-promo-btn p-font-bold float-right">Activate</Button>
                                </Col>
                            </Row>
                            <Row className="margin-y-40">
                                <Col xs={{span:12, offset: 1}}>
                                    <h3><AiOutlineCreditCard size={24}/> Phishing Code</h3>
                                </Col>
                                <Col xs={{span:10}}>
                                    <Button className="settings-promo-btn p-font-bold float-right" style={{paddingLeft:'24px', paddingRight:'24px'}}>5861</Button>
                                </Col>
                            </Row>
                            <Row className="margin-y-40">
                                <Col xs={{span:12, offset: 1}}>
                                    <h3><img src={walletVerify} width={24} height={24} /> Wallet Verification</h3>
                                </Col>
                                <Col xs={{span:10}}>
                                    <Button className="settings-promo-btn p-font-bold float-right">Activate</Button>
                                </Col>
                            </Row>
                            <Row className="margin-y-40">
                                <Col xs={{span:12, offset: 1}}>
                                    <h3><img src={password} width={24} height={24} /> Password</h3>
                                </Col>
                                <Col xs={{span:10}}>
                                    <Button className="settings-promo-btn p-font-bold float-right" style={{paddingLeft:'16px', paddingRight:'16px'}}>Change</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div className="shadow-white-div">
                            <Row>
                                <Col xs={{span:22, offset:1}}>
                                    <h3><img src={preferances} width={24} height={24} className="vertical-align-text-bottom"/> Preferances</h3>
                                </Col>
                            </Row>
                            <Row style={{marginBottom:'30px'}}>
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3>Notifications <AiOutlineBell size={36} className="vertical-align-text-bottom"/></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-color">Online <Checkbox style={{marginLeft:'20px'}}/></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-bold">Transactions <Switch defaultChecked style={{marginLeft:'40px'}} className="vertical-align-text-bottom" /></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-bold">New Campaigns <Switch defaultChecked style={{marginLeft:'40px'}} className="vertical-align-text-bottom" /></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-bold">New IDOs <Switch defaultChecked style={{marginLeft:'40px'}} className="vertical-align-text-bottom" /></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-bold">News <Switch defaultChecked style={{marginLeft:'40px'}} className="vertical-align-text-bottom" /></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-color">E-mail <Checkbox style={{marginLeft:'20px'}}/></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-bold">Transactions <Switch defaultChecked style={{marginLeft:'40px'}} className="vertical-align-text-bottom" /></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-bold">New Campaigns <Switch defaultChecked style={{marginLeft:'40px'}} className="vertical-align-text-bottom" /></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-bold">New IDOs <Switch defaultChecked style={{marginLeft:'40px'}} className="vertical-align-text-bottom" /></h3>
                                </Col>
                            </Row>
                            <Row className="margin-y-10">
                                <Col xs={{span:12, offset: 12}} sm={{span:12, offset: 11}} md={{span:12, offset:11}}  lg={{span:12, offset:11}} style={{textAlign:'right'}}>
                                    <h3 className="p-font-bold">News <Switch defaultChecked style={{marginLeft:'40px'}} className="vertical-align-text-bottom" /></h3>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default SettingsContent;
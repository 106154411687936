import React, { Component } from 'react';
import { Row, Col, Carousel, Modal, Image, Button } from 'antd';
import { GoPrimitiveDot } from 'react-icons/go';
import IdoTokenSaleCampaign from './IdoTokenSaleCampaign';
import PromotionCampaign from './PromotionCampaign';
import MyCampaign from './MyCampaign';

import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"



import { LandingPageCarouselInvestor, LandingPageCarouselPromoter, LandingPageCarouselCampaigner } from '../../components/layout/Icons';

const LandingPageCarouselInvestorImage = LandingPageCarouselInvestor()
const LandingPageCarouselPromoterImage = LandingPageCarouselPromoter()
const LandingPageCarouselCampaignerImage = LandingPageCarouselCampaigner()
export default class LandingPageContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isComingSoonModalVisible: false,
      isInvestorVideoModalVisible: false,
      isPromoterModalVisible: false,
      isCampaignerModalVisible: false,
    }
  }


  comingSoonModal = () => {
    this.setState({ isComingSoonModalVisible: !this.state.isComingSoonModalVisible })
  }

  investorVideoModal = () => {
    this.setState({ isInvestorVideoModalVisible: !this.state.isInvestorVideoModalVisible })
  }
  comingSoonModalPromoter = () => {
    this.setState({ isPromoterModalVisible: !this.state.isPromoterModalVisible })
  }
  comingSoonModalCampaigner = () => {
    this.setState({ isCampaignerModalVisible: !this.state.isCampaignerModalVisible })
  }

  render() {


    return (<div>
      <Row style={{ backgroundColor: '#ffffff'}}>
        <Col sm={24}>
          <Carousel autoplaySpeed={10000} dotPosition={'bottom'} dots={{ className: 'landing-page-carousel' }} draggable>
            <div>
              <Row type="flex" style={{ alignItems: "center" }} justify="center">
                <Col xs={24} md={8}>
                  <Row>
                    <Col xs={{ span: 23, offset: 1 }} md={{ span: 18, offset: 6 }}>
                      <h1 className='landing-page-title'>Investors</h1>
                      <h1 className='landing-page-text'>Join IDO token sales, build your future</h1>
                      <Row>
                        <Col xs={12}>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Expert assessed</h2>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Profile specified</h2>
                        </Col>
                        <Col xs={12}>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Pre-audited</h2>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Socially promoted</h2>
                        </Col>
                      </Row>
                      <Row className='margin-top-20'>
                        <Col xs={16} lg={16}>
                          <a href='/investor' className="colorful-button nowrap" style={{ fontSize: 18 }}>
                            See token sales
                          </a>
                        </Col>
                        <Col xs={1} lg={8}>
                          <a onClick={this.investorVideoModal} className="glightbox_video" style={{ zIndex: 10 }}>
                            <svg width="60" height="60" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path className="inner-circle" d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z" fill="#BF2428"></path>
                              <circle className="outer_circle" cx="65.5" cy="65.5" r="64" stroke="#5340FF"></circle>
                              <path className="play" fillRule="evenodd" clipRule="evenodd" d="M60 76V57L77 66.7774L60 76Z" fill="#FFFFFF"></path>
                            </svg>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={16} style={{ padding: '8px 0px 48px 4px', overflow: 'hidden' }} align='right'>
                  {LandingPageCarouselInvestorImage}
                </Col>
              </Row>
            </div>
            <div>
              <Row type="flex" style={{ alignItems: "center" }} justify="center">
                <Col xs={24} md={8}>
                  <Row>
                    <Col xs={{ span: 23, offset: 1 }} md={{ span: 18, offset: 6 }}>
                      <h1 className='landing-page-title'>Promoter</h1>
                      <h1 className='landing-page-text'>Participate in promotions earn from your contributions</h1>
                      <Row>
                        <Col xs={11}>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Influencers</h2>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Publishers</h2>
                        </Col>
                        <Col xs={13}>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Channel owners</h2>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Airdrop & Bounty hunters</h2>
                        </Col>
                      </Row>
                      <Row className='margin-top-20' gutter={[48, 48]}>
                        <Col xs={16} lg={16}>
                          <a onClick={this.comingSoonModalPromoter} className="colorful-button nowrap" style={{ fontSize: 18 }}>
                            See Promotions
                          </a>
                        </Col>
                        <Col xs={1} lg={8}>
                          <a onClick={this.comingSoonModalPromoter} style={{ zIndex: 10 }} className="glightbox_video">
                            <svg width="60" height="60" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path className="inner-circle" d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z" fill="#BF2428"></path>
                              <circle className="outer_circle" cx="65.5" cy="65.5" r="64" stroke="#5340FF"></circle>
                              <path className="play" fillRule="evenodd" clipRule="evenodd" d="M60 76V57L77 66.7774L60 76Z" fill="#FFFFFF"></path>
                            </svg>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                </Col>
                <Col xs={24} md={16} pull={2} style={{ padding: '8px 4px 48px 4px', overflow: 'hidden' }} align='right'>
                  {LandingPageCarouselPromoterImage}
                </Col>
              </Row>
            </div>
            <div>
              <Row type="flex" style={{ alignItems: "center" }} justify="center">
                <Col xs={24} md={8}>
                  <Row>
                    <Col xs={{ span: 23, offset: 1 }} md={{ span: 18, offset: 6 }}>

                      <h1 className='landing-page-title'>Campaigners</h1>
                      <h1 className='landing-page-text'>Launch your own IDO, Promotion Airdrop & Bounty Campaigners</h1>
                      <Row>
                        <Col xs={12}>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Brands</h2>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Projects</h2>
                        </Col>
                        <Col xs={12}>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Companies</h2>
                          <h2 className='landing-page-list-text'> <GoPrimitiveDot size={24} className='icon-inline' /> Start-ups</h2>
                        </Col>
                      </Row>
                      <Row className='margin-top-20' gutter={[48, 48]}>
                        <Col xs={16} lg={16}>
                          <a onClick={this.comingSoonModalCampaigner} className="colorful-button nowrap" style={{ fontSize: 18 }}>
                            Launch Campaign
                          </a>
                        </Col>
                        <Col xs={2} lg={8}>
                          <a onClick={this.comingSoonModalCampaigner} style={{ zIndex: 10 }} className="glightbox_video">
                            <svg width="60" height="60" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path className="inner-circle" d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z" fill="#BF2428"></path>
                              <circle className="outer_circle" cx="65.5" cy="65.5" r="64" stroke="#5340FF"></circle>
                              <path className="play" fillRule="evenodd" clipRule="evenodd" d="M60 76V57L77 66.7774L60 76Z" fill="#FFFFFF"></path>
                            </svg>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={16} pull={2} style={{ padding: '8px 4px 48px 4px', overflow: 'hidden' }} align='right'>
                  {LandingPageCarouselCampaignerImage}
                </Col>
              </Row>
            </div>
          </Carousel>
        </Col>
      </Row>
      <Row className='margin-top-20'>
        <Col xs={24} lg={{ span: 22, offset: 1 }} style={{ backgroundColor: '#ffffff' }}>
          <IdoTokenSaleCampaign />
        </Col>
      </Row>
      <Row className='margin-top-20'>
        <Col xs={24} lg={{ span: 22, offset: 1 }} style={{ backgroundColor: '#ffffff' }}>
          <PromotionCampaign />
        </Col>
      </Row>
      <Row className='margin-top-20'>
        <Col xs={24} lg={{ span: 22, offset: 1 }} style={{ backgroundColor: '#ffffff' }}>
          <MyCampaign />
        </Col>
      </Row>

      <Modal className='' title="" visible={this.state.isComingSoonModalVisible} maskClosable={true}
        onCancel={this.comingSoonModal}
        maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
        <Row>
          <Col sm={24} align='center' className='margin-y-40'>
            <Image
              style={{}}
              width={100}
              preview={false}
              src={blueP}
            /> <br />
            <p style={{
              textAlign: 'center',
              fontSize: 24,
              color: '#5340ff'
            }}>
              COMING SOON...
            </p>
          </Col>
        </Row>
      </Modal>




      {/* INVESTOR VIDEOLU MODAL */}
      <Modal className='' title="" visible={this.state.isInvestorVideoModalVisible} maskClosable={true}
        onCancel={this.investorVideoModal}
        maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>

        <Row style={{ marginTop: '20px' }}>
          <Col sm={24} align='center'>
            <iframe width="100%" height="210" src="https://www.youtube.com/embed/tQAS97LHAw0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Col>
        </Row>

      </Modal>

      {/* PROMOTER VIDEOLU MODAL */}
      <Modal className='' title="" visible={this.state.isPromoterModalVisible} maskClosable={true}
        onCancel={this.comingSoonModalPromoter}
        maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
        <Row align='middle' style={{ marginBottom: 40, marginTop: 10 }}>
          <Col xs={8} lg={4} align='left'>
            <Image
              style={{}}
              width={80}
              preview={false}
              src={blueP}
            />
          </Col>
          <Col xs={12} lg={16} align='left'>
            <p style={{
              textAlign: 'left',
              fontSize: 24,
              color: '#5340ff'
            }}>
              This feature is coming soon
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: '-50px' }}>
          <Col xs={24} align='center'>
            <iframe width="100%" height="210" src="https://www.youtube.com/embed/4Ml73PfMBmk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Col>
        </Row>

      </Modal>

      {/* CAMPAIGNER VIDEOLU MODAL */}
      <Modal className='' title="" visible={this.state.isCampaignerModalVisible} maskClosable={true}
        onCancel={this.comingSoonModalCampaigner}
        maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
        <Row align='middle' style={{ marginBottom: 40, marginTop: 10 }}>
          <Col xs={8} lg={4} align='left'>
            <Image
              style={{}}
              width={80}
              preview={false}
              src={blueP}
            />
          </Col>
          <Col xs={12} lg={16} align='left'>
            <p style={{
              textAlign: 'left',
              fontSize: 24,
              color: '#5340ff'
            }}>
              This feature is coming soon
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: '-50px' }}>
          <Col sm={24} align='center'>
            <iframe width="100%" height="210" src="https://www.youtube.com/embed/8jmmOy_QE_A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                        </Col>
        </Row>

      </Modal>


    </div>);
  }
}

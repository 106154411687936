import React, {Component} from 'react';
import {Alert, Col, Row, Table} from "antd";
import {IoWalletOutline} from "react-icons/io5"
import {FiCopy} from "react-icons/fi";
import {ImQrcode} from "react-icons/im";
import {AiOutlineSearch} from "react-icons/ai";
import Modal from "antd/es/modal/Modal";
import promodexQr from "../../assets/images/promodexqr.png"
import promodexP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"
import {GiTakeMyMoney, GiWatch} from "react-icons/gi";

const columns = [
    {
        key: 'date',
        title: 'Date(UTC)',
        dataIndex: 'date',
        filters: [
            {text:'Date', value:'Today'},
            {text:'Date2', value:'2021-Oct-24 19:40'},
        ]
    },
    {
        key: 'type',
        title: 'Type',
        dataIndex: 'type',
        filters: [
            {text:'IDO', value:'IDO'},
            {text:'Airdrop', value:'Airdrop'},
            {text:'Exchange', value:'Exchange'},
            {text:'Campaign', value:'Campaign'},
            {text:'Bounty', value:'Bounty'},
        ],
        filterIcon: <AiOutlineSearch style={{color:'black'}} />,
        onFilter: (value,record) => {
            console.log('VALUE : ' , value)
            console.log('record : ' , record)
            return record.type === value
        }
    },
    {
        key: 'transfer',
        title: 'Transfer',
        dataIndex: 'transfer',
    },
    {
        key: 'asset',
        title: 'Asset',
        dataIndex: 'asset',
    },
    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
    },
    {
        key: 'action',
        title: '',
        dataIndex: 'action',
        render: () => <h5 className='p-font-color'>details</h5>
    },
];

const data = [
    {
        date: '2021-Oct-24 19:40',
        type: 'IDO',
        transfer: 'Withdraw',
        asset: 'BUSD',
        amount: '76.00000000',
        status: 'Success',
    },
    {
        date: '2021-Oct-24 19:40',
        type: 'IDO',
        transfer: 'Deposit',
        asset: 'XYM',
        amount: '76.00000000',
        status: 'Success',
    },
    {
        date: '2021-Oct-24 19:40',
        type: 'Airdrop',
        transfer: 'Deposit',
        asset: 'FANFAN',
        amount: '76.00000000',
        status: 'Success',
    },
    {
        date: '2021-Oct-24 19:40',
        type: 'Exchange',
        transfer: 'Swap',
        asset: 'BUSD-PROMO',
        amount: '76.00000000',
        status: 'Success',
    },
    {
        date: '2021-Oct-24 19:40',
        type: 'Champaign',
        transfer: 'Deposit',
        asset: 'PROMO',
        amount: '76.00000000',
        status: 'Success',
    },
    {
        date: '2021-Oct-24 19:40',
        type: 'Champaign',
        transfer: 'Withdraw',
        asset: 'CHILIZ',
        amount: '9.732.00000000',
        status: 'Success',
    },
    {
        date: '2021-Oct-24 19:40',
        type: 'Bounty',
        transfer: 'Deposit',
        asset: 'ZOOM',
        amount: '76.00000000',
        status: 'Success',
    },
];


class WalletContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCopied: false,
            qrCodeModal: false,
            fadingOut: false
        }

    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({fadingOut: true})
        },1)
    }

    handleCopy = () => {
        this.setState({isCopied: true}, () => {
            setTimeout(() => {
                this.setState({isCopied: false})
            },3000)
        })
    }

    handleTableChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    showQrCodeModal = (status) => {
        this.setState({qrCodeModal: status})
    }

    render() {
        return (
            <>
            {this.state.fadingOut &&
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        textAlign: 'center',
                        fontSize:24,
                        zIndex:20,
                        color:'#5340ff'
                    }}>
                        COMING SOON...
                    </div>
                }
            
            <div style={this.state.fadingOut ? {filter: 'blur(4px)', transition: 'filter 4s', pointerEvents: 'none', userSelect: 'none'} : undefined }>
                <Row>
                    <Col xs={24}>
                        <div className="shadow-white-div" style={{marginTop:'0px', marginBottom:'0px'}}>
                            <Row gutter={{ xs:32, sm:32, md: 32, xl: 0 }} className="h3-padding-0">
                                <Col md={24} xl={1}>
                                    <IoWalletOutline className='vertical-align-text-bottom' size={24}/>
                                </Col>
                                <Col md={24} xl={17}>
                                    <h4>9294054DASGFSASFD56SF32DSF23SDFS34DG99GOPKSDG5O5K</h4>
                                </Col>
                                <Col md={8} xl={2}>
                                    <h3>BSC</h3>
                                </Col>
                                <Col md={8} xl={2}>
                                    <ImQrcode size={24} onClick={() => this.showQrCodeModal(true)} style={{cursor:'pointer'}}/>
                                </Col>
                                <Col md={8} xl={2}>
                                    <FiCopy size={24} onClick={this.handleCopy} style={{cursor:'pointer', pointerEvents: this.state.isCopied ? 'none' : 'auto'}}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                {/** QR CODE MODAL START*/}
                <Modal title="Qr Code" visible={this.state.qrCodeModal} footer={null} onOk={() => this.showQrCodeModal(false)} onCancel={() => this.showQrCodeModal(false)}>
                    <div className="justify-content-center">
                        <img src={promodexQr} alt="promodex.io" />
                    </div>
                </Modal>
                {/** QR CODE MODAL END*/}
                {this.state.isCopied &&
                    <Alert message="Copied" type="success" style={{float:"right", width:'6%', borderRadius:'10px'}}/>
                }

                <Row>
                    <Col xs={24} md={{span:11, offset: 0}} lg={{span:4,offset:0}} className="shadow-white-div">
                        <Row>
                            <Col xs={24}>
                                <h5 className="p-font-bold">PROMO TOKEN IN WALLET</h5>
                            </Col>
                        </Row>
                        <Row className="margin-y-10">
                            <Col xs={24}>
                                <Row>
                                    <Col xs={8}>
                                        <img alt='Promo' src={promodexP} width={32} height={32} className="float-left vertical-align-text-bottom" />
                                    </Col>
                                    <Col xs={14}>
                                        <h4 className="p-font-color"> <strong>10.000</strong> PROMO</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="margin-top-20">
                            <Col xs={24}>
                                <h5 className="p-font-bold">VALUE IN USDT</h5>
                            </Col>
                        </Row>
                        <Row className="margin-y-10">
                            <Col xs={24} align='center'>
                                <h4 className="p-font-color"> <strong>10.000</strong> USDT</h4>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={{span:11, offset: 1}} lg={{span:4,offset:1}} className="shadow-white-div">
                        <Row>
                            <Col xs={24}>
                                <h5 className="p-font-bold">PROMO TOKEN STAKED</h5>
                            </Col>
                        </Row>
                        <Row className="margin-y-10">
                            <Col xs={24}>
                                <Row>
                                    <Col xs={8}>
                                        <img alt='Promo' src={promodexP} width={32} height={32} className="float-left vertical-align-text-bottom" />
                                    </Col>
                                    <Col xs={14}>
                                        <h4 className="p-font-color"> <strong>10.000</strong> PROMO</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row className="margin-y-10">
                            <Col xs={24} align='center'>
                                <h4 className="p-font-color p-font-bold text-underline clickable-text"> See Staking Details</h4>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={{span:11, offset: 0}} lg={{span:4,offset:1}} className="shadow-white-div">
                        <Row>
                            <Col xs={24}>
                                <h5 className="p-font-bold">DURATION STAKED</h5>
                            </Col>
                        </Row>
                        <Row className="margin-top-20">
                            <Col xs={24}>
                                <Row>
                                    <Col xs={8}>
                                        <GiWatch size={36} />
                                    </Col>
                                    <Col xs={14}>
                                        <h4 className="p-font-color">
                                            <strong>180 Days</strong>
                                            <br/>
                                            <small style={{color:'#dfcafc'}}>34 Days left</small>
                                        </h4>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={{span:11, offset: 0}} lg={{span:4,offset:1}} className="shadow-white-div">
                        <Row>
                            <Col xs={24}>
                                <h5 className="p-font-bold">EARNED</h5>
                            </Col>
                        </Row>
                        <Row className="margin-y-10">
                            <Col xs={24}>
                                <Row>
                                    <Col xs={8}>
                                        <GiTakeMyMoney size={32} className="float-left vertical-align-text-bottom" />
                                    </Col>
                                    <Col xs={14}>
                                        <h4 className="p-font-color"> <strong>78</strong> PROMO</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="margin-top-20">
                            <Col xs={24}>
                                <h5 className="p-font-bold">VALUE IN USDT</h5>
                            </Col>
                        </Row>
                        <Row className="margin-y-10">
                            <Col xs={24} align='center'>
                                <h4 className="p-font-color"> <strong>13.000</strong> USDT</h4>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} md={{span:11, offset: 0}} lg={{span:4,offset:1}}
                         className="shadow-white-div buy-promo-token-div" style={{backgroundColor:'#6b03fe'}} align='middle'>
                        <br/><br/>
                        <h1 style={{color:'#ffffff',fontSize:'24px'}}>BUY PROMO TOKEN</h1>
                    </Col>
                </Row>

                {/*<div className="shadow-white-div">*/}
                {/*    <Row>*/}
                {/*        <Col xl={24}>*/}
                {/*            <h3 className='font-size-20'><IoWalletOutline className='vertical-align-text-bottom'*/}
                {/*                                                          size={26}/> Balance</h3>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row>*/}
                {/*        <Col md={24} lg={12}>*/}
                {/*            <Row>*/}
                {/*                <Col xl={6}>*/}
                {/*                    <h5>Promo token in wallet:</h5>*/}
                {/*                    <h5>Value in USDT:</h5>*/}
                {/*                </Col>*/}
                {/*                <Col xl={8}>*/}
                {/*                    <h5><strong>10.000 PROMO</strong></h5>*/}
                {/*                    <h5><strong>30.000 USDT</strong></h5>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Col>*/}
                {/*        <Col md={24} lg={12}>*/}
                {/*            <Row>*/}
                {/*                <Col xl={6}>*/}
                {/*                    <h5>Promo token staked:</h5>*/}
                {/*                    <h5>Duration staked:</h5>*/}
                {/*                    <h5>Earned:</h5>*/}
                {/*                    <h5>Value in USDT:</h5>*/}
                {/*                </Col>*/}
                {/*                <Col xl={8}>*/}
                {/*                    <h5><strong>5.000 PROMO</strong></h5>*/}
                {/*                    <h5><strong>180 days (34 days left)</strong></h5>*/}
                {/*                    <h5><strong>78 PROMO</strong></h5>*/}
                {/*                    <h5><strong>30.000 USDT</strong></h5>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row style={{marginTop: '20px'}}>*/}
                {/*        <Col md={4} xl={8} className="justify-content-center">*/}
                {/*            <Button className="promo-btn">Buy PROMO token</Button>*/}
                {/*        </Col>*/}
                {/*        <Col md={4} xl={8} className="justify-content-center">*/}
                {/*            <img className="vertical-align-text-bottom" src={metamask} width={24} height={24}/>*/}
                {/*            <h4 style={{marginLeft: '10px'}}> Add to Metamask</h4>*/}
                {/*        </Col>*/}
                {/*        <Col md={4} xl={8} className="justify-content-center">*/}
                {/*            <Button className="promo-btn">See Staking Details</Button>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</div>*/}
                <Row>
                    <Col xl={24} className="shadow-white-div">
                        <h3 className='p-font-bold'>
                            <IoWalletOutline size={22} className="vertical-align-text-bottom"/> WALLET ACTIVITIES &nbsp;&nbsp; &nbsp;&nbsp;
                            <span style={{color: '#6b03fe'}}>{data.length}</span> </h3>
                        <Table size='small' scroll={{x: 800}} loading={false} bordered={false} showHeader={true}
                               pagination={{
                                   defaultPageSize: 10,
                                   showSizeChanger: false,
                                   pageSizeOptions: ['10', '20', '30']
                               }}
                               columns={columns} dataSource={data}
                               onChange={this.handleTableChange}/>
                    </Col>
                </Row>
            </div>
            </>
            
        );
    }
}

export default WalletContent;
import React, {Component} from 'react';
import {Button, Col, Divider, Row, Select, Table} from "antd";
import {AiOutlineUser, AiOutlineVerified} from "react-icons/ai";
import {RiEditBoxLine, RiMedal2Line, RiSearchLine} from "react-icons/ri";
import PromoInput from "../../components/input/PromoInput";
import {ImAttachment} from "react-icons/im";
import {BsThreeDotsVertical} from "react-icons/bs";
import {FcHighPriority, FcOk} from "react-icons/fc";
import checkmark from "../../assets/images/icon/checkmark.png";
import hakanUnal from "../../assets/images/Hakan_Unal_greybg512px-115x115.png"
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {VscVerified} from "react-icons/vsc";
import {IoDiamondOutline} from "react-icons/io5";


const columns = [
    {
        key: 'channel',
        title: 'Channel',
        dataIndex: 'Channel',
        render: tag => {
            return(
                <Row>
                    <Col xs={4}>
                        <AiOutlineUser size={30}/>
                    </Col>
                    <Col xs={16}>
                        <h4>koin_media <br/><small>Instagram Business</small></h4>
                    </Col>
                </Row>
            )
        }
    },
    {
        key: 'connection',
        title: 'Connection',
        dataIndex: 'Connection',
        render: tag => {
            if(tag === 'Connected'){
                return <h4><img src={checkmark} width={24} height={24}/> {tag}</h4>
            }else {
                return <h4> <FcHighPriority/> {tag}</h4>
            }
        }
    },
    {
        key: 'influencer',
        title: 'Influencer',
        dataIndex: 'Influencer',
    },
    {
        key: 'ipg',
        title: 'IPG',
        dataIndex: 'IPG',
    },
    {
        key: 'manage',
        title: 'Manage',
        dataIndex: 'Manage',
        render: () => <BsThreeDotsVertical style={{cursor:'pointer'}}/>
    },
];

const data = [
    {
        Channel: 'Dummy Data',
        Connection: 'Connected',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Broken',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Connected',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Broken',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Connected',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Broken',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Connected',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Broken',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Connected',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Broken',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Connected',
        Influencer: 'Qualified',
        IPG: '56',
    },
    {
        Channel: 'Dummy Data',
        Connection: 'Broken',
        Influencer: 'Qualified',
        IPG: '56',
    },
];


class ProfileContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "Hacı",
            middleName: "Ahmet",
            lastName: "Gökmen",
            gender: "male",
            phone: "5333454556",
            email: "info@promodex.io",
            language: "Turkish",
            birthDate: "20.01.1980",
            address: "Mustafa Kemal cd. Atatürk sk. Cumhuriyet apt. no:35/11 Maltepe",
            postalCode: "34875",
            city: "İstanbul",
            state: "Marmara",
            country: "Turkey",
            isEditable: false
        }
    }

    handleInput = (event, state) => {
        this.setState({[state]: event.target.value})
    }

    handleEditProfile = () => {
        this.setState({
            isEditable: !this.state.isEditable
        })
    }

    render() {
        return (
            <div>
                <Row gutter={[32, 8]}>
                    <Col md={24} lg={12} lg={12}>
                        <Row className="shadow-white-div">
                            <Col xs={24} md={16} lg={18}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={12} lg={8}>
                                        <img width={96}
                                             height={96}
                                             style={{borderRadius:'50%'}}
                                             src={hakanUnal}/>
                                    </Col>
                                    <Col xs={12} lg={16}>

                                        <h3 style={{color: '#4c4c66'}}>Obasey Chidy</h3>
                                        <h4 style={{color: '#828294'}}>obasey@gmail.com</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} md={8} lg={6}>
                                <Row>
                                    <Col lg={24} align='center'>
                                        <h4 className='p-font-bold'>My KYC Status</h4>
                                        <h5 className='p-font-bold' style={{
                                            color: '#ffffff',
                                            backgroundColor:'#59cbff',
                                            borderRadius:'6px',
                                            textAlign:'center',
                                        }}>VERIFIED <VscVerified size={20} className="vertical-align-text-bottom"/></h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={24} align='center'>
                                        <h4 className='p-font-bold'>My TIER LEVEL</h4>
                                        <h5 className='p-font-bold' style={{
                                            color: '#ffffff',
                                            backgroundColor:'#ff6565',
                                            borderRadius:'6px',
                                            textAlign:'center',
                                        }}>PREMIUM <IoDiamondOutline size={20} className="vertical-align-text-bottom"/></h5>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24} className="shadow-white-div">
                                <Row>
                                    <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                        <h3 style={{color:'#4c4c66', fontWeight:'700'}}>Personal Information</h3>
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <h4 className="profile-promo-btn" onClick={this.handleEditProfile}>{this.state.isEditable ? "Update profile" : "Edit Profile"}</h4>
                                        {/*<Button size="small"*/}
                                        {/*        icon={this.state.isEditable ? null :<RiEditBoxLine className='vertical-align-text-bottom' size={18}/>}*/}
                                        {/*        onClick={this.handleEditProfile}*/}
                                        {/*        className="profile-promo-btn">{this.state.isEditable ? "Update profile" : "Edit Profile"}</Button>*/}
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <h4 className="profile-promo-btn">Complete KYC</h4>
                                        {/*<Button size="small"*/}
                                        {/*        icon={<RiSearchLine className='vertical-align-text-bottom' size={18}/>}*/}
                                        {/*        className="profile-promo-btn">Complete KYC</Button>*/}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={24}>
                                        <Row style={{marginBottom: '10px'}} className="profile-PromoInput-style">
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false}
                                                           labelText="Name"
                                                           value={this.state.name}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "name")}/>
                                            </Col>
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false}
                                                           labelText="Middle Name"
                                                           value={this.state.middleName}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "middleName")}/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '10px'}} className="profile-PromoInput-style">
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false}
                                                           labelText="Last Name"
                                                           value={this.state.lastName}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "lastName")}/>
                                            </Col>
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false} labelText="Gender"
                                                           value={this.state.gender}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "gender")}/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '10px'}} className="profile-PromoInput-style">
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <Row>
                                                    <Col span={20}>
                                                        <small
                                                            style={{fontSize: 12}}
                                                            className="profile-input-label"
                                                        >
                                                            Phone number
                                                        </small>
                                                    </Col>
                                                    <Col span={20}>
                                                        <PhoneInput
                                                            country={"tr"}
                                                            regions={['europe']}
                                                            masks={{tr: '(...) ... .. ..'}}
                                                            inputClass="phone-input"
                                                            buttonClass="phone-input-flag"
                                                            value={this.state.phone}
                                                            disabled={!this.state.isEditable}
                                                            onChange={e => this.setState({ phone: e})}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false} labelText="E-mail"
                                                           value={this.state.email}
                                                           type="email"
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "email")}/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '10px'}} className="profile-PromoInput-style">
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false}
                                                           labelText="Language"
                                                           value={this.state.language}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "language")}/>
                                            </Col>
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false} labelText="Date of Birth"
                                                           value={this.state.birthDate}
                                                           type="date"
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "birthDate")}/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '10px'}} className="profile-PromoInput-style">
                                            <Col xs={24} md={24} lg={{span: 22, offset: 1}}>
                                                <PromoInput isHorizontalInput={false}
                                                           labelText="Address"
                                                           value={this.state.address}
                                                           maxLength={150}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "address")}/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '10px'}} className="profile-PromoInput-style">
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false}
                                                           labelText="Zip/Postal Code"
                                                           value={this.state.postalCode}
                                                           type="number"
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "postalCode")}/>
                                            </Col>
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false} labelText="City"
                                                           value={this.state.city}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "city")}/>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '10px'}} className="profile-PromoInput-style">
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false}
                                                           labelText="State"
                                                           value={this.state.state}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "state")}/>
                                            </Col>
                                            <Col xs={24} md={24} lg={{span: 10, offset: 1}}>
                                                <PromoInput isHorizontalInput={false} labelText="Country"
                                                           value={this.state.country}
                                                           disabled={!this.state.isEditable}
                                                           onChange={(event) => this.handleInput(event, "country")}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={24} lg={12}>
                        <div className="shadow-white-div">
                            <Row>
                                <Col lg={24}>
                                    <Row>
                                        <Col xs={12}>
                                            <h3 className="p-font-bold">My Connections</h3>
                                        </Col>
                                        <Col xs={12} align="right">
                                            <Button size="medium" className="connect-wallet-btn">
                                                <ImAttachment className="vertical-align-text-bottom" size={18} />
                                                Connect new channel
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="margin-top-10">
                                <Col lg={24}>
                                    <Table size='small' scroll={{x: 500}} loading={false} bordered={false} showHeader={true}
                                           pagination={{
                                               defaultPageSize: 8,
                                               showSizeChanger: false,
                                               pageSizeOptions: ['10', '20', '30']
                                           }}
                                           columns={columns} dataSource={data}
                                           onChange={this.handleTableChange}/>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProfileContent;
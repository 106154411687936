import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../../../redux/promodex/actions";
import { Row, Col, Tabs, Select, notification } from "antd";


import Staking from './tabs/Staking';
import StakePreparing from './tabs/StakePreparing';

const { TabPane } = Tabs;


const { Option } = Select;

class Stake extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Stake tabıyla ilgili tanımlamalar
      stakeCondition: false,
      activeTab: '1',
    }
  }




  openNotification = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    })
  }


  changeTab = (key) => {
    if (key === '2' && !this.state.stakeCondition) {
      this.openNotification('warning', "Warning", "Please read and accept the terms")
    } else {
      this.setState({ activeTab: key });
    }

    // if (key === "2" && this.state.stakeCondition) {
    // } else if (key === "1") {
    //   this.setState({ activeTab: key });
    // } else if (key === "2" && !this.state.stakeCondition) {
    //   this.openNotification('warning', "Warning", "Please read and accept the terms")
    // }
  }

  //Stake ile ilgili fonksiyonlar burada yazılmalı
  handleStakeCondition = (event) => {
    this.setState({ stakeCondition: event.target.checked })
  }



  render() {

    return (
      <Row className='margin-y-10'>
        <Col sm={24} md={{ span: 20, offset: 2 }} className='shadow-white-div project-page-tabs'>
          <Tabs animated={false} activeKey={this.state.activeTab} onChange={this.changeTab}>
            <TabPane tab="Preparing" key="1" className='padding-16'>
              <StakePreparing
                handleStakeCondition={this.handleStakeCondition}
                changeTab={this.changeTab}
                BottomButtons={this.props.BottomButtons}
                GreenCircleCheckmarkIcon={this.props.GreenCircleCheckmarkIcon}
                RedCircleCrossmarkIcon={this.props.RedCircleCrossmarkIcon}
                GreenCircleCheckmarkIconSmall={this.props.GreenCircleCheckmarkIconSmall} />
            </TabPane>
            <TabPane tab="Staking" key="2" className='padding-16'>
              <Staking
                changeTab={this.changeTab}
                BottomButtons={this.props.BottomButtons}
                GreenCircleCheckmarkIcon={this.props.GreenCircleCheckmarkIcon}
                GreenCircleCheckmarkIconSmall={this.props.GreenCircleCheckmarkIconSmall} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(Stake));

import React, {Component} from 'react';
import {Alert, Button, Checkbox, Col, Divider, Row, Select, Upload} from "antd";
import PromoInput from "../../components/input/PromoInput";
import {AiOutlineDoubleRight, AiOutlineDown, AiOutlineUpload} from "react-icons/ai";
import Modal from "antd/es/modal/Modal";

const CheckboxGroup = Checkbox.Group;

const {Option} = Select;

const termsAndConditions = [
    'Please read the Terms and Conditions then agree to all the following to proceed!',
    'I have tested my token with the PROMODEX app on a test network (Ex. Ropsten)',
    'My token has a function to disable special transfers or has no special transfers',
    'My token is not already listed on PancakeSwap and i have not given out any tokens to users',
    'I understand fees paid to launch a SALE are non-recoverable',
    'I understand that i have to finalize my sale within 48 hours of hitting the hardcap!',
    'I am using PROMODEX as a software tool only and am alone responsible for anything i create on it',
    'I understand that i am responsible for following my local laws and regulations including KYC and AML practices',
    'I have read and agree to the terms and conditions',
];

class CreateIdo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmTermsModal: false,
            isConfirmTerms: false,

            checkedList: [],
            indeterminate: false,
            checkAll: false,
            showAlert: false,

            experts: [{
                expertName: "",
                costOfReview: ""
            }],
            participants: [{
                participant: "",
                campaignId: ""
            }],

            confirmCostOfService: false,
            confirmTerms: false,
            checkModal: false,
            showNotConfirmedAlert: false,


            isChecked: false
        }
    }


    showConfirmTermsModal = (status) => {
        this.setState({confirmTermsModal: status, isConfirmTerms: false})
    }

    handleConfirmCheckbox = () => {
        if (this.state.isConfirmTerms) {
            this.setState({isConfirmTerms: false})
        } else {
            this.showConfirmTermsModal(true)
        }
    }

    handleCheckboxGroupOnChange = list => {
        this.setState({
            checkedList: list,
            indeterminate: !!list.length && list.length < termsAndConditions.length,
            checkedAll: list.length === termsAndConditions.length
        })
    };

    onCheckAllChange = (e) => {
        this.setState({
            checkedList: e.target.checked ? termsAndConditions : [],
            indeterminate: false,
            checkAll: e.target.checked
        })
    };


    handleConfirmButton = () => {
        if (this.state.checkedList.length === termsAndConditions.length) {
            this.setState({isConfirmTerms: true, confirmTermsModal: false})
        } else {
            this.setState({showAlert: true})
        }
    }

    handleAddExperts = () => {
        const experts = this.state.experts
        experts.push({
            expertName: "",
            costOfReview: ""

        })
        this.setState({experts: experts})
    }
    handleExpertsInput = (event, name, index) => {
        const experts = this.state.experts
        if (name === 'expertName') {
            experts[index][name] = event
        } else {
            experts[index][name] = event.target.value
        }
        this.setState({experts: experts}, () => console.log('this experts : ', this.state.experts))
    }
    handleAddParticipants = () => {
        const participants = this.state.participants
        participants.push({
            participant: "",
            campaignId: ""
        })
        this.setState({participants: participants})
    }
    handleParticipantsInput = (event, name, index) => {
        const participants = this.state.participants
        participants[index][name] = event
        this.setState({participants: participants}, () => console.log('this participants : ', this.state.participants))
    }

    showCheckModal = (status) => {
        if (this.state.confirmCostOfService && this.state.confirmTerms) {
            this.setState({checkModal: status, showNotConfirmedAlert: false})
        } else {
            this.setState({showNotConfirmedAlert: true})
        }
    }


    handleConfirmDetails = () => {
    //    CHECK MODAL CONFIRM BUTTON
        this.showCheckModal(false)
        this.setState({isChecked: true})
    }

    render() {
        return (
            <>
                <Row>
                    <Col xs={23} className="shadow-white-div-margin-10">
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>MY WALLET</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}>
                                <Row>
                                    <Col xs={24}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Blockchain Network
                                        </small>
                                    </Col>
                                    <Col className="label-select" xs={8} md={8} align='middle'>
                                        <Select defaultValue="1"
                                                style={{pointerEvents: 'none', color: '#ffffff'}}>
                                            <Option value="1">CONNECTED TO</Option>
                                        </Select>
                                    </Col>
                                    <Col className="select-with-box" xs={6} md={12}>
                                        <Select defaultValue="bnc-smart-chain"
                                                value={this.state.blockchainNetwork}
                                                suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                onChange={(e) => this.setState({blockchainNetwork: e})}>
                                            <Option value="all">All</Option>
                                            <Option value="bnc-smart-chain">Binance Smart Chain</Option>
                                            <Option value="dummy">Dummy</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}>
                                <PromoInput isHorizontalLabel={false} labelText="Your Wallet address"
                                           defaultValue={this.state.walletAddress}
                                           value={this.state.walletAddress}
                                           onChange={(e) => this.handleInput(e, 'walletAddress')}/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                 className='select-after-borderless'>
                                <PromoInput isHorizontalLabel={false} labelText="Wallet Balance"
                                           value={this.state.walletBalance}
                                           onChange={(e) => this.handleInput(e, 'walletBalance')}
                                           addonAfter={
                                               <Select defaultValue="promo" className="select-after">
                                                   <Option value="promo">PROMO</Option>
                                                   <Option value="dummy">Dummy</Option>
                                                   <Option value="dummy2">Dummy2</Option>
                                                   <Option value="dummy3">Dummy3</Option>
                                               </Select>
                                           }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={23} className="shadow-white-div-margin-10">
                        <Row>
                            <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                <h5 style={{marginLeft: '8px'}}>GENERAL</h5>
                            </Col>
                        </Row>
                        <Row className="profile-PromoInput-style">
                            <Col xs={{span: 23, offset: 1}} md={{span: 9, offset: 1}}>
                                <Row>
                                    <Col span={24}>
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Type of token sale
                                        </small>
                                    </Col>
                                    <Col className="label-select" xs={10} md={10}>
                                        <Select defaultValue="1"
                                                style={{pointerEvents: 'none', color: '#ffffff', width: '100%'}}>
                                            <Option value="1">CREATE A NEW</Option>
                                        </Select>
                                    </Col>
                                    <Col className="select-with-box" xs={10} md={14}>
                                        <Select style={{width: '100%'}}
                                                value={this.state.typeOfTokenSale}
                                                suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                onChange={(e) => this.setState({typeOfTokenSale: e})}>
                                            <Option hidden>Pick One</Option>
                                            <Option value="private">PRIVATE SALE</Option>
                                            <Option value="public">PUBLIC SALE</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="margin-y-10" md={{span: 2}} align="middle">
                                <AiOutlineDoubleRight size={30} style={{marginTop: '10px'}}/>
                            </Col>
                            <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 0}}>
                                <Row gutter={[8, 8]}>
                                    <Col span={6} justify="bottom">
                                        <small
                                            style={{fontSize: 12}}
                                            className="profile-input-label"
                                        >
                                            Project
                                        </small>
                                    </Col>
                                    <Col span={16} align='right'>
                                        <Button
                                            size="small"
                                            style={{fontSize: 12}}
                                            className="promodex-addmore-btn"
                                        >
                                            + Register a new project
                                        </Button>
                                    </Col>
                                    <Col span={22}>
                                        <Select
                                            showSearch
                                            style={{width: '100%'}}
                                            placeholder="Choose your project (if you dont have one please register your project first)"
                                            optionFilterProp="children"
                                            onChange={(e) => this.setState({project: e})}>
                                            onFocus={this.handleProjectOnFocus}
                                            onBlur={this.handleProjectOnBlur}
                                            onSearch={this.handleProjectOnSearch}
                                            filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                            >
                                            <Option value="project1">Project 1</Option>
                                            <Option value="project2">Project 2</Option>
                                            <Option value="project3">Project 3</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.state.typeOfTokenSale === "private" && this.state.project &&
                <>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 8, offset: 1}}>
                                    <h4 style={{marginLeft: '8px'}}>EXPERT REVIEW</h4>
                                    <small style={{marginLeft: '8px'}}>Would you like your project to be reviewed and
                                        graded
                                        by 3 crypto experts?</small>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 4, offset: 0}}>
                                    <Row>
                                        <Col span={20}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Review
                                            </small>
                                        </Col>
                                        <Col span={20}>
                                            <Select defaultValue="NO" value={this.state.expertReview}
                                                    onChange={(e) => this.setState({expertReview: e})}>
                                                <Option value={true}>YES</Option>
                                                <Option value={false}>NO</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                {this.state.expertReview &&
                                <Col xs={{span: 23, offset: 1}} md={{span: 10}}>
                                    <Row>
                                        <Col xs={24} lg={12}>
                                            <Row>
                                                <Col span={24}>
                                                    <small
                                                        style={{fontSize: 12}}
                                                        className="profile-input-label"
                                                    >
                                                        Choose Expert
                                                    </small>
                                                </Col>
                                                <Col span={12} align='right' className="select-after-borderless">
                                                    <Select
                                                        showSearch
                                                        style={{width: '100%'}}
                                                        placeholder="Choose Expert"
                                                        optionFilterProp="children"
                                                        onChange={(e) => this.handleExpertsInput(e, 'expertName', 0)}>
                                                        onFocus={this.handleProjectOnFocus}
                                                        onBlur={this.handleProjectOnBlur}
                                                        onSearch={this.handleProjectOnSearch}
                                                        filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                        >
                                                        <Option value="project1">IAN BALINA</Option>
                                                        <Option value="project2">RANDOM DATA</Option>
                                                        <Option value="project3">ANY DATA</Option>
                                                    </Select>
                                                </Col>
                                                <Col span={12}>
                                                    <Button style={{color: '#6236ff'}}>See profile</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} lg={12}>

                                            <PromoInput isHorizontalLabel={false} labelText="Cost of Review"
                                                       placeholder="300" type="number"
                                                       value={this.state.experts[0].costOfReview}
                                                       onChange={(e) => this.handleExpertsInput(e, 'costOfReview', 0)}
                                                       addonAfter={
                                                           <Select defaultValue="bnb" className="select-after">
                                                               <Option value="promo">PROMO</Option>
                                                               <Option value="bnb">BNB</Option>
                                                           </Select>}/>
                                        </Col>
                                    </Row>
                                    {this.state.experts.map((data, index) => {
                                        if (index === 0) return
                                        return (
                                            <Row>
                                                <Col xs={24} lg={12}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <small
                                                                style={{fontSize: 12}}
                                                                className="profile-input-label"
                                                            >
                                                                Choose Expert
                                                            </small>
                                                        </Col>
                                                        <Col span={12} align='right'
                                                             className="select-after-borderless">
                                                            <Select
                                                                showSearch
                                                                style={{width: '100%'}}
                                                                placeholder="Choose Expert"
                                                                optionFilterProp="children"
                                                                onChange={(e) => this.handleExpertsInput(e, 'expertName', index)}>
                                                                onFocus={this.handleProjectOnFocus}
                                                                onBlur={this.handleProjectOnBlur}
                                                                onSearch={this.handleProjectOnSearch}
                                                                filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                                >
                                                                <Option value="project1">IAN BALINA</Option>
                                                                <Option value="project2">RANDOM DATA</Option>
                                                                <Option value="project3">ANY DATA</Option>
                                                            </Select>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Button style={{color: '#6236ff'}}>See profile</Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} lg={12}>

                                                    <PromoInput isHorizontalLabel={false} labelText="Cost of Review"
                                                               placeholder="300" type="number"
                                                               value={this.state.experts[index].costOfReview}
                                                               onChange={(e) => this.handleExpertsInput(e, 'costOfReview', index)}
                                                               addonAfter={
                                                                   <Select defaultValue="bnb" className="select-after">
                                                                       <Option value="promo">PROMO</Option>
                                                                       <Option value="bnb">BNB</Option>
                                                                   </Select>}/>
                                                </Col>
                                            </Row>
                                        )
                                    })

                                    }
                                    <Row className="margin-y-10">
                                        <Col xs={23} align='right'>
                                            <Button
                                                size="small"
                                                style={{fontSize: 12}}
                                                className="promodex-addmore-btn"
                                                onClick={this.handleAddExperts}
                                            >
                                                + ADD MORE EXPERT FOR REVIEW
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                }

                            </Row>
                            <Row className="margin-top-20">
                                <Col xs={{span: 20, offset: 1}}>
                                    <p style={{marginLeft: '8px'}}>
                                        Experts review your project accordint to 10 criteria and mark with a rewiew
                                        point
                                        for each. All 3 experts avarage points are defined as the project overall point
                                        for
                                        each criteria. And those criteria and points will be visible to all potential
                                        investors. If your project is reviewed and
                                        highly gradeed by experts it will be more reliable for them and potentially more
                                        invesment is raised..
                                        Cost of review is added to total cost of service in the followiing.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 style={{marginLeft: '8px'}}>DEFINE PARTICIPANT PROFILE</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <Row>
                                        <Col span={20}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Participants (who can participate the sale)
                                            </small>
                                        </Col>
                                        <Col span={20}>
                                            <Select className='select-style' placeholder="Select"
                                                    suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                    onChange={(e) => this.handleParticipantsInput(e, 'participant', 0)}>
                                                <Option value="1">PARTICIPANTS OF MY PROMOTION</Option>
                                                <Option value="dummy">dummy</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="margin-y-10" md={{span: 2}}>
                                    <AiOutlineDoubleRight size={30} style={{marginTop: '10px'}}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 0}}>
                                    <Row>
                                        <Col span={20}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Campaign ID
                                            </small>
                                        </Col>
                                        <Col span={24}>
                                            <Select className='select-style' placeholder='Choose in your campaigns'
                                                    suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                    onChange={(e) => this.handleParticipantsInput(e, 'campaignId', 0)}>
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {this.state.participants.map((data, index) => {
                                if (index === 0) return
                                return (
                                    <Row className="profile-PromoInput-style">
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                            <Row>
                                                <Col span={20}>
                                                    <small
                                                        style={{fontSize: 12}}
                                                        className="profile-input-label"
                                                    >
                                                        Participants (who can participate the sale)
                                                    </small>
                                                </Col>
                                                <Col span={20}>
                                                    <Select className='select-style' placeholder="Select"
                                                            onChange={(e) => this.handleParticipantsInput(e, 'participant:', index)}>
                                                        <Option value="1">PARTICIPANTS OF MY PROMOTION</Option>
                                                        <Option value="dummy">dummy</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="margin-y-10" md={{span: 2}}>
                                            <AiOutlineDoubleRight size={30} style={{marginTop: '10px'}}/>
                                        </Col>
                                        <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 0}}>
                                            <Row>
                                                <Col span={20}>
                                                    <small
                                                        style={{fontSize: 12}}
                                                        className="profile-input-label"
                                                    >
                                                        Campaign ID
                                                    </small>
                                                </Col>
                                                <Col span={24}>
                                                    <Select className='select-style'
                                                            placeholder='Choose in your campaigns'
                                                            onChange={(e) => this.handleParticipantsInput(e, 'campaignId:', index)}>
                                                        <Option value="1">1</Option>
                                                        <Option value="2">2</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })

                            }
                            <Row className="margin-y-10">
                                <Col xs={23} align='right'>
                                    <Button
                                        size="small"
                                        style={{fontSize: 12}}
                                        className="promodex-addmore-btn"
                                        onClick={this.handleAddParticipants}
                                    >
                                        + ADD MORE PARTICIPANT
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 style={{marginLeft: '8px'}}>TOKEN DETAILS</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <PromoInput isHorizontalLabel={false}
                                               labelText="Enter token address to withdraw token details from blockchain network automatically"
                                               type="email" placeholder="Enter token address"/>
                                </Col>
                                <Col className="margin-y-10" md={{span: 2}}>
                                    <AiOutlineDoubleRight size={30} style={{marginTop: '10px'}}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 0}}>
                                    <ul className="no-bullets p-font-bold">
                                        <li>Token Name</li>
                                        <li>Token Symbol</li>
                                        <li>Token Decimal</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 style={{marginLeft: '8px'}}>SALE DETAILS</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}} lg={{span: 5, offset: 1}}>
                                    <Row>
                                        <Col span={20}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Sale unit
                                            </small>
                                        </Col>
                                        <Col span={22}>
                                            <Select className="select-style" defaultValue="bnb"
                                                    suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                    onChange={(e) => this.setState({saleUnit: e})}>
                                                <Option value="bnb">BNB</Option>
                                                <Option value="promo">PROMO</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false} labelText="Softcap"
                                               placeholder="Softcap ex: 20"
                                               onChange={(e) => this.setState({softcap: e.target.value})}
                                               addonAfter={
                                                   <Select defaultValue="bnb" className="select-after">
                                                       <Option value="promo">PROMO</Option>
                                                       <Option value="bnb">BNB</Option>
                                                   </Select>}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false} labelText="Minimum contribution"
                                               placeholder="Min. contribution ex: 0,1"
                                               onChange={(e) => this.setState({minContribution: e.target.value})}
                                               addonAfter={
                                                   <Select defaultValue="bnb" className="select-after">
                                                       <Option value="promo">PROMO</Option>
                                                       <Option value="bnb">BNB</Option>
                                                   </Select>}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalInput={false} labelText="Date of Birth"
                                               value={this.state.startDate}
                                               type="datetime-local"
                                               onChange={(event) => this.setState({startDate: event.target.value})}/>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false} labelText="Swap rate"
                                               placeholder="No of token per BNB"
                                               onChange={(e) => this.setState({swapRate: e.target.value})}
                                               addonAfter={
                                                   <Select style={{pointerEvents: 'none', color: '#ffffff'}}
                                                           defaultValue="bnb" className="select-after">
                                                       <Option value="bnb">TOKEN/BNB</Option>
                                                   </Select>}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false} labelText="Hardcap"
                                               placeholder="Hardcap ex: 100"
                                               onChange={(e) => this.setState({hardcap: e.target.value})}
                                               addonAfter={
                                                   <Select defaultValue="bnb" className="select-after">
                                                       <Option value="promo">PROMO</Option>
                                                       <Option value="bnb">BNB</Option>
                                                   </Select>}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false} labelText="Maximum contribution"
                                               placeholder="Max. contribution ex: 10"
                                               onChange={(e) => this.setState({maxContribution: e.target.value})}
                                               addonAfter={
                                                   <Select defaultValue="bnb" className="select-after">
                                                       <Option value="promo">PROMO</Option>
                                                       <Option value="bnb">BNB</Option>
                                                   </Select>}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalInput={false} labelText="Date of Birth"
                                               value={this.state.endDate}
                                               type="datetime-local"
                                               onChange={(event) => this.setState({endDate: event.target.value})}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 style={{marginLeft: '8px'}}>DEX LISTING</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}>
                                    <Row>
                                        <Col span={20}>
                                            <small
                                                style={{fontSize: 12}}
                                                className="profile-input-label"
                                            >
                                                Listing DEX
                                            </small>
                                        </Col>
                                        <Col span={20}>
                                            <Select className="select-style" defaultValue="pancake"
                                                    value={this.state.dex}
                                                    suffixIcon={<AiOutlineDown style={{color:'#7651fc'}}/>}
                                                    onChange={(e) => this.setState({dex: e})}>
                                                <Option value="pancake">PANCAKESWAP</Option>
                                                <Option value="promo">PROMO</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false} labelText="DEX listing price"
                                               placeholder="No of token per BNB" value={this.state.listingPrice}
                                               onChange={(e) => this.setState({listingPrice: e.target.value})}
                                               addonAfter={
                                                   <Select style={{pointerEvents: 'none', color: '#ffffff'}}
                                                           defaultValue="bnb" className="select-after">
                                                       <Option value="bnb">TOKEN/BNB</Option>
                                                   </Select>}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false}
                                               labelText="Percentage of fund to be allocated on DEX"
                                               placeholder="Min:50 - Max:100" value={this.state.allocatedDex}
                                               onChange={(e) => this.setState({allocatedDex: e.target.value})}
                                               addonAfter={
                                                   <Select style={{pointerEvents: 'none', color: '#ffffff'}}
                                                           defaultValue="percent" className="select-after">
                                                       <Option value="percent">%</Option>
                                                   </Select>}/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalInput={false} labelText="Liquidity unlock time"
                                               value={this.state.unlockDate}
                                               type="datetime-local"
                                               onChange={(event) => this.setState({unlockDate: event.target.value})}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 style={{marginLeft: '8px'}}>COST OF SERVICE</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false}
                                               labelText="NO - AMOUNT - UNIT"
                                               placeholder="ex: 1000" value={this.state.costOfServiceAmount1}
                                               onChange={(e) => this.setState({costOfServiceAmount1: e.target.value})}
                                               addonBefore={
                                                   <Select defaultValue="1"
                                                           style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                       <Option value="1">1.</Option>
                                                   </Select>
                                               }
                                               addonAfter={
                                                   <Select defaultValue="bnb" className="select-after">
                                                       <Option value="bnb">BNB</Option>
                                                       <Option value="promo">PROMO</Option>
                                                       <Option value="token">TOKEN</Option>
                                                       <Option value="dummy">Dummy</Option>
                                                   </Select>
                                               }/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false}
                                               labelText="NO - AMOUNT - UNIT"
                                               placeholder="ex: 1000" value={this.state.costOfServiceAmount1}
                                               onChange={(e) => this.setState({costOfServiceAmount1: e.target.value})}
                                               addonBefore={
                                                   <Select defaultValue="1"
                                                           style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                       <Option value="1">1.</Option>
                                                   </Select>
                                               }
                                               addonAfter={
                                                   <Select defaultValue="promo" className="select-after">
                                                       <Option value="bnb">BNB</Option>
                                                       <Option value="promo">PROMO</Option>
                                                       <Option value="token">TOKEN</Option>
                                                       <Option value="dummy">Dummy</Option>
                                                   </Select>
                                               }/>
                                </Col>
                                <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                     className='select-after-borderless'>
                                    <PromoInput isHorizontalLabel={false}
                                               labelText="NO - AMOUNT - UNIT"
                                               placeholder="ex: 1000" value={this.state.costOfServiceAmount1}
                                               onChange={(e) => this.setState({costOfServiceAmount1: e.target.value})}
                                               addonBefore={
                                                   <Select defaultValue="1"
                                                           style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                       <Option value="1">1.</Option>
                                                   </Select>
                                               }
                                               addonAfter={
                                                   <Select defaultValue="token" className="select-after">
                                                       <Option value="bnb">BNB</Option>
                                                       <Option value="promo">PROMO</Option>
                                                       <Option value="token">TOKEN</Option>
                                                       <Option value="dummy">Dummy</Option>
                                                   </Select>
                                               }/>
                                </Col>
                            </Row>
                            <Row className="margin-top-20">
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <Checkbox onChange={(e) => this.setState({confirmCostOfService: e.target.checked})}>CONFIRM
                                        THE COST OF SERVICE AS</Checkbox>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row>
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <h5 style={{marginLeft: '8px'}}>DISCLAMER</h5>
                                </Col>
                            </Row>
                            <Row className="profile-PromoInput-style">
                                <Col xs={{span: 20, offset: 1}}>
                                    <p style={{marginLeft: '8px'}}>
                                        Disclaimer: This process is entirely decentralized, we cannot be held reponsible
                                        for incorrect entry of information or be held liable for anything related to
                                        your use of our platform. Please ensure you enter all your details to the best
                                        accuracy possible and that you are in
                                        compliance with your local laws and regulations. This is a beta version! We
                                        cannot guarantee there will be no bugs. Use at your own risk! For tokens with
                                        burns, rebase or other special transfers please ensure you have a way to
                                        whitelist multiple addresses or turn off the
                                        special
                                        transfer events (By setting fees to 0 for example for the duration of the
                                        presale
                                    </p>
                                </Col>
                            </Row>
                            <Row className="margin-top-20">
                                <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                    <Checkbox style={{marginLeft: '8px'}}
                                              onChange={(e) => this.setState({confirmTerms: e.target.checked})}>
                                        CONFIRM TERMS AND CONDITIONS
                                    </Checkbox>
                                </Col>
                            </Row>
                            <Modal title="TERMS AND CONDITIONS" visible={this.state.confirmTermsModal}
                                   onOk={() => this.showConfirmTermsModal(false)}
                                   onCancel={() => this.showConfirmTermsModal(false)}
                                   footer={[
                                       <Button style={{color: '#ffffff', backgroundColor: '#e02020'}} key="cancel"
                                               size="large" onClick={() => this.showConfirmTermsModal(false)}>
                                           CANCEL
                                       </Button>,
                                       <Button style={{color: '#ffffff', backgroundColor: '#6dd400'}} key="submit"
                                               size="large" onClick={this.handleConfirmButton}>
                                           CONFIRM
                                       </Button>,
                                   ]}>
                                <div className="checkbox-group-vertical">
                                    <CheckboxGroup options={termsAndConditions} value={this.state.checkedList}
                                                   onChange={this.handleCheckboxGroupOnChange}/>
                                    <Checkbox indeterminate={this.state.indeterminate} defaultChecked={false}
                                              onChange={this.onCheckAllChange}
                                              checked={this.state.checkAll}>
                                        Agree to all
                                    </Checkbox>
                                    {this.state.showAlert &&
                                    <Alert style={{marginTop: '10px'}}
                                           message="You cannot confirm until you accept all the terms." type="error"
                                           showIcon/>
                                    }
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                    {this.state.showNotConfirmedAlert &&
                    <Row>
                        <Col xs={23}>
                            <Alert style={{marginTop: '10px'}}
                                   message="You cannot check until you accept all the terms." type="error"
                                   showIcon/>
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col xs={23} className="shadow-white-div-margin-10">
                            <Row align="center">
                                <Col className="margin-y-10" xs={{span: 20, offset: 1}} md={{span: 6, offset: 1}}
                                     align="middle">
                                    <Button size="large" block className="promo-btn p-font-bold" disabled={this.state.isChecked}
                                            style={{backgroundColor: this.state.isChecked && '#6dd400',color: this.state.isChecked && '#ffffff'}}
                                            onClick={() => this.showCheckModal(true)}>{this.state.isChecked ? 'CONFIRMED' : 'CHECK'}</Button>
                                </Col>
                                <Col className="margin-y-10" md={{span: 2}} align="middle">
                                    <AiOutlineDoubleRight size={30}/>
                                </Col>
                                <Col className="margin-y-10" xs={{span: 20, offset: 0}} md={{span: 6, offset: 0}}
                                     align="middle">
                                    <Button size="large" block className="promo-btn p-font-bold">STAKE</Button>
                                </Col>
                                <Col className="margin-y-10" md={{span: 2}} align="middle">
                                    <AiOutlineDoubleRight size={30}/>
                                </Col>
                                <Col className="margin-y-10" xs={{span: 20, offset: 0}} md={{span: 6, offset: 0}}
                                     align="middle">
                                    <Button size="large" block className="promo-btn p-font-bold">LAUNCH</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/** CHECK MODAL*/}
                    <Modal title="CHECK" visible={this.state.checkModal}
                           onOk={() => this.showCheckModal(false)}
                           onCancel={() => this.showCheckModal(false)}
                           width={1500}
                           footer={[
                               <Button style={{color: '#ffffff', backgroundColor: '#e02020'}} key="cancel"
                                       size="large" onClick={() => this.showCheckModal(false)}>
                                   CANCEL
                               </Button>,
                               <Button style={{color: '#ffffff', backgroundColor: '#6dd400'}} key="submit"
                                       size="large" onClick={this.handleConfirmDetails}>
                                   CONFIRM DETAILS
                               </Button>,
                           ]}>
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <h5 style={{marginLeft: '8px'}}>GENERAL</h5>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                        <Row>
                                            <Col span={24}>
                                                <small
                                                    style={{fontSize: 12}}
                                                    className="profile-input-label"
                                                >
                                                    Type of token sale
                                                </small>
                                            </Col>
                                            <Col className="select-with-box" xs={24}>
                                                <Select style={{width: '100%'}}
                                                        value={this.state.typeOfTokenSale}
                                                        disabled>
                                                    <Option hidden>Pick One</Option>
                                                    <Option value="private">PRIVATE SALE</Option>
                                                    <Option value="public">PUBLIC SALE</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                        <Row>
                                            <Col span={6} justify="bottom">
                                                <small
                                                    style={{fontSize: 12}}
                                                    className="profile-input-label"
                                                >
                                                    Project
                                                </small>
                                            </Col>
                                            <Col span={22}>
                                                <Select
                                                    showSearch
                                                    style={{width: '100%'}}
                                                    placeholder="Choose your project (if you dont have one please register your project first)"
                                                    optionFilterProp="children"
                                                    value={this.state.project}
                                                    disabled
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="project1">Project 1</Option>
                                                    <Option value="project2">Project 2</Option>
                                                    <Option value="project3">Project 3</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.expertReview &&
                            <Col xs={{span: 23}} className="shadow-white-div-margin-10">
                                {this.state.experts.map((data, index) => {
                                    return (
                                        <Row>
                                            <Col xs={24} lg={{span: 11, offset: 1}}>
                                                <Row>
                                                    <Col span={24}>
                                                        <small
                                                            style={{fontSize: 12}}
                                                            className="profile-input-label"
                                                        >
                                                            EXPERT {index + 1}
                                                        </small>
                                                    </Col>
                                                    <Col span={24} className="select-after-borderless">
                                                        <Select
                                                            showSearch
                                                            style={{width: '100%'}}
                                                            placeholder="Choose Expert"
                                                            optionFilterProp="children"
                                                            value={this.state.experts[index].expertName}
                                                            disabled
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            <Option value="project1">IAN BALINA</Option>
                                                            <Option value="project2">RANDOM DATA</Option>
                                                            <Option value="project3">ANY DATA</Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={24} lg={{span: 11, offset: 1}}>
                                                <PromoInput isHorizontalLabel={false} labelText="Cost of Review"
                                                           placeholder="300" type="number"
                                                           value={this.state.experts[index].costOfReview}
                                                           onChange={(e) => this.handleExpertsInput(e, 'costOfReview', index)}
                                                           addonAfter={
                                                               <Select defaultValue="bnb" className="select-after">
                                                                   <Option value="promo">PROMO</Option>
                                                                   <Option value="bnb">BNB</Option>
                                                               </Select>}/>
                                            </Col>
                                        </Row>
                                    )
                                })
                                }
                            </Col>
                            }
                        </Row>
                        <Row>
                            <Col xs={{span: 23}} className="shadow-white-div-margin-10">
                                {this.state.participants.map((data, index) => {
                                    return (
                                        <Row className="profile-PromoInput-style">
                                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                                <Row>
                                                    <Col span={20}>
                                                        <small
                                                            style={{fontSize: 12}}
                                                            className="profile-input-label"
                                                        >
                                                            Participants (who can participate the sale)
                                                        </small>
                                                    </Col>
                                                    <Col span={20}>
                                                        <Select className='select-style' placeholder="Select"
                                                                onChange={(e) => this.handleParticipantsInput(e, 'participant:', index)}>
                                                            <Option value="1">PARTICIPANTS OF MY PROMOTION</Option>
                                                            <Option value="dummy">dummy</Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}>
                                                <Row>
                                                    <Col span={20}>
                                                        <small
                                                            style={{fontSize: 12}}
                                                            className="profile-input-label"
                                                        >
                                                            Campaign ID
                                                        </small>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Select className='select-style'
                                                                placeholder='Choose in your campaigns'
                                                                onChange={(e) => this.handleParticipantsInput(e, 'campaignId:', index)}>
                                                            <Option value="1">1</Option>
                                                            <Option value="2">2</Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                })
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <h5 style={{marginLeft: '8px'}}>SALE DETAILS</h5>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}
                                         lg={{span: 5, offset: 1}}>
                                        <Row>
                                            <Col span={20}>
                                                <small
                                                    style={{fontSize: 12}}
                                                    className="profile-input-label"
                                                >
                                                    Sale unit
                                                </small>
                                            </Col>
                                            <Col span={22}>
                                                <Select className="select-style" defaultValue="bnb"
                                                        value={this.state.saleUnit} disabled>
                                                    <Option value="bnb">BNB</Option>
                                                    <Option value="promo">PROMO</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false} labelText="Softcap"
                                                   placeholder="Softcap ex: 20" defaultValue={this.state.softcap}
                                                   disabled
                                                   addonAfter={
                                                       <Select defaultValue="bnb" className="select-after">
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="bnb">BNB</Option>
                                                       </Select>}/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false} labelText="Minimum contribution"
                                                   placeholder="Min. contribution ex: 0,1"
                                                   defaultValue={this.state.minContribution}
                                                   disabled
                                                   addonAfter={
                                                       <Select defaultValue="bnb" className="select-after">
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="bnb">BNB</Option>
                                                       </Select>}/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalInput={false} labelText="Date of Birth"
                                                   defaultValue={this.state.startDate}
                                                   type="datetime-local" disabled/>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false} labelText="Swap rate"
                                                   placeholder="No of token per BNB"
                                                   defaultValue={this.state.swapRate}
                                                   disabled
                                                   addonAfter={
                                                       <Select style={{pointerEvents: 'none', color: '#ffffff'}}
                                                               defaultValue="bnb" className="select-after">
                                                           <Option value="bnb">TOKEN/BNB</Option>
                                                       </Select>}/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false} labelText="Hardcap"
                                                   placeholder="Hardcap ex: 100" defaultValue={this.state.hardcap}
                                                   disabled
                                                   addonAfter={
                                                       <Select defaultValue="bnb" className="select-after">
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="bnb">BNB</Option>
                                                       </Select>}/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false} labelText="Maximum contribution"
                                                   placeholder="Max. contribution ex: 10"
                                                   defaultValue={this.state.maxContribution}
                                                   disabled
                                                   addonAfter={
                                                       <Select defaultValue="bnb" className="select-after">
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="bnb">BNB</Option>
                                                       </Select>}/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 12, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalInput={false} labelText="Date of Birth"
                                                   defaultValue={this.state.endDate}
                                                   disabled
                                                   type="datetime-local"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <h5 style={{marginLeft: '8px'}}>DEX LISTING</h5>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}}
                                         lg={{span: 5, offset: 1}}>
                                        <Row>
                                            <Col span={20}>
                                                <small
                                                    style={{fontSize: 12}}
                                                    className="profile-input-label"
                                                >
                                                    Listing DEX
                                                </small>
                                            </Col>
                                            <Col span={20}>
                                                <Select className="select-style" disabled defaultValue="pancake"
                                                        value={this.state.dex}>
                                                    <Option value="pancake">PANCAKESWAP</Option>
                                                    <Option value="promo">PROMO</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false} labelText="DEX listing price" disabled
                                                   placeholder="No of token per BNB"
                                                   defaultValue={this.state.listingPrice}
                                                   addonAfter={
                                                       <Select style={{pointerEvents: 'none', color: '#ffffff'}}
                                                               defaultValue="bnb" className="select-after">
                                                           <Option value="bnb">TOKEN/BNB</Option>
                                                       </Select>}/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="Percentage of fund to be allocated on DEX" disabled
                                                   placeholder="Min:50 - Max:100" defaultValue={this.state.allocatedDex}
                                                   addonAfter={
                                                       <Select style={{pointerEvents: 'none', color: '#ffffff'}}
                                                               defaultValue="percent" className="select-after">
                                                           <Option value="percent">%</Option>
                                                       </Select>}/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 11, offset: 1}} lg={{span: 5, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalInput={false} labelText="Liquidity unlock time"
                                                   defaultValue={this.state.unlockDate}
                                                   type="datetime-local" disabled/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={23} className="shadow-white-div-margin-10">
                                <Row>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <h5 style={{marginLeft: '8px'}}>COST OF SERVICE</h5>
                                    </Col>
                                </Row>
                                <Row className="profile-PromoInput-style">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="NO - AMOUNT - UNIT"
                                                   placeholder="ex: 1000" defaultValue={this.state.costOfServiceAmount1}
                                                   disabled
                                                   addonBefore={
                                                       <Select defaultValue="1"
                                                               style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                           <Option value="1">1.</Option>
                                                       </Select>
                                                   }
                                                   addonAfter={
                                                       <Select defaultValue="bnb" className="select-after">
                                                           <Option value="bnb">BNB</Option>
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="token">TOKEN</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="NO - AMOUNT - UNIT"
                                                   placeholder="ex: 1000" defaultValue={this.state.costOfServiceAmount1}
                                                   disabled
                                                   addonBefore={
                                                       <Select defaultValue="1"
                                                               style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                           <Option value="1">1.</Option>
                                                       </Select>
                                                   }
                                                   addonAfter={
                                                       <Select defaultValue="promo" className="select-after">
                                                           <Option value="bnb">BNB</Option>
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="token">TOKEN</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                    <Col xs={{span: 23, offset: 1}} md={{span: 7, offset: 1}}
                                         className='select-after-borderless'>
                                        <PromoInput isHorizontalLabel={false}
                                                   labelText="NO - AMOUNT - UNIT"
                                                   placeholder="ex: 1000" defaultValue={this.state.costOfServiceAmount1}
                                                   disabled
                                                   addonBefore={
                                                       <Select defaultValue="1"
                                                               style={{pointerEvents: 'none', color: '#ffffff'}}>
                                                           <Option value="1">1.</Option>
                                                       </Select>
                                                   }
                                                   addonAfter={
                                                       <Select defaultValue="token" className="select-after">
                                                           <Option value="bnb">BNB</Option>
                                                           <Option value="promo">PROMO</Option>
                                                           <Option value="token">TOKEN</Option>
                                                           <Option value="dummy">Dummy</Option>
                                                       </Select>
                                                   }/>
                                    </Col>
                                </Row>
                                <Row className="margin-top-20">
                                    <Col xs={{span: 23, offset: 1}} md={{span: 10, offset: 1}}>
                                        <Checkbox
                                            disabled>CONFIRM
                                            THE COST OF SERVICE AS</Checkbox>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal>
                </>
                }
            </>
        );
    }
}

export default CreateIdo;
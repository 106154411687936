import React from 'react'
import { mainContractAddress } from '../../contract/abi/main';
import { busdContractAddress } from '../../contract/abi/busd';
import { getApiBase } from '../../services/config';

// This page will contain all Add To Metamask functions.

const apiBase = getApiBase()

// Add Promo token to your metamask
export const AddPromoToMetamask = async () => {
    const tokenAddress = mainContractAddress;
    const tokenSymbol = 'PROMO';
    const tokenDecimals = 8;
    const tokenImage = apiBase + 'media/tokenImages/promoToken.png';
    try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: tokenAddress, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: tokenDecimals, // The number of decimals in the token
                    image: tokenImage, // A string url of the token logo
                },
            },
        });

        if (wasAdded) {
            console.log('Thanks for your interest!');
        } else {
            console.log('Your loss!');
        }
    } catch (error) {
        console.log(error);
    }
}


// Add Busd token to your metamask
export const AddBusdToMetamask = async () => {
    const tokenAddress = busdContractAddress;
    const tokenSymbol = 'BUSD';
    const tokenDecimals = 18;
    try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: tokenAddress, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: tokenDecimals, // The number of decimals in the token
                    // image: tokenImage, // A string url of the token logo
                },
            },
        });

        if (wasAdded) {
            console.log('Thanks for your interest!');
        } else {
            console.log('Your loss!');
        }
    } catch (error) {
        console.log(error);
    }
}

// Add BNB CHAIN to your metamask
export const AddBNBToMetamask = async () => {
    window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'Binance Coin',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com']
        }]
        })
        .catch((error) => {
        console.log(error)
        }) 
}
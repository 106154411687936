import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Button } from "antd";
import { AllTokenSales } from '../../../components/layout/Icons';


const AllTokenSalesIcon = AllTokenSales()

class Statistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }
  render() {
    return (
      <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }} style={{ marginTop: 10 }} className={this.props.showSummary ? 'shadow-white-div marginless-bottom summary-collapse' : 'shadow-white-div marginless-bottom summary-collapse summary-close'}>
        <Row gutter={[8, 16]} className="margin-top-10">
          <Col xs={{ span: 24 }}>
            <Row>
              <Col xs={24} align='middle'>
                <p className="investor-title"><span className='icon-inline'>{AllTokenSalesIcon}</span>Overall Statistics </p>
              </Col>
            </Row>
            <Row>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>No of Stakers:</h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>21400</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>PROMO staked: </h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>22.240.000</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} align='middle'>
                <Row>
                  <Col xs={{ span: 12, offset: 0 }} align='left'>
                    <h5>Max APY-Period:</h5>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }} align='left'>
                    <h5 style={{ fontWeight: 100 }}>30%-365 days</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 6, offset: 0 }}>
                <Button className="margin-top-10 connect-wallet-btn sm-btn" size="small">
                  See token explorer
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(Statistics));

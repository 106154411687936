import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../redux/promodex/actions";
import { Button, Col, Form, Row, notification, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { AiOutlineUserAdd } from "react-icons/ai";
import PromoInput from "../../components/input/PromoInput";
import { resetPassword } from './api';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ innerWidth: window.innerWidth });
    setInterval(() => {
      this.setState({ innerWidth: window.innerWidth })
    }, 500)

  }
  handleContinue = async (values) => {
    if (!values.email) return;

    const payload = {
      email: values.email
    }


    resetPassword(payload)
      .then(response => {
        console.log('RESPONSE : ', response.data.status)
        if (response.data.status === 404) {
          notification.error({
            message: 'Incorrect',
            description: 'Your e-mail is incorrect',
          });
          return;
        }
        if (response.data.status === 406) {
          notification.error({
            message: 'Not registered with email',
            description: response.data.message,
          });
          return;
        }
        if (response.data.status === 200) {
          notification.success({
            message: 'Success',
            description: 'We are sending an email for your password reset process',
          });
          return;
        }
      }).catch(error => console.log('ERROR : ', error))

  };


  render() {
    return (<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
      <Row>
        <Col sm={{ span: 18, offset: 6 }}>
          <div
            className="box-shadow"
            style={{
              backgroundColor: "fff",
              margin: '0px 12px 10px 12px',
              borderRadius: "0px 0px 20px 20px",
            }}
          >
            <Form onFinish={this.handleContinue}
              name="basic"
              labelCol={{
                span: 0,
              }}
              wrapperCol={{
                span: 22,
                offset: 1
              }}
              initialValues={{
                remember: true,
              }}
              onFinishFailed={this.onFinishFailed}
              autoComplete="off"
              className="profile-promoInput-style">
              <Form.Item
                wrapperCol={{
                  offset: 7,
                  span: 12,
                }}
              >
                <p className='register-title'>
                  Forgot password
                </p>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  lg: {
                    span: 22,
                    offset: 1
                  }
                }}
                label=""
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Email address!',
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  }
                ]}
              >
                <PromoInput labelFontSize={14} labelColorClass="promo-input-label"
                  isHorizontalLabel={false}
                  labelText="Enter your Email"
                  placeholder=""
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({ email: e.target.value })
                  }
                  suffixIcon={<AiOutlineUserAdd />} />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  lg: {
                    span: 22,
                    offset: 1
                  }
                }}>
                <Button block htmlType="submit" onClick={this.handleContinue} className="margin-top-10 colorful-button nowrap register-button">
                  Change Password
                </Button>
                {/* a etiketi ile submit işlemi olmadığından dolayı görünmez bir butonu submit buton yapıp ref ile triggerlıyoruz. */}
                {/* <Button ref={(ref) => this.submitButton = ref} htmlType="submit" onClick={this.handleContinue} style={{ display: 'none' }}></Button> */}
              </Form.Item>

              <Row className="margin-y-40">
                <Col xs={24} align="center">
                  <p onClick={() => {
                    this.props.setPromodexRedux({ forgotPassword: false })
                  }} style={{ paddingBottom: "20px", color: '#5340FF', cursor: 'pointer' }}>Go back to sign in</p>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Spin>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ForgotPassword));
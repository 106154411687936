import React, { Component } from 'react';
import PromodexLayout from '../../components/layout/PromodexLayout';
import CreateProjectContent from './CreateProjectContent';

export default class CreateProjectLayout extends Component {
  render() {
    return (
      <PromodexLayout page='investorPage' content={<CreateProjectContent />} />
    )
  }
}

import { publicApi, protectedApi } from '../../services/axios'


export const getReport = (idoID) => {
    return publicApi.get(`/management/salelogreport/?projectId=1&idoId=` + idoID)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}
import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Button, Table, Select, Popconfirm, Form, Spin } from 'antd';
import BorderTextInput from '../../../components/input/BorderTextInput';
import BorderImageUpload from '../../../components/input/BorderImageUpload';
import BorderTextSelectbox from '../../../components/input/BorderTextSelectbox';
import { createProjectChain, createProjectTokenDetail, deleteProjectChain, getChains, getProjectChains } from '../api';
import { AiOutlineDelete } from 'react-icons/ai';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

class BlockchainInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chains: [],
      chain: null,
      contractAddress: null,
      dataSource: [],
      columns: [
        {
          title: 'Chain',
          dataIndex: 'chain',
          key: 'chain',
        },
        {
          title: 'Contract Address',
          dataIndex: 'contractAddress',
          key: 'contractAddress',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Popconfirm title='Sure to delete?' onConfirm={() => this.handleDelete(record.id)} size="middle">
              <a color='#5340FF'><AiOutlineDelete size={18} style={{ color: '#5340FF' }} /></a>
            </Popconfirm>
          ),
        },
      ],
      loading: false,
    }

  }



  componentDidMount() {
    this.getChains()
  }

  // Get Project chains for the table 
  getProjectChains = async () => {
    try {
      // send request to get chains by project id
      const projectChains = await getProjectChains(this.props.promodexInformation.project.id)

      let tempArr = []

      for (let i = 0; i < projectChains.length; i++) {
        // create a data for table that includes chains info.

        let data = {
          key: i + 1,
          chain: this.convertIdToName(projectChains[i].chain),
          contractAddress: projectChains[i].address,
          id: projectChains[i].id
        }

        tempArr.push(data)

      }
      // push data to state
      this.setState({
        dataSource: tempArr
      })

    } catch (ex) {
      console.log(ex)
    }
  }

  getChains = async () => {
    const chains = await getChains()
    this.setState({ chains: chains })
  }

  convertIdToName = (id) => {
    const chain = this.state.chains.filter(data => data.id === id)[0]
    return chain.name
  }


  handleDelete = async (id) => {
    try {
      const removeCommunityChannel = await deleteProjectChain(id)
      this.getProjectChains()
    } catch (ex) {
      console.log(ex)
    }
  }


  handleAddAccount = async () => {
    if (this.state.chain === null || this.state.contractAddress === null) {
      this.props.openNotification('warning', 'Cant Create Empty', 'You cant create without contractAddress or chain')
    }



    const payload = {
      address: this.state.contractAddress,
      chain: this.state.chain,
      projectId: this.props.promodexInformation.project.id
    }

    try {
      const projectChain = await createProjectChain(payload)
      this.getProjectChains()
      // this.props.setPromodexRedux({ project: createProject_ })
      // this.props.handleSteps(1)

    } catch (error) {
      console.log('ERROR : ', error)
    }


  }


  handleSave = (value) => {
    if (!value.name) return;
    if (this.props.promodexInformation.project === null) {
      this.props.openNotification('error', 'No Project Found', 'Please create your project first!')
    }

    this.setState({ loading: true }, async () => {
      value.symbol = this.props.promodexInformation.tokenSymbol
      value.image = this.props.promodexInformation.tokenImage

      let data = new FormData()

      data.append('projectId', this.props.promodexInformation.project.id)
      data.append('tokenName', value.name)
      if (value.symbol !== null && value.symbol !== undefined) {
        data.append('tokenSymbol', value.symbol)
      }
      if (value.image !== null && value.symbol !== undefined) {
        data.append('tokenImage', value.image)
      }
      data.append('tokenTicker', value.ticker)
      data.append('totalSupply', value.totalSupply)
      data.append('circulationSupply', value.circulationSupply)
      data.append('marketCap', value.marketCap)
      data.append('marketPrice', value.marketPrice)
      data.append('atl', value.atl)
      data.append('ath', value.ath)
      data.append('tokenDecimal', value.decimal)
      try {
        const projectTokenDetail = await createProjectTokenDetail(data)

        if (projectTokenDetail) {
          setTimeout(() => {
            this.setState({ loading: false }, () => {
              this.props.openNotification('success', 'Blockchain Informations Saved', 'Blockchain informations are successfuly saved!')
              this.props.handleSteps(this.props.currentStep + 1)
            })
          }, 1000)
        }
      } catch (error) {
        console.log('ERROR : ', error)
      }
    })
  }




  render() {

    const socialMediaOptions = this.state.chains.map((chain, index) => {
      return (
        <Option key={index} value={chain.id}>{chain.name}</Option>
      )
    })



    return (
      <div style={this.props.style}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
        <Form onFinish={this.handleSave}
          name="basic"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 22,
            offset: 1
          }}
          initialValues={{
            remember: true,
          }}
          // onFinishFailed={this.onFinishFailed}
          autoComplete="off">
          <Row className='margin-top-20'>
            <Col xs={24} className='shadow-white-div'>
              <Row gutter={[24, 8]}>
                <Col xs={24} align='center'>
                  <p style={this.props.titleStyle}>Blockchain Info</p>
                </Col>
                <Col xs={24}>
                  <span style={this.props.titleStyle}>Coin/Token</span>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label=""
                    name="name"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your token name!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'Token Name'} size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={{ span: 8, offset: 0 }}>
                  <Form.Item
                    label=""
                    name="ticker"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your ticker!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'Ticker'} size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Row>
                    <Col xs={{ span: 8, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                      <Form.Item
                        label=""
                        name="symbol"
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                        rules={[
                          {
                            required: false,
                            message: 'Please enter your token symbol!',
                          },
                        ]}
                      >
                        <BorderImageUpload crop={false} labelText={'Token Symbol'} filename='tokenSymbol' />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 8, offset: 4 }} lg={{ span: 12, offset: 0 }}>
                      <Form.Item
                        label=""
                        name="image"
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                        rules={[
                          {
                            required: false,
                            message: 'Please enter your token image!',
                          },
                        ]}
                      >
                        <BorderImageUpload crop={false} labelText={'Token Image'} filename='tokenImage' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[24, 8]}>
                <Col xs={24}>
                  <span style={this.props.titleStyle}>Token Metrics</span>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label=""
                    name="totalSupply"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your total supply!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'Total Supply'} size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={{ span: 8, offset: 0 }}>
                  <Form.Item
                    label=""
                    name="circulationSupply"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your circulation supply!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'Circulation Supply'} size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label=""
                    name="marketCap"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your market cap!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'Market Cap'} size='large' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 8]} className='margin-top-20'>
                <Col xs={24}>
                  <span style={this.props.titleStyle}>Market Metrics</span>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label=""
                    name="marketPrice"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your market price!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'Market price'} size='large' />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={{ span: 4, offset: 0 }}>
                  <Form.Item
                    label=""
                    name="atl"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your atl!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'ATL'} size='large' type='number' />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={{ span: 4, offset: 0 }}>
                  <Form.Item
                    label=""
                    name="ath"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your ath!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'ATH'} size='large' />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={4}>
                  <Form.Item
                    label=""
                    name="decimal"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your token decimal!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'Token Decimal'} size='large' />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={4}>
                  <Form.Item
                    label=""
                    name="tradeVolume"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter Last 24th Trade volume!',
                      },
                    ]}
                  >
                    <BorderTextInput labelText={'Last 24th Trade Volume'} size='large' />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={{ span: 6, offset: 18 }} align='right'>
                  {/* <Form.Item
                    label=""
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button htmlType='submit' block className="margin-top-10 colorful-button nowrap register-button">
                      Save
                    </Button>
                  </Form.Item> */}
                </Col>
              </Row>
              <Row gutter={[24, 24]} align='middle' className='margin-top-20'>
                <Col xs={24}>
                  <span style={this.props.titleStyle}>Chain Info / Contract</span>
                </Col>
                <Col xs={24} lg={8} >
                  <BorderTextSelectbox labelText={'Add a New Chain '} select={
                    <Select value={this.state.tags} onChange={(event) => this.setState({ chain: event })}
                      style={{ width: '100%' }} size='large' defaultValue={undefined}>
                      {socialMediaOptions}
                    </Select>
                  } />
                </Col>
                <Col xs={24} lg={8}>
                  <BorderTextInput onChange={(event) => this.setState({ contractAddress: event.target.value })}
                    labelText={'Add Contract'} size='large' />
                </Col>
                <Col xs={24} lg={{ span: 6, offset: 2 }}>
                  <Button onClick={this.handleAddAccount} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Add a new Contract
                  </Button>
                </Col>
              </Row>
              <Row className='margin-top-20'>
                <Col xs={24}>
                  <Table scroll={{ x: 800 }} size='small' className='ant-table-strip'
                    showHeader={false} pagination={false} dataSource={this.state.dataSource} columns={this.state.columns} />
                </Col>
              </Row>
              <Row className='margin-top-20'>
                <Col xs={{ span: 10 }} md={{ span: 4 }} align='left'>
                  <Button onClick={() => this.props.handleSteps(this.props.currentStep - 1)} block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Previus
                  </Button>
                </Col>
                <Col xs={{ span: 10, offset: 4 }} md={{ span: 4, offset: 16 }} align='right'>
                  <Button htmlType='submit' block="true" className="colorful-button nowrap" style={{ padding: '0px 0px' }}>
                    Save & Next
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        </Spin>
      </div>
    )
  }
}


const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(BlockchainInfo));
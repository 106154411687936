import React, {Component} from 'react';
import PromodexLayout from "../../components/layout/PromodexLayout";
import SettingsContent from "./SettingsContent";

class SettingsLayout extends Component {
    render() {
        return (
            <div>
                <PromodexLayout page="settings" content={<SettingsContent/>} />
            </div>
        );
    }
}

export default SettingsLayout;
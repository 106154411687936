import React, { Component } from 'react';
import { Button, Col, Row, Form, Checkbox, notification, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineUserAdd } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";
import PromoInput from "../../components/input/PromoInput";
import { signUp } from './api';
import { userAgreement } from '../../utils/Constants';


// This regex used to make the password strong
const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%.,:;+-/\^&\*])(?=.{8,})");

// "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*])(?=.{8,})"
// “^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 20}$”

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,

            loading: false
        }
    }

    handleRecaptcha = (value) => {
    };

    componentDidMount() {
        this.setState({ innerWidth: window.innerWidth })
        setInterval(() => {
            this.setState({ innerWidth: window.innerWidth })
        }, 500)

    }



    onFinish = (values) => {
        if (!values.email) return;
        this.setState({ loading: true })
        let data = new FormData();
        data.append("email", values.email)
        data.append("password1", values.password)
        data.append("password2", values.password)
        signUp(data).then(response => {
            this.setState({ loading: false })
            if (response.data.status === 200) {
                notification.success({
                    message: 'User Created',
                    description:
                        'User created, please check your email for confirmation. We are sending you to login page',
                });
                setTimeout(() => {
                    window.location.reload()
                },2000)
            }
        })
            .catch(error => {
                console.log('ERROR : ', error)
                notification.error({
                    message: 'Email already exist',
                    description:
                        'This email address already exist.',
                });
                return;
            })
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    handleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    render() {
        return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
            <Row>
                <Col sm={{ span: 18, offset: 6 }}>
                    <div className="box-shadow" style={{
                        backgroundColor: "fff",
                        margin: '0px 12px 10px 12px',
                        borderRadius: "0px 0px 20px 20px",
                    }}>
                        <Form onFinish={this.onFinish}
                            name="basic"
                            labelCol={{
                                span: 0,
                            }}
                            wrapperCol={{
                                span: 22,
                                offset: 1
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                            className="profile-promoInput-style">
                            <Form.Item
                                wrapperCol={{
                                    offset: 4,
                                    span: 20,
                                }}
                            >
                                <p className='register-title'>
                                    Register with your email address
                                </p>
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    lg: {
                                        span: 22,
                                        offset: 1
                                    }
                                }}
                                label=""
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Email address!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    }
                                ]}
                            >
                                <PromoInput labelFontSize={14} labelColorClass="login-input-label"
                                    isHorizontalLabel={false} labelText="Enter your Email"
                                    onChange={(event) => this.setState({ email: event.target.value })}
                                    suffixIcon={<AiOutlineUserAdd />} />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    lg: {
                                        span: 22,
                                        offset: 1
                                    }
                                }}
                                label=""
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && strongRegex.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Min 8 Char. Password must have at least one uppercase and lowercase (A-Z), one digit(0-9), one symbols(!,+,% etc.) !'));
                                        },
                                    }),
                                ]}
                            >
                                <PromoInput labelFontSize={14} labelColorClass="login-input-label"
                                    isHorizontalLabel={false} type={this.state.showPassword ? "text" : "password"} onChange={(event) => this.setState({ password: event.target.value })}
                                    labelText="Create a password" suffixIcon={this.state.showPassword ? <AiOutlineEye onClick={this.handleShowPassword} /> : <AiOutlineEyeInvisible onClick={this.handleShowPassword} />} />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    lg: {
                                        span: 22,
                                        offset: 1
                                    }
                                }}
                                label=""
                                name="passwordAgain"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <PromoInput labelFontSize={14} labelColorClass="login-input-label"
                                    isHorizontalLabel={false} type={this.state.showPassword ? "text" : "password"}
                                    labelText="Confirm password" suffixIcon={this.state.showPassword ? <AiOutlineEye onClick={this.handleShowPassword} /> : <AiOutlineEyeInvisible onClick={this.handleShowPassword} />} />
                            </Form.Item>
                            <Form.Item
                                name="agreeToPolicy"
                                valuePropName="checked"
                                wrapperCol={{
                                    offset: 1,
                                    span: 24,
                                }}
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error('You must accept Terms & Conditions')),
                                    },
                                ]}
                            >

                                <Checkbox>I agree the
                                    <a target='_blank' href={userAgreement} style={{ color: '#5340FF' }}>
                                        &nbsp;Terms & Conditions
                                    </a>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="googleRecaptcha"
                                valuePropName="checked"
                                wrapperCol={{
                                    span: 24,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please verify that you are not a robot!',
                                        fontSize: 12
                                    },
                                ]}
                            >
                                {/**for the testing purpose the site key is google test key.
                                                 When switching to the test environment, it should be replaced with the original key.
                                                 You can get that key via https://www.google.com/recaptcha/admin/create*/}
                                <ReCAPTCHA
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                    sitekey="6LcK-TgeAAAAAL8i69YNA_XtcuOrnxCv1gqxXyOi"
                                    onChange={this.handleRecaptcha}
                                />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    lg: {
                                        span: 22,
                                        offset: 1
                                    }
                                }}>
                                <Button block htmlType="submit" onClick={this.onFinish} className="margin-top-10 colorful-button nowrap register-button">
                                    Register Now
                                </Button>
                            </Form.Item>
                            <Row className="margin-top-20">
                                <Col xs={{ span: 24 }} align="middle">
                                    <p>Register with</p>
                                </Col>
                            </Row>
                            <Row gutter={[8, 0]}>
                                <Col xs={{ span: 6, offset: 1 }} style={{ marginBottom: '20px' }}>
                                    <Button
                                        type=""
                                        block
                                        icon={
                                            <img
                                                style={{ float: "left" }}
                                                src="https://img.icons8.com/color/24/000000/twitter.png"
                                            />
                                        }
                                        size="medium"
                                        style={{
                                            borderRadius: "10px",
                                            marginRight: "4px",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Twitter
                                    </Button>
                                </Col>
                                <Col xs={{ span: 6, offset: 1 }}>
                                    <Button
                                        type=""
                                        block
                                        icon={
                                            <img
                                                style={{ float: "left" }}
                                                src="https://img.icons8.com/color/24/000000/google-logo.png"
                                            />
                                        }
                                        size="medium"
                                        style={{
                                            borderRadius: "10px",
                                            marginRight: "4px",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        Google
                                    </Button>
                                </Col>
                                <Col xs={{ span: 7, offset: 1 }}>
                                    <Button
                                        type=""
                                        block
                                        icon={
                                            <img
                                                style={{ float: "left" }}
                                                src="https://img.icons8.com/color/24/000000/facebook-new.png"
                                            />
                                        }
                                        size="medium"
                                        style={{
                                            borderRadius: "10px",
                                            marginRight: "4px",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        Facebook
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Spin>;
    }
}

import React, {Component} from 'react';
import PromodexLayout from "../../components/layout/PromodexLayout";
import ReferralsContent from "./ReferralsContent";

class ReferralsLayout extends Component {
    render() {
        return (
            <div>
                <PromodexLayout page='referrals' content={<ReferralsContent/>}/>
            </div>
        );
    }
}

export default ReferralsLayout;
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../redux/promodex/actions";
import { Button, Col, Form, Row, notification, Spin, Modal, Image } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { AiOutlineUserAdd, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";
import PromoInput from "../../components/input/PromoInput";
import { logIn, logInWithFacebook, logInWithGoogle } from './api';
import { GoogleLogin } from 'react-google-login';
import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            isRecaptchaVerified: false,

            showPassword: false,

            loading: false,


            isComingSoonModalVisible: false,
        };
    }

    componentDidMount() {
    }

    errorGoogle = (error) => {
        console.log(error)
    }

    responseGoogle = (response) => {
        const token = response.getAuthResponse().id_token
        const payload = {
            auth_token: token
        }
        logInWithGoogle(payload).then(res => {
            if (res.data.status === 401) {
                notification.error({
                    message: 'Already registered',
                    description: res.data.message,
                });
                return;
            }

            const profile = res.data.profile[0][0]
            const access_token = res.data.tokens.access
            const refresh_token = res.data.tokens.refresh

            const user = res.data.user[0]
            user.profile = profile
            user.access_token = access_token
            user.refresh_token = refresh_token
            localStorage.setItem("user", JSON.stringify(user));
            const from = { pathname: "/" }
            this.props.history.push(from)
            // window.location.reload();

        }).catch(err => console.log(err))
    }




    responseFacebook = async (response) => {
        if(!response) return
        const payload = {
            auth_token: response.accessToken
        }
        if(response.accessToken){
            try{
                const createdUser = await logInWithFacebook(payload)
                if (createdUser.data.status === 401) {
                    notification.error({
                        message: 'Already registered',
                        description: createdUser.data.message,
                    });
                    return;
                }

                const profile = createdUser.data.profile[0][0]
                const access_token = createdUser.data.tokens.access
                const refresh_token = createdUser.data.tokens.refresh
    
                const user = createdUser.data.user[0]
                user.profile = profile
                user.access_token = access_token
                user.refresh_token = refresh_token
                
                localStorage.setItem("user", JSON.stringify(user));
                const from = { pathname: "/" }
                this.props.history.push(from)
            } catch(ex){
                console.log(ex)
            }
        }
    }







    handleRecaptcha = (value) => {
        this.setState({ isRecaptchaVerified: true })
    };

    handleContinue = async (values) => {
        if (!values.email) return;
        this.setState({ loading: true })
        // let data = new FormData();
        // data.append("email", values.email)
        // data.append("password", values.password)

        const payload = {
            email: values.email,
            password: values.password
        }

        logIn(payload).then(response => {
            this.setState({ loading: false })
            // if email is not verified
            if (response.data.status === 403) {
                notification.error({
                    message: 'Verification error',
                    description: 'Please confirm your e-mail',
                });
                return;
            }
            // if incorret email or password
            if (response.data.status === 404) {
                notification.error({
                    message: 'Incorrect',
                    description: 'Your e-mail or password is incorrect',
                });
                return;
            }
            localStorage.setItem("user", JSON.stringify(response.data));
            const from = { pathname: "/" }
            this.props.history.push(from)
            // window.location.reload();
        }).catch(error => {
            console.log(error)
        })
    };

    handleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    comingSoonModal = () => {
        this.setState({ isComingSoonModalVisible: !this.state.isComingSoonModalVisible })
    }


    render() {
        return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading} size="large" >
            <Modal
                className=''
                title={<h3 style={{ textAlign: "center", color: "#5340FF" }}>Coming Soon</h3>}
                visible={this.state.isComingSoonModalVisible}
                maskClosable={true}
                onCancel={this.comingSoonModal}
                maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }}
                footer={null}>
                <Row>
                    <Col xs={24} align='center' className='margin-y-40'>
                        <Image
                            style={{}}
                            width={100}
                            preview={false}
                            src={blueP}
                        /> <br />
                        <p style={{
                            textAlign: 'center',
                            fontSize: 24,
                            color: '#5340ff'
                        }}>
                            This feature cooming soon
                        </p>
                    </Col>
                </Row>
            </Modal>
            <Row>
                <Col xs={24} lg={{ span: 18, offset: 6 }}>
                    <div
                        className="box-shadow"
                        style={{
                            backgroundColor: "fff",
                            margin: '0px 12px 10px 12px',
                            borderRadius: "0px 0px 20px 20px",
                        }}
                    >
                        <Form onFinish={this.handleContinue}
                            name="basic"
                            labelCol={{
                                span: 0,
                            }}
                            wrapperCol={{
                                span: 22,
                                offset: 1
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                            className="profile-promoInput-style">
                            <Form.Item
                                wrapperCol={{
                                    offset: 7,
                                    span: 12,
                                }}
                            >
                                <p className='register-title'>
                                    Sign in to your account
                                </p>
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    lg: {
                                        span: 22,
                                        offset: 1
                                    }
                                }}
                                label=""
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Email address!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    }
                                ]}
                            >
                                <PromoInput labelFontSize={14} labelColorClass="promo-input-label"
                                    isHorizontalLabel={false}
                                    labelText="Enter your Email"
                                    value={this.state.email}
                                    onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                    }
                                    suffixIcon={<AiOutlineUserAdd />} />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    lg: {
                                        span: 22,
                                        offset: 1
                                    }
                                }}
                                label=""
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password!',
                                    },
                                ]}
                            >
                                <PromoInput labelFontSize={14} labelColorClass="promo-input-label"
                                    isHorizontalLabel={false} type={this.state.showPassword ? "text" : "password"}
                                    labelText="Password"
                                    value={this.state.password}
                                    onChange={(e) =>
                                        this.setState({ password: e.target.value })
                                    }
                                    suffixIcon={this.state.showPassword ? <AiOutlineEye onClick={this.handleShowPassword} /> : <AiOutlineEyeInvisible onClick={this.handleShowPassword} />} />
                            </Form.Item>
                            <Form.Item
                                name="googleRecaptcha"
                                valuePropName="checked"
                                wrapperCol={{
                                    span: 24,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please verify that you are not a robot!',
                                        fontSize: 12
                                    },
                                ]}
                            >
                                {/**for the testing purpose the site key is google test key.
                                                 When switching to the test environment, it should be replaced with the original key.
                                                 You can get that key via https://www.google.com/recaptcha/admin/create*/}
                                <ReCAPTCHA style={{ display: 'flex', justifyContent: 'center' }}
                                    sitekey="6LcK-TgeAAAAAL8i69YNA_XtcuOrnxCv1gqxXyOi"
                                    onChange={this.handleRecaptcha}
                                />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    lg: {
                                        span: 22,
                                        offset: 1
                                    }
                                }}>
                                <Button block={true} htmlType="submit" onClick={this.handleContinue} className="margin-top-10 colorful-button nowrap register-button">
                                    Sign In
                                </Button>
                                {/* a etiketi ile submit işlemi olmadığından dolayı görünmez bir butonu submit buton yapıp ref ile triggerlıyoruz. */}
                                {/* <Button ref={(ref) => this.submitButton = ref} style={{ display: 'none' }}></Button> */}
                            </Form.Item>
                            <Row className="margin-top-20">
                                <Col xs={{ span: 24 }} align="middle">
                                    <p>Sign in with</p>
                                </Col>
                            </Row>
                            <Row gutter={[8, 0]}>
                                <Col xs={{ span: 6, offset: 1 }} style={{ marginBottom: '20px' }}>
                                    <Button
                                        type=""
                                        block
                                        icon={
                                            <img
                                                style={{ float: "left" }}
                                                src="https://img.icons8.com/color/24/000000/twitter.png"
                                            />
                                        }
                                        size="medium"
                                        style={{
                                            borderRadius: "10px",
                                            marginRight: "4px",
                                            marginLeft: "10px",
                                        }}
                                        onClick={this.comingSoonModal}
                                    >
                                        Twitter
                                    </Button>
                                </Col>
                                <Col xs={{ span: 6, offset: 1 }}>
                                    <GoogleLogin
                                        clientId="924603876555-thqevpbu6gsh77njurudmuvj4s500388.apps.googleusercontent.com"
                                        render={renderProps => (
                                            <Button
                                                disabled={renderProps.disabled}
                                                type=""
                                                block
                                                icon={
                                                    <img
                                                        style={{ float: "left" }}
                                                        src="https://img.icons8.com/color/24/000000/google-logo.png"
                                                    />
                                                }
                                                size="medium"
                                                style={{
                                                    borderRadius: "10px",
                                                    marginRight: "4px",
                                                    marginLeft: "4px",
                                                }}
                                                onClick={renderProps.onClick}
                                            // onClick={this.comingSoonModal}
                                            >
                                                Google
                                            </Button>
                                        )}
                                        buttonText="Login"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.errorGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </Col>
                                <Col xs={{ span: 7, offset: 1 }}>
                                    <FacebookLogin
                                        appId="959170381421781"
                                        callback={this.responseFacebook}
                                        render={renderProps => (
                                            <Button
                                                type=""
                                                block
                                                icon={
                                                    <img
                                                        style={{ float: "left" }}
                                                        src="https://img.icons8.com/color/24/000000/facebook-new.png"
                                                    />
                                                }
                                                size="medium"
                                                style={{
                                                    borderRadius: "10px",
                                                    marginRight: "4px",
                                                    marginLeft: "4px",
                                                }}
                                                onClick={renderProps.onClick}
                                                // onClick={this.comingSoonModal}
                                            >
                                                Facebook
                                            </Button>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} align="center" className="margin-top-20">
                                    <p onClick={() => {
                                        this.props.setPromodexRedux({ forgotPassword: true })
                                    }} style={{ paddingBottom: "20px", color: '#5340FF', cursor: 'pointer' }}>Forgot password?</p>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Spin>;
    }
}

const mapState = (globalState) => {
    return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(Login));
import { Row, Col, Button, Image, Divider } from 'antd';
import React, { Component } from 'react';
import { BsWallet2 } from "react-icons/bs";
import { FiLayers } from "react-icons/fi";
import { VscVerified } from "react-icons/vsc";
import { IoDiamondOutline } from "react-icons/io5";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../redux/promodex/actions";
import whiteP from "../../assets/images/logo/promodex_white_background.png"
import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"
import ImageWithIcon from "../../components/profile/ImageWithIcon";

import facebook from "../../assets/images/social-media-icons/facebook.png"
import instagram from "../../assets/images/social-media-icons/instagram.png"
import twitter from "../../assets/images/social-media-icons/twitter.png"

import InvestorCards from './InvestorCards';
import { EyeImage, EyeInvisibleImage, HideSummary, WalletBlack, Dolar, AllTokenSales } from '../../components/layout/Icons';

import { CheckNetwork } from '../../web3/utils/CheckNetwork';

import { getIdos, getReport } from "./api"
const EyeIcon = EyeImage()
const EyeInvisibleIcon = EyeInvisibleImage()
const WalletBlackIcon = WalletBlack()
const DolarIcon = Dolar()
const AllTokenSalesIcon = AllTokenSales()


class InvestorContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSummary: false,
            fadingOut: false,
            idoData: null
        }
    }


    getIdos = async () => {
        const idos = await getIdos()
        this.setState({ idoData: idos })
    }
    async componentDidMount() {
        this.getIdos()
        // this.getReport()
        setTimeout(() => {
            this.setState({ fadingOut: true })
        }, 1)
    }

    getReport = async () => {
        const temp = []
        for(let i = 1; i<6;i++){
            const report = await getReport(i)
            temp.push(report)
        }
        this.setState({idoReport: temp})

    }




    handleShowSummary = () => {
        this.setState({ showSummary: !this.state.showSummary })
    }


    render() {
        const HideSummaryButton = HideSummary(this.state.showSummary ? EyeInvisibleIcon : EyeIcon, this.state.showSummary ? 'Hide' : 'Show')
        return <div>
            <Row>
                <Col xs={24} lg={{span: 8, offset: 1}} style={{marginTop:10}} className={this.state.showSummary ? 'shadow-white-div summary-collapse' : 'shadow-white-div summary-collapse summary-close'}>
                    <Row>
                        <Col xs={12}>
                            <p className="investor-title"><span className='icon-inline'>{WalletBlackIcon}</span> My Wallet </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={12} lg={12}>
                            <Row>
                                <Col xs={11}>
                                    <small className="promodex-grey-title"
                                        style={{ fontSize: 12 }}>
                                        Promo in Wallet
                                    </small>
                                </Col>
                                <Col xs={12} align="center">
                                    <Link to="/investor" className="linkMenu" style={{ fontSize: 12 }}>
                                        Buy PROMO
                                    </Link>

                                </Col>
                                <Col xs={22}>
                                    <Row style={{ backgroundColor: '#5340ff', borderRadius: '10px' }} align='center'>
                                        <Col xs={2} md={4} lg={4}>
                                            <Image
                                                style={{ marginLeft: '-20px', marginTop: 4 }}
                                                width={50}
                                                preview={false}
                                                src={whiteP}
                                            />
                                        </Col>
                                        <Col xs={8} style={{ paddingTop: '10px' }}>
                                            <h3 style={{ color: '#ffffff', textAlign: 'left', fontSize: 12 }}>{this.props.promodexInformation?.promoBalance}</h3>
                                        </Col>
                                        <Col xs={8} style={{ paddingTop: '10px' }}>
                                            <h4 style={{ color: '#ffffff', textAlign: 'center', fontSize: 12 }}>Promo</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                                <Col xs={11}>
                                    <small className="promodex-grey-title"
                                        style={{ fontSize: 12 }}>
                                        Promo Staked
                                    </small>
                                </Col>
                                <Col xs={12} align="center">
                                    <Link to="/stakepromo" className="linkMenu" style={{ fontSize: 12 }}>
                                        Stake PROMO
                                    </Link>

                                </Col>
                                <Col xs={22}>
                                    <Row style={{
                                        backgroundColor: '#fffff',
                                        border: '1px solid #e2e2e8',
                                        borderRadius: '10px'
                                    }} align='center'>
                                        <Col xs={2} md={4} lg={4}>
                                            <Image
                                                style={{ marginLeft: '-20px', marginTop: 4 }}
                                                width={35}
                                                preview={false}
                                                src={blueP}
                                            />
                                        </Col>
                                        <Col xs={8} style={{ paddingTop: '10px' }}>
                                            <h3 style={{ color: '#9292a1', textAlign: 'left', fontSize: 14 }}>Coming  </h3>
                                        </Col>
                                        <Col xs={8} style={{ paddingTop: '10px' }}>
                                            <h3 style={{ color: '#9292a1', textAlign: 'center', fontSize: 14 }}>Soon</h3>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={12} sm={12}>
                            <Row>
                                <Col xs={24}>
                                    <small className="promodex-grey-title"
                                        style={{ fontSize: 12 }}>
                                        Total Promo Value
                                    </small>
                                </Col>
                                <Col xs={22}>
                                    <div style={{ border: '1px solid rgba(40,61,92,0.25)', borderRadius: '9.5px', padding: '10px 10px' }}>
                                        <Row>
                                            <Col sm={{ span: 17, offset: 5 }}>
                                                {/* <Button href={`investor`} className="connect-wallet-btn" size="small" block>
                                                    Buy PROMO
                                                </Button> */}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{ span: 4, offset: 1 }}>
                                                {DolarIcon}
                                            </Col>
                                            <Col sm={{ span: 18, offset: 0 }}>
                                                <p style={{ fontSize: 18, color: '#283D5C', textAlign: 'center' }}>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{ span: 17, offset: 5 }}>
                                                <Button href='/stakepromo' className="connect-wallet-btn" size="small" block>
                                                    Stake PROMO
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col sm={24} lg={{ span: 7, offset: 1 }} style={{marginTop:10}} className={this.state.showSummary ? 'shadow-white-div summary-collapse' : 'shadow-white-div summary-collapse summary-close'}>
                    <Row gutter={[8, 16]} className="margin-top-10">
                        <Col sm={{ span: 11 }}>
                            <Row>
                                <Col xs={24} align='middle'>
                                    <p className="investor-title"><span className='icon-inline'>{AllTokenSalesIcon}</span> All Token Sales </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} align='middle'>
                                    <Row>
                                        <Col xs={{ span: 12, offset: 0 }} align='left'>
                                            <h4>Live:</h4>
                                        </Col>
                                        <Col xs={{ span: 2, offset: 1 }} align='right'>
                                            <h4 className='p-font-color'>5</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} align='middle'>
                                    <Row>
                                        <Col xs={{ span: 12, offset: 0 }} align='left'>
                                            <h4>Upcoming:</h4>
                                        </Col>
                                        <Col xs={{ span: 2, offset: 1 }} align='right'>
                                            <h4 className='p-font-color'>7</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} align='middle'>
                                    <Row>
                                        <Col xs={{ span: 12, offset: 0 }} align='left'>
                                            <h4>Ended:</h4>
                                        </Col>
                                        <Col xs={{ span: 2, offset: 1 }} align='right'>
                                            <h4 className='p-font-color'>36</h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1}>
                            <Divider type="vertical" style={{ borderColor: 'rgba(40,61,92,0.10)', height: '6em', boxShadow: '-2px 0 8px 1px rgba(40,61,92,0.10)' }} />
                        </Col>
                        <Col sm={{ span: 11, offset: 1 }}>
                            <Row>
                                <Col xs={24} align='middle'>
                                    <p className="investor-title"><span className='icon-inline'>{AllTokenSalesIcon}</span> Your Participations </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} align='middle'>
                                    <Row>
                                        <Col xs={{ span: 12, offset: 0 }} align='left'>
                                            <h4>Public sale:</h4>
                                        </Col>
                                        <Col xs={{ span: 2, offset: 1 }} align='right'>
                                            <h4 className='p-font-color'>2</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} align='middle'>
                                    <Row>
                                        <Col xs={{ span: 12, offset: 0 }} align='left'>
                                            <h4>Private sale:</h4>
                                        </Col>
                                        <Col xs={{ span: 2, offset: 1 }} align='right'>
                                            <h4 className='p-font-color'>1</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col xs={24} align='middle'>
                                    <Row>
                                        <Col xs={{ span: 12, offset: 0 }} align='left'>
                                            <h4>Presale:</h4>
                                        </Col>
                                        <Col xs={{ span: 2, offset: 1 }} align='right'>
                                            <h4 className='p-font-color'>1</h4>
                                        </Col>
                                    </Row>
                                </Col> */}
                                <Col sm={{ span: 6, offset: 0 }}>
                                    <Button className="margin-top-10 connect-wallet-btn sm-btn" size="small">
                                        See your participations
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }} style={{marginTop:10}}
                    className={this.state.showSummary ? 'shadow-white-div marginless-bottom summary-collapse' : 'shadow-white-div marginless-bottom summary-collapse summary-close'}>
                    {this.state.fadingOut &&
                        <div style={{
                            position: 'absolute',
                            top: '40%',
                            left: '10%',
                            textAlign: 'center',
                            fontSize: 24,
                            zIndex: 20,
                            color: '#5340ff'
                        }}>
                            COMING SOON...
                        </div>
                    }
                    <div style={this.state.fadingOut ? { filter: 'blur(4px)', transition: 'filter 4s', pointerEvents: 'none', userSelect: 'none' } : undefined}>

                        <Row>
                            <Col xs={24} align='middle'>
                                <h4 className="promodex-grey-title"><FiLayers className="vertical-align-text-bottom"
                                    size={20} /> My Status</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <small className='p-font-bold'>My KYC Status</small>
                                <h5 className='p-font-bold' style={{
                                    color: '#ffffff',
                                    backgroundColor: '#59cbff',
                                    borderRadius: '6px',
                                    textAlign: 'center',
                                }}>VERIFIED <VscVerified size={20} className="vertical-align-text-bottom" /></h5>
                            </Col>
                            <Col lg={10} push={1} style={{ marginTop: '14px' }}>
                                {/* <Link to="/dashboard" className="linkMenu " style={{ fontSize: 12 }}>
                                Check now
                            </Link> */}
                                <Button className="connect-wallet-btn sm-btn pr-16" size="small">
                                    Check now
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <small className='p-font-bold'>My TIER LEVEL</small>
                                <h5 className='p-font-bold' style={{
                                    color: '#ffffff',
                                    backgroundColor: '#ff6565',
                                    borderRadius: '6px',
                                    textAlign: 'center',
                                }}>PREMIUM <IoDiamondOutline size={20} className="vertical-align-text-bottom" /></h5>
                            </Col>

                            <Col lg={10} push={1} style={{ marginTop: '14px' }}>
                                {/* <Link to="/dashboard" className="linkMenu" style={{ fontSize: 12 }}>
                                Upgrade now
                            </Link> */}
                                <Button className="connect-wallet-btn sm-btn" size="small">
                                    Upgrade now
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <small className='p-font-bold'>My Loyalty Score</small>
                                <h5 className='p-font-bold' style={{
                                    color: '#ffffff',
                                    backgroundColor: '#f7b500',
                                    borderRadius: '6px',
                                    textAlign: 'center',
                                }}>24/100</h5>
                            </Col>

                            <Col lg={10} push={1} style={{ marginTop: '14px' }}>
                                {/* <Link to="/dashboard" className="linkMenu" style={{ fontSize: 12 }}>
                                Upgrade now
                            </Link> */}
                                <Button className="connect-wallet-btn sm-btn" size="small">
                                    Upgrade now
                                </Button>
                            </Col>
                        </Row>
                        {/* <Row>
                        <Col xs={24} align='middle'>
                            <Link to="/profile">
                                <h4 className="p-font-color p-font-bold text-underline clickable-text"> See
                                    Profile Details</h4>
                            </Link>
                        </Col>
                    </Row> */}
                    </div>
                </Col>
                <Col xs={{ span: 23, offset: 0 }} align='right'>
                    <div className='hide-summary-btn' onClick={this.handleShowSummary}>
                        {HideSummaryButton}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 24 }} lg={{span:20, offset:2}} className='shadow-white-div margin-top-20'>
                    {this.state.idoData &&
                        <InvestorCards data={this.state.idoData} />
                    }
                </Col>
            </Row>

        </div>;
    }
}
const mapState = (globalState) => {
    return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(InvestorContent));
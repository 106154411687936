import { publicApi, protectedApi } from '../../services/axios'

export const getLinkTypes = () => {
  return protectedApi.get(`management/linktypes`)
    .then((response) => {
      return response.data.results;
    })
    .catch((error) => {
      return error;
    });
}

export const getSocialMedias = () => {
  return protectedApi.get(`management/socialmedias`)
    .then((response) => {
      return response.data.results;
    })
    .catch((error) => {
      return error;
    });
}

export const getChannelTypes = () => {
  return protectedApi.get(`management/channeltypes`)
    .then((response) => {
      return response.data.results;
    })
    .catch((error) => {
      return error;
    });
}
export const getChannelTags = () => {
  return protectedApi.get(`management/channeltags`)
    .then((response) => {
      return response.data.results;
    })
    .catch((error) => {
      return error;
    });
}
export const getChains = () => {
  return protectedApi.get(`management/chains`)
    .then((response) => {
      return response.data.results;
    })
    .catch((error) => {
      return error;
    });
}

export const getProjectTypes = async () => {
  try {
    const response = await protectedApi.get(`management/projecttypes`);
    return response.data.results;
  } catch (error) {
    return error;
  }
}

export const fileUpload = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/fileuploads`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}

export const createProject = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/projects`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getProjectLinks = async (projectId) => {
  // This will get links by project id
  try {
    const response = await protectedApi.get(`management/projectlinks?projectId=${projectId}`);
    return response.data.results;
  } catch (error) {
    return error;
  }
}
export const createProjectLink = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/projectlinks`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}
export const deleteProjectLink = async (id) => {
  try {
    const response = await protectedApi.delete(`management/projectlink/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
}


export const getProjectSocialMedias = async (projectId) => {
  // This will get socail medias by project id
  try {
    const response = await protectedApi.get(`management/socialmedialinks?projectId=${projectId}`);
    return response.data.results;
  } catch (error) {
    return error;
  }
}
export const createProjectSocialMedia = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/socialmedialinks`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}
export const deleteProjectSocialMedia = async (id) => {
  try {
    const response = await protectedApi.delete(`management/socialmedialink/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
}


export const getProjectCommunityChannels = async (projectId) => {
  // This will get community channels by project id
  try {
    const response = await protectedApi.get(`management/communitychannels?projectId=${projectId}`);
    return response.data.results;
  } catch (error) {
    return error;
  }
}
export const createProjectCommunityChannel = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/communitychannels`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}
export const deleteProjectCommunityChannel = async (id) => {
  try {
    const response = await protectedApi.delete(`management/communitychannel/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
}


export const getProjectChains = async (projectId) => {
  // This will get chains by project id
  try {
    const response = await protectedApi.get(`management/projectchains?projectId=${projectId}`);
    return response.data.results;
  } catch (error) {
    return error;
  }
}
export const createProjectChain = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/projectchains`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}
export const deleteProjectChain = async (id) => {
  try {
    const response = await protectedApi.delete(`management/projectchain/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
}

export const createProjectTokenDetail = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/projecttokendetails`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}


export const createProjectContent = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/projectcontents`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}
export const getProjectContents = async (projectId) => {
  // This will get chains by project id
  try {
    const response = await protectedApi.get(`management/projectcontents?projectId=${projectId}`);
    return response.data.results;
  } catch (error) {
    return error;
  }
}



export const createProjectTeamMember = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/projectteams`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
}
export const getProjectTeamMembers = async (projectId) => {
  // This will get chains by project id
  try {
    const response = await protectedApi.get(`management/projectteams?projectId=${projectId}`);
    return response.data.results;
  } catch (error) {
    return error;
  }
}
export const deleteProjectTeamMember = async (id) => {
  try {
    const response = await protectedApi.delete(`management/projectteam/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
}
import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Select, Button, Form } from 'antd';
import BorderTextInput from '../../../components/input/BorderTextInput';
import BorderImageUpload from '../../../components/input/BorderImageUpload';
import BorderTextSelectbox from '../../../components/input/BorderTextSelectbox';
import BorderTextTextarea from '../../../components/input/BorderTextTextarea';

import TextCard from '../../../components/card/TextCard';
import { createProject, getProjectTypes } from '../api';

const { Option } = Select;

class ProjectInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      projectFields: [],
      projectTags: [],
      projectTypes: []
    }

  }

  getProjectTypes = async () => {
    try {
      const projectTypes = await getProjectTypes()
      this.setState({projectTypes: projectTypes})
    }catch(ex) {
      console.log('Project Types Error : ', ex)
    }
  }

  componentDidMount() {
    this.getProjectTypes()

    this.props.setPromodexRedux({ projectIcon: null })
    this.props.setPromodexRedux({ projectLogo: null })
    this.props.setPromodexRedux({ projectThumbnail: null })
  }


  handleTags = (event) => {
    if (event?.length >= 6) {
      event.pop();
      this.props.notification('warning', 'MAX 5', 'You can add max 5 tags')
    }
    this.setState({ tags: event })
  }

  handleInputChange = (name, event) => {
    this.setState({ [name]: event.target.value })
  }
  handleSelectChange = (name, event) => {
    this.setState({ [name]: event })
  }

  handleMultipleInputChange = (name, event) => {
    // if (!event.target.value.includes(',')) return

    const textArray = event.target.value.split(',').map(data => data.trim()).filter(data => data !== "")

    this.setState({ [name]: textArray })
  }


  handleSave = async (value) => {
    if (!value.name) return;

    value.logo = this.props.promodexInformation.projectLogo
    value.icon = this.props.promodexInformation.projectIcon
    value.thumbnail = this.props.promodexInformation.projectThumbnail

    let data = new FormData()

    data.append('name', value.name)
    if (value.logo !== null){
      data.append('logo', value.logo)
    }
    if (value.icon !== null){
      data.append('icon', value.icon)
    }
    if (value.thumbnail !== null){
      data.append('thumbnail', value.thumbnail)
    }
    data.append('tags', this.state.projectTags)
    data.append('interests', this.state.projectFields)
    data.append('title', value.title)
    data.append('description', value.description)
    data.append('projectType', value.category)
    try {
      const createProject_ = await createProject(data)

      this.props.setPromodexRedux({ project: createProject_ })
      this.props.handleSteps(1)

    } catch (error) {
      console.log('ERROR : ', error)
    }
  }

  render() {

    const interestCards = this.state?.projectFields?.map((field, index) => {
      return (
        <Col key={index}>
          <TextCard context={field} />
        </Col>
      )
    })

    const tagCards = this.state?.projectTags?.map((tag, index) => {
      return (
        <Col key={index}>
          <TextCard context={tag} />
        </Col>
      )
    })


    const projectTypeOptions = this.state.projectTypes.map((type, index) => {
      return(
        <Option key={index} value={type.id}>{type.name}</Option>
      )
    })



    return (
      <div style={this.props.style}>
        <Form onFinish={this.handleSave}
          name="basic"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 22,
            offset: 1
          }}
          initialValues={{
            remember: true,
          }}
          // onFinishFailed={this.onFinishFailed}
          autoComplete="off">

          <Row className='margin-top-20'>
            <Col xs={24} className='shadow-white-div'>
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <p style={this.props.titleStyle}>Project / Brand</p>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label=""
                    name="name"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your project name!',
                      },
                    ]}
                  >
                    <BorderTextInput onChange={(event) => this.handleInputChange('projectName', event)} labelText={'Project / Brand'} size='large' />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Row>
                    <Col xs={{ span: 8, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                      <Form.Item
                        label=""
                        name="icon"
                        rules={[
                          {
                            required: false,
                            message: 'Please upload your icon!',
                          },
                        ]}
                      >
                        <BorderImageUpload labelText={'Icon'} filename='projectIcon' />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 8, offset: 4 }} lg={{ span: 12, offset: 0 }}>
                      <Form.Item
                        label=""
                        name="logo"
                        rules={[
                          {
                            required: false,
                            message: 'Please upload your logo!',
                          },
                        ]}
                      >
                        <BorderImageUpload labelText={'Logo'} filename='projectLogo' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={{ span: 8, offset: 0 }}>
                  <Form.Item
                    label=""
                    name="thumbnail"
                    rules={[
                      {
                        required: false,
                        message: 'Please upload your thumbnail!',
                      },
                    ]}
                  >
                    <BorderImageUpload labelText={'Thumbnail'} filename='projectThumbnail' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <p style={this.props.titleStyle}>Classifications</p>
                </Col>
                <Col xs={24} lg={8}>
                  <BorderTextSelectbox labelText={'Category'} select={
                    <Form.Item
                      label=""
                      name="category"
                      wrapperCol={{
                        offset: 0,
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select your category!',
                        },
                      ]}
                    >

                      <Select onChange={(event) => this.handleSelectChange('projectCategory', event)}
                        style={{ width: '100%' }} size='large' defaultValue={undefined}>
                          {projectTypeOptions}
                      </Select>
                    </Form.Item>} />
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label=""
                    name="interests"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please select your fields/interests!',
                      },
                    ]}
                  >
                    <BorderTextInput onChange={(event) => this.handleMultipleInputChange('projectFields', event)}
                      labelText={'Field / Interest'} size='large' placeholder={'split your fields with comma e.g: promo,bnb,ethereum'} />
                  </Form.Item>
                  <Row>
                    {interestCards}
                  </Row>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label=""
                    name="tags"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please select your tags!',
                      },
                    ]}
                  >
                    <BorderTextInput onChange={(event) => this.handleMultipleInputChange('projectTags', event)}
                      labelText={'Tags'} size='large' placeholder={'split your tags with comma e.g: #Marketing,#Bsc,#Ads'} />
                  </Form.Item>
                  <Row>
                    {tagCards}
                  </Row>
                </Col>
              </Row>
              <Row className='margin-top-20'>
                <Col xs={24}>
                  <Form.Item
                    label=""
                    name="title"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your short description!',
                      },
                    ]}
                  >
                    <BorderTextInput onChange={(event) => this.handleInputChange('projectTitle', event)} labelText='Short Description' maxLength={50} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='margin-y-20'>
                <Col xs={24}>
                  <Form.Item
                    label=""
                    name="description"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter your brief description!',
                      },
                    ]}
                  >
                    <BorderTextTextarea onChange={(event)=> this.handleInputChange('projectDescription', event)} labelText='Brief Description' maxLength={250} showCount={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={{ span: 6, offset: 18 }} align='right'>
                  <Form.Item
                    label=""
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button htmlType='submit' block className="margin-top-10 colorful-button nowrap register-button">
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}



const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(ProjectInformation));
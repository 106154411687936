import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../../redux/promodex/actions";
import { Row, Col, Image } from "antd";
import { IoDiamondOutline } from "react-icons/io5";

import whiteP from "../../../assets/images/logo/promodex_white_background.png"
import blueP from "../../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"


import { WalletBlack } from '../../../components/layout/Icons';
const WalletBlackIcon = WalletBlack()


class MyWallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }


  render() {
    return (
      <Col xs={24} lg={{ span: 7, offset: 1 }} style={{marginTop:10}} className={this.props.showSummary ? 'shadow-white-div summary-collapse' : 'shadow-white-div summary-collapse summary-close'}>
        <Row>
          <Col xs={24} lg={12}>
            <p className="investor-title"><span className='icon-inline'>{WalletBlackIcon}</span> My Wallet </p>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12} lg={12}>
            <Row>
              <Col xs={24} lg={11}>
                <small className="promodex-grey-title"
                  style={{ fontSize: 12, fontWeight: 100 }}>
                  Promo in Wallet
                </small>
              </Col>
              <Col xs={24} lg={22}>
                <Row style={{ backgroundColor: '#5340ff', borderRadius: '10px' }} align='center'>
                  <Col xs={2} md={4} lg={4}>
                    <Image
                      style={{ marginLeft: '-20px', marginTop: 4 }}
                      width={50}
                      preview={false}
                      src={whiteP}
                    />
                  </Col>
                  <Col xs={8} style={{ paddingTop: '10px' }}>
                    <h3 style={{ color: '#ffffff', textAlign: 'left' }}>10.000</h3>
                  </Col>
                  <Col xs={8} style={{ paddingTop: '10px' }}>
                    <h4 style={{ color: '#ffffff', textAlign: 'center' }}>Promo</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col xs={24} lg={11}>
                <small className="promodex-grey-title"
                  style={{ fontSize: 12, fontWeight: 100 }}>
                  Promo Staked
                </small>
              </Col>
              <Col xs={24} lg={22}>
                <Row style={{
                  backgroundColor: '#fffff',
                  border: '1px solid #e2e2e8',
                  borderRadius: '10px'
                }} align='center'>
                  <Col xs={2} md={4} lg={4}>
                    <Image
                      style={{ marginLeft: '-20px', marginTop: 4 }}
                      width={35}
                      preview={false}
                      src={blueP}
                    />
                  </Col>
                  <Col xs={8} style={{ paddingTop: '10px' }}>
                    <h3 style={{ color: '#9292a1', textAlign: 'left' }}>10.000</h3>
                  </Col>
                  <Col xs={8} style={{ paddingTop: '10px' }}>
                    <h4 style={{ color: '#9292a1', textAlign: 'center' }}>Promo</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={12}>
            <Row>
              <Col xs={24}>
                <small className="promodex-grey-title"
                  style={{ fontSize: 12, fontWeight: 100 }}>
                  Tier Status
                </small>
              </Col>
              <Col xs={24}>
                <div style={{ border: '1px solid rgba(40,61,92,0.25)', borderRadius: '9.5px', padding: '10px 10px' }}>
                  <Row>
                    <Col xs={{ span: 24, offset: 0 }} align='center'>
                      <p style={{ fontWeight: 300, color: '#000659', fontSize: 12 }}>Tier Score
                        <span style={{ color: '#283D5C', fontWeight: 100 }}> 10.500</span></p>
                    </Col>
                    <Col xs={{ span: 16, offset: 4 }} align='center'>
                      <p className='p-font-bold' style={{
                        color: '#ffffff',
                        backgroundColor: '#FE005E',
                        borderRadius: '6px',
                        textAlign: 'center',
                      }}>PREMIUM <IoDiamondOutline size={20} className="vertical-align-text-bottom" /></p>
                      <a target='_blank' style={{ fontSize: 12, color: '#5340FF', fontWeight: 100 }} href=''>
                        How to upgrade
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }
}

const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(MyWallet));

import React from "react";
import "antd/dist/antd.css";
import "../src/assets/css/styles.css"
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import DashboardLayout from "./pages/dashboard/DashboardLayout";
import ActivityLayout from "./pages/activity/ActivityLayout";
import PromodexLayout from "./components/layout/PromodexLayout";
import WalletLayout from "./pages/wallet/WalletLayout";
import StatisticsLayout from "./pages/statistics/StatisticsLayout";
import ProfileLayout from "./pages/profile/ProfileLayout";
import ReferralsLayout from "./pages/referrals/ReferralsLayout";
import StakePromoLayout from "./pages/stakePromo/StakePromoLayout";
import SettingsLayout from "./pages/settings/SettingsLayout";
import UnknownLayout from "./pages/UnknownPage/UnknownLayout";
import CreatePagesLayout from "./pages/CreatePages/CreatePagesLayout";
import PrivateRoute from "./routes/PrivateRoutes.js";
import Page404 from "./pages/404/404.js";
import LandingPageLayout from "./pages/landingPage/LandingPageLayout.js";
import LoginRegisterLayout from "./pages/login/LoginRegisterLayout.js";
import ChangePassword from "./pages/login/ChangePassword.js";
import InvestorLayout from "./pages/investor/InvestorLayout";
import ProjectPageLayout from "./pages/project/ProjectPageLayout";
import ProjectSalePageLayout from "./pages/projectSalePage/ProjectSalePageLayout";
import ScrollToTop from "./routes/ScrollToTop";

import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import CreateProjectLayout from "./pages/createProject/CreateProjectLayout";

const getLibrary = provider => {
  return new Web3(provider)
}

const routes = (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={configureStore()}>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={LandingPageLayout} />
            <Route exact path="/login" component={LoginRegisterLayout} />
            <Route exact path="/register" component={LoginRegisterLayout} />
            <Route exact path="/authentication/reset/:uid/:token" component={ChangePassword} />
            <Route exact path="/investor" component={InvestorLayout} />
            <Route exact path="/project" component={ProjectPageLayout} />
            <Route exact path="/sale" component={ProjectSalePageLayout} />
            <Route exact path="/stakepromo" component={StakePromoLayout} />
            <Route exact path="/createproject" component={CreateProjectLayout} />
            {/* <PrivateRoute exact path="/dashboard" component={DashboardLayout} />
        <PrivateRoute exact path="/activity" component={ActivityLayout} />
        <PrivateRoute exact path="/wallet" component={WalletLayout} />
        <PrivateRoute exact path="/statistics" component={StatisticsLayout} />
        <PrivateRoute exact path="/profile" component={ProfileLayout} />
        <PrivateRoute exact path="/referrals" component={ReferralsLayout} />
        <PrivateRoute exact path="/settings" component={SettingsLayout} />
        <PrivateRoute exact path="/promo" component={PromodexLayout} />
        <PrivateRoute exact path="/unknown" component={UnknownLayout} />
        <PrivateRoute exact path="/create" component={CreatePagesLayout} /> */}
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>

        </ScrollToTop>
      </BrowserRouter>
    </Provider>

  </Web3ReactProvider>
);
ReactDOM.render(routes, document.querySelector("#root"));

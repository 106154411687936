import React, { Component } from 'react';
import PromodexLayout from '../../components/layout/PromodexLayout';
import ProjectPageContent from './ProjectPageContent';

export default class ProjectPageLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            innerWidth:0
        }
    }

    componentDidMount() {
        this.setState({innerWidth: window.innerWidth})
        setInterval(() => {
            this.setState({innerWidth: window.innerWidth})
        }, 500)
    }
  render() {
    return <PromodexLayout page='projectPage' content={<ProjectPageContent innerWidth={this.state.innerWidth} />} />;
  }
}

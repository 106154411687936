import {publicApi, protectedApi} from '../../services/axios'


export const getProject = (id) => {
  return publicApi.get(`/management/projectallinfo/${id}`)
  .then((response) => {
      return response;
  })
  .catch((error) => {
      return error;
  });
}
import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../../../../redux/promodex/actions";
import { Button, Col, Row, Input, Select, notification } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";

import { WalletBlack, PromoToken, BinanceToken } from '../../../../../components/layout/Icons';
const WalletBlackIcon = WalletBlack()
const PromoTokenIcon = PromoToken()
const BinanceTokenIcon = BinanceToken()

class Unstaking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authorizeContract: 0,
    }
  }


  handleConfirmStaking = () => {
    if (this.state.authorizeContract !== 1) {
      notification.open({
        message: 'Warning!',
        description: this.state.authorizeContract > 1 ?
          'You already confirmed your stake.' : 'You must Authorize your contract first',
        duration: 3,
      });
    } else {
      this.setState({ authorizeContract: 2 })
    }
  }
  handleAuthorizeContract = () => {
    if (this.state.authorizeContract !== 0) {
      notification.open({
        message: 'Warning!',
        description: this.state.authorizeContract >= 1 ?
          'You already Authorized your contract.' : '',
        duration: 3,
      });

      return
    } else {
      this.setState({ authorizeContract: 1 })
    }
  }
  render() {

    const RedCircleCrossmarkIcon = this.props.RedCircleCrossmarkIcon
    const GreenCircleCheckmarkIconSmall = this.props.GreenCircleCheckmarkIconSmall
    const BottomButtons = this.props.BottomButtons

    return (
      <div>
        <Row gutter={[16, 16]}>
          <Col sm={24} md={12}>
            {/* <p>Please check if all the requirement are completed</p> */}
            <div className='stake-promo-steps-div'>
              <Row style={{
                borderBottom: '1px solid rgba(40,61,92,0.50)',
                padding: '4px 4px'
              }}>
                <Col sm={24} align='center'>
                  <p style={{ fontWeight: 700 }}>Your status</p>
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 24 }} align='center'>
                  <br />
                  <Row gutter={[24, 24]} className='margin-y-20'>
                    <Col sm={{ span: 8, offset: 6 }} align='left'>
                      <p style={{ fontWeight: 100, marginLeft: 10 }}><span className='icon-inline'>{WalletBlackIcon}</span> Wallet connection </p>
                    </Col>
                    <Col sm={8} align='left'>
                      {GreenCircleCheckmarkIconSmall}
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} className='margin-y-20'>
                    <Col sm={{ span: 8, offset: 6 }} align='left'>
                      <p style={{ fontWeight: 100 }}><span className='icon-inline'>{PromoTokenIcon}</span> PROMO availability </p>
                    </Col>
                    <Col sm={8} align='left'>
                      {GreenCircleCheckmarkIconSmall}
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} className='margin-y-20'>
                    <Col sm={{ span: 8, offset: 6 }} align='left'>
                      <p style={{ fontWeight: 100 }}><span className='icon-inline'>{BinanceTokenIcon}</span> BNB availability </p>
                    </Col>
                    <Col sm={8} align='left'>
                      {GreenCircleCheckmarkIconSmall}
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} className='margin-y-20'>
                    <Col sm={{ span: 8, offset: 6 }} align='left'>
                      <p style={{ fontWeight: 100, marginLeft: 10 }}><span className='icon-inline'>{WalletBlackIcon}</span> Eligibility for staking  </p>
                    </Col>
                    <Col sm={8} align='left'>
                      {GreenCircleCheckmarkIconSmall}
                    </Col>
                  </Row>
                </Col>
                <Col sm={{ span: 24 }} align='center' style={{
                  backgroundColor: 'rgba(163, 230, 53, 0.25)',
                  marginTop: 30,
                  paddingTop: 18,
                  paddingBottom: 8
                }}>
                  <p>You are ready for staking</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={24} md={12}>
            {/* <p>Please check if all the requirement are completed</p> */}
            <div className='stake-promo-steps-div'>
              <Row style={{
                borderBottom: '1px solid rgba(40,61,92,0.50)',
                padding: '4px 4px'
              }}>
                <Col sm={24} align='center'>
                  <p style={{ fontWeight: 700 }}>Stake PROMO</p>
                </Col>
              </Row>
              <Row gutter={[0, 24]}>
                <Col sm={{ span: 24 }} align='center' style={{ padding: '24px 24px' }}>
                  <br />
                  <small style={{ textAlign: 'left' }}>Your wallet address</small>
                  <p style={{
                    fontWeight: 100,
                    border: '1px solid rgba(40,61,92,0.50)',
                    borderRadius: 8,
                    padding: '4px 4px',
                    textAlign: 'left',
                  }}><span className='icon-inline'>{WalletBlackIcon}</span> {this.props.promodexInformation?.metamaskAccount} </p>

                </Col>
                <Col sm={{ span: 24 }} align='center'>
                  <p style={{ textAlign: 'center', lineHeight: 'normal', fontSize: 12 }}>Enter amount of promo to stake</p>
                  <Row gutter={[24, 24]}>
                    <Col sm={{ span: 8, offset: 4 }} align='right'>
                      <div style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>
                        <Row>
                          <Col sm={6} style={{ padding: 4 }}>
                            <p style={{
                              padding: '4px 8px',
                              border: '1px solid rgba(40,61,92,0.10)',
                              margin: 0,
                              borderRadius: 5,
                              fontSize: 12,
                              fontWeight: 100,
                              color: '#283D5C',
                              background: '#FAFBFE',
                              textAlign: 'center',
                              display: 'inline-block',
                              lineHeight: 'normal'
                            }}>
                              MAX
                            </p>
                          </Col>
                          <Col sm={{ span: 14, offset: 1 }} style={{ padding: 4 }} align='right'>
                            <Input className='input-border-style' type='number' placeholder="500" size='small' />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={4} align='right'>
                      <p style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>PROMO</p>
                    </Col>
                  </Row>
                </Col>
                <Col sm={{ span: 24 }} align='center'>
                  <p style={{ textAlign: 'center', lineHeight: 'normal', fontSize: 12 }}>Enter period to stake</p>
                  <Row gutter={[24, 24]}>
                    <Col sm={{ span: 8, offset: 4 }} align='right'>
                      <div style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>
                        <Row>
                          <Col sm={6} style={{ padding: 4 }}>
                            <p style={{
                              padding: '4px 8px',
                              border: '1px solid rgba(40,61,92,0.10)',
                              margin: 0,
                              borderRadius: 5,
                              fontSize: 12,
                              fontWeight: 100,
                              color: '#283D5C',
                              background: '#FAFBFE',
                              textAlign: 'center',
                              display: 'inline-block',
                              lineHeight: 'normal'
                            }}>
                              MAX
                            </p>
                          </Col>
                          <Col sm={{ span: 14, offset: 1 }} style={{ padding: 4 }} align='right'>
                            <Input className='input-border-style' type='number' placeholder="365" size='small' />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={4} align='right'>
                      <p style={{
                        border: '1px solid rgba(40,61,92,0.25)',
                        borderRadius: 8,
                        textAlign: 'center'
                      }}>Days</p>
                    </Col>
                  </Row>
                </Col>
                <Col sm={{ span: 24 }} align='center' className='margin-top-20' style={{ backgroundColor: 'rgba(163, 230, 53, 0.25)' }}>
                  {this.state.authorizeContract === 0 ?
                    <p className='margin-y-10'>Amount of PROMO and Staking period is defined <br /> Now authorize staking contract</p>
                    :
                    this.state.authorizeContract === 1 ?
                      <p className='margin-y-10'>Staking contract is approved <br /> Now confirm staking PROMO</p>
                      :
                      this.state.authorizeContract === 2 ?
                        <p className='margin-y-10'>Staking confirmed <br /> Now finalize the transaction</p>
                        : <p className='margin-y-10'>Welldone <br /> You finalize the transaction</p>
                  }
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className='margin-y-20' gutter={[24, 24]}>
          <Col sm={{ span: 12 }} align='center'>
            <Button onClick={() => this.props.changeTab('1')} className="connect-wallet-btn" size="medium">
              <AiOutlineArrowLeft className='vertical-align-text-bottom' size={20} style={{ marginRight: 10 }} />
              Back
            </Button>
          </Col>
          <Col sm={{ span: 12 }}>
            <Row>
              <Col sm={24} lg={{ span: 12 }} align='center'>
                <Button className={this.state.authorizeContract === 0 ? "colorful-button nowrap" : "colorless-button nowrap"}
                  size="medium" style={{ paddingTop: 5 }} onClick={this.handleAuthorizeContract}>
                  Authorize Contract
                </Button>
              </Col>
              <Col sm={24} lg={12} align='center'>
                <Button className={this.state.authorizeContract === 1 ? "colorful-button nowrap" : "colorless-button nowrap"}
                  size="medium" style={{ paddingTop: 5 }} onClick={this.handleConfirmStaking}>
                  Confirm Staking
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {BottomButtons}
      </div>
    )
  }
}



const mapState = (globalState) => {
  return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(Unstaking));

import React, {Component} from 'react';
import PromodexLayout from "../../components/layout/PromodexLayout";
import StatisticsContent from "./StatisticsContent";

class StatisticsLayout extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <PromodexLayout page='statistics' content={<StatisticsContent/>}/>
            </div>
        );
    }
}

export default StatisticsLayout;
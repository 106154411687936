import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, Image, Row, Select, Menu, Dropdown, Modal, Alert, notification, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setPromodexRedux } from "../../redux/promodex/actions";
import PromodexLogoHeader from "../../assets/images/logo/Promodex-350x195-cropped.png";
import Layout, { Content, Footer, Header } from "antd/es/layout/layout";
import {
    AiOutlineMenuFold,
    AiOutlineMenuUnfold,
    AiOutlineDown,
    AiOutlineSearch,
    AiOutlineUser,
    AiOutlinePlayCircle
} from "react-icons/ai";
import { VscHome } from "react-icons/vsc";
import { MdOutlineCampaign, MdOutlineInventory, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { IoWalletOutline } from 'react-icons/io5';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import blueP from "../../assets/images/logo/Promodex_Blue_icon_512x512px_opt.png"



import { useWeb3React } from '@web3-react/core';
import { injected } from '../../web3/connectors/Injected';
import Web3 from 'web3';

import { AddPromoToMetamask } from '../../web3/utils/AddToMetamask';
import { logout } from '../../pages/login/api';
import { getBUSDBalance, getMAINBalance } from "../../web3/contractService/service";

import {
    Youtube, Linkedin, Facebook, Instagram, Twitter, Telegram, Medium,
    Theme, Metamask, MetamaskConnection, TrustWalletConnection,
    WalletConnectConnection, RecomendWallet, ConnectedWallet, Binance,
    PromoToken, BinanceToken, WalletOutline, StakePromo, DisconnectWallet, SignOut, HeaderHome, HeaderInvestor, HeaderPromoter, HeaderCampaigner
} from './Icons';
import { privacyPolicy, userAgreement } from '../../utils/Constants';

const YoutubeIcon = Youtube()
const LinkedinIcon = Linkedin()
const FacebookIcon = Facebook()
const InstagramIcon = Instagram()
const TwitterIcon = Twitter()
const TelegramIcon = Telegram()
const MediumIcon = Medium()
const ThemeIcon = Theme()
const HeaderHomeIcon = HeaderHome()
const HeaderInvestorIcon = HeaderInvestor()
const HeaderPromoterIcon = HeaderPromoter()
const HeaderCampaignerIcon = HeaderCampaigner()
const MetamaskIcon = Metamask()
const MetamaskConnectionButton = MetamaskConnection()
const TrustWalletConnectionButton = TrustWalletConnection()
const WalletConnectConnectionButton = WalletConnectConnection()
const RecomendWalletButton = RecomendWallet()
const BinanceIcon = Binance()
const PromoTokenIcon = PromoToken()
const BinanceTokenIcon = BinanceToken()
const WalletIcon = WalletOutline()
const StakePromoIcon = StakePromo()
const DisconnectWalletButton = DisconnectWallet()
const SignOutButton = SignOut()


const { Option } = Select;

const MyAccountMenuCss = {
    color: '#000659',
    borderBottom: '1px solid rgba(0,6,89,0.10)'
}

// Trust wallet entagration
const trustConnector = new WalletConnect({
    bridge: "https://bridge.walletconnect.org", // Required
    qrcodeModal: QRCodeModal,
});


const PromodexLayout = (props) => {
    const { active, account, activate, library, deactivate, connector } = useWeb3React();

    const [language, setLanguage] = useState('en')
    const [collapsed, setCollapsed] = useState(false)
    const [innerWidth, setInnerWidth] = useState(0)
    const [isConnectWalletModalVisible, setIsConnectWalletModalVisible] = useState(false)
    const [showWalletAllert, setShowWalletAllert] = useState(false)
    const [isComingSoonModalVisible, setIsComingSoonModalVisible] = useState(null)
    const [isPromoterModalVisible, setIsPromoterModalVisible] = useState(null)
    const [isCampaignerModalVisible, setIsCampaignerModalVisible] = useState(null)
    const [isInvestorVideoModalVisible, setIsInvestorVideoModalVisible] = useState(null)
    const [mobileHeaderChange, setMobileHeaderChange] = useState()
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user')))
    const [busdBalance, setBusdBalance] = useState(0)
    const [promoBalance, setPromoBalance] = useState(0)
    const [binanceBalance, setBinanceBalance] = useState(0)
    const [chainId, setChainId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [trustAccount, setTrustAccount] = useState(null)



    let busdBalanceIndex = 0
    const changeBusdBalance = async () => {
        if (busdBalanceIndex === 0) {
            let balance = await getBUSDBalance(account)
            setBusdBalance(balance)
            busdBalanceIndex += 1

            props.setPromodexRedux({ busdBalance: balance })
        }
    }

    let promoBalanceIndex = 0
    const changePromoBalance = async () => {
        if (promoBalanceIndex === 0) {
            let balance = await getMAINBalance(account)
            setPromoBalance(balance)
            promoBalanceIndex += 1

            props.setPromodexRedux({ promoBalance: balance })

        }
    }

    let binanceBalanceIndex = 0
    const changeBinanceBalance = () => {
        if (binanceBalanceIndex === 0) {
            const web3 = new Web3(window.ethereum);
            web3.eth.getBalance(account).then(response => {
                const balance = web3.utils.fromWei(response)
                setBinanceBalance(balance)
                promoBalanceIndex += 1

                props.setPromodexRedux({ bnbBalance: balance })
            })
        }
    }

    const notification_ = (title, description, type) => {
        notification[type]({
            message: title,
            description: description,
            duration: 0
        });
    }
    const notificationWithLink = (title, description, url, type) => {
        notification[type]({
            message: title,
            description: <a href={url} rel='noopener noreferrer' target='_blank' style={{ color: '#5340FF' }}>{description}</a>,
            duration: 0
        });
    }




    const connectTrustWallet = () => {

        trustConnector.createSession();

        // if (!connector.connected) {
        //     // create new session
        //     connector.createSession();
        // }






    }


    useEffect(() => {

        trustConnector.on("connect", (error, payload) => {
            if (error) {
                throw error;
            }

            // Get provided accounts and chainId
            const { accounts, chainId } = payload.params[0];

            if (chainId !== 56) {
                notification_("Information", "Please Binance Smart Chain Network", "info")
                trustConnector.killSession()
            } else {
                notification_("Information", "Trust wallet account connected", "success")
                props.setPromodexRedux({ metamaskAccount: accounts })
                setTrustAccount(accounts)

                // trustConnector.killSession()
            }


        });


        trustConnector.on("session_update", (error, payload) => {
            if (error) {
                throw error;
            }

            // Get updated accounts and chainId
            const { accounts, chainId } = payload.params[0];
            console.log(accounts, chainId)

        });

        trustConnector.on("disconnect", (error, payload) => {
            if (error) {
                throw error;
            }
            notification_("Information", "Trust wallet disconnect", "warning")
            setTrustAccount(null)

        });


        if (!trustConnector.connected) return

        //trust wallet tüm bilgiler bu datada
        console.log(trustConnector.session)


        setTrustAccount(trustConnector.session.accounts[0])


    }, [])

    const comingSoonModal = () => {
        setIsComingSoonModalVisible(!isComingSoonModalVisible)
    }
    const comingSoonModalPromoter = () => {
        setIsPromoterModalVisible(!isPromoterModalVisible)
    }
    const comingSoonModalCampaigner = () => {
        setIsCampaignerModalVisible(!isCampaignerModalVisible)
    }

    const investorVideoModal = () => {
        setIsInvestorVideoModalVisible(!isInvestorVideoModalVisible)
    }

    useEffect(() => {
        if (!active) return
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => {
                props.setPromodexRedux({ metamaskAccount: accounts[0] })
                busdBalanceIndex = 0
                promoBalanceIndex = 0
                binanceBalanceIndex = 0
                if (account) {
                    changeBusdBalance()
                    changePromoBalance()
                    changeBinanceBalance()
                }

            })
        }

    }, [])

    useEffect(() => {
        if (!active) return
        window.ethereum.on('chainChanged', (chainId) => {
            setChainId(chainId)
            busdBalanceIndex = 0
            promoBalanceIndex = 0
            binanceBalanceIndex = 0
            if (account) {
                changeBusdBalance()
                changePromoBalance()
                changeBinanceBalance()
            }

            if (chainId !== '0x38') {
                // NOT BINANCE CHAIN ID
                setBusdBalance(0)
                notification_('Chain Changed!', 'You are not in "Binance Smart Chain" you cant buy promo with this network!', 'warning')
                notificationWithLink('Connecting To Metamask', 'Connecting MetaMask to Binance Smart Chain',
                    'https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain', 'info')
            }
        })

    }, [])


    useEffect(() => {
        setInnerWidth(window.innerWidth)
    }, [window.innerWidth])

    useEffect(() => {
        props.setPromodexRedux({ isProjectLoaded: true })
    }, [])


    useEffect(async () => {
        if (trustConnector.connected) return
        if (account) {
            document.getElementById("trigger").click()
            props.setPromodexRedux({ metamaskAccount: account })
            window.sessionStorage.setItem('metamaskAccount', account)

            setTimeout(() => {
                changeBusdBalance()
                changePromoBalance()
                changeBinanceBalance()
            }, 2500)
        }
        if (!account) {
            const account_ = window.sessionStorage.getItem('metamaskAccount')
            if (account_) {
                try {
                    await activate(injected);
                } catch (ex) {
                    console.log('CONNECT EX : ', ex)
                }
            }
        }

    }, [account])


    // toggle = () => {
    //     this.setState({collapsed: !this.state.collapsed});
    // };

    // This function will handle the mobile header toggle
    const rightMenu = () => {
        setMobileHeaderChange(!mobileHeaderChange)
    }

    const connectWalletModal = () => {
        setIsConnectWalletModalVisible(!isConnectWalletModalVisible)
    }


    const connectMetamask = async () => {
        try {
            await activate(injected);
            if (chainId !== null && chainId !== '0x38') {
                // NOT BINANCE CHAIN ID
                setBusdBalance(0)
                notification_('Not Binance Smart Chain!', 'You are not in "Binance Smart Chain" you cant buy promo without "Binance Smart Chain" network!', 'warning')
                notificationWithLink('Connecting To Metamask', 'Connecting MetaMask to Binance Smart Chain',
                    'https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain', 'info')
                connectWalletModal()
                return;
            }
            connectWalletModal()

        } catch (ex) {
            console.log('CONNECT EX : ', ex)
        }
    }

    const disconnectWallet = () => {
        if (trustConnector.connected) {
            trustConnector.killSession()
        } else {
            try {
                deactivate();
                sessionStorage.removeItem('metamaskAccount')
                window.location.reload(true);
            } catch (ex) {
                console.log('DISCONNECT EX : ', ex)
            }
        }

    }


    const handleConnectWallet = (walletType) => {
        if (window.ethereum) {
            if (walletType === 'metamask' && window.ethereum.isMetaMask) {
                connectMetamask()
            }
            if (walletType === 'trustWallet') {
                connectTrustWallet()
            }
        } else {
            if (innerWidth < 600) {
                notification_('Not Metamask Browser!', 'You need to open webpage in metamask application', 'error')
            } else {
                notification_('Not Installed!', 'You need to install metamask first', 'error')
            }
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(account).then(res => {
            notification.open({
                message: 'COPIED',
                description:
                    '',
                duration: 3
            });
        })
            .catch(err => {
                alert('HATA')
            })
    }

    // This func will get string and convert that string a shorter version
    // e.g : 1ASF1jjk24jk34F423  -> 1ASF...F423
    const compressString = (string) => {
        const firstPart = string.slice(0, 4)
        const lastPart = string.slice(-4);
        const finalStr = firstPart + "..." + lastPart
        return finalStr
    }



    const ConnectedWalletButton = ConnectedWallet(compressString(String(account ? account : trustAccount)), handleCopy, BinanceIcon)


    const SignOut = () => {
        logout()
            .then(response => {
                localStorage.removeItem('user')
                window.location.reload(true);
            })
            .catch(error => {
                console.log('ERROR : ', error)
                localStorage.removeItem('user')
                window.location.reload(true);
            })
    }


    return (
        <div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}>
                {/** Layout Header*/}
                {innerWidth > 1000 ?
                    //    WEB HEADER
                    <Row style={{ zIndex: 2, marginBottom: 20 }}>

                        <Col sm={{ span: 20, offset: 2 }}>
                            <Header
                                style={{
                                    width: "100%",
                                    height: "12%",
                                    padding: "0px 0px 0px 0px",
                                    backgroundColor: "white",
                                }}
                            >
                                <Row className='margin-top-10'>
                                    <Col sm={{ span: 3 }}>
                                        <Select className='borderless-selectbox' defaultValue="Community" size='small'
                                            suffixIcon={<AiOutlineDown style={{ color: '#000' }} />}>
                                            <Option value="community">
                                                <a target='_blank' href='https://t.me/promodexio_global'>
                                                    <h6>Community</h6>
                                                </a>
                                            </Option>
                                            <Option value="announcement">
                                                <a target='_blank' href='https://t.me/promodexio'>
                                                    <h6>Announcement</h6>
                                                </a>
                                            </Option>
                                        </Select>
                                    </Col>
                                    <Col sm={{ span: 3 }}>
                                        <a target='_blank' href='https://www.linkedin.com/company/promodexio/'>
                                            {LinkedinIcon}
                                        </a>
                                        <a target='_blank' href='https://twitter.com/promodexio'>
                                            {TwitterIcon}
                                        </a>
                                        <a target='_blank' href='https://promodex.medium.com/'>
                                            {MediumIcon}
                                        </a>
                                    </Col>
                                    <Col sm={{ span: 18, offset: 0 }}>
                                        <ul className="linkMenu" style={{ float: 'right' }}>
                                            <li className="linkMenu">
                                                <AiOutlineSearch size={24} onClick={comingSoonModal}
                                                    style={{
                                                        color: '#5340ff',
                                                        marginTop: 2,
                                                        cursor: 'pointer'
                                                    }} />
                                            </li>
                                            <li className="linkMenu">
                                                {/* <Dropdown overlay={null
                                                // <Menu className='circle-language-menu'>
                                                //     <Button className='circle-language-button'>
                                                //         EN
                                                //     </Button><br />
                                                //     <Button className='circle-language-button'>
                                                //         EN
                                                //     </Button><br />
                                                //     <Button className='circle-language-button'>
                                                //         EN
                                                //     </Button><br />
                                                // </Menu>
                                            } trigger={['click']}>
                                                
                                            </Dropdown> */}
                                                <Button className='circle-language-button'>
                                                    EN
                                                </Button>
                                            </li>
                                            <li className="linkMenu">
                                                <span style={{ cursor: 'pointer' }} onClick={comingSoonModal}>{ThemeIcon}</span>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row className='margin-top-20'>
                                    <Col sm={6}>
                                        <Link to="/">
                                            <Image
                                                width={250}
                                                preview={false}
                                                src={PromodexLogoHeader}
                                            />
                                        </Link>
                                    </Col>
                                    <Col sm={{ span: 18 }}>
                                        <Row>
                                            <Col xl={3}>
                                                <Link to="/" className="linkMenu" style={{
                                                    fontSize: '14px'
                                                }}>
                                                    {HeaderHomeIcon}&nbsp; HOME
                                                </Link>
                                            </Col>
                                            <Col xl={3}>
                                                <Link to="/investor" className="linkMenu" style={{
                                                    fontSize: '14px'
                                                }}>
                                                    {HeaderInvestorIcon}&nbsp; 
                                                    Investor
                                                </Link>
                                            </Col>
                                            <Col xl={3}>
                                                <p onClick={comingSoonModalPromoter} className="linkMenu" style={{
                                                    fontSize: '14px',
                                                    cursor: 'pointer'
                                                }}>
                                                    {HeaderPromoterIcon}&nbsp; 
                                                    Promoter
                                                </p>
                                            </Col>
                                            <Col xl={4}>
                                                <p onClick={comingSoonModalCampaigner} className="linkMenu" style={{
                                                    fontSize: '14px',
                                                    cursor: 'pointer'
                                                }}>
                                                    {HeaderCampaignerIcon}&nbsp; Campaigner
                                                </p>
                                            </Col>
                                            <Col xl={6} align="right">
                                                {account || trustAccount ?
                                                    <Dropdown overlay={
                                                        <Menu>
                                                            <Menu.Item key={'1'}>
                                                                <Row>
                                                                    <Col sm={{ span: 4 }}>
                                                                        {PromoTokenIcon}
                                                                    </Col>
                                                                    <Col sm={{ span: 14 }}>
                                                                        {promoBalance} Promo
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'2'}>
                                                                <Row>
                                                                    <Col sm={{ span: 4 }}>
                                                                        {BinanceTokenIcon}
                                                                    </Col>
                                                                    <Col sm={{ span: 14 }}>
                                                                        {busdBalance} BUSD
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'3'}>
                                                                <Row>
                                                                    <Col sm={{ span: 4 }}>
                                                                        {BinanceTokenIcon}
                                                                    </Col>
                                                                    <Col sm={{ span: 14 }}>
                                                                        {binanceBalance} BNB
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'4'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col sm={{ span: 4 }}>
                                                                        {WalletIcon}
                                                                    </Col>
                                                                    <Col sm={{ span: 14 }}>
                                                                        My Wallet
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'5'} onClick={() => props.history.push('/stakepromo')}>
                                                                <Row>
                                                                    <Col sm={{ span: 4 }}>
                                                                        {StakePromoIcon}
                                                                    </Col>
                                                                    <Col sm={{ span: 14 }}>
                                                                        Stake Promo
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'6'}>
                                                                <Row>
                                                                    <Col sm={{ span: 12, offset: 6 }}>
                                                                        <div className="dissconnect-walet-btn" onClick={disconnectWallet}>
                                                                            {DisconnectWalletButton}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                        </Menu>} placement="bottomCenter" arrow icon={<AiOutlineDown />}>
                                                        <div style={{ marginTop: 10 }} className="connected-wallet"
                                                            onMouseEnter={() => {
                                                                //changeBusdBalance()
                                                                //changePromoBalance()
                                                                //changeBinanceBalance()
                                                            }}>
                                                            {ConnectedWalletButton}
                                                        </div>
                                                    </Dropdown> :
                                                    <Button className="margin-top-10 connect-wallet-btn" size="large" onClick={connectWalletModal}>
                                                        <IoWalletOutline className='vertical-align-text-bottom' size={20} style={{ marginRight: 10 }} />
                                                        Connect Wallet
                                                    </Button>
                                                }
                                            </Col>
                                            <Col xl={5} align="right" style={{ marginTop: "-2px" }}>
                                                {user ?
                                                    <Dropdown overlay={
                                                        <Menu>
                                                            <Menu.Item key={'01'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col sm={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Activities
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'02'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col sm={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Campaigns
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'03'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col sm={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Referrals
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'04'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col sm={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Profile
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'05'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col sm={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Settings
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'06'}>
                                                                <Row>
                                                                    <Col sm={{ span: 24, offset: 0 }} align='center'>
                                                                        <div className="signOut-btn" onClick={SignOut}>
                                                                            {SignOutButton}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                        </Menu>} placement="bottomCenter" arrow icon={<AiOutlineDown />}>
                                                        <Button className="margin-top-10 colorful-button nowrap" size="large"
                                                            style={{ marginTop: account ? 14 : 10 }}>
                                                            My Account
                                                            <AiOutlineUser className='icon-inline' size={20}
                                                                style={{
                                                                    marginLeft: 10,
                                                                    borderRadius: '50%',
                                                                    border: '1px solid #FFFFFF'
                                                                }} />
                                                        </Button>
                                                    </Dropdown> :
                                                    <a href='/login' className="margin-top-10 colorful-button nowrap" size="large">
                                                        Sign In / Register
                                                    </a>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* Connect Wallet Modal */}
                                <Modal className='connect-wallet-modal' title="" visible={isConnectWalletModalVisible} maskClosable={true}
                                    onCancel={connectWalletModal}
                                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
                                    <Row>
                                        <Col sm={{ span: 10, offset: 1 }}>
                                            <p style={{
                                                fontFamily: 'Helvetica-Bold',
                                                fontSize: 18,
                                                color: '#5340FF'
                                            }}>Connect your wallet</p>
                                        </Col>
                                        <Col sm={{ span: 8, offset: 0 }}>
                                            {showWalletAllert &&
                                                // Show Wallet alert for TrustWallet, WalletConnect and recommend a wallet
                                                <Alert message="Comming Soon..." type='info' style={{ textAlign: 'center', position: 'fixed', width: '200px', zIndex: '100' }} />
                                            }
                                        </Col>
                                    </Row>
                                    <Row gutter={[0, 24]}>
                                        <Col sm={{ span: 10, offset: 1 }}>
                                            <div className='connect-wallet-buttons' onClick={() => handleConnectWallet('metamask')}>
                                                {MetamaskConnectionButton}
                                            </div>
                                        </Col>
                                        <Col sm={{ span: 10, offset: 1 }}>
                                            <div className='connect-wallet-buttons' onClick={() => handleConnectWallet('trustWallet')}>
                                                {TrustWalletConnectionButton}
                                            </div>
                                        </Col>
                                        <Col sm={{ span: 10, offset: 1 }}>
                                            <div className='connect-wallet-buttons' onClick={comingSoonModal}>
                                                {WalletConnectConnectionButton}
                                            </div>
                                        </Col>
                                        <Col sm={{ span: 10, offset: 1 }}>
                                            <div className='connect-wallet-buttons' onClick={comingSoonModal}>
                                                {RecomendWalletButton}
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal>
                            </Header>
                        </Col>
                    </Row>
                    :
                    // MOBILE HEADER
                    <>
                        <Header style={{ width: '100%', backgroundColor: "white" }}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={23}>
                                    <a href="/">
                                        <Image preview={false}
                                            width={150}
                                            style={{
                                                objectFit: "cover",
                                                maxWidth: "100%",
                                                marginTop: 10,
                                                marginLeft: "-30px",
                                            }}
                                            src={PromodexLogoHeader} />
                                    </a>
                                </Col>
                                <Col span={1}>
                                    {mobileHeaderChange !== true ?
                                        <AiOutlineMenuFold className="mobileMenu" size={40} onClick={rightMenu} />
                                        :
                                        <AiOutlineMenuUnfold className="mobileMenu" size={40} onClick={rightMenu} />
                                    }
                                </Col>
                            </Row>
                        </Header>
                        <Drawer
                            placement="right"
                            closable={false}
                            onClose={rightMenu}
                            visible={mobileHeaderChange}
                            key="left">
                            <Row gutter={[16, 24]}>
                                <Col xs={24}>

                                    <AiOutlineMenuUnfold className="mobileMenu" size={40} onClick={rightMenu} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs={24} align={'center'}>
                                    <ul className="linkMenu">
                                        <li className="linkMenuMobile"><a href="/"
                                            className="linkMenu"><Image preview={false}
                                                src={PromodexLogoHeader} /></a></li>
                                        <li className="linkMenuMobile margin-top-20 margin-y-10"><a href="/"
                                            className="linkMenu">Homepage</a></li>
                                        <li className="linkMenuMobile margin-y-10"><a href='/investor'
                                            className="linkMenu">Investor</a></li>
                                        <li className="linkMenuMobile margin-y-10"><a onClick={comingSoonModalPromoter}
                                            className="linkMenu">Promoter</a></li>
                                        <li className="linkMenuMobile margin-y-10"><a onClick={comingSoonModalCampaigner}
                                            className="linkMenu">Campaigner</a></li>
                                        {/* <li className="linkMenuMobile margin-y-40">
                                        <Button onClick={() => handleConnectWallet('metamask')} className="margin-top-20 connect-wallet-btn" size="large">connect
                                            wallet</Button>
                                    </li> */}
                                        <li className='linkMenuMobile margin-y-40'>
                                            {account || trustAccount ?
                                                <Dropdown trigger={['click']} overlay={
                                                    <Menu>
                                                        <Menu.Item key={'001'}>
                                                            <Row>
                                                                <Col sm={{ span: 4 }}>
                                                                    {PromoTokenIcon}
                                                                </Col>
                                                                <Col sm={{ span: 14 }}>
                                                                    120 Promo
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Button className="connect-wallet-btn" style={{ height: '80%', fontSize: 10, padding: '0px 4px' }} size="small">
                                                                        Buy more
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Menu.Item>
                                                        <Menu.Item key={'002'}>
                                                            <Row>
                                                                <Col sm={{ span: 4 }}>
                                                                    {BinanceTokenIcon}
                                                                </Col>
                                                                <Col sm={{ span: 14 }}>
                                                                    0.02 BNB
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Button className="connect-wallet-btn" style={{ height: '80%', fontSize: 10, padding: '0px 4px' }} size="small">
                                                                        Buy more
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Menu.Item>
                                                        <Menu.Item key={'003'}>
                                                            <Row>
                                                                <Col sm={{ span: 4 }}>
                                                                    {WalletIcon}
                                                                </Col>
                                                                <Col sm={{ span: 14 }}>
                                                                    My Wallet
                                                                </Col>
                                                            </Row>
                                                        </Menu.Item>
                                                        <Menu.Item key={'004'}>
                                                            <Row>
                                                                <Col sm={{ span: 4 }}>
                                                                    {StakePromoIcon}
                                                                </Col>
                                                                <Col sm={{ span: 14 }}>
                                                                    Stake Promo
                                                                </Col>
                                                            </Row>
                                                        </Menu.Item>
                                                        <Menu.Item key={'005'}>
                                                            <Row>
                                                                <Col sm={{ span: 12, offset: 6 }}>
                                                                    <div className="dissconnect-walet-btn">
                                                                        {DisconnectWalletButton}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Menu.Item>
                                                    </Menu>} placement="bottomCenter" arrow icon={<AiOutlineDown />}>
                                                    <div style={{ marginTop: 10 }} className="connected-wallet">
                                                        {ConnectedWalletButton}
                                                    </div>
                                                </Dropdown> :
                                                <Button className="margin-top-10 connect-wallet-btn" size="large" onClick={() => handleConnectWallet('metamask')}>
                                                    <IoWalletOutline className='vertical-align-text-bottom' size={20} style={{ marginRight: 10 }} />
                                                    Connect Wallet
                                                </Button>
                                            }
                                        </li>
                                        <li className="linkMenuMobile margin-y-10">
                                        {user ?
                                                    <Dropdown trigger={['click']} overlay={
                                                        <Menu>
                                                            <Menu.Item key={'01'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col xs={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Activities
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'02'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col xs={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Campaigns
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'03'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col xs={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Referrals
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'04'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col xs={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Profile
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'05'} onClick={comingSoonModal}>
                                                                <Row>
                                                                    <Col xs={{ span: 24 }} align='center' style={MyAccountMenuCss}>
                                                                        Settings
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                            <Menu.Item key={'06'}>
                                                                <Row>
                                                                    <Col xs={{ span: 24, offset: 0 }} align='center'>
                                                                        <div className="signOut-btn" onClick={SignOut}>
                                                                            {SignOutButton}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Menu.Item>
                                                        </Menu>} placement="bottomCenter" arrow icon={<AiOutlineDown />}>
                                                        <Button className="margin-top-10 colorful-button nowrap" size="large"
                                                            style={{ marginTop: account ? 14 : 10 }}>
                                                            My Account
                                                            <AiOutlineUser className='icon-inline' size={20}
                                                                style={{
                                                                    marginLeft: 10,
                                                                    borderRadius: '50%',
                                                                    border: '1px solid #FFFFFF'
                                                                }} />
                                                        </Button>
                                                    </Dropdown> :
                                                    <a href='/login' className="margin-top-10 colorful-button nowrap" size="large">
                                                        Sign In / Register
                                                    </a>
                                                }
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className='margin-top-20' gutter={[4, 16]}
                                style={{
                                    position: 'absolute',
                                    bottom: 10,
                                }}>
                                <Col xs={24} align="center">
                                    <a target='_blank' href='https://www.youtube.com/channel/UCEtCnSnFlHqgB6woV4HVP4w'>
                                        {YoutubeIcon}
                                    </a>
                                    <a target='_blank' href='https://www.linkedin.com/company/promodexio/'>
                                        {LinkedinIcon}
                                    </a>
                                    <a target='_blank' href='https://www.facebook.com/promodexio/'>
                                        {FacebookIcon}
                                    </a>
                                    <a target='_blank' href='https://www.instagram.com/promodexio/'>
                                        {InstagramIcon}
                                    </a>
                                    <a target='_blank' href='https://twitter.com/promodexio'>
                                        {TwitterIcon}
                                    </a>
                                    <a target='_blank' href='https://t.me/promodexio_global?utm_medium=social&utm_source=linktree&utm_campaign=community+channel'>
                                        {TelegramIcon}
                                    </a>
                                    <a target='_blank' href='https://promodex.medium.com/'>
                                        {MediumIcon}
                                    </a>
                                </Col>
                                <Col xs={24}>
                                    <Row>
                                        <Col xs={12} align='center'>
                                            <a target='_blank' href={userAgreement} className='font-size-12'>Terms of Use</a>
                                        </Col>
                                        <Col xs={12} align='center'>
                                            <a target='_blank' href={privacyPolicy} className='font-size-12'>Privacy Policy</a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Drawer>
                    </>
                }

                {/* INVESTOR VIDEOLU MODAL */}
                <Modal className='' title="" visible={isInvestorVideoModalVisible} maskClosable={true}
                    onCancel={investorVideoModal}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>

                    <Row style={{ marginTop: '20px' }}>
                        <Col sm={24} align='center'>
                            <iframe width="373" height="210" src="https://www.youtube.com/embed/4Ml73PfMBmk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Col>
                    </Row>

                </Modal>

                {/* PROMOTER VIDEOLU MODAL */}
                <Modal className='' title="" visible={isPromoterModalVisible} maskClosable={true}
                    onCancel={comingSoonModalPromoter}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
                    <Row align='bottom' style={{ marginBottom: 40, marginTop: 10 }}>
                        <Col sm={4} align='left' value={0}>
                            <Image
                                style={{}}
                                width={80}
                                preview={false}
                                src={blueP}
                            />
                        </Col>
                        <Col sm={16} align='left' value={50}>
                            <p style={{
                                textAlign: 'left',
                                fontSize: 24,
                                color: '#5340ff'
                            }}>
                                This feature is coming soon
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '-50px' }}>
                        <Col sm={24} align='center'>
                            <iframe width="373" height="210" src="https://www.youtube.com/embed/4Ml73PfMBmk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Col>
                    </Row>

                </Modal>

                {/* CAMPAIGNER VIDEOLU MODAL */}
                <Modal className='' title="" visible={isCampaignerModalVisible} maskClosable={true}
                    onCancel={comingSoonModalCampaigner}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
                    <Row align='bottom' style={{ marginBottom: 40, marginTop: 10 }}>
                        <Col sm={4} align='left' value={0}>
                            <Image
                                style={{}}
                                width={80}
                                preview={false}
                                src={blueP}
                            />
                        </Col>
                        <Col sm={16} align='left' value={50}>
                            <p style={{
                                textAlign: 'left',
                                fontSize: 24,
                                color: '#5340ff'
                            }}>
                                This feature is coming soon
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '-50px' }}>
                        <Col sm={24} align='center'>
                            <iframe width="373" height="210" src="https://www.youtube.com/embed/8jmmOy_QE_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                        </Col>
                    </Row>

                </Modal>





                <Modal className='' title="" visible={isComingSoonModalVisible} maskClosable={true}
                    onCancel={comingSoonModal}
                    maskStyle={{ background: 'rgba(83, 64, 255, 0.12)' }} footer={null}>
                    <Row>
                        <Col sm={24} align='center' className='margin-y-40'>
                            <Image
                                style={{}}
                                width={100}
                                preview={false}
                                src={blueP}
                            /> <br />
                            <p style={{
                                textAlign: 'center',
                                fontSize: 24,
                                color: '#5340ff'
                            }}>
                                This feature is coming soon
                            </p>
                        </Col>
                    </Row>
                </Modal>

                <Layout className="site-layout" style={{ backgroundColor: 'white' }}>
                    <Content
                        style={{
                            backgroundColor: '#eff7fa',
                            margin: '0px 0px 0px 0px',
                            padding: '8px 0px',
                            minHeight: 280,
                        }}>
                        {props.content}
                    </Content>
                    <Footer style={{
                        borderTop: '1px solid #ececec',
                        backgroundColor: '#ffffff'
                    }}>
                        <Row gutter={[24, 24]}>
                            <Col sm={{ span: 20, offset: 2 }} md={{ span: 10, offset: 4 }} lg={{ span: 5, offset: 1 }}>
                                <Image src={PromodexLogoHeader} preview={false} width={96} style={{ verticalAlign: 'bottom' }} />
                                <h5 className='p-font-bold'> Programmatic Promotion Marketplace </h5>
                                <a target='_blank' href='https://coinmarketcap.com/'>
                                    <h5> See PROMO Statistics </h5>
                                </a>
                                <h5 onClick={AddPromoToMetamask} style={{ marginTop: '-10px', marginBottom: '-10px' }}> Add Promo to <span className='icon-inline'>{MetamaskIcon}</span></h5>
                                <h5> Token Explorer </h5>
                            </Col>
                            <Col sm={24} md={{ span: 10, offset: 0 }} lg={{ span: 5, offset: 1 }}>
                                <h4><strong>About</strong></h4>
                                <a target='_blank' href='https://drive.google.com/file/d/1krYUAkdyG2JZF327jIRj145XHkkmmkJb/view'>
                                    <h5>Whitepaper</h5>
                                </a>
                                <a target='_blank' href='https://drive.google.com/file/d/1LzNtTRYe0JTRD8Y1O5ni9vyCWL_7BFfd/view'>
                                    <h5>Tokenomics</h5>
                                </a>
                                <a target='_blank' href='https://drive.google.com/file/d/1PRmiRd-bfojkq4IVoQMSfznASqIn2asD/view'>
                                    <h5>Onepager</h5>
                                </a>
                                <a target='_blank' href='https://www.youtube.com/watch?v=ZnMfRoYBG6Q&ab_channel=promodexio'>
                                    <h5>Video</h5>
                                </a>

                            </Col>
                            <Col sm={24} md={{ span: 10, offset: 4 }} lg={{ span: 5, offset: 1 }}>
                                <h4><strong>Community</strong></h4>
                                <a target='_blank' href=''>
                                    <h5>Telegram/English</h5>
                                </a>
                                <a target='_blank' href=''>
                                    <h5>Telegram/Turkish</h5>
                                </a>
                                <a target='_blank' href=''>
                                    <h5>Telegram/Russian</h5>
                                </a>
                                <a target='_blank' href=''>
                                    <h5>Support</h5>
                                </a>
                            </Col>
                            <Col sm={24} md={{ span: 10, offset: 0 }} lg={{ span: 5, offset: 1 }}>
                                <h4><strong>General</strong></h4>
                                <a target='_blank' href='https://promodex.io/blog/'>
                                    <h5>Blog</h5>
                                </a>
                                <a target='_blank' href=''>
                                    <h5>Tier Model</h5>
                                </a>
                                <a target='_blank' href=''>
                                    <h5>Staking</h5>
                                </a>
                                <a target='_blank' href=''>
                                    <h5>FAQ</h5>
                                </a>
                            </Col>
                        </Row>
                        <Row className='margin-top-20'>
                            <Col xs={24} lg={{ span: 6, offset: 8 }}>
                                <Row style={{ marginLeft: 16 }}>
                                    <Col xs={12}>
                                        <a target='_blank' href={userAgreement} className='font-size-12'>Terms of Use</a>
                                    </Col>
                                    <Col xs={12}>
                                        <a target='_blank' href={privacyPolicy} className='font-size-12'>Privacy Policy</a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} lg={{ span: 5, offset: 5 }} align="center">
                                <a target='_blank' href='https://www.youtube.com/channel/UCEtCnSnFlHqgB6woV4HVP4w'>
                                    {YoutubeIcon}
                                </a>
                                {/* <a target='_blank' href=''>
                                {GithubIcon}
                            </a> */}
                                <a target='_blank' href='https://www.linkedin.com/company/promodexio/'>
                                    {LinkedinIcon}
                                </a>
                                <a target='_blank' href='https://www.facebook.com/promodexio/'>
                                    {FacebookIcon}
                                </a>
                                <a target='_blank' href='https://www.instagram.com/promodexio/'>
                                    {InstagramIcon}
                                </a>
                                <a target='_blank' href='https://twitter.com/promodexio'>
                                    {TwitterIcon}
                                </a>
                                <a target='_blank' href='https://t.me/promodexio_global?utm_medium=social&utm_source=linktree&utm_campaign=community+channel'>
                                    {TelegramIcon}
                                </a>
                                <a target='_blank' href='https://promodex.medium.com/'>
                                    {MediumIcon}
                                </a>
                            </Col>
                        </Row>
                    </Footer>
                </Layout>
            </Spin>
        </div>
    );
}

const mapState = (globalState) => {
    return { promodexInformation: globalState.promodexInformation };
};
export default connect(mapState, { setPromodexRedux })(withRouter(PromodexLayout));
import React, {Component} from 'react';
import PromodexLayout from "../../components/layout/PromodexLayout";
import ProfileContent from "./ProfileContent";

class ProfileLayout extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <PromodexLayout page='profile' content={<ProfileContent/>}/>
            </div>
        );
    }
}

export default ProfileLayout;
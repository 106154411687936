import { ADD_ROOM, REDUX_CHANGED } from "../constants/";

export const setSelectedRooms = (rooms) => {
  return {
    type: ADD_ROOM,
    rooms: rooms,
  };
};

export const setPromodexRedux = (promodexInformation) => {
  return {
    type: REDUX_CHANGED,
    promodexInformation: promodexInformation,
  };
};

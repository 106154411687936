import { ethers } from "ethers";

class SaleService {
  buy = async (values) => {
    let { ethereum, contract, cAddress, sender, amount, decimal, actionWith } =
      values;

    let _amount = ethers.utils.parseUnits(amount.toString(), decimal);
    let encoded = contract.methods.buy(_amount, actionWith).encodeABI();

    let tx = {
      from: sender,
      to: cAddress,
      data: encoded,
      nonce: "0x00",
    };

    let txHash = await ethereum
      .request({
        method: "eth_sendTransaction",
        params: [tx],
      })
      .catch((err) => console.log(err));

    return txHash;
  };

  getAddressBuyLimit = async (values) => {
    let { ethereum, contract, cAddress, sender, decimal, actionWith } = values;

    let _addressBuyLimit = await contract.methods
      .getAddressBuyLimit(actionWith, sender)
      .call()
      .then((limit) => limit);

    let addressBuyLimit = ethers.utils.formatUnits(_addressBuyLimit, decimal);
    let result =
      parseFloat(addressBuyLimit) > 1 ? parseFloat(addressBuyLimit) : 0;

    return result;
  };

  getAddressBuyLimitLeft = async (values) => {
    let { ethereum, contract, cAddress, sender, decimal, actionWith } = values;

    //İsimler değişiecek
    let _addressBuyLimitLeft = await contract.methods
      .getAddressBuyLimitLeft(actionWith, sender)
      .call()
      .then((limit) => limit);

    let addressBuyLimitLeft = ethers.utils.formatUnits(
      _addressBuyLimitLeft,
      decimal
    );

    let result =
      parseFloat(addressBuyLimitLeft) > 1 ? parseFloat(addressBuyLimitLeft) : 0;

    return result;
  };

  getLocksByWithdrawalAddress = async (values) => {
    let { ethereum, contract, cAddress, sender, decimal, actionWith } = values;

    let _depositIds = await contract.methods
      .getLocksByWithdrawalAddress(sender)
      .call()
      .then((result) => result);

    return _depositIds;
  };

  getLockDetails = async (values) => {
    let { ethereum, contract, cAddress, sender, decimal, actionWith } = values;

    let _depositIds = await contract.methods
      .getLockDetails(actionWith)
      .call()
      .then((result) => result);

    return _depositIds;
  };

  withdrawTokens = async (values) => {
    let { ethereum, contract, cAddress, sender, amount, decimal, actionWith } =
      values;

    let encoded = contract.methods.withdrawTokens(actionWith).encodeABI();

    let tx = {
      from: sender,
      to: cAddress,
      data: encoded,
      nonce: "0x00",
    };

    let txHash = await ethereum
      .request({
        method: "eth_sendTransaction",
        params: [tx],
      })
      .catch((err) => console.log(err));

    return txHash;
  };

  checkAddressWhiteListStatus = async (values) => {
    let { ethereum, contract, cAddress, sender, decimal, actionWith } = values;

    let _whiteListed = await contract.methods
      .getAddressWhiteListStatus(actionWith, sender)
      .call()
      .then((res) => res);

    return _whiteListed;
  };

  totalLockedAmounts = async (values) => {
    let { ethereum, contract, cAddress, sender, decimal, actionWith } = values;

    // decimail action with contractın decimali burda main contract !

    let _lockedAmount = await contract.methods
      .totalLockedAmounts(actionWith)
      .call()
      .then((res) => res);

    let lockedAmount = ethers.utils.formatUnits(_lockedAmount, decimal);

    return lockedAmount;
  };
}

export default new SaleService();
